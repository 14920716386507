import { Button, Dialog, IconButton } from "@material-ui/core";
import { AddCircleOutline, Cancel, EditOutlined } from "@material-ui/icons";
import Card from "components/Card/Card";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
} from "redux-form";

import { uploadNewMedia } from "service/WebSeparateService";
import Mic from "@material-ui/icons/Mic";
import VoiceRecorder from "components/VoiceRecorder/VoiceRecorder";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "@tinymce/tinymce-react";
import TextEditor from "components/Editor/Editor";

class QuizForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      position: null,
      dialogShow: false,
    };
  }

  renderQuestionEditor =({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  })=>{
    return(
      <div>
        <div style={{ marginTop: "10px" }}>
               <TextEditor
                         placeholder="Question Title || इथे प्रश्न लिहा"
                            height="120"
                            onChange={input.onChange}
                            onFocus={input.onFocus}
                            value={input}
                              />
         {touched && error && <span>{error}</span>}
        </div>
      </div>
    ) 
  }
  renderAnswerEditor =({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  })=>{
    return(
      <div>
        <div style={{ marginTop: "10px" }}>
               <TextEditor
                         placeholder="Answer"
                            height="120"
                            onChange={input.onChange}
                            onFocus={input.onFocus}
                            value={input}
                              />
         {touched && error && <span>{error}</span>}
        </div>
      </div>
    ) 
  }



 

renderDropDownField = (formValues) => {

    const { input, label, meta, options } = formValues;

    const { touched, error } = meta;

    const formcss = touched ? `form-control is-${error && touched ? 'invalid' : 'valid'}`: 'form-control';

    return (
        <div className="form-group">
            <label htmlFor={input.name}>{label}</label>
            <select {...input} className={formcss} id="exampleFormControlSelect2">
                {
                  this.props.outcomeList && this.props.outcomeList.length > 0
                  ?
                  
                    this.props.outcomeList.map((option) => <option value={option.element_id}>{option.learning_element}</option>)
                  
:
null
              }
            </select>
            {(touched && error) ? <div className="help-block text-danger">{error}</div> : ''}
        </div>
    );
}

  required = value => value ? undefined : 'Required'


  renderInputField = ({ input, label, type, meta: { touched, error } }) => {

    return(
      <div>
        {/* <label>{label}</label> */}
        <div style={{ marginTop: "10px" }}>
          <input
            {...input}
            type={type}
            placeholder={label}
            className="form-control"
          />
          {touched && error && <span>{error}</span>}
        </div>
      </div>
    ) 
    
  }

  renderTextareaField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      {/* <label>{label}</label> */}
      <div style={{ marginTop: "10px" }}>
        <textarea
          {...input}
          type={type}
          placeholder={label}
          className="form-control"
        
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input} className="form-control">
          {children}
          {console.log(children, "children")}
        </select>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderSelectQuestionTypeField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div style={{ marginTop: "10px" }}>
        <select {...input} className="form-control">
          {children}
        </select>

        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  onChangeFile = (e, f, label) => {
    this.setState(
      {
        file: e.target.files[0],
        position: f.name,
      },
      () => {
        this.uploadMedia(label);
      }
    );
  };

  uploadMedia = (type) => {
    console.log("button clicked");
    let { file, position } = this.state;
    const fd = new FormData();
    fd.append("file", file);
    if (type == "IMAGE") {
      fd.append("media_type", "IMAGE");
    } else {
      fd.append("media_type", "AUDIO");
    }
    fd.append("name", file.name);
    // fd.append("tags", null);
    try {
      uploadNewMedia(fd).then(
        (res) => {
          let {
            data: { data },
          } = res;
          console.log(
            "File uploaded",
            data.file_url,
            "position ----",
            position
          );
          // this.props.dispatch(change());
          this.props.setUrl(position, data.file_url);
        },
        (err) => {
          console.log("File uploaded failed", err);
        }
      );
    } catch (e) {
      console.log(e, "e someting has went wrong");
    }
  };

  renderFileInput = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <React.Fragment>
      <div className="p-2">
        <label>{label}</label>
        <input
          type="file"
          accept="image/png, image/jpeg , image/gif"
          placeholder={label}
          onChange={(e) => this.onChangeFile(e, input)}
          className="form-control"
        />
        {touched && error && <span>{error}</span>}
      </div>
      {/* <div className="p-2">
        <button type="button" onClick={() => this.uploadMedia()} className="btn btn-primary">Upload</button>
      </div> */}
    </React.Fragment>
  );

  renderAudioInput = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <React.Fragment>
      <div className="p-2">
        <label>{label}</label>
        <input
          type="file"
          accept="audio/mp3, audio/mpeg"
          placeholder={label}
          onChange={(e) => this.onChangeFile(e, input, label)}
          className="form-control"
        />
        {touched && error && <span>{error}</span>}
      </div>
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "25px",
          border: " 1px solid gray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          console.log(input, "input");
          this.setState({
            dialogShow: true,
            position: input.name,
          });
        }}
      >
        <Mic titleAccess="Record Live" />
      </div>
      {/* <div className="p-2">
        <button type="button" onClick={() => this.uploadMedia()} className="btn btn-primary">Upload</button>
      </div> */}
    </React.Fragment>
  );

  renderVideoInput = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <React.Fragment>
      <div className="p-2">
        <label>{label}</label>
        <textarea
          {...input}
          type={type}
          placeholder={label}
          className="form-control"
        />
        {touched && error && <span>{error}</span>}
      </div>
      {/* <div className="p-2">
        <button type="button" onClick={() => this.uploadMedia()} className="btn btn-primary">Upload</button>
      </div> */}
    </React.Fragment>
  );

  renderTextAnswers = ({ fields, question, meta: { error } }) => (
    <div>
      {/* <div className="p-2">
        <button type="button" onClick={() => fields.push()} className="btn btn-info">Add Answer</button>
      </div> */}
      {fields.map((answer, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <Field
            name={answer}
            type="text"
            component={this.renderAnswerEditor}
            label={`Answer #${index + 1}`}
            className="form-control"
          />
          <IconButton onClick={() => fields.remove(index)}>
            <Cancel fontSize="large" />
          </IconButton>
        </div>
      ))}
      <div className="p-2">
        <button
          type="button"
          onClick={() => fields.push()}
          className="btn btn-info"
        >
          Add Answer
        </button>
      </div>
      <div>
        <Field
          name={`${question}.correctAnswer`}
          component={this.renderSelectField}
          label="Correct Answer"
          className="form-control"
          validate={this.required}
        >
          <option value="">Please select correct answer</option>
          {fields.map((answer, index) => (
            <option key={index + 1} value={index + 1}>{`Answer #${
              index + 1
            }`}</option>
          ))}
        </Field>
      </div>

      {error && <div className="error">{error}</div>}
    </div>
  );

  renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div>
      <div className="p-2">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => fields.push({messageForCorrectAnswer:"अभिनंदन! आपण दिलेल्या प्रश्नाचं उत्तर अगदी बरोबर आहे",
          messageForIncorrectAnswer:"अधिक अभ्यास करून पुन्हा प्रयत्न करावा",
          time:30,
          point:2,})}
        >
          Add Question
        </button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </div>
      {fields.map((question, index) => (
        <div key={index}>
          <Card>
            <div style={{ padding: "20px" }}>
              <button
                type="button"
                title="Remove Question"
                onClick={() => fields.remove(index)}
                className="btn btn-warn"
              />

               <IconButton
                    style={{ marginLeft: "90%" }}
                    aria-label="Edit"
                    onClick={() => 
                      fields.remove(index)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
              <h4 style={{ padding: "4px" }}>Question No{index + 1}</h4>
             <div style={{ display:'flex'}}>
              <div style={{ width:'55em'}}>
              <Field
                name={`${question}.question`}
                type="text"
                component={this.renderQuestionEditor}
                label="Question Title || इथे प्रश्न लिहा. "
                validate={this.required}
                plugins="autoresize link"
                toolbar="undo redo | bold italic | link"
              />
              </div>
              
            <Button onClick={()=>{
              
            }}>
              <EditOutlined/>
              </Button>
              </div>
              <Field
                name={`${question}.questionType`}
                component={this.renderSelectQuestionTypeField}
                label="Question Type"
                className="form-control"
                validate={this.required}
              >
                <option value="">Please select a question type</option>
                <option value="text">Text</option>
                <option value="photo">Photo/GIF</option>
                <option value="VIDEO">VIDEO</option>
                <option value="AUDIO">AUDIO</option>
              </Field>
              {this.props.questionType[index] == "photo" && (
                <Field
                  name={`${question}.questionPic`}
                  type="file"
                  component={this.renderFileInput}
                  label="IMAGE"
                />
              )}
              {this.props.questionType[index] == "VIDEO" && (
                <Field
                  name={`${question}.questionVideo`}
                  type="text"
                  component={this.renderVideoInput}
                  label="Video Url"
                />
              )}
              {this.props.questionType[index] == "AUDIO" && (
                <Field
                  name={`${question}.questionAUDIO`}
                  type="file"
                  component={this.renderAudioInput}
                  label="AUDIO"
                />
              )}
              <FieldArray
                name={`${question}.answers`}
                component={this.renderTextAnswers}
                question={question}
              />
              <Field
                name={`${question}.point`}
                type="number"
                component={this.renderInputField}
                label="Mark"
                validate={this.required}
              />
              <div
                style={{
                  marginTop: "5%",
                  borderBottom: "2px solid black",
                  marginBottom: "5%",
                  fontSize: "24px",
                }}
              >
                अभिप्राय
              </div>
              <Field
                name={`${question}.messageForCorrectAnswer`}
                type="text"
                component={this.renderTextareaField}
                label="या प्रश्नाचे उत्तर बरोबर आल्यास मिळणारी शाबासकी इथे लिहा"
              />
              <Field
                name={`${question}.messageForIncorrectAnswer`}
                type="text"
                component={this.renderTextareaField}
                label="अधिक अभ्यास करून पुन्हा प्रयत्न करावा."
              />
              <Field
                name={`${question}.explanation`}
                type="text"
                component={this.renderTextareaField}
                label="Explanation || अभिप्राय "
              />
              <Field
                name={`${question}.explanationLink`}
                type="text"
                component={this.renderTextareaField}
                label="Additional link to understand this concept || संकल्पना समजून घेण्यासाठी अतिरिक्त माहिती"
              />
              <Field
                name={`${question}.time`}
                type="number"
                component={this.renderInputField}
                label="Time(second)"
              />
            </div>
            <button
              style={{
                marginLeft: "2%",
                marginRight: "2%",
                marginBottom: "2%",
              }}
              type="button"
              onClick={() => fields.push({
                messageForCorrectAnswer:"अभिनंदन! आपण दिलेल्या प्रश्नाचं उत्तर अगदी बरोबर आहे",
            messageForIncorrectAnswer:"अधिक अभ्यास करून पुन्हा प्रयत्न करावा",
            time:30,
            point:2,
              })}
              className="btn btn-info"
            >
              Add more question
            </button>{" "}
          </Card>
        </div>
      ))}
    </div>
  );

  handleRecordedFile = (file) => {
    console.log("file", file);

    if (!file.url) {
      this.setState({
        dialogShow: false,
      });
      return;
    }

    var blobFile = new File([file.blob], `${new Date().getTime()}.mp3`);
    console.log("file", blobFile);
    let { position } = this.state;
    const fd = new FormData();
    fd.append("file", blobFile);
    fd.append("media_type", "AUDIO");
    fd.append("name", file.name);
    // fd.append("tags", null);
    try {
      uploadNewMedia(fd).then(
        (res) => {
          let {
            data: { data },
          } = res;
          console.log(
            "File uploaded",
            data.file_url,
            "position ----",
            position
          );
          // this.props.dispatch(change());
          this.props.setUrl(position, data.file_url);
          this.setState({
            dialogShow: false,
          });
        },
        (err) => {
          console.log("File uploaded failed", err);
        }
      );
    } catch (e) {
      console.log(e, "e someting has went wrong");
    }
  };
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      editquestion,
    } = this.props;
console.log(this.props.outcomeList)

    return (
      <>
        <Dialog
          onClose={() => {
            this.setState({
              dialogShow: false,
            });
          }}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogShow}
        >
          <VoiceRecorder
            onDone={(file) => {
              this.handleRecordedFile(file);
            }}
          />
        </Dialog>
        <div className="QuizForm">
          <form name="quiz-form" onSubmit={handleSubmit}>
            {!editquestion ? (
              <>
        {/* <Field
          name="learningOutcome"
          component={this.renderDropDownField}
          label="Select Learning Outcome"
          
        >
        </Field> */}
                        <Field
                  name="quizTitle"
                  type="text"
                  component={this.renderInputField}
                  label="प्रश्नपत्रिका शीर्षक"
                />
                <Field
                  name="quizSynopsis"
                  type="text"
                  component={this.renderTextareaField}
                  label="या प्रश्नपत्रिके संदर्भातील सूचना व माहिती इथे लिहा."
                />
              </>
            ) : null}
            <FieldArray name="questions" component={this.renderQuestions} />
            <div className="p-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
const validate = (formValues) => {
    const errors = {};
    if (!formValues.learningOutcome || 'none' === formValues.learningOutcome) {
        errors.learningOutcome = "Please select learning outcome.";
    }
    return errors;
}
QuizForm = reduxForm({
  form: "quizForm",
  validate,
  initialValues: {
    quizSynopsis: "खालील प्रश्न काळजीपूर्वक वाचा आणि दिलेल्या पर्यायातून योग्य पर्याय निवडा.",
    
  }
})(QuizForm);

const selector = formValueSelector("quizForm");

const mapDispatchToProps = (dispatch) => {
  return {
    setUrl: (position, url) => dispatch(change("quizForm", position, url)),
  };
};

QuizForm = connect((state) => {
  const questions = selector(state, "questions");
  const questionType =
    questions && questions.map((question) => question.questionType);

  return { questionType: questionType };
}, mapDispatchToProps)(QuizForm);

export default QuizForm;
