//default imports
import React from "react";

// external package imports
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Sample from "../../assets/SampleFile.xlsx";
//custom imports
// import class from "./FLNHome.module.css";
import {
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import home from "assets/img/FLNExcel.png";
import GridItem from "components/Grid/GridItem";
import { ArrowBack, ArrowForward, DeleteOutline, GetApp, GetAppRounded, Publish, PublishRounded, Refresh, RefreshRounded } from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar";
import { Spinner } from "react-bootstrap";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "350px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
  committeeCategoryInput: {
    color: "#415EB6",
    border: 0,
    backgroundColor: "#DFE4E8",
    width: "200px",
    height: "46px",
    marginRight: "20px",
  },
  disabledButton: {
    cursor: 'not-allowed'
}
};

const classes = styles;
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    meetingsForm: [
      {
        date: "",
        attendance: "",
        details: "",
      },
    ],
    emptyMeetings: [
      {
        date: "",
        attendance: "",
        details: "",
      },
    ],
    mediumList: null,
    selectedMedium: null,
    selectedClass: null,
    classList: null,
    file: null,
    confirmModal: false,
    statusArray: null,
    errorModal: false,
    errorArray: null,
    snack: false,
    offsetForTable: 0,
    statusCount: null,
    schoolId: JSON.parse(localStorage.getItem("HMSchool")) ? JSON.parse(localStorage.getItem("HMSchool")).school_id :
      JSON.parse(localStorage.getItem('AMdetails')) ? JSON.parse(localStorage.getItem('AMdetails')).school_id : null,
    StudentList: null,
    offsetForTable2: 0,
    statusLength2: null,
    message: "",
    snackModal: false,
    responseList: null,
    error: false,
    validateFile: null,
    showloaderCircle: false
  };

  componentDidMount() {
    this.getMedium();
    this.getStatus();
    this.getSchoolwiseDetails();
  }

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
          });
        }
      });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  getStatus = async () => {
    console.log(this.state.offsetForTable, "this.state.offsetForTable");
    let data = await getHMService().getFLNStatus(this.state.offsetForTable);
    console.log(data.data.count, "data");
    if (data) {
      this.setState({
        statusArray: data.data.results,
        statusCount: data.data.count,
      });
    }
  };

  renderStatusTable = (res) => {
    console.log(res, "Res");
  };

  onValChange = (event) => {
    console.log(event.currentTarget.files[0], "event");
    let filename = event.currentTarget?.files[0]?.name;
    let fileExt = filename.split(".").pop();
    console.log(fileExt, "extension");
    if (fileExt === "xlsx") {
      this.setState({
        file: event.currentTarget.files[0],
      });
      console.log(this.state.file, "fd");
    } else {
      this.setState({
        validateFile: "Check file type again",
      });
    }
  };

  submitStundets = () => {
    this.setState({
      showloaderCircle: true
    })
    const schoolId = JSON.parse(localStorage.getItem("HMSchool")) ? JSON.parse(localStorage.getItem("HMSchool")).school_id :
      JSON.parse(localStorage.getItem('AMdetails')) ? JSON.parse(localStorage.getItem('AMdetails')).school_id : null;
    console.log(
      schoolId,
      "schoolId",
      this.state.selectedClass,
      this.state.selectedMedium,
      this.state.file
    );
    let formData = new FormData();
    formData.append("file", this.state.file);
    formData.append("school", schoolId);
    formData.append("student_class", this.state.selectedClass);
    formData.append("medium", this.state.selectedMedium);

    let param = {
      school: schoolId,
      student_class: this.state.selectedClass,
      medium: this.state.selectedMedium,
      file: this.state.file,
    };

    getHMService()
      .createFLNStudnetList(formData)
      .then((res) => {
        console.log(res, "res45");
        this.getStatus();
        this.setState(
          {
            message: res.message,
            responseList: res.response,
            snackModal: true,
            error: false,
            showloaderCircle: false
          },
          () => {
            // window.location.reload();
          }
        );
        // window.location.reload()
      })
      .catch((e) => {
        console.log(e.message, "e");
        if (e.message) {
          this.setState({
            snackModal: true,
            error: true,
          });
        }
      });
  };

  nextData = () => {
    let data = this.state.offsetForTable + 10;
    console.log(this.state.statusCount, "this.state.statusLength");
    if (data > this.state.statusCount) {
      return;
    } else {
      this.setState(
        {
          offsetForTable: this.state.offsetForTable + 10,
        },
        () => {
          this.getStatus();
        }
      );
    }
  };

  nextDataFortable = () => {
    let data = this.state.offsetForTable2 + 10;
    console.log(this.state.statusLength2, "this.state.statusLength");
    if (data > this.state.statusLength2) {
      return;
    } else {
      this.setState(
        {
          offsetForTable2: this.state.offsetForTable2 + 10,
        },
        () => {
          this.getSchoolwiseDetails();
        }
      );
    }
  };

  getSchoolwiseDetails = async () => {
    // const schoolID = JSON.parse(localStorage.getItem("HMSchool")).school_id,
    let data = await getHMService().getSchoolWiseStudentListDetails(
      this.state.schoolId,
      this.state.offsetForTable2
    );
    console.log(data, "data");
    if (data) {
      this.setState({
        StudentList: data.data.data,
        statusLength2: data.data.data.length,
      });
    }
  };

  getYTVideoID = (value) => {
    if (value) {
      let url = value.hasOwnProperty("url") ? value.url : value;
      url = url.toString().trim();
      var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#]*).*/;
      var match = url.match(regExp);
      return match && match[1];
    } else {
      return;
    }
  };

  render() {
    let { stdWiseGraph, snackModal } = this.state;
    return (
      <>
        <Modal
          open={snackModal}
          onClose={() => {
            this.setState({
              snackModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{ maxHeight: 400, overflow: "auto", borderRadius: "10px" }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: this.state.error ? "#bb2124" : '#4BB543',
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "white",
              }}
            >
              फाईल अपलोड स्टेटस
            </div>
            {this.state.error ? (
              <div style={{ textAlign: "center", color: "white", }}>
                ‘तुम्ही अपलोड केलेल्या फाईलमध्ये माध्यम व इयत्ता आणि सर्व अंक,
                शब्द इ. माहिती इंग्रजी मध्ये आहे का हे पुन्हा तपासून घावे
                {""}
                <div>
                  अधिक माहिती साठी हा व्हिडिओ बाघा :{" "}
                  <a href="https://youtu.be/aUb2vAJxTN4">
                    {" "}
                    https://youtu.be/aUb2vAJxTN4{" "}
                  </a>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: "#7965DA",
                      marginTop: "20px",
                      color: "#fff",
                    }}
                    onClick={() =>
                      this.setState({ snackModal: false }, () => {
                        window.location.reload();
                      })
                    }
                  >
                    Ok
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center", fontWeight: "bolder", color: "white", }}>
                <div> तुमची फाईल अपलोड होत आहे... </div>
                <div>
                  {" "}
                  याबद्दल जास्त माहिती{" "}
                  <span style={{ color: "#2282CC" }}>
                    फाईल अपलोड केलेले प्रयत्न
                  </span>{" "}
                  या टेबल मधे दिलेलीे आहे
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: "#7965DA",
                      marginTop: "20px",
                      color: "#fff",
                    }}
                    onClick={() => {
                      this.getStatus();
                      window.location.reload();
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Modal>

        <Modal
          open={this.state.errorModal}
          onClose={() => {
            this.setState({
              errorModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{ maxHeight: 400, overflow: "auto" }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              तुम्ही भरलेली माहिती with error
            </div>
            {console.log(this.state.errorArray, "errorArray")}
            <div>
              {this.state.errorArray?.length > 0 ? (
                this.state.errorArray?.map((res) => {
                  return (
                    <div style={{ border: "1px solid black", padding: "5px" }}>
                      <div>error on Serial No : {res.SN}</div>
                      <div>
                        error :{" "}
                        {res.error.saral_id
                          ? res.error.saral_id
                          : res.error.phone}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  No errors
                </div>
              )}
            </div>
          </Box>
        </Modal>
        <Snackbar
          autoHideDuration={4000}
          color={"success"}
          close={false}
          open={this.state.validateFile}
          message={this.state.validateFile}
          place="br"
          rtlActive={true}
          closeNotification={() => {
            this.setState({
              validateFile: null,
            });
          }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginTop: "15px",
              // backgroundColor: "#DFE4E8",
              fontWeight: "bold",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "column",
                //   marginLeft: "18%",
              }}
            >
              <div
                style={{
                  //   backgroundColor: "#DFE4E8",
                  padding: "10px",
                  color: "#4B66B9",
                  //   padding: "10px",
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>महत्वाच्या सूचना:</div>
                    <div>
                      १. सरल पोर्टल वेबसाईट <a href="https://student.maharashtra.gov.in/stud_db/users/login" target="_blank" >(https://student.maharashtra.gov.in/stud_db/users/login)</a> वर आपल्या शाळेचा लॉगिन आयडी आणि पासवर्ड टाकून लॉगिन करावे.
                    </div>

                    <div>
                      2.सरल वेबसाईट मधील Reports ➔HM Level ➔ Student Catalogue मध्ये जाऊन इयत्ता निहाय विद्यार्थ्यांची यादी डाऊनलोड करून घ्यावी.
                    </div>
                    <div>
                      ३.डाउनलोड केलेल्या फाईल  मधे कोणतेही बदल करू नये. नमुना फाईल  खाली पाहू शकता.
                    </div>
                    <div>
                      ४.प्रत्येक फाईलला आपल्या सोयीनुसार नाव द्यावे, जेणेकरून अपलोड करताना चूक होणार नाही.
                    </div>
                    <div>
                      ५.खाली दिलेल्या बटणावर क्लिक करून माध्यम व इयत्ता निवडावी.
                    </div>
                    <div>
                      ६. एकावेळी एकाच इयत्तेच्या सर्व विद्यार्थ्यांची माहिती अपलोड करावी. मग पुढच्या इयत्तेची माहिती अपलोड करावी.
                    </div>
                    <div>
                      ७.इयत्तेतील विद्यार्थी संख्या व अपलोड झालेल्या विद्यार्थ्यांची संख्या तपासून पहावी.
                    </div>    
                    <div>
                      ८. तुम्ही हे केवळ एकदाच करायचे आहे, त्यानंतर मोबाईल वरून अध्ययन स्तर निश्चिती करता येईल.
                    </div>
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '200%' }}
                  >
                    <Typography>
                      अधिक  माहितीकरिता  खालील वीडियो पहा :
                    </Typography>
                    <iframe
                      height='300'
                      style={{
                        width: '100%',
                      }}
                      src={`https://www.youtube.com/embed/${this.getYTVideoID(
                        'https://youtu.be/lRMBl83raMc'
                      )}`}
                    ></iframe>
                  </div>
                </div>
                <div>
                  <img
                    src={home}
                    style={{ width: "100%", height: "auto", marginBottom: "15px", padding: "10px" }}
                    alt="Example image of Student List"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a
                    href={Sample}
                    download="SampleFile.xlsx"
                    style={{
                      // backgroundColor: "#D547E1",
                      padding: "7px",
                      borderRadius: "10px",
                      color: "blue",
                    }}
                  >
                    नमुना फाईल येथून डाउनलोड करा.
                  </a>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "25%", marginTop: '2rem' }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "20px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "160px",
                    color: "#958989",
                  }}
                >
                  माध्यम
                </p>
                <p
                  style={{
                    textAlign: "center",
                    width: "300px",
                    color: "#958989",
                  }}
                >
                  इयत्ता
                </p>
                <p
                  style={{
                    textAlign: "center",
                    width: "200px",
                    color: "#958989",
                  }}
                >
                  विद्यार्थी यादी
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Select
                  variant="outlined"
                  style={{
                    color: "#415EB6",
                    border: 0,
                    backgroundColor: "white",
                    width: "200px",
                    height: "46px",
                    marginRight: "20px",
                  }}
                  className={classes.committeeCategoryInput}
                  value={this.state.selectedMedium}
                  onChange={(e) =>
                    this.setState(
                      {
                        selectedMedium: e.target.value,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    )
                  }
                  placeholder="Category"
                >
                  {this.state.mediumList?.map((res) => {
                    return (
                      <MenuItem
                        value={res.medium_id}
                        className={classes.committeeCategoryInput}
                        key={res.medium_name}
                      >
                        {res.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>

                <Select
                  variant="outlined"
                  style={{
                    color: "#415EB6",
                    border: 0,
                    backgroundColor: "white",
                    width: "200px",
                    height: "46px",
                    marginRight: "20px",
                  }}
                  className={classes.committeeCategoryInput}
                  value={this.state.selectedClass}
                  onChange={(e) =>
                    this.setState({
                      selectedClass: e.target.value,
                    })
                  }
                  placeholder="Category"
                >
                  {this.state.classList?.map((res) => {
                    return (
                      <MenuItem
                        value={res.class_id}
                        className={classes.committeeCategoryInput}
                        key={res.class_name}
                      >
                        {res.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>

                <input
                  style={{
                    background: "white",
                    color: "#415EB6",
                    maxWidth: "200px",
                    height: "46px",
                    marginLeft: "10px",
                    paddingTop: "7px",
                  }}
                  type="file"
                  accept=".xlsx"
                  placeholder="Mobile"
                  className={classes.committeeCategoryInput}
                  onChange={(e) => this.onValChange(e)}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "50px",
              }}
            >
              {!this.state.showloaderCircle ?
                <Button
                  endIcon={<PublishRounded />}
                  disabled={
                    !this.state.selectedClass ||
                    !this.state.selectedMedium ||
                    !this.state.file
                  }
                  className={(!this.state.file || !this.state.selectedClass || !this.state.selectedMedium) ? styles.disabledButton : ""}
                  style={{
                    backgroundColor: (!this.state.file || !this.state.selectedClass || !this.state.selectedMedium) ? "grey" : "#7965DA",
                    color: "#fff",
                    padding: "10px",
                  }}
                  onClick={() => this.submitStundets()}
                >
                  अपलोड करा
                </Button> : <CircularProgress />}
            </div>
          </div>

          <div>
            <Card>
              <CardHeader
                color="primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>फाईल अपलोड केलेले प्रयत्न</h4>
                <div>
                  <Button
                    style={{ backgroundColor: "#0d6efd", color: "#fff" }}
                    onClick={() => window.location.reload()}
                    endIcon={<RefreshRounded />}
                  >
                    Refresh
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div style={(styles.inputWrapper, styles.list)}>
                  <TableContainer>
                    <Table
                      //   className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">माध्यम</TableCell>
                          <TableCell align="center">इयत्ता</TableCell>
                          <TableCell align="center">स्टेटस</TableCell>
                          <TableCell align="center">
                            यशस्वीरित्या अपलोड विद्यार्थी संख्या
                          </TableCell>
                          <TableCell align="center">स्टेटस यादी पहा</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log(this.state.statusArray, "sta")}
                        {this.state.statusArray?.map((res) => {
                          return (
                            <TableRow>
                              <TableCell
                                align="left"
                              >
                                {res.medium_name}
                              </TableCell>
                              <TableCell align="center">
                                {res.class_name}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    color:
                                      res.status === "SUCCESS"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {res.status}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                {res.upload_records}
                              </TableCell>
                              <TableCell align="center">
                                {res.errors_url ? (
                                  <Button
                                    onClick={() => {
                                      if (res.errors_url) {
                                        window.open(res.errors_url);
                                      }
                                    }}
                                    style={{
                                      backgroundColor: "#0d6efd",
                                      color: "#fff",
                                    }}
                                    endIcon={<GetAppRounded />}
                                  >
                                    Download
                                  </Button>
                                ) : (
                                  "NA"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter style={{ display: "flex" }}>
                        <IconButton
                          onClick={() => {
                            this.setState(
                              {
                                offsetForTable:
                                  this.state.offsetForTable > 0
                                    ? this.state.offsetForTable - 10
                                    : this.state.offsetForTable,
                              },
                              () => {
                                this.getStatus();
                              }
                            );
                          }}
                        >
                          <ArrowBack />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            this.nextData();
                          }}
                        >
                          <ArrowForward />
                        </IconButton>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card>
              <CardHeader color="primary">
                <h4>यशस्वी नोंद झालेली अंतिम माहिती</h4>
              </CardHeader>
              <CardBody>
                <div style={(styles.inputWrapper, styles.list)}>
                  <TableContainer>
                    <Table
                      //   className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">माध्यम</TableCell>
                          <TableCell align="center">इयत्ता</TableCell>
                          <TableCell align="center">
                            यशस्वीरित्या अपलोड विद्यार्थी संख्या
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log(this.state.StudentList, "sta")}
                        {this.state.StudentList?.map((res) => {
                          return (
                            <TableRow>
                              <TableCell
                                align="left"
                              >
                                {res.medium_name}
                              </TableCell>
                              <TableCell align="center">
                                {res.class_name}
                              </TableCell>
                              <TableCell align="center">
                                <div>{res.total_student}</div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter style={{ display: "flex" }}>
                        <IconButton
                          onClick={() => {
                            this.setState(
                              {
                                offsetForTable2:
                                  this.state.offsetForTable2 > 0
                                    ? this.state.offsetForTable2 - 10
                                    : this.state.offsetForTable2,
                              },
                              () => {
                                this.getSchoolwiseDetails();
                              }
                            );
                          }}
                        >
                          <ArrowBack />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            this.nextDataFortable();
                          }}
                        >
                          <ArrowForward />
                        </IconButton>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
          </div>
        </div >
      </>
    );
  }
}
