// export let BASE_URL = "http://api.vopa.in/vopa/api";
//  export let BASE_URL = "https://lessons.vopa.in/vopa/api"; // for prod
// export let BASE_URL = "http://dev.vopa.in/vopa/api/"; // for dev
// /http://vopa-api-lb-999322704.ap-south-1.elb.amazonaws.com/vopa/api/health/
//export let BASE_URL = "http://lessons.vopa.in/vopa/api";
// export let Web_separate = "http://dev.vopa.in/content/api/"; // for web separate(dev)
// export let Web_separate = "https://lessons.vopa.in/content/api/"// for web separate(prod)

export let Web_separate = "https://hm.vschoolindia.com/content/api/"; // for web separate(dev)
 export let BASE_URL = "https://hm.vschoolindia.com/vopa/api/"; // for dev

// export let BASE_URL = "http://hm-dev.vopa.in/vopa/api/"; // for dev

// export let Web_separate = "http://hm-dev.vopa.in/content/api/"; // for web separate(dev)

export let KOITA_URL = "http://hm-dev.vopa.in/student_activity/api/"; //for Koita
