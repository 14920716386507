import {
    Button,
    Card,
    FormHelperText,
    Input,
    CircularProgress,
    Box,
    Modal,
    Switch,
  } from "@material-ui/core";
  import CardBody from "components/Card/CardBody";
  import CardFooter from "components/Card/CardFooter";
  import CardHeader from "components/Card/CardHeader";
  import React from "react";
  import {
    getSuperAdminService,
    getContentWriterService,
    getHMService,
  } from "./../../service/service";
  import {
    Field,
    FieldArray,
    reduxForm,
    formValueSelector,
    change,
  } from "redux-form";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  import Select from "@material-ui/core/Select";
  import InputLabel from "@material-ui/core/InputLabel";
  import EditIcon from "@material-ui/icons/Edit";
  import ClearIcon from "@material-ui/icons/Clear";
  import ExpandLess from "@material-ui/icons/ExpandLess";
  import ExpandMore from "@material-ui/icons/ExpandMore";
  import SaveIcon from "@material-ui/icons/Save";
  import DeleteIcon from "@material-ui/icons/Delete";
  import IconButton from "@material-ui/core/IconButton";
  import {
    getMcqAiGenerated,
    addAIquestionsToDB,
  } from "service/WebSeparateService";
  import Snackbar from "components/Snackbar/Snackbar";
  import {
    AddBoxOutlined,
    ArrowBack,
    ArrowForward,
    Cancel,
    DeleteOutline,
    Edit,
    Forward,
    Save,
    ExpandMoreIcon,
  } from "@material-ui/icons";
  import AlertDialog from "components/AlertDialog/AlertDialog";
  
  export default class FAQs extends React.Component {
    state = {
      loginType: "",
      category: "",
      question: "",
      answer: "",
      youtubeLink: "",
      uploadImage: "",
      checkEverything: false,
      loginTypeArr: [
        {
          "id": 1,
          "login_type_name": "STUDENT",
      },
        {
            "id": 2,
            "login_type_name": "TEACHER",
        },
        {
            "id": 3,
            "login_type_name": "HEADMASTER",
        },
        {
            "id": 4,
            "login_type_name": "ADMINISTRATIVE",
        }
    ],
      categoryArr: [],
      allCategories: [],
      imageModal: false,
      imageURL: "",
      tab1:true,
      tab2:false,
      tab3:false,
      categoryName:"",
      editLogin:"",
      saveCategoryName:false,
      message: "",
      error: false,
      editCategoryArr:[],
      deleteCategoryName:false,
      editCategoryName:false,
      currentCategoy:null,
      deleteFAQName:false,
      editFAQName:false,
      curentFAQ:null,
      offset:0,
      searchText:"",
      allFAQLength:0
    };
  
    inputRef = React.createRef();
  
  
    componentDidMount() {
      this.getAllFAQS();
    }
  
    getAllFAQS = () => {
  
      if(this.state.category != "" ){
        let url = `/faqs/list/?search=${this.state.searchText}&category=${this.state.category?.id}&offset=${this.state.offset}&limit=10`
        getSuperAdminService()
        .getAllFAQsList(url)
        .then((res) => {
          if (res.status === 200) {
            res=res?.data
            let arr = res.faqs
  
            let newArray = arr.map((obj, index) => {
              return { ...obj, edited: false,isUp :true }; // Adding 'id' key with values starting from 1
            });
            this.setState({
              allCategories:newArray,
              allFAQLength:res.count
            });
          }
        });
    
  
        
      }
  
      
    };
  
  
    getCategoryType = (id)=>{
  
  
      getSuperAdminService()
      .getFAQCategoryTypes(id)
      .then((res) => {
        if (res.status === 200) {
          res=res?.data
          let arr = res?.results
  
          let newArray = arr.map((obj, index) => {
            return { ...obj, edited: false }; // Adding 'id' key with values starting from 1
          });
  
          this.setState({
            editCategoryArr : newArray,
            categoryArr: res?.results
          })
      
        }
      });
    
    }
  
  
  
  
    editFAQCategory =()=>{
  
      let param = {
        category_name: this.state.currentCategoy?.category_name,
        login_type:this.state.loginType?.login_type_name 
      }
  
  
      getSuperAdminService()
      .editFAQCategoryTypes(param,this.state.currentCategoy?.id)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            message: "The Category has been Updated",
            error: false,
          },()=>{
            this.getCategoryType(this.state.loginType?.login_type_name)
          })
        }
      });
    }
    
    deleteFAQCategory =()=>{
  
      getSuperAdminService()
      .deleteFAQCategoryTypes(this.state.currentCategoy?.id)
      .then((res) => {
        if (res.status === 204) {
          this.setState({
            message: "The Category has been Deleted",
            error: false,
          },()=>{
            this.getCategoryType(this.state.loginType?.login_type_name)
          })
        }
      });
    }
    
    addCategory =()=>{
  
      let param = {
        category_name: this.state.categoryName,
        login_type: this.state.loginType?.login_type_name
  
      }
      getSuperAdminService()
      .addFAQCategoryTypes(param)
      .then((res) => {
        if (res.status === 201) {
          this.setState({
            message: "The Category has been Created",
            error: false,
            categoryName:""
          },()=>{
            this.getCategoryType(this.state.loginType?.login_type_name)
          })
  
        }
      });
    }
    editFAQQuestion =()=>{
      let param = {
        question: this.state.curentFAQ?.question,
        answer: this.state.curentFAQ?.answer,
        youtube_url: this.state.curentFAQ?.youtube_url,
        image_url:this.state.curentFAQ?.image_url,
        category: this.state.curentFAQ?.category
    }
  
  
  
      getSuperAdminService()
      .editFAQQuestionTypes(param,this.state.curentFAQ?.id)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ 
            message: "The FAQ has been Edited",
            error: false,
  
           },()=>{
              this.getAllFAQS()
           });
        }
      });
    }
  
    deleteFAQQuestion =()=>{
  
      console.log("fdafa",this.state.curentFAQ)
  
      getSuperAdminService()
      .deleteFAQQuestionTypes(this.state.curentFAQ?.id)
      .then((res) => {
        if (res.status === 204) {
          this.setState({ 
            message: "The FAQ has been Deleted",
            error: false,
  
           },()=>{
              this.getAllFAQS()
           });
        }
      });
    }
  
   
    onChangeFile = async (event) => {
      const fd = new FormData();
      fd.append(
        "image",
        event.currentTarget ? event.currentTarget.files[0] : event
      );
      fd.append("school_id", 1);
      let mediaRes = await getHMService().uploadSchoolImages(fd);
      console.log(mediaRes.data.file, "media");
      this.setState({
        uploadImage: mediaRes.data.file,
      });
    };
  
    onEditImageFile = async (event,res) => {
      const fd = new FormData();
      fd.append(
        "image",
        event.currentTarget ? event.currentTarget.files[0] : event
      );
      fd.append("school_id", 1);
      let mediaRes = await getHMService().uploadSchoolImages(fd);
      console.log(mediaRes.data.file, "media");
  
      let updatedArray = this.state.allCategories.map(obj => {
        if (obj.id === res?.id) {
          return { ...obj,image_url:mediaRes.data.file}; // Increment age by 1 as an example
        } else {
          return { ...obj };
        }
      });
      this.setState({ allCategories: updatedArray });
      
    };
  
  
    
  
  
    fillForm = () => {
      if (
        this.state.loginType != "" &&
        this.state.category != "" &&
        this.state.question != "" &&
        this.state.answer != ""
      ) {
  
  
        let param = {
          question: this.state.question,
          answer: this.state.answer,
          youtube_url: this.state.youtubeLink,
          image_url:this.state.uploadImage,
          category: this.state.category.id
      }
  
      getSuperAdminService()
      .addFAQList(param)
      .then((res) => {
        if (res.status === 201) {
          this.inputRef.current.value = null;
          this.setState({ 
            checkEverything: false,
            question: "",
            answer: "",
            youtubeLink: "",
            uploadImage: "",
            message: "The FAQ has been Created",
            error: false,
  
           },()=>{
              this.getAllFAQS()
           });
       
          
        }
      });
  
  
  
      } else {
        this.setState({
          checkEverything: true,
        });
      }
    };
  
    BackPage = () => {
      let data =
        this.state.offset > 0 ? this.state.offset - 10 : this.state.offset;
      if(this.state.offset != 0){
        this.setState(
          {
            offset: data,
          },
          () => {
            this.getAllFAQS();
          }
        );
      }
  
    };
  
    nextPage = () => {
      let data = this.state.offset + 10;
      if (data > this.state.allFAQLength) {
        return;
      } else {
        this.setState(
          {
            offset: data,
          },
          () => {
            this.getAllFAQS();
          }
        );
      }
    };
  
    render() {
      let { message, error } = this.state;
      return (
        <>
         <AlertDialog
          open={this.state.saveCategoryName}
          title="Categories"
          text="Do you want to add this Category?"
          onCancel={() => {
            this.setState({
              saveCategoryName:false
            })
          }}
          onConfirm={() => {
            this.setState({
              saveCategoryName:false
            },()=>{
              this.addCategory()
            })
          }}
        />
          <AlertDialog
          open={this.state.deleteCategoryName}
          title="Categories"
          text="Do you want to Delete this Category?"
          onCancel={() => {
            this.setState({
              deleteCategoryName:false
            })
          }}
          onConfirm={() => {
            this.setState({
              deleteCategoryName:false
            },()=>{
              this.deleteFAQCategory()
            })
          }}
        />
          <AlertDialog
          open={this.state.editCategoryName}
          title="Categories"
          text="Do you want to Edit this Category?"
          onCancel={() => {
            this.setState({
              editCategoryName:false
            })
          }}
          onConfirm={() => {
            this.setState({
              editCategoryName:false
            },()=>{
              this.editFAQCategory()
            })
          }}
        />
        <AlertDialog
          open={this.state.deleteFAQName}
          title="FAQ"
          text="Do you want to Delete this FAQ?"
          onCancel={() => {
            this.setState({
              deleteFAQName:false
            })
          }}
          onConfirm={() => {
            this.setState({
              deleteFAQName:false
            },()=>{
              this.deleteFAQQuestion()
            })
          }}
        />
        <AlertDialog
          open={this.state.editFAQName}
          title="FAQ"
          text="Do you want to Edit this FAQ?"
          onCancel={() => {
            this.setState({
              editFAQName:false
            })
          }}
          onConfirm={() => {
            this.setState({
              editFAQName:false
            },()=>{
              this.editFAQQuestion()
            })
          }}
        />
          <Snackbar
            autoHideDuration={5000}
            message={message}
            color={error ? "danger" : "success"}
            close={false}
            place="br"
            closeNotification={() => {
              this.setState({
                message: null,
              });
            }}
            rtlActive={true}
            open={message ? true : false}
          ></Snackbar>
          <Modal
            open={this.state.imageModal}
            onClose={() => {
              this.setState({
                imageModal: false,
              });
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "300px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {this.state.imageURL ? (
                  <>
                    {
                      <img
                        src={this.state.imageURL}
                        style={{ maxWidth: "90%", maxHeight: "90%" }}
                        alt="Your Image"
                      />
                    }
                  </>
                ) : (
                  <p>No File</p>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    textAlign: "center",
                    backgroundColor: "#7965DA",
                    padding: "10px",
                    width: "7%",
                    borderRadius: "10px",
                    color: "#fff",
                    // marginLeft: "15px",
                    marginTop: "30px",
                  }}
                  onClick={() => {
                    this.setState({
                      imageModal: false,
                    });
                  }}
                >
                  Close
                </Button>
              </div>
            </Box>
          </Modal>
          <Snackbar
            autoHideDuration={5000}
            message={message}
            color={error ? "danger" : "success"}
            close={false}
            place="br"
            closeNotification={() => {
              this.setState({
                message: null,
              });
            }}
            rtlActive={true}
            open={message ? true : false}
          ></Snackbar>
          <h3 style={{ color: "#000000", padding: "5px" }}>FAQS</h3>
          <p style={{ color: "#958989", padding: "5px" }}>
            The below section is used for creating FAQs for users.
          </p>
          <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent:"center"
              }}
            >
              <div className="col-3">
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <FormControl fullWidth>
                    <span style={{ color: "#415EB6" }} id="School">
                      Select Login Type
                    </span>
                    <Select
                      style={{ backgroundColor: "#DFE4E8" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.loginType?.login_type_name}
                      onChange={(e) => {
                        console.log("E", e.target.value);
                        this.setState(
                          {
                            loginType: e.target.value,
                          },
                          () => {
                            this.getCategoryType(e.target.value.login_type_name)
                          }
                        );
                      }}
                    >
                      {this.state.loginTypeArr.map((stdClass) => {
                        return (
                          <MenuItem value={stdClass} key={stdClass.id}>
                            {stdClass.login_type_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-3">
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <FormControl fullWidth>
                    <span style={{ color: "#415EB6" }} id="School">
                      Select Category type
                    </span>
                    <Select
                                      disabled={this.state.tab3 == true ? true : false}
                      style={{ backgroundColor: "#DFE4E8" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.category?.category_name}
                      onChange={(e) => {
                        console.log("E", e);
                        this.setState({
                          category: e.target.value,
                        },()=>{
                          this.getAllFAQS()
                        });
                      }}
                    >
                      {this.state.categoryArr.map((stdClass) => {
                        return (
                          <MenuItem value={stdClass} key={stdClass?.id}>
                            {stdClass?.category_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          <div style={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"center"
  
          }} >
          <button 
          onClick={()=>{
            this.setState({
              tab1:true,
      tab2:false,
      tab3:false,
            })
          }}
          style={{
            width:"100px",
            margin:"10px",
            border:0,
            borderBottomWidth: this.state.tab1 ? '1px' : '0',
              borderBottomStyle: 'solid',
              borderBottomColor: 'black',
          }} >
             Add New FAQ
          </button>
          <button 
          onClick={()=>{
            this.setState({
              tab1:false,
      tab2:true,
      tab3:false,
            })
          }}
          style={{
            width:"100px",
            margin:"10px",
            border:0,
            borderBottomWidth: this.state.tab2 ? '1px' : '0',
              borderBottomStyle: 'solid',
              borderBottomColor: 'black',
          }}  >
             View FAQ
          </button>
          <button
          onClick={()=>{
            this.setState({
              tab1:false,
      tab2:false,
      tab3:true,
            })
          }}
         
          style={{
            width:"100px",
            margin:"10px",
            border:0,
            borderBottomWidth: this.state.tab3 ? '1px' : '0',
              borderBottomStyle: 'solid',
              borderBottomColor: 'black',
          }} 
         >
             Add Category
          </button>
          </div>
          <hr />
  
          {
            this.state.tab1 == true ? (
  <>
  <div
            style={{
              margin: "20px",
              backgroundColor: "#ECFBFF",
              padding:"20px"
            }}
          >
  
  <h4 style={{
              color:"black",
              marginLeft:"10px",
            }} >
            Add New FAQ 
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p
                style={{
                  marginLeft: "10px",
                  marginTop:"10px"
                }}
              >
                Question:
              </p>
              <input
                onChange={(event) => {
                  this.setState({ question: event.target.value });
                }}
                value={this.state.question}
                type="text"
                style={{
                  width: "70%",
                  border: 0,
                  marginLeft: "20px",
                  backgroundColor: "#DFE4E8",
                }}
              />
            </div>
            <div>
              <p
                style={{
                  marginLeft: "10px",
                }}
              >
                {" "}
                Answers:
              </p>
              <textarea
                style={{
                  border: "0",
                  backgroundColor: "#DFE4E8",
                  marginBottom: "5px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  paddingLeft: "10px",
                  width: "400px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                value={this.state.answer}
                onChange={(event) => {
                  this.setState({ answer: event.target.value });
                }}
                placeholder="Answer"
              >
                {this.state.answer}
              </textarea>
            </div>
  
            <div>
              <p
                style={{
                  marginLeft: "10px",
                }}
              >
                Youtube Link :
              </p>
              <input
                value={this.state.youtubeLink}
                onChange={(event) => {
                  this.setState({ youtubeLink: event.target.value });
                }}
                placeholder="Youtube Link"
                type="text"
                style={{
                  width: "50%",
                  border: 0,
                  marginLeft: "20px",
                  backgroundColor: "#DFE4E8",
                  height: "40px",
                }}
              />
              <input
                ref={this.inputRef}
                type="file"
                accept="image/jpeg, image/png"
                style={{
                  marginTop: "5px",
                  paddingTop: "7px",
                  marginLeft: "40px",
                }}
                onChange={(e) => {
                  this.onChangeFile(e);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <button
                onClick={() => {
                  this.fillForm();
                }}
                style={{
                  backgroundColor: "#855AD8",
                  borderRadius: "10px",
                  color: "white",
                  width: "230px",
                  border: "none",
                  height: "40px",
                  margin: "10px",
                  fontSize: "12px",
                }}
              >
                Publish Help Topic
              </button>
            </div>
            {this.state.checkEverything == true &&
            (this.state.loginType == "" ||
              this.state.category == "" ||
              this.state.question == "" ||
              this.state.answer == "") ? (
              <p
                style={{
                  color: "red",
                }}
              >
                Please fill form carefully
              </p>
            ) : null}
          </div>
  </>
            ) : (
              null
            )
          }
            {
              this.state.tab2 == true ? (
                <>
                <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "20px",
            }}
          >
            <h4 style={{ color: "#000000", padding: "5px", textAlign: "center" }}>
              View/Edit Help Topics
            </h4>
            <input 
            type="text"
              style={{
                width: "300px",
                borderWidth: "1px",
                marginLeft: "20px",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "10px",
                paddingLeft:"5px",
                paddingTop:"5px",
                backgroundColor:"transparent"
              }}
              onChange={(e)=>{
                this.setState({
                  searchText:e.target.value
                },()=>{
                  this.getAllFAQS()
                })
              }}
              placeholder=" Search help Item"
            />
             
          </div>
          {this.state.allCategories?.map((res) => {
            return (
              <>
                {res?.isUp == true ? (
                  <>
                    {res?.edited == true ? (
                        <>
                        <div
                    style={{
                      margin: "20px",
                      backgroundColor: "#ECFBFF",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        
                      }}
                    >
                    <div className="col-4" >
  
                    </div>
                    <div className="col-4" >
  
                        </div>
                      <div className="col-3" style={{
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"center"
                      }} >
                        <IconButton
  
                            style={{
                            borderRadius: "10px",
                            width: "40px",
                            border: "none",
                            height: "40px",
                            margin: "10px",
                            fontSize: "12px",
                          }}    
   onClick={() => {
                            let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,edited:false}; // Increment age by 1 as an example
                            } else {
                              return { ...obj, edited:false };
                            }
                          });
                          this.setState({
                            allCategories:updatedArray
                          })
                          }}                         
      >
    <ClearIcon />
      </IconButton>
      <IconButton
      onClick={()=>{
        this.setState({
        editFAQName:true,
        curentFAQ:res
      })
      }}
      >
    <SaveIcon />
      </IconButton>
      <IconButton
      onClick={()=>{
      this.setState({
        deleteFAQName:true,
        curentFAQ:res
      })
      }}
      >
        <DeleteIcon />
      </IconButton>
                      </div>
                      <div className="col-1">
                        {res.isUp == false ? (
                          null
                        ) : (
                          <IconButton
                            onClick={() => {
                            let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,isUp:false}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                          this.setState({
                            allCategories:updatedArray
                          })
                          }}
                          >
                            <ExpandLess style={{fontSize:"30px"}} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "10px",
                          marginTop:"10px"
                        }}
                      >
                        Question:
                      </p>
                      <input
                onChange={(event) => {
                  let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,question:event.target.value}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                                  this.setState({ allCategories: updatedArray });
                }}
                value={res.question}
                type="text"
                style={{
                  width: "70%",
                  border: 0,
                  marginLeft: "20px",
                  backgroundColor: "#DFE4E8",
                }}
              />
                    </div>
                    <div>
                      <p
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        Answers:
                      </p>
                      <textarea
                style={{
                  border: "0",
                  backgroundColor: "#DFE4E8",
                  marginBottom: "5px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  paddingLeft: "10px",
                  width: "400px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  minHeight:"200px"
                }}
                value={res.answer}
                onChange={(event) => {
                  let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,answer:event.target.value}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                                  this.setState({ allCategories: updatedArray });
                }}
                placeholder="Answer"
              >
                {res.answer}
              </textarea>
                    </div>
  
                    <div>
                      <p
                        style={{
                          marginLeft: "10px",
                          marginTop:"20px"
                        }}
                      >
                        Youtube Link :
                      </p>
  
                      <input
                value={res?.youtube_url}
                onChange={(event) => {
                  let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,youtube_url:event.target.value}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                                  this.setState({ allCategories: updatedArray });
                }}
                placeholder="Youtube Link"
                type="text"
                style={{
                  width: "50%",
                  border: 0,
                  marginLeft: "20px",
                  backgroundColor: "#DFE4E8",
                  height: "40px",
                }}
              />
                     
                     <input 
                type="file"
                accept="image/jpeg, image/png"
                style={{
                  marginTop: "5px",
                  paddingTop: "7px",
                  marginLeft: "40px",
                }}
                onChange={(event) => {
                  this.onEditImageFile(event,res)
                }}
              />
                    </div>
                  </div>
                        </>
                    ) : (
                      <>
                        <div
                    style={{
                      margin: "20px",
                      backgroundColor: "#ECFBFF",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                    <div className="col-4" >
  
                    </div>
                    <div className="col-4" >
  
                        </div>
                      <div className="col-3" style={{
                        display:"flex",
                        justifyContent:"center"
                      }} >
                        <button
                          onClick={() => {
                            let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,edited:true}; // Increment age by 1 as an example
                            } else {
                              return { ...obj, edited:false };
                            }
                          });
                          this.setState({
                            allCategories:updatedArray
                          })
                          }}
                          style={{
                            backgroundColor: "#D9D7DA",
                            borderRadius: "10px",
                            color: "black",
                            width: "40px",
                            border: "none",
                            height: "40px",
                            margin: "10px",
                            fontSize: "12px",
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="col-1">
                        {res.isUp == false ? (
                          null
                        ) : (
                          <IconButton
                            style={{
                              border: "0px",
                              backgroundColor: "transparent",
                            }}
                            onClick ={()=>{
                              let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,isUp:false}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                          this.setState({
                            allCategories:updatedArray
                          })
                            }}
                           
                          >
                            <ExpandLess style={{fontSize:"30px"}} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "10px",
                          marginTop:"10px"
                        }}
                      >
                        Question:
                      </p>
                      <div
                        style={{
                          width: "70%",
                          border: 0,
                          marginLeft: "20px",
                          backgroundColor: "#DFE4E8",
                          paddingLeft:"10px"
                        }}
                      >
                        {res?.question}
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        Answers:
                      </p>
                      <div
                        style={{
                          width: "70%",
                          border: 0,
                          marginLeft: "20px",
                          backgroundColor: "#DFE4E8",
                          marginTop: "10px",
                          paddingLeft:"10px"
                        }}
                      >
                        {res?.answer}
                      </div>
                    </div>
  
                    <div>
                      <p
                        style={{
                          marginLeft: "10px",
                          marginTop:"20px"
                        }}
                      >
                        Youtube Link :
                      </p>
                      <div
                        style={{
                          width: "50%",
                          border: 0,
                          marginLeft: "20px",
                          backgroundColor: "#DFE4E8",
                          height: "40px",
                          paddingLeft:"10px",
                          paddingTop:"10px",
                        }}
                      >
                        {res?.youtube_url}
                      </div>
                      <div
                        onClick={() => {
  
                          this.setState({
                            imageURL: res?.image_url,
                            imageModal: true,
                          });
                        }}
                        style={{
                          width: "10%",
                          backgroundColor: "#DFE4E8",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingLeft: "5px",
                          marginLeft: "20px",
                          marginTop: "20px",
                        }}
                      >
                        Image
                      </div>
                    </div>
                  </div>
                      </>
                    ) } 
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        margin: "20px",
                        backgroundColor: "#ECFBFF",
                        padding: "10px",
                        flexDirection: "row",
                        display:"flex",
                      }}
                    >
                    <div className="col-11" >
                    <div
                      
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      {res?.question}
                    </div>
                    </div>
                    <div  className="col-11"  >
                    <div
                      >
                        <IconButton
                          style={{
                            border: "0px",
                            backgroundColor: "transparent",
                          }}
                    
                          onClick={() => {
                            let updatedArray = this.state.allCategories.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,isUp:true}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                          this.setState({
                            allCategories:updatedArray
                          })
                          }}
                        >
                    <ExpandMore style={{fontSize:"30px"}} />                            
                        </IconButton>
                      </div>
                    </div>
                     
  
                    </div>
                  </>
                )}
              </>
            );
          })}
          {
            this.state.allCategories?.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center",marginTop:"50px",marginBottom:"50px" }}>
              <Button
                onClick={() => {
                  this.BackPage();
                }}
              >
                <ArrowBack />
              </Button>
              <Button
                onClick={() => {
                  this.nextPage();
                }}
              >
                <ArrowForward />
              </Button>
            </div>
            ): null
          }
          <div style={{
            marginBottom:"80px"
          }} ></div>
                </>
              ) : null
            }
          
          {
            this.state.tab3 == true ? (
              <>
                <div style={{
                  marginLeft:"10px"
                }} >
                <h4 style={{ color: "#000000", padding: "5px",  }}>
                Add Category ( Student Login ):
            </h4>
                <div style={{
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center"
                }} >
                  <p style={{
                    marginLeft:"20px",
                    marginTop:"15px"
                  }} >
                    Category Name :
                  </p>
                  <input 
                  disabled={this.state.loginType == "" ? true : false}
                  placeholder="Category Name"
                  type="text"
                  onChange={(event) => {
                  this.setState({ categoryName: event.target.value });
                }}
                value={this.state.categoryName}
                style={{
                  width: "300px",
                  display: "flex",
                  border: "1px solid black",
                  height:"40px",
                  borderRadius:"10px",
                  marginLeft:"30px",
                  backgroundColor:"transparent",
                  paddingLeft:"10px"
                }}
                   />
                          <button
                          
                          onClick={()=>{
                            if(this.state.categoryName != ""){
                              this.setState({
                                saveCategoryName:true
                              })
                            }
                          }}
                          style={{
                            border: "0px",
                            backgroundColor: "black",
                            marginLeft:"30px",
                            color:"white",
                            width:"30px",
                            height:"30px",
                            borderRadius:"10px"
                          }}
                        >
                            +
                        </button>
                </div>
                </div>
                <h4 style={{ color: "#000000", padding: "5px",  }}>
                Current Category :
            </h4>
            <div style={{
              flexWrap:"wrap",
              display:"flex",
  
            }} >
  
            {this.state.editCategoryArr.map((res) => {
                        return (
                           <>
                          {
                            res?.edited == false ? (
                              <div  style={{
                              backgroundColor:"#DFE4E8",
                              borderRadius:"10px",
                              display:"flex",
                              flexDirection:"row",
                              justifyContent:"space-between",
                              width:"30%",
                              marginLeft:"20px",
                              marginTop:"20px"
                            }} >
                              <p style={{
                                marginTop:"10px",
                                marginLeft:"5px"
                              }} >
                              {res?.category_name}
                              </p>
                            <IconButton
                            onClick={()=>{
                              let updatedArray = this.state.editCategoryArr.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,edited:true}; // Increment age by 1 as an example
                            } else {
                              return { ...obj, edited:false };
                            }
                          });
                          this.setState({
                            editCategoryArr:updatedArray
                          })
                            }}    
                            >
                              <EditIcon />
                            </IconButton>
                            </div>
                            )  : (
                              <div  style={{
                              backgroundColor:"#DFE4E8",
                              borderRadius:"10px",
                              display:"flex",
                              flexDirection:"row",
                              justifyContent:"space-between",
                              width:"30%",
                              marginLeft:"20px",
                              marginTop:"20px"
                            }} >
                            <input
                style={{
                  border: "0px",
                              backgroundColor: "transparent",
                }}
                type="text"
                  onChange={(event) => {
  
                    let updatedArray = this.state.editCategoryArr.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,category_name:event.target.value}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                                  this.setState({ editCategoryArr: updatedArray });
                                }}
                                value={res.category_name}
                            />
                             
                              <div style={{
                                display:"flex",
                                flexDirection:"row"
                              }} >
                              <IconButton
  
                        onClick={()=>{
                              let updatedArray = this.state.editCategoryArr.map(obj => {
                            if (obj.id === res?.id) {
                              return { ...obj,edited:false}; // Increment age by 1 as an example
                            } else {
                              return { ...obj };
                            }
                          });
                          this.setState({
                            editCategoryArr:updatedArray
                          })
                            }}                              
                            >
                          <ClearIcon />
                            </IconButton>
                            <IconButton
                            onClick={()=>{
                              this.setState({
                                currentCategoy:res,
                              editCategoryName:true
                              })
                            }}
                            >
                          <SaveIcon />
                            </IconButton>
                            <IconButton
                            onClick={()=>{
                              this.setState({
                                currentCategoy:res,
                                deleteCategoryName:true,
                              })
                            }}
                            >
                              <DeleteIcon />
                            </IconButton>
                              </div>
  
                            </div>
                            )
                          }
                           </>
                        );
                      })}
            </div>
                </>
            ) : null
          }
  
        </>
      );
    }
  }
  