import React from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { getSuperAdminService, getHMService } from "../../service/service";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "react-bootstrap-typeahead/css/Typeahead.css";
import * as constants from "./../../common/constants";
import Snackbar from "components/Snackbar/Snackbar";
import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import csv from "../../assets/user.csv";
import hmCsv from "../../assets/SchoolHM.csv";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "9em",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding:'5px'
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "68%",
    paddingBottom: "15px",
  },
  uploadFile: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  uploadButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}

export default class UserProfile extends React.Component {
  state = {
    userDetail: {
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      email: "",
      user_type: "",
      assigned_principal: "",
    },
    adminList: [],
    state: null,
    board: null,
    district: null,
    message: "",
    error: false,
    loading: false,
    className: [],
    classList: [
      { id: 1, label: "1st" },
      { id: 2, label: "2st" },
    ],
    selectedClass: [],
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    adminList: [],
    validations: {
      state: {
        valid: false,
        touched: false,
      },
      board: {
        valid: false,
        touched: false,
      },
      district: {
        valid: false,
        touched: false,
      },
      class: {
        valid: false,
        touched: false,
      },
      userType: {
        valid: false,
        touched: false,
      },
      assignAdmin: {
        valid: false,
        touched: false,
      },
      first_name: {
        valid: false,
        touched: false,
      },
      middle_name: {
        valid: false,
        touched: false,
      },
      last_name: {
        valid: false,
        touched: false,
      },
      email: {
        valid: false,
        touched: false,
      },
      mobile: {
        valid: false,
        touched: false,
      }
    },
    bulkValidation: {
      classUpload: {
        valid: false,
        touched: false,
      },
      stateUpload: {
        valid: false,
        touched: false,
      },
      boardUpload: {
        valid: false,
        touched: false,
      },
      syllabusUpload: {
        valid: false,
        touched: false,
      },
      assignAdmin: {
        valid: false,
        touched: false,
      }
    },
    userFile: null,
    uploadClassName: [],
    uploadSelectedClass: [],
    uploadState: null,
    uploadBoard: null,
    uploadDistrict: null,
    uploadLoading: false,
    principalDetail: {
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      email: "",
      user_type: "",
      assigned_principal: "",
      isSetAuto: false,
      isSetAutoHM: false,
      adminName: "",
      filterAdmin: [],
      adminId: "",
      userButton: false
    },
    tabIndex: 0,
    HMuserFile: null,
    addState: {
      helperText: null,
      value: null,
    },
    addDistrict: {
      helperText: null,
      value: null,
    },
    addBlock: {
      helperText: null,
      value: null,
    },
    block: {
      helperText: null,
      value: null,
    },
    addDistricts : [],
    addBlocks : [],
    state: {
      helperText: null,
      value: null,
    },
    district: {
      helperText: null,
      value: null,
    },
    districts: [],
    blocks: [],
    school: {
      helperText: null,
      value: null,
    },
    schools :[],
    HMDetail: {
      first_name: "",
      middle_name : "",
      last_name: "",
      mobile: "",
      email: "",
      user_type : "HEADMASTER"
    },
    searchText: {
      value: "",
      helperText: null,
    },
    filteredSchools:[]
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    try {
      let stateReq = getSuperAdminService().getAllState();
      let allAdminReq = getSuperAdminService().getAllAdminList();
      let results = await Promise.all([stateReq, allAdminReq]);
      const states = results[0].data.response;
      const allAdmins = results[1].data.response;
      const sortedAdmins = allAdmins.sort((a, b) => a.fullname.localeCompare(b.fullname))
      this.setState({
        stateList: states,
        adminList: sortedAdmins,
        filterAdmin: sortedAdmins
      });
    } catch (error) {
      console.log(error);
    }
  }

  onChangeText(e) {
    let { userDetail } = this.state;
    let updatedValidations = { ...this.state.validations };
    updatedValidations[e.target.name].touched = true;
    if (e.target.name == "mobile") {
      updatedValidations[e.target.name].valid =
        e.target.value.toString().length == 10 ? true : false;
    } else if (e.target.name == "email") {
      var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      updatedValidations[e.target.name].valid =
        String(e.target.value).search(filter) != -1;
    } else {
      updatedValidations[e.target.name].valid = e.target.value ? true : false;
    }
    userDetail = { ...userDetail, [e.target.name]: e.target.value };
    this.setState({
      userDetail,
      validations: updatedValidations,
    });
  }

  onChangeHMText = (e) =>{
    let { HMDetail } = this.state;
    let updatedValidations = { ...this.state.validations };
    updatedValidations[e.target.name].touched = true;
    if (e.target.name == "mobile") {
      updatedValidations[e.target.name].valid =
        e.target.value.toString().length == 10 ? true : false;
    } else if (e.target.name == "email") {
      var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      updatedValidations[e.target.name].valid =
        String(e.target.value).search(filter) != -1;
    } else {
      updatedValidations[e.target.name].valid = e.target.value ? true : false;
    }
    HMDetail = { ...HMDetail, [e.target.name]: e.target.value };
    this.setState({
      HMDetail,
      validations: updatedValidations,
    });
  }

  async onCreateUser() {
    this.setState({
      loading: true,
      userButton: true
    });
    try {
      let data = this.state.userDetail;
      let res = await getSuperAdminService().addAdminContentWriter(data);
      res = res.data;
      if (res && res.status === 200) {
        let { selectedClass, state, board, district } = this.state;
        let classDetail = [];
        selectedClass.map((data, index) => {
          classDetail.push({
            state_id: state,
            board_id: board,
            district_id: district,
            class_id: data.class_id,
          });
        });
        let apiData = {
          user_id: res.response.user_id,
          class_details: classDetail,
        };
        getSuperAdminService()
          .assignClassesToUser(apiData)
          .then((res) => {
            this.setState(
              {
                error: false,
                loading: false,
                message: "User created successfully",
              },
              () => {
                console.log(this.props, "props from component")
                setTimeout(() => {
                  this.props.onRedirect()
                }, 1000);
              }
            );
          });
      } else if (
        res.status === 500 ||
        res.message.toString().startsWith("Mobile number") ||
        res.message.hasOwnProperty("mobile")
      ) {
        this.setState({
          loading: false,
          message: "Mobile number or email address is already taken",
          error: true,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        message: "Something has went wrong",
        error: true,
      });
    }
  }

  async onUploadUsers() {
    this.setState({
      uploadLoading: true,
    });
    let classDetail = this.state.uploadClassName.map(val => {
      return {
        state_id: this.state.uploadState,
        board_id: this.state.uploadBoard,
        district_id: this.state.uploadDistrict,
        class_id: val
      }
    })
    try {
      let data = new FormData();
      data.append("class_details", JSON.stringify(classDetail));
      data.append("assigned_principal", this.state.principalDetail.assigned_principal)
      data.append("filename", this.state.userFile)
      let res = await getSuperAdminService().bulkUploadUsers(data);
      res = res.data;
      if (res && res.status === 200) {
        this.setState(
          {
            error: false,
            uploadLoading: false,
            message: "User created successfully",
          },
          () => {
            console.log(this.props, "props from component")
            setTimeout(() => {
              this.props.onRedirect()
            }, 1000);
          });
      }
    } catch (error) {
      this.setState({
        uploadLoading: false,
        message: "Something has went wrong",
        error: true,
      });
    }
  }

  onCreateHMUser = async() =>{
    let {HMDetail , school} = this.state;
    this.setState({
      loading: true
    });
    try {
      let data = {
      ...HMDetail,
      school_id : school.value
      }
      console.log(data,"data onCreateHMUser")
      let res = await getSuperAdminService().addAdminContentWriter(data);
      res = res.data;
      console.log(res,"res")
      if (res && res.status === 200) {
        this.setState(
          {
            error: false,
            loading: false,
            message: res.message,
          },
          ()=>{
            // window.location.reload()
          });
      }
      else{
        this.setState(
          {
            // error: false,
            loading: false,
            message: res.message,
          },
          ()=>{
            // window.location.reload()
          });
      }
    }
    catch(e){
      this.setState(
        {
          // error: false,
          loading: false,
          // message: res.message,
        },)
      console.log(e,"e error occured")
    }
  }

  ValiadateAMuser  = () =>{
    for (var key in this.state.HMDetail) {
      console.log(this.state.HMDetail[key],"this.state.HMDetail[key]")
      if (this.state.HMDetail[key] !== null && this.state.HMDetail[key] != "")
          return false;
  }
  return true;
  
  }
  onCreateAMUser = async() =>{
    let {HMDetail , school,block,district} = this.state;
    this.setState({
      loading: true
    });
    console.log(Object.values(this.state.HMDetail).includes(''))
  
   
   if(!Object.values(this.state.HMDetail).includes(''))
  {
    delete this.state.HMDetail.user_type
    try {
    let data ={}
    if(block.value)
    {
      data = {
      ...HMDetail,
      student_district_id : district.value,
       student_block_id  :block.value 
      }

    }
    else{
      data = {
        ...HMDetail,
        student_district_id : district.value,
       
        }
    }
    
    console.log(data,"data onCreateHMUser")
    let res = await getSuperAdminService().createAMUser(data);
    res = res.data;
    console.log(res,"res")
    if (res) {
      this.setState(
        {
          error: false,
          loading: false,
          message: res.data,
        },
        ()=>{
          // window.location.reload()
        });
    }
    else{
      this.setState(
        {
          // error: false,
          loading: false,
          message: res.data,
        },
        ()=>{
          // window.location.reload()
        });
    }
  }
  catch(e){
    this.setState(
      {
        // error: false,
        loading: false,
        message: e.response.data.data,
      },)
    console.log(e.response,"e error occured")
  }}
  }

  renderStateMenuHM(type){
    let {stateList , districts , blocks ,schools} = this.state;
    let array =
    type == "state"
      ? stateList
        : type == "block"
          ? blocks : 
          type == "school" ? schools
          : districts;
  return array.map((state, index) => {
    let value =
      type == "state"
        ? state.state_id
        : type == "block"
            ? state.student_block_id :
            type == "school" ? 
            state.school_id
            : state.student_district_id;
    let name =
      type == "state"
        ? state.state_name
        : type == "block"
            ? state.block_name :
            type == "school" ?
            state.school_name
            : state.district_name;
    return (
      <MenuItem key={index + "state"} value={value}>
        {name}
      </MenuItem>
    );
  });
  }

  renderStateMenu(type) {
    let { stateList, boardList, districtList, classList } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "board"
          ? boardList
          : type == "class"
            ? classList
            : districtList;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "board"
            ? state.board_id
            : type == "class"
              ? state.class_id
              : state.district_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "board"
            ? state.board_name
            : type == "class"
              ? state.class_name
              : state.district_name;
      return (
        <MenuItem key={index + "state"} value={value}>
          {name}
        </MenuItem>
      );
    });
  }

  renderAdminList() {
    let { adminList } = this.state;
    return adminList.map((admin, index) => {
      return (
        <MenuItem key={"admin" + index} value={admin.user_id}>
          {admin.fullname}
        </MenuItem>
      );
    });
  }

  getBoardByState = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.state.touched = true;
    updatedValidations.state.valid = e.target.value ? true : false;
    this.setState(
      {
        state: e.target.value,
        validations: updatedValidations,
      },
      async () => {
        let data = { state_id: this.state.state };
        let res = await getSuperAdminService().getAllBoardFromState(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ boardList: res.response });
        }
      }
    );
  };

  getBoardByStateForBulk = (e) => {
    let updatedValidations = { ...this.state.bulkValidation };
    updatedValidations.stateUpload.touched = true;
    updatedValidations.stateUpload.valid = e.target.value ? true : false;
    this.setState(
      {
        uploadState: e.target.value,
        bulkValidation: updatedValidations,
      },
      async () => {
        let data = { state_id: this.state.uploadState };
        let res = await getSuperAdminService().getAllBoardFromState(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ boardList: res.response });
        }
      }
    );
  };

  getDistrictByBoard = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.board.touched = true;
    updatedValidations.board.valid = e.target.value ? true : false;
    this.setState(
      {
        board: e.target.value,
        validations: updatedValidations,
      },
      async () => {
        let data = { board_id: this.state.board };
        let res = await getSuperAdminService().getAllDistrictFromBoard(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ districtList: res.response });
        }
      }
    );
  };

  getDistrictByBoardForBulk = (e) => {
    let updatedValidations = { ...this.state.bulkValidation };
    updatedValidations.boardUpload.touched = true;
    updatedValidations.boardUpload.valid = e.target.value ? true : false;
    this.setState(
      {
        uploadBoard: e.target.value,
        bulkValidation: updatedValidations,
      },
      async () => {
        let data = { board_id: this.state.uploadBoard };
        let res = await getSuperAdminService().getAllDistrictFromBoard(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ districtList: res.response });
        }
      }
    );
  };

  getClassesByDistrict = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.district.touched = true;
    updatedValidations.district.valid = e.target.value ? true : false;
    this.setState(
      {
        district: e.target.value,
        validations: updatedValidations,
      },
      async () => {
        let data = { district_id: this.state.district };
        let res = await getSuperAdminService().getAllClassesFromDistrict(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ classList: res.response });
        }
      }
    );
  };

  getClassesByDistrictForBulk = (e) => {
    let updatedValidations = { ...this.state.bulkValidation };
    updatedValidations.syllabusUpload.touched = true;
    updatedValidations.syllabusUpload.valid = e.target.value ? true : false;
    this.setState(
      {
        uploadDistrict: e.target.value,
        bulkValidation: updatedValidations,
      },
      async () => {
        let data = { district_id: this.state.uploadDistrict };
        let res = await getSuperAdminService().getAllClassesFromDistrict(data);
        res = res.data;
        if (res && res.status == 200) {
          this.setState({ classList: res.response });
        }
      }
    );
  };

  handleClassChange = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.class.touched = true;
    updatedValidations.class.valid = e.target.value ? true : false;
    let updatedSelectedClasses = e.target.value.map((id) => {
      let itemIndex = this.state.classList.findIndex((i) => i.class_id === id);
      if (itemIndex > -1) {
        return this.state.classList[itemIndex];
      }
    });
    this.setState({
      className: e.target.value,
      selectedClass: updatedSelectedClasses,
      validations: updatedValidations,
    });
  };

  handleClassChangeForUpload = (e) => {
    let updatedValidations = { ...this.state.bulkValidation };
    updatedValidations.classUpload.touched = true;
    updatedValidations.classUpload.valid = e.target.value ? true : false;
    let updatedSelectedClasses = e.target.value.map((id) => {
      let itemIndex = this.state.classList.findIndex((i) => i.class_id === id);
      if (itemIndex > -1) {
        return this.state.classList[itemIndex];
      }
    });
    this.setState({
      uploadClassName: e.target.value,
      uploadSelectedClass: updatedSelectedClasses,
      bulkValidation: updatedValidations,
    });
  };

  isFormValid = () => {
    let isFormValid = true;
    for (let field in this.state.validations) {
      if (!this.state.validations[field].valid) {
        isFormValid = false;
      }
    }
    return !isFormValid;
  };

  isBulkFormValid = () => {
    let { bulkValidation, userFile } = this.state;
    let isFormValid = true;
    let isFieldsValidated = true;
    for (let field in bulkValidation) {
      if (!bulkValidation[field].valid) {
        isFieldsValidated = false;
      }
    }
    if (!isFieldsValidated || userFile == null) {
      isFormValid = false
    }
    return !isFormValid;
  };

  onBulkUploadFile = (file) => {
    this.setState({
      userFile: file
    })
  }
  setOpen = (val) => {
    this.setState({
      isSetAuto: val
    })
  }
  setOpenHM = (val) => {
    this.setState({
      isSetAutoHM: val
    })
  }

  onUploadHMUser = (file) => {
    this.setState({
      HMuserFile: file
    })
  }

  onChangeHandle = (val) => {
    console.log(val, "val");

    let { userDetail } = this.state;
    let updatedValidations = { ...this.state.validations };
    updatedValidations.assignAdmin.touched = true;
    updatedValidations.assignAdmin.valid = val
      ? true
      : false;
    userDetail = {
      ...userDetail,
      assigned_principal: val.user_id,
    };
    this.setState({
      userDetail,
      validations: updatedValidations,
    });
    console.log(this.state.userDetail);
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue,
      HMDetail: {
        first_name: "",
        middle_name : "",
        last_name: "",
        mobile: "",
        email: "",
        user_type : "HEADMASTER"
      },
      district: {
        value: "",
        helperText: null,
      },
      block: {
        helperText: null,
        value: "",
      },
    loading: false });
  };

  onHMSubmitFileUpload = () => {
    let { HMuserFile } = this.state;
    this.setState({
      uploadLoading: true
    })
    let formData = new FormData();
    formData.append("filename", HMuserFile)
    getHMService().uploadHMUserByCsv(formData).then(res => {
      this.setState({
        uploadLoading: false,
        error: false,
        message: 'HM Details Uploaded'
      })
    }, err => {
      console.log(err, "err frm HM upload")
      this.setState({
        uploadLoading: false,
        error: true,
        message: 'Something went wrong'
      })
    })
  }

  getStudentDistrict = async(state_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
              this.setState(
                {
                  districts: response.response,
                  // addDistricts: response.response,
                  district: {
                    value:
                      response.response.length > 0
                        ? response.response[0].student_district_id
                        : null,
                    helperText: null,
                  },
                },
                () => {
                  this.getStudentBlock(this.state.districts[0].student_district_id, this.state.singleSchoolFlag);
                } 
              );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
            addDistricts: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getStudentBlock = async(district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
              this.setState(
                {
                  blocks: response.response,
                  addBlocks: response.response,
                  block: {
                    value:
                      response.response.length > 0
                        ? response.response[0].student_block_id
                        : null,
                    helperText: null,
                  },
                },
                () => {
                  this.getSchools(this.state.block.value);
                } 
              );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getStudentDistrictForAM = async(state_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
              this.setState(
                {
                  districts: response.response,
                  // addDistricts: response.response,
                  district: {
                    value:
                      response.response.length > 0
                        ? response.response[0].student_district_id
                        : null,
                    helperText: null,
                  },
                },
                () => {
                  this.getStudentBlockForAM(this.state.districts[0].student_district_id, this.state.singleSchoolFlag);
                } 
              );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
            addDistricts: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  getStudentBlockForAM = async(district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
              this.setState(
                {
                  blocks: response.response,
                  addBlocks: response.response,
                  // block: {
                  //   value:
                  //     response.response.length > 0
                  //       ? response.response[0].student_block_id
                  //       : null,
                  //   helperText: null,
                  // },
                } 
              );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  onChangeSchoolHandle = (searchContent) => {
    const param = {
      student_block_id: this.state.block.value,
      searchText: searchContent,
    };
     getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        console.log(response,"response")
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  }

  getSchools = async(block) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };
  

  render() {
  const { validations, message, error, loading, uploadLoading, bulkValidation, tabIndex, state, district ,block ,school , schools,searchText} = this.state;
    return (
      <div>

        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              message: "",
            })
          }
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary">
          <Tab label="create User" {...this.a11yProps(0)}></Tab>
          <Tab label="upload User" {...this.a11yProps(1)}></Tab>
          <Tab label="Create HM User" {...this.a11yProps(2)}></Tab>
          <Tab label="upload HM Users" {...this.a11yProps(3)}></Tab>
          <Tab label="Create AM User" {...this.a11yProps(4)}></Tab>
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <GridContainer>
            <GridItem >
              <Card style={{ boxShadow: "none" }}>
                <CardHeader >
                  <h4 >Create Profile</h4>
                  <span>
                    Fill in the basic details to create an Admin or Content Writer
                </span>
                </CardHeader>
                <CardBody>
                  <div
                    className="row">
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.state.valid && validations.state.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">State</InputLabel>
                          <Select
                            style={{ width: "8em" }}
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={this.state.state}
                            onChange={(e) => this.getBoardByState(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...validations };
                              updatedValidations.state.touched = true;
                              this.setState({
                                validations: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("state")}
                          </Select>
                          <FormHelperText>
                            {!validations.state.valid && validations.state.touched
                              ? "State is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.board.valid && validations.board.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">Board</InputLabel>
                          <Select
                            style={{ width: "8em" }}
                            labelId="demo-controlled-open-select-label1"
                            id="demo-controlled-open-select1"
                            value={this.state.board}
                            onChange={(e) => this.getDistrictByBoard(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...validations };
                              updatedValidations.board.touched = true;
                              this.setState({
                                validations: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("board")}
                          </Select>
                          <FormHelperText>
                            {!validations.board.valid && validations.board.touched
                              ? "Board is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.district.valid &&
                            validations.district.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">
                            Syllabus Set
                    </InputLabel>
                          <Select
                            style={{ width: "8em" }}
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            value={this.state.district}
                            onChange={(e) => this.getClassesByDistrict(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...validations };
                              updatedValidations.district.touched = true;
                              this.setState({
                                validations: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("district")}
                          </Select>
                          <FormHelperText>
                            {!validations.district.valid &&
                              validations.district.touched
                              ? " Syllabus Set is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.class.valid && validations.class.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">Class</InputLabel>
                          <Select
                            style={{ width: "8em" }}
                            multiple
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            value={this.state.className}
                            onChange={(e) => this.handleClassChange(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...validations };
                              updatedValidations.class.touched = true;
                              this.setState({
                                validations: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("class")}
                          </Select>
                          <FormHelperText>
                            {!validations.class.valid && validations.class.touched
                              ? "Class is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="First Name"
                          id="first_name"
                          name="first_name"
                          error={
                            !validations.first_name.valid &&
                            validations.first_name.touched
                          }
                          helperText={
                            !validations.first_name.valid &&
                              validations.first_name.touched
                              ? "First name is required"
                              : ""
                          }
                          value={this.state.userDetail.first_name}
                          onChange={this.onChangeText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.first_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Middle Name"
                          id="middle_name"
                          name="middle_name"
                          error={
                            !validations.middle_name.valid &&
                            validations.middle_name.touched
                          }
                          helperText={
                            !validations.middle_name.valid &&
                              validations.middle_name.touched
                              ? "Middle name is required"
                              : ""
                          }
                          value={this.state.userDetail.middle_name}
                          onChange={this.onChangeText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.middle_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Last Name"
                          id="last_name"
                          name="last_name"
                          value={this.state.userDetail.last_name}
                          onChange={this.onChangeText.bind(this)}
                          error={
                            !validations.last_name.valid &&
                            validations.last_name.touched
                          }
                          helperText={
                            !validations.last_name.valid &&
                              validations.last_name.touched
                              ? "Last name is required"
                              : ""
                          }
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.last_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Email"
                          id="email"
                          name="email"
                          value={this.state.userDetail.email}
                          onChange={this.onChangeText.bind(this)}
                          error={
                            !validations.email.valid && validations.email.touched
                          }
                          helperText={
                            !validations.email.valid && validations.email.touched
                              ? "Enter valid email address"
                              : ""
                          }
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.email.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="Number"
                          label="Mobile Number"
                          id="mobile"
                          name="mobile"
                          value={this.state.userDetail.mobile}
                          onChange={this.onChangeText.bind(this)}
                          error={
                            !validations.mobile.valid && validations.mobile.touched
                          }
                          helperText={
                            !validations.mobile.valid && validations.mobile.touched
                              ? "Enter valid mobile number"
                              : ""
                          }
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.mobile.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.userType.valid &&
                            validations.userType.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">
                            User Type
                    </InputLabel>
                          <Select
                            style={{ width: "8em" }}
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={this.state.userDetail.user_type}
                            onChange={(e) => {
                              let { userDetail } = this.state;
                              let updatedValidations = { ...this.state.validations };
                              updatedValidations.userType.touched = true;
                              updatedValidations.userType.valid = e.target.value
                                ? true
                                : false;
                              userDetail = {
                                ...userDetail,
                                user_type: e.target.value,
                              };
                              this.setState({
                                userDetail,
                                validations: updatedValidations,
                              });
                            }}
                            onClick={(e) => {
                              let updatedValidations = { ...validations };
                              updatedValidations.userType.touched = true;
                              this.setState({
                                validations: updatedValidations,
                              });
                            }}
                          >
                            <MenuItem key={1} value={constants.USER_TYPE.ADMIN}>
                              {"ADMIN"}
                            </MenuItem>
                            <MenuItem
                              key={2}
                              value={constants.USER_TYPE.CONTENT_WRITER}
                            >
                              {"CONTENT WRITER"}
                            </MenuItem>
                            <MenuItem key={3} value={constants.USER_TYPE.SUPER_ADMIN}>
                              {"SUPER ADMIN"}
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            {!validations.userType.valid &&
                              validations.userType.touched
                              ? "User type is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !validations.assignAdmin.valid &&
                            validations.assignAdmin.touched
                          }
                        >
                          {/* <InputLabel id="demo-simple-select-label">
                      Assign Admin
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={this.state.userDetail.assigned_principal}
                      onChange={(e) => {
                        let { userDetail } = this.state;
                        let updatedValidations = { ...this.state.validations };
                        updatedValidations.assignAdmin.touched = true;
                        updatedValidations.assignAdmin.valid = e.target.value
                          ? true
                          : false;
                        userDetail = {
                          ...userDetail,
                          assigned_principal: e.target.value,
                        };
                        this.setState({
                          userDetail,
                          validations: updatedValidations,
                        });
                      }}
                      onClick={(e) => {
                        let updatedValidations = { ...validations };
                        updatedValidations.assignAdmin.touched = true;
                        this.setState({
                          validations: updatedValidations,
                        });
                      }}
                    >
                      {this.renderAdminList()}
                      {console.log(this.state.adminList)}
                    </Select> */}
                          <Autocomplete
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            style={{ width: "9em" }}
                            open={this.state.isSetAuto}
                            disableClearable
                            onOpen={() => {
                              this.setOpen(true);
                            }}
                            onClose={() => {
                              this.setOpen(false);
                            }}
                            onChange={(event, value) => {
                              this.setState({
                                adminName: event.target.value,
                                adminId: value.user_id
                              })
                              this.onChangeHandle(value);
                              console.log("admin ID", value);
                            }}
                            getOptionLabel={(option) => option.fullname}
                            options={this.state.filterAdmin}
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search admin "
                                style={{ marginTop: "1em", width: "10em" }}
                                // onChange={(ev) => {
                                //   if (
                                //     ev.target.value !== "" ||
                                //     ev.target.value !== null
                                //   ) {
                                //     // this.onChangeHandle(ev.target.value,params);
                                //   }
                                // }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {console.log(this.state.adminId)}
                          {console.log(this.state.filterAdmin)}
                          <FormHelperText>
                            {!validations.assignAdmin.valid &&
                              validations.assignAdmin.touched
                              ? "Admin is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                </CardBody>
                <CardFooter style={styles.cardFooter}>
                  {!loading ? (
                    <Button
                      color="primary"
                      disabled={this.isFormValid()}
                      onClick={this.onCreateUser.bind(this)}
                    >
                      Create Profile
                    </Button>
                  ) : (
                      <CircularProgress color="primary" />
                    )}
                  {this.state.userButton ? <Button
                    color="primary"
                    // disabled={this.isFormValid()}
                    onClick={() => {
                      this.setState(
                        {
                          state: "",
                          board: "",
                          district: "",
                          className: [],
                          userDetail: {
                            first_name: "",
                            middle_name: "",
                            last_name: "",
                            mobile: "",
                            email: "",
                            user_type: "",
                            assigned_principal: "",

                          },

                        })
                    }}
                  >
                    add another user
                  </Button> : null}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <GridContainer>
            <GridItem>
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <h4>Bulk upload users</h4>
                  <span>
                    Fill in the basic details to create an Admin or Content Writer
                </span>
                  <div className="row">
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !bulkValidation.stateUpload.valid && bulkValidation.stateUpload.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">State</InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={this.state.uploadState}
                            onChange={(e) => this.getBoardByStateForBulk(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...bulkValidation };
                              updatedValidations.stateUpload.touched = true;
                              this.setState({
                                bulkValidation: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("state")}
                          </Select>
                          <FormHelperText>
                            {!bulkValidation.stateUpload.valid && bulkValidation.stateUpload.touched
                              ? "State is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !bulkValidation.boardUpload.valid && bulkValidation.boardUpload.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">Board</InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label1"
                            id="demo-controlled-open-select1"
                            value={this.state.uploadBoard}
                            onChange={(e) => this.getDistrictByBoardForBulk(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...bulkValidation };
                              updatedValidations.boardUpload.touched = true;
                              this.setState({
                                bulkValidation: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("board")}
                          </Select>
                          <FormHelperText>
                            {!bulkValidation.boardUpload.valid && bulkValidation.boardUpload.touched
                              ? "Board is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !bulkValidation.syllabusUpload.valid &&
                            bulkValidation.syllabusUpload.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">
                            Syllabus Set
                    </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            value={this.state.uploadDistrict}
                            onChange={(e) => this.getClassesByDistrictForBulk(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...bulkValidation };
                              updatedValidations.syllabusUpload.touched = true;
                              this.setState({
                                bulkValidation: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("district")}
                          </Select>
                          <FormHelperText>
                            {!bulkValidation.syllabusUpload.valid &&
                              bulkValidation.syllabusUpload.touched
                              ? " Syllabus Set is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !bulkValidation.assignAdmin.valid &&
                            bulkValidation.assignAdmin.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">
                            Assign Principal
                    </InputLabel>
                          <Select
                            style={{ width: "10em" }}
                            labelId="demo-controlled-open-select-labe4"
                            id="demo-controlled-open-select4"
                            value={this.state.principalDetail.assigned_principal}
                            onChange={(e) => {
                              let { principalDetail } = this.state;
                              let updatedValidations = { ...this.state.bulkValidation };
                              updatedValidations.assignAdmin.touched = true;
                              updatedValidations.assignAdmin.valid = e.target.value
                                ? true
                                : false;
                              principalDetail = {
                                ...principalDetail,
                                assigned_principal: e.target.value,
                              };
                              this.setState({
                                principalDetail,
                                bulkValidation: updatedValidations,
                              });
                            }}
                            onClick={(e) => {
                              let updatedValidations = { ...bulkValidation };
                              updatedValidations.assignAdmin.touched = true;
                              this.setState({
                                bulkValidation: updatedValidations,
                              });
                            }}
                          >
                            {this.renderAdminList()}
                          </Select>
                          <FormHelperText>
                            {!bulkValidation.assignAdmin.valid &&
                              bulkValidation.assignAdmin.touched
                              ? "Admin is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl
                          style={styles.dropdown}
                          error={
                            !bulkValidation.classUpload.valid && bulkValidation.classUpload.touched
                          }
                        >
                          <InputLabel id="demo-simple-select-label">Class</InputLabel>
                          <Select
                            multiple
                            labelId="demo-controlled-open-select-label3"
                            id="demo-controlled-open-select3"
                            value={this.state.uploadClassName}
                            onChange={(e) => this.handleClassChangeForUpload(e)}
                            onClick={(e) => {
                              let updatedValidations = { ...bulkValidation };
                              updatedValidations.classUpload.touched = true;
                              this.setState({
                                bulkValidation: updatedValidations,
                              });
                            }}
                          >
                            {this.renderStateMenu("class")}
                          </Select>
                          <FormHelperText>
                            {!bulkValidation.classUpload.valid && bulkValidation.classUpload.touched
                              ? "Class is required"
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl style={{ marginTop: "4em" }} >
                          <input style={{ marginTop: "3em" }} type="file" accept=".csv" onChange={(event) => this.onBulkUploadFile(event.target.files[0])} style={styles.uploadFile} />
                          <div>Download sample file <a href={csv} download="User.csv">here</a></div>
                        </FormControl>
                      </div>
                    </div>
                    <div style={styles.uploadButton}>
                      {!uploadLoading ? (
                        <Button
                          color="primary"
                          disabled={this.isBulkFormValid()}
                          onClick={this.onUploadUsers.bind(this)}
                        >
                          Upload
                        </Button>
                      ) : (
                          <CircularProgress color="primary" />
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <GridContainer>
            <GridItem>
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <h4>Create Head Master</h4>
                  <span>
                    Please fill data to create HM suser.
                </span>
                <div className="row">
                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="state-label">Select State</span>
                    <Select
                      labelId="state-label"
                      id="state"
                      value={state.value}
                      error={state.helperText}
                      helperText={!state.value && state.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            state: { value: e.target.value, helperText: null },
                            searchText: { value: "", helperText: null },
                            schools :[],
                          }, () => {                              
                            this.getStudentDistrict(this.state.state.value, this.state.singleSchoolFlag)
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("state")}

                    </Select>
                  </FormControl>
                  </div>
                </div>

                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="District">Select District</span>
                    <Select
                      labelId="District"
                      id="District_dropdown"
                      value={district.value}
                      error={district.helperText}
                      helperText={!district.value && district.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            district: {
                              value: e.target.value,
                              helperText: null,
                            },
                            block: {
                              helperText: null,
                              value: "",
                            },
                            school: {
                              helperText: null,
                              value: "",
                            },
                            schools :[],
                            searchText: { value: "", helperText: null },
                          }, () => {
                            this.getStudentBlock(this.state.district.value, this.state.singleSchoolFlag);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("district")}
                    </Select>
                  </FormControl>
                  </div>
                </div>

                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="Block">Select Block</span>
                    <Select
                      labelId="Block"
                      id="Block_dropdown"
                      value={block.value}
                      error={block.helperText}
                      helperText={!block.value && block.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            block: {
                              value: e.target.value,
                              helperText: null,
                            },
                            updateBlock: {
                              value: e.target.value,
                              helperText: null,
                            },
                            school: "",
                            searchText: { value: "", helperText: null },
                          },
                          () => {                  
                            this.getSchools(this.state.block.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("block")}
                    </Select>
                  </FormControl>
                  </div>
                </div>

                <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="School">Select School</span>
                    {/* <Select
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            school: {
                              value: e.target.value,
                              helperText: null,
                            },
                            updateschool: {
                              value: e.target.value,
                              helperText: null,
                            },
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("school")}
                    </Select> */}

                    <Autocomplete
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            style={{ width: "9em" }}
                            open={this.state.isSetAuto}
                            disableClearable
                            onOpen={() => {
                              this.setOpen(true);
                            }}
                            onClose={() => {
                              this.setOpen(false);
                            }}
                            onChange={(event, value) => {
                              this.setState(
                                {
                                  school: {
                                    value: value.school_id,
                                    helperText: null,
                                  },
                                  updateschool: {
                                    value: value.school_id,
                                    helperText: null,
                                  },
                                }
                              );
                              this.onChangeHandle(value);
                              console.log("admin ID", value,event.target.value);
                            }}
                            getOptionLabel={(option) => `${option.school_name} ${option.u_dise}`}
                            options={this.state.filteredSchools}
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                labelId="School"
                                id="School_dropdown"
                                value={school.value}
                                error={school.helperText}
                                helperText={!school.value && school.helperText}
                                style={{  width: "21em" }}
                                onChange={(ev) => {
                                  if (
                                    ev.target.value !== "" ||
                                    ev.target.value !== null
                                  ) {
                                     this.onChangeSchoolHandle(ev.target.value);
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                  </FormControl>
                  </div>
                </div>
               <div>
               
              

                          {console.log(this.state.filteredSchools,"filteredSchools")}
               
                </div>
                <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="First Name"
                          id="first_name"
                          name="first_name"
                          error={
                            !validations.first_name.valid &&
                            validations.first_name.touched
                          }
                          helperText={
                            !validations.first_name.valid &&
                              validations.first_name.touched
                              ? "First name is required"
                              : ""
                          }
                          value={this.state.HMDetail.first_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.first_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Middle Name"
                          id="middle_name"
                          name="middle_name"
                          error={
                            !validations.middle_name.valid &&
                            validations.middle_name.touched
                          }
                          helperText={
                            !validations.middle_name.valid &&
                              validations.middle_name.touched
                              ? "Middle name is required"
                              : ""
                          }
                          value={this.state.HMDetail.middle_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.middle_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Last Name"
                          id="last_name"
                          name="last_name"
                          error={
                            !validations.last_name.valid &&
                            validations.last_name.touched
                          }
                          helperText={
                            !validations.last_name.valid &&
                              validations.last_name.touched
                              ? "Last name is required"
                              : ""
                          }
                          value={this.state.HMDetail.last_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.first_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="email"
                          id="email"
                          name="email"
                          error={
                            !validations.email.valid &&
                            validations.email.touched
                          }
                          helperText={
                            !validations.email.valid &&
                              validations.email.touched
                              ? "Email is required"
                              : ""
                          }
                          value={this.state.HMDetail.email}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.email.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="mobile"
                          id="mobile"
                          name="mobile"
                          error={
                            !validations.mobile.valid &&
                            validations.mobile.touched
                          }
                          helperText={
                            !validations.mobile.valid &&
                              validations.mobile.touched
                              ? "Mobile is required"
                              : ""
                          }
                          value={this.state.HMDetail.mobile}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.mobile.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                <div className="col-6">
                  {loading ? (
                  <div style={{marginRight:'50px'}}>
                    <CircularProgress color="secondary" />
                  </div>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        this.onCreateHMUser();
                      }}
                    >
                      Create User
                    </Button>
                  )}
                </div>
              </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <GridContainer>
            <GridItem>
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <h4>Bulk upload Head Master</h4>
                  <span>
                    Fill and upload the csv to generate data
                </span>
                  <div className="row">
                    <div className="col-8">
                      <div style={styles.inputWrapper}>
                        <FormControl style={{ marginTop: "4em" }} >
                          <input style={{ marginTop: "3em" }} type="file" accept=".csv" onChange={(event) => this.onUploadHMUser(event.target.files[0])} style={styles.uploadFile} />
                          <div>Download sample file <a href={hmCsv} download="SchoolHM.csv">here</a></div>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      {!uploadLoading ? (
                        <Button
                          color="primary"
                          // disabled={this.isBulkFormValid()}
                          onClick={() => this.onHMSubmitFileUpload()}
                        >
                          Upload
                        </Button>
                      ) : (
                          <CircularProgress color="primary" />
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <GridContainer>
            <GridItem>
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <h4>Create AM User</h4>
                  <span>
                    Please fill data to create AM suser.
                </span>
                <div className="row">
                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="state-label">Select State</span>
                    <Select
                      labelId="state-label"
                      id="state"
                      value={state.value}
                      error={state.helperText}
                      helperText={!state.value && state.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            state: { value: e.target.value, helperText: null },
                            searchText: { value: "", helperText: null },
                            schools :[],
                          }, () => {                              
                            this.getStudentDistrictForAM(this.state.state.value, this.state.singleSchoolFlag)
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("state")}

                    </Select>
                  </FormControl>
                  </div>
                </div>

                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="District">Select District</span>
                    <Select
                      labelId="District"
                      id="District_dropdown"
                      value={district.value}
                      error={district.helperText}
                      helperText={!district.value && district.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            district: {
                              value: e.target.value,
                              helperText: null,
                            },
                            block: {
                              helperText: null,
                              value: "",
                            },
                            school: {
                              helperText: null,
                              value: "",
                            },
                            schools :[],
                            searchText: { value: "", helperText: null },
                          }, () => {
                            this.getStudentBlockForAM(this.state.district.value, this.state.singleSchoolFlag);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("district")}
                    </Select>
                  </FormControl>
                  </div>
                </div>

                <div className="col-6">
                  <div style={styles.inputWrapper}>
                  <FormControl fullWidth>
                    <span id="Block">Select Block</span>
                    <Select
                      labelId="Block"
                      id="Block_dropdown"
                      value={block.value}
                      error={block.helperText}
                      helperText={!block.value && block.helperText}
                      onChange={(e) => {
                        this.setState(
                          {
                            block: {
                              value: e.target.value,
                              helperText: null,
                            },
                            updateBlock: {
                              value: e.target.value,
                              helperText: null,
                            },
                            school: "",
                            searchText: { value: "", helperText: null },
                          },
                          () => {                  
                            this.getSchools(this.state.block.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenuHM("block")}
                    </Select>
                  </FormControl>
                  </div>
                </div>

             
               <div>
               
              

                          {console.log(this.state.filteredSchools,"filteredSchools")}
               
                </div>
                <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="First Name"
                          id="first_name"
                          name="first_name"
                          error={
                            !validations.first_name.valid &&
                            validations.first_name.touched
                          }
                          helperText={
                            !validations.first_name.valid &&
                              validations.first_name.touched
                              ? "First name is required"
                              : ""
                          }
                          value={this.state.HMDetail.first_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.first_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Middle Name"
                          id="middle_name"
                          name="middle_name"
                          error={
                            !validations.middle_name.valid &&
                            validations.middle_name.touched
                          }
                          helperText={
                            !validations.middle_name.valid &&
                              validations.middle_name.touched
                              ? "Middle name is required"
                              : ""
                          }
                          value={this.state.HMDetail.middle_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.middle_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="Last Name"
                          id="last_name"
                          name="last_name"
                          error={
                            !validations.last_name.valid &&
                            validations.last_name.touched
                          }
                          helperText={
                            !validations.last_name.valid &&
                              validations.last_name.touched
                              ? "Last name is required"
                              : ""
                          }
                          value={this.state.HMDetail.last_name}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.first_name.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="email"
                          id="email"
                          name="email"
                          error={
                            !validations.email.valid &&
                            validations.email.touched
                          }
                          helperText={
                            !validations.email.valid &&
                              validations.email.touched
                              ? "Email is required"
                              : ""
                          }
                          value={this.state.HMDetail.email}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.email.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div style={styles.inputWrapper}>
                        <TextField
                          style={styles.dropdown}
                          type="text"
                          label="mobile"
                          id="mobile"
                          name="mobile"
                          error={
                            !validations.mobile.valid &&
                            validations.mobile.touched
                          }
                          helperText={
                            !validations.mobile.valid &&
                              validations.mobile.touched
                              ? "Mobile is required"
                              : ""
                          }
                          value={this.state.HMDetail.mobile}
                          onChange={this.onChangeHMText.bind(this)}
                          onClick={(e) => {
                            let updatedValidations = { ...validations };
                            updatedValidations.mobile.touched = true;
                            this.setState({
                              validations: updatedValidations,
                            });
                          }}
                        />
                      </div>
                    </div>

                <div className="col-6">
                  {loading ? (
                  <div style={{marginRight:'50px'}}>
                    <CircularProgress color="secondary" />
                  </div>
                  ) : (
                    <Button
                    disabled={this.ValiadateAMuser()}
                      color="primary"
                      onClick={() => {
                        this.onCreateAMUser();
                      }}
                    >
                      Create User
                    </Button>
                  )}
                </div>
              </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </TabPanel>
      </div>
    );
  }
}
