//default imports
import React from "react";

//custom imports
import styles from "./HMDashboard.module.css";
import { Button, Typography } from "@material-ui/core";
import welcomePage from '../../assets/img/welcomePage.png'
export default class HMDashboardPage extends React.Component {
  render() {
    return (
        <div
          style={{
            fontWeight: "bold",
            justifyContent: "center",
            overflow: 'hidden',
            padding: "10px",
          }}
          className={styles.mainContainer}
        >
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: "100%",
                height: 'auto',
              }}
              src={welcomePage}
            />
          </div> */}
          <div
            style={{ fontSize: "20px", padding: "10px", fontWeight: "bolder" }}
          >
            सुस्वागतम!
          </div>
          <div style={{ padding: "10px", color: "#415EB6" }}>
            आदरणीय मुख्याध्यापक,
            <br />
            व्हीस्कुल पोर्टल वर आपले स्वागत! व्हीस्कुल ॲप शालेय शिक्षणाची
            गुणवत्ता वाढण्यासाठी कटिबद्ध आहे. याच प्रक्रियेचा भाग म्हणून
            शिक्षकांना व शाळेला मदत करणारे तंत्रज्ञान आम्ही मोफत उपलब्ध करून देत
            आहोत. या पोर्टल वर तुम्हाला विविध गोष्टी करता येतील, पूर्ण माहिती
            वाचून मगच योग्य कृती करावी.
          </div>

          <div className={styles.infoDiv}>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>डिजीटल शाळा व्यवस्थापन समिती</Typography>
              <Typography>
                तुमच्या शाळेची माहिती तुम्ही येथे भरली तर तुमच्या शाळेतील
                व्हीस्कुल वापरणाऱ्या विद्यार्थ्यांना ही सर्व माहिती दिसेल व
                व्हीस्कुल तुमच्या शाळेचेच ॲप आहे असे वाटेल. ही माहिती नेहमी
                अद्ययावत करायची आहे.
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/user/SMCHome")}
                >
                  माहिती भरा/ पहा
                </Button>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>अध्ययन स्तर निश्चिती</Typography>
              <Typography>
                तुमच्या शाळेची विद्यार्थ्यांची अध्ययन स्तर निश्चिती मोबाईलवरूनच
                सोप्या पद्धतीने करण्यासाठीच्या प्राथमिक कृती येथे करायच्या आहेत.
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/user/FLNAnylsis")}
                >
                  माहिती भरा/ पहा
                </Button>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>अध्ययन निष्पत्ती नोंदणी</Typography>
              <Typography>
                तुमच्या शाळेची विद्यार्थ्यांची अध्ययन निष्पत्ती मोबाईलवरूनच सोप्या पद्धतीने करण्यासाठीच्या प्राथमिक कृती येथे करायच्या आहेत
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/user/LOAnylsis")}
                >
                  माहिती भरा/ पहा
                </Button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}