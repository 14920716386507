//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./AMDashboard.module.css";
import {Typography} from "@material-ui/core";

//custom imports
// import classes from "./FLNHome.module.css";
import {
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { Autocomplete } from "@material-ui/lab";
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    blocks: [],
    schools: [],
  };

  render() {
    let { stdWiseGraph, block, school } = this.state;

    return (
      <>
        <div
          style={{
            marginTop: "15px",
            // backgroundColor: "#DFE4E8",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "30px", padding: "10px",marginBottom:'10px' }}>सुस्वागतम!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "column",
              //   marginLeft: "18%",
            }}
          >
            <div
              style={{
                // backgroundColor: "#DFE4E8",
                padding: "10px",
                color: "#4B66B9",
                padding: "10px",
                width: "100%",
                marginBottom:'40px'
              }}
            >
              आदरणीय अधिकारी महोदय,<br/><br/> व्हीस्कुल पोर्टल वर आपले स्वागत! व्हीस्कुल
              ॲप शालेय शिक्षणाची गुणवत्ता वाढण्यासाठी कटिबद्ध आहे. याच
              प्रक्रियेचा भाग म्हणून शिक्षकांना व शाळेला मदत करणारे तंत्रज्ञान
              आम्ही मोफत उपलब्ध करून देत आहोत.<br/><br/>या पोर्टल वर तुम्हाला विविध
              गोष्टी करता येतील, पूर्ण माहिती वाचून मगच योग्य कृती करावी.
            </div>
          </div>

          <div className={styles.infoDiv}>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>डिजीटल शाळा व्यवस्थापन समिती</Typography>
              <Typography>
              तुमच्या जिल्ह्यातील शाळांची माहिती इथे दिसेल व या माहितीचा उपयोग करून जिल्हास्तरावरील आर्थिक व शैक्षणिक नियोजन अधिक अर्थपूर्ण होईल. 
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/admin/SMCAnalytics")}
                >
                  अधिक माहिती पहा
                </Button>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>अध्ययन स्तर निश्चिती</Typography>
              <Typography>
              आपल्या जिल्ह्यातील शाळांच्या अध्ययन स्तराबद्दलची सविस्तर माहिती इथे पाहता येईल. या माहितीचा उपयोग करून शाळा, केंद्र, तालुका यांचा आढावा घेत येईल तसेच त्यांना नेमकी मदत देखील पुरवता येईल. 
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/admin/NipunBharat")}
                >
                  अधिक माहिती पहा
                </Button>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bolder' }}>अध्ययन निष्पत्ती नोंदणी</Typography>
              <Typography>
                आपल्या जिल्ह्यातील शाळामधील झालेल्या अध्ययन निष्पत्तीची अधिक माहिती इथे पाहता येईल.
              </Typography>
              <div style={{ marginTop: "15px" }}>
                <Button
                  style={{
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    width: '12rem'
                  }}
                  onClick={() => this.props.history.push("/admin/LOAdminAnalysis")}
                >
                  अधिक माहिती पहा
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
