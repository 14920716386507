import React, { useRef } from "react";
import Modal from "@material-ui/core/Modal";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import classes from "./SMCCreateForm.module.css";
import { getHMService } from "service/service";
import Snackbar from "./../../components/Snackbar/Snackbar";
import { FormControlLabel, Switch, Checkbox, Tooltip, Input, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import {
  DeleteOutline,
  CheckCircle,
  PhoneInTalk,
  Save,
  HelpOutline,
} from "@material-ui/icons";
import { elementType } from "prop-types";
import { uploadNewMedia } from "service/WebSeparateService";
import AlertDialog from "components/AlertDialog/AlertDialog";
// import { ConstructionOutlined } from "@mui/icons-material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import ReactTooltip from "react-tooltip";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}

// let schoolInfo = JSON.parse(localStorage.getItem("HMSchool"));

// let checkSchoolDetails = schoolInfo.school_detail != undefined;
// let checkSmcDetails = schoolInfo.smc != undefined;

let classAndstudents = [
  {
    id: 1,
    grade: "1st",
    students: "",
  },
  {
    id: 2,
    grade: "2nd",
    students: "",
  },
  {
    id: 3,
    grade: "3rd",
    students: "",
  },
  {
    id: 4,
    grade: "4th",
    students: "",
  },
  {
    id: 5,
    grade: "5th",
    students: "",
  },
  {
    id: 6,
    grade: "6th",
    students: "",
  },
  {
    id: 7,
    grade: "7th",
    students: "",
  },
  {
    id: 8,
    grade: "8th",
    students: "",
  },
  {
    id: 9,
    grade: "9th",
    students: "",
  },
  {
    id: 10,
    grade: "10th",
    students: "",
  },
];

class SMCCreateForm extends React.Component {
  state = {
    isAddMember: false,
    isAddDetail: false,
    isEditDetail: false,
    memberForm: [
      {
        name: "",
        phone: "",
        gender: "",
        category: "",
        patent: "",
      },
      {
        name: "",
        phone: "",
        gender: "",
        category: "",
        patent: "",
      },
      {
        name: "",
        phone: "",
        gender: "",
        category: "",
        patent: "",
      },
      {
        name: "",
        phone: "",
        gender: "",
        category: "",
        patent: "",
      },
    ],
    emptyForm: {
      name: "",
      phone: "",
      gender: "",
      category: "",
      patent: "",
    },
    meetingsForm: [
      {
        date: "",
        attendance: "",
        details: "",
        photo: "",
      },
      {
        date: "",
        attendance: "",
        details: "",
        photo: "",
      },
      {
        date: "",
        attendance: "",
        details: "",
        photo: "",
      },
    ],
    emptyMeetings: {
      date: "",
      attendance: "",
      details: "",
      photo: "",
    },
    dateForm: [{ meetings_date: "" }, { meetings_date: "" }],
    emptyDate: { meetings_date: "" },
    error: false,
    message: "",
    HMDetails: {},
    // HMSchool: JSON.parse(localStorage.getItem("HMSchool")),
    HMSchool: JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"))
        ? JSON.parse(localStorage.getItem("AMdetails"))
        : null,
    isHMDetailsPresent: false,
    isSMCDetailsPresent: false,
    smcDetails: {},
    WHOLE_BUILDING: null,
    LABS: null,
    GROUND: null,
    COMPOUND: null,
    CLASSROOMS: null,
    whole_building: "",
    labs: "",
    ground: "",
    compound: "",
    classrooms: "",
    tabIndex: 0,
    // classCheck: false,
    // groundCheck: false,
    // washroomCheck: false,
    // internetCheck: false,
    // computerCheck: false,
    // electricityCheck: false,
    // wallCheck: false,
    // MY STATES
    currentImage: 0,
    headMasterName: "",
    headMasterNumber: "",
    seniorBoyTeacherName: "",
    seniorBoyTeacherNumber: "",
    seniorGirlTeacherName: "",
    seniorGirlTeacherNumber: "",
    schoolAddress: "",
    schoolAddressLink: "",
    schooltype: "",
    totalStudent: "",
    girlTotalStudent: "",
    boyTotalStudent: "",
    totalTeacher: "",
    girlTotalTeacher: "",
    boyTotalTeacher: "",
    allClassStudent: classAndstudents,
    isReview: false,
    isTeacherDetails: false,
    isCommitteeDetails: false,
    isInformation: false,
    isExtraInfo: false,
    schoolAchievement: "",
    schoolChallenges: "",
    schoolStrength: "",
    isReviewInfo: false,
    isReviewComittee: false,
    schoolDetailsId: null,
    isSubmitPrathmik: false,
    isSubmitShalaVyavsthpan: false,
    castwisecount: [
      {
        id: 1,
        grade: "OPEN",
        male: "",
        female: "",
      },
      {
        id: 2,
        grade: "OBC",
        male: "",
        female: "",
      },
      {
        id: 3,
        grade: "SC",
        male: "",
        female: "",
      },
      {
        id: 4,
        grade: "ST",
        male: "",
        female: "",
      },
      {
        id: 5,
        grade: "NT",
        male: "",
        female: "",
      },
      {
        id: 6,
        grade: "VJNT",
        male: "",
        female: "",
      },
      {
        id: 7,
        grade: "OTHER",
        male: "",
        female: "",
      },
    ],
    smcFormData: [],
    headMasterMessage: "",
    schoolHistoryPic: "",
    schoolStrengthPic: "",
    schoolChallengesPic: "",
    headMasterPic: "",
    fifthScholarship: "नाही",
    fifthScholarshipCount: "",
    eighthScholarship: "नाही",
    eighthScholarshipCount: "",
    tenthScholarship: "नाही",
    tenthScholarshipCount: "",
    navodayaScholarship: "नाही",
    navodayaScholarshipCount: "",
    cookName: "",
    studentGettingFoodCount: "",
    cookSalary: "",
    eatingStudentPic: "",
    extraInfoFood: "",
    uniformsCount: "",
    girlsUniformCount: "",
    boysUniformCount: "",
    remainingUniform: "",
    internetCheck: false,
    smartTvCheck: false,
    powerConnectionCheck: false,
    boysWashroomCheck: false,
    girlsWashroomCheck: false,
    mathsBoxCheck: false,
    sweeperCheck: false,
    boundaryWallCheck: false,
    groundCheck: false,
    libraryCheck: false,
    labCheck: false,
    balaCheck: false,
    solidClassRoomsCheck: false,
    weakClassCoomsCheck: false,
    projectorCheck: false,
    playCaterialCheck: false,
    backyardCheck: false,
    ablCheck: false,
    inverterCheck: false,
    computerRoomCheck: false,
    headMasterCaste: "",
    seniorBoyTeacherCaste: "",
    seniorGirlTeacherCaste: "",
    isFormReview: false,
    matsCount: "",
    benchesCount: "",
    remainingSeats: "",
    remainingMats: "",
    freeTextBookCount: "",
    isWaterSouceCommon: false,
    isTubeHandPump: false,
    otherWaterDetails: "",
    totalBooksCount: "",
    alertbox: false,
    securityWall: "",
    dialogCheck: false,
    //
    wholeBuildingName: "",
    securityName: "",
    groundName: "",
    hallName: "",
    className: "",
    HMpicNAme: "",
    historyPicName: "",
    schoolStrengthName: "",
    visionName: "",
    foodName: "",
    meetPicName: "",
    //
    gamesPic: "",
    restroomPic: "",
    waterPic: "",
    rampPic: "",
    kitchenPic: "",
    studyMaterialPic: "",
    librarayMaterialPic: "",
    otherPic: "",
    schoolDevelopment: "",
    schoolDevelopmentPic: "",
    totalMeetingsInYear: "",
    publicParticipationCost: "",
    publicParticipation: "",
    donorsForm: [{ donorsName: "" }, { donorsName: "" }, { donorsName: "" }],
    emptyDonors: { donorsName: "" },
    eighthNMSS: "नाही",
    eighthNMSSCount: "",
    elementaryExam: "नाही",
    elementaryExamCount: "",
    intermediate: "नाही",
    intermediateCount: "",
    totalCooks: "",
    cookForm: [
      {
        cookName: "",
        cookSalary: "",
      },
    ],
    emptyCook: {
      cookName: "",
      cookSalary: "",
    },
    governmentSchemesForm: [
      { schemeName: "", beneficiariesNumber: "" },
      { schemeName: "", beneficiariesNumber: "" },
      { schemeName: "", beneficiariesNumber: "" },
    ],
    emptyGovernmentScheme: { schemeName: "", beneficiariesNumber: "" },
    futurePlan: "",
    futurePlanPic: "",
    instructionForParents: "",
    checkAlertBox: false,
    disableStatus: true,
    yearList: null,
    YearValue: null,
    quaterList: null,
    quaterValue: null,
    NoForm: false,
    showTopContainer: false
  };

  componentDidMount() {
    this.setFlag();

    console.log("hm school", this.state.HMSchool);
    this.getYears()
    this.getPreviousData();
  }
  getYears = () => {

    getHMService().getYearHm().
      then((res) => {
        console.log(res, "res")
        if (res.data) {
          this.setState({
            yearList: res.data.years
          })
        }
      })
  }

  getHalfarray = () => {


    getHMService().getHalfValues(this.state.YearValue).
      then((res) => {
        console.log(res, "res")
        if (res.data) {
          this.setState({
            quaterList: res.data.quaters,
            disableStatus: false
          })
        }
      })

  }

  getSchoolDetailsForHm = () => {

    if (this.state.quaterValue && this.state.YearValue) {
      let param = {
        quarter: this.state.quaterValue,
        year: this.state.YearValue,
        school: this.state.HMSchool?.school_id
      }
      console.log(param, "param")

      getHMService().getSchoolDetailsHm(param).
        then((res) => {
          console.log(res, "res")
          if (res.data.response.length <= 0) {
            this.setState({
              isTeacherDetails: true,
              NoForm: false
            })
          }
          else {
            this.setState({
              isTeacherDetails: false,
              NoForm: true
            })
          }
        })
    }
  }
  //   getPreviousData = () => {
  //     getHMService()
  //       .getSMCFormDetailsForDraft(this.state?.HMSchool?.school_id)
  //       .then(async (res) => {
  //         if (res) {
  //           const { data } = res;
  //           const { response } = data;

  //           console.log(response, "component did mount draft");
  //           // var flagOne = false;
  //           // var flagTwo = false;

  //           if (response.id) {
  //             let classAndstudents = [
  //               {
  //                 id: 1,
  //                 grade: "1st",
  //                 students: "",
  //               },
  //               {
  //                 id: 2,
  //                 grade: "2nd",
  //                 students: "",
  //               },
  //               {
  //                 id: 3,
  //                 grade: "3rd",
  //                 students: "",
  //               },
  //               {
  //                 id: 4,
  //                 grade: "4th",
  //                 students: "",
  //               },
  //               {
  //                 id: 5,
  //                 grade: "5th",
  //                 students: "",
  //               },
  //               {
  //                 id: 6,
  //                 grade: "6th",
  //                 students: "",
  //               },
  //               {
  //                 id: 7,
  //                 grade: "7th",
  //                 students: "",
  //               },
  //               {
  //                 id: 8,
  //                 grade: "8th",
  //                 students: "",
  //               },
  //               {
  //                 id: 9,
  //                 grade: "9th",
  //                 students: "",
  //               },
  //               {
  //                 id: 10,
  //                 grade: "10th",
  //                 students: "",
  //               },
  //             ];

  //             this.setState({
  //               isAddMember: false,
  //               isAddDetail: false,
  //               isEditDetail: false,
  //               memberForm: [
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //               ],
  //               emptyForm: {
  //                 name: "",
  //                 phone: "",
  //                 gender: "",
  //                 category: "",
  //                 patent: "",
  //               },
  //               meetingsForm: [
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //               ],
  //               emptyMeetings: {
  //                 date: "",
  //                 attendance: "",
  //                 details: "",
  //                 photo: "",
  //               },
  //               dateForm: [{ meetings_date: "" }, { meetings_date: "" }],
  //               emptyDate: { meetings_date: "" },
  //               error: false,
  //               message: "",
  //               HMDetails: {},
  //               HMSchool: JSON.parse(localStorage.getItem("HMSchool")),
  //               isHMDetailsPresent: false,
  //               isSMCDetailsPresent: false,
  //               smcDetails: {},
  //               WHOLE_BUILDING: null,
  //               LABS: null,
  //               GROUND: null,
  //               COMPOUND: null,
  //               CLASSROOMS: null,
  //               whole_building: "",
  //               labs: "",
  //               ground: "",
  //               compound: "",
  //               classrooms: "",
  //               tabIndex: 0,
  //               // classCheck: false,
  //               // groundCheck: false,
  //               // washroomCheck: false,
  //               // internetCheck: false,
  //               // computerCheck: false,
  //               // electricityCheck: false,
  //               // wallCheck: false,
  //               // MY STATES
  //               currentImage: 0,
  //               headMasterName: "",
  //               headMasterNumber: "",
  //               seniorBoyTeacherName: "",
  //               seniorBoyTeacherNumber: "",
  //               seniorGirlTeacherName: "",
  //               seniorGirlTeacherNumber: "",
  //               schoolAddress: "",
  //               schoolAddressLink: "",
  //               schooltype: "",
  //               totalStudent: "",
  //               girlTotalStudent: "",
  //               boyTotalStudent: "",
  //               totalTeacher: "",
  //               girlTotalTeacher: "",
  //               boyTotalTeacher: "",
  //               allClassStudent: classAndstudents,
  //               isReview: false,
  //               isTeacherDetails: true,
  //               isCommitteeDetails: false,
  //               isInformation: false,
  //               isExtraInfo: false,
  //               schoolAchievement: "",
  //               schoolChallenges: "",
  //               schoolStrength: "",
  //               isReviewInfo: false,
  //               isReviewComittee: false,
  //               schoolDetailsId: null,
  //               isSubmitPrathmik: false,
  //               isSubmitShalaVyavsthpan: false,
  //               castwisecount: [
  //                 {
  //                   id: 1,
  //                   grade: "OPEN",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 2,
  //                   grade: "OBC",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 3,
  //                   grade: "SC",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 4,
  //                   grade: "ST",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 5,
  //                   grade: "NT",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 6,
  //                   grade: "VJNT",
  //                   male: "",
  //                   female: "",
  //                 },
  //                 {
  //                   id: 7,
  //                   grade: "OTHER",
  //                   male: "",
  //                   female: "",
  //                 },
  //               ],
  //               smcFormData: [],
  //               headMasterMessage: "",
  //               schoolHistoryPic: "",
  //               schoolStrengthPic: "",
  //               schoolChallengesPic: "",
  //               headMasterPic: "",
  //               fifthScholarship: "नाही",
  //               fifthScholarshipCount: "",
  //               eighthScholarship: "नाही",
  //               eighthScholarshipCount: "",
  //               tenthScholarship: "नाही",
  //               tenthScholarshipCount: "",
  //               navodayaScholarship: "नाही",
  //               navodayaScholarshipCount: "",
  //               cookName: "",
  //               studentGettingFoodCount: "",
  //               cookSalary: "",
  //               eatingStudentPic: "",
  //               extraInfoFood: "",
  //               uniformsCount: "",
  //               girlsUniformCount: "",
  //               boysUniformCount: "",
  //               remainingUniform: "",
  //               internetCheck: false,
  //               smartTvCheck: false,
  //               powerConnectionCheck: false,
  //               boysWashroomCheck: false,
  //               girlsWashroomCheck: false,
  //               mathsBoxCheck: false,
  //               sweeperCheck: false,
  //               boundaryWallCheck: false,
  //               groundCheck: false,
  //               libraryCheck: false,
  //               labCheck: false,
  //               balaCheck: false,
  //               solidClassRoomsCheck: false,
  //               weakClassCoomsCheck: false,
  //               projectorCheck: false,
  //               playCaterialCheck: false,
  //               backyardCheck: false,
  //               ablCheck: false,
  //               inverterCheck: false,
  //               computerRoomCheck: false,
  //               headMasterCaste: "",
  //               seniorBoyTeacherCaste: "",
  //               seniorGirlTeacherCaste: "",
  //               isFormReview: false,
  //               matsCount: "",
  //               benchesCount: "",
  //               remainingSeats: "",
  //               remainingMats: "",
  //               freeTextBookCount: "",
  //               isWaterSouceCommon: false,
  //               isTubeHandPump: false,
  //               otherWaterDetails: "",
  //               totalBooksCount: "",
  //               alertbox: false,
  //               securityWall: "",
  //               dialogCheck: false,
  //               //
  //               wholeBuildingName: "",
  //               securityName: "",
  //               groundName: "",
  //               hallName: "",
  //               className: "",
  //               HMpicNAme: "",
  //               historyPicName: "",
  //               schoolStrengthName: "",
  //               visionName: "",
  //               foodName: "",
  //               meetPicName: "",
  //               //
  //               gamesPic: "",
  //               restroomPic: "",
  //               waterPic: "",
  //               rampPic: "",
  //               kitchenPic: "",
  //               studyMaterialPic: "",
  //               librarayMaterialPic: "",
  //               otherPic: "",
  //               schoolDevelopment: "",
  //               schoolDevelopmentPic: "",
  //               totalMeetingsInYear: "",
  //               publicParticipationCost: "",
  //               publicParticipation: "",
  //               donorsForm: [
  //                 { donorsName: "" },
  //                 { donorsName: "" },
  //                 { donorsName: "" },
  //               ],
  //               emptyDonors: { donorsName: "" },
  //               eighthNMSS: "नाही",
  //               eighthNMSSCount: "",
  //               elementaryExam: "नाही",
  //               elementaryExamCount: "",
  //               intermediate: "नाही",
  //               intermediateCount: "",
  //               totalCooks: "",
  //               cookForm: [
  //                 {
  //                   cookName: "",
  //                   cookSalary: "",
  //                 },
  //               ],
  //               emptyCook: {
  //                 cookName: "",
  //                 cookSalary: "",
  //               },
  //               governmentSchemesForm: [
  //                 { schemeName: "", beneficiariesNumber: "" },
  //                 { schemeName: "", beneficiariesNumber: "" },
  //                 { schemeName: "", beneficiariesNumber: "" },
  //               ],
  //               emptyGovernmentScheme: {
  //                 schemeName: "",
  //                 beneficiariesNumber: "",
  //               },
  //               futurePlan: "",
  //               futurePlanPic: "",
  //               instructionForParents: "",
  //               checkAlertBox: false,
  //             });
  //           } else {
  //             let classAndstudents = [
  //               {
  //                 id: 1,
  //                 grade: "1st",
  //                 students:
  //                   response?.gender_wise[0]?.total_students ||
  //                   response?.gender_wise[0]?.total_students === 0
  //                     ? response.gender_wise[0].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 2,
  //                 grade: "2nd",
  //                 students:
  //                   response?.gender_wise[1]?.total_students ||
  //                   response?.gender_wise[1]?.total_students === 0
  //                     ? response.gender_wise[1].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 3,
  //                 grade: "3rd",
  //                 students:
  //                   response?.gender_wise[2]?.total_students ||
  //                   response?.gender_wise[2]?.total_students === 0
  //                     ? response.gender_wise[2].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 4,
  //                 grade: "4th",
  //                 students:
  //                   response?.gender_wise[3]?.total_students ||
  //                   response?.gender_wise[3]?.total_students === 0
  //                     ? response.gender_wise[3].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 5,
  //                 grade: "5th",
  //                 students:
  //                   response?.gender_wise[4]?.total_students ||
  //                   response?.gender_wise[4]?.total_students === 0
  //                     ? response.gender_wise[4].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 6,
  //                 grade: "6th",
  //                 students:
  //                   response?.gender_wise[5]?.total_students ||
  //                   response?.gender_wise[5]?.total_students === 0
  //                     ? response.gender_wise[5].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 7,
  //                 grade: "7th",
  //                 students:
  //                   response?.gender_wise[6]?.total_students ||
  //                   response?.gender_wise[6]?.total_students === 0
  //                     ? response.gender_wise[6].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 8,
  //                 grade: "8th",
  //                 students:
  //                   response?.gender_wise[7]?.total_students ||
  //                   response?.gender_wise[7]?.total_students === 0
  //                     ? response.gender_wise[7].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 9,
  //                 grade: "9th",
  //                 students:
  //                   response?.gender_wise[8]?.total_students ||
  //                   response?.gender_wise[8]?.total_students === 0
  //                     ? response.gender_wise[8].total_students
  //                     : "",
  //               },
  //               {
  //                 id: 10,
  //                 grade: "10th",
  //                 students:
  //                   response?.gender_wise[9]?.total_students ||
  //                   response?.gender_wise[9]?.total_students === 0
  //                     ? response.gender_wise[9].total_students
  //                     : "",
  //               },
  //             ];
  //             let castWiseData = [
  //               {
  //                 id: 1,
  //                 grade: "OPEN",
  //                 male:
  //                   response?.caste_wise[0]?.boys ||
  //                   response?.caste_wise[0]?.boys === 0
  //                     ? response?.caste_wise[0].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[0]?.girls ||
  //                   response?.caste_wise[0]?.girls === 0
  //                     ? response?.caste_wise[0].girls
  //                     : "",
  //               },

  //               {
  //                 id: 2,
  //                 grade: "OBC",
  //                 male:
  //                   response?.caste_wise[1]?.boys ||
  //                   response?.caste_wise[1]?.boys === 0
  //                     ? response?.caste_wise[1].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[1]?.girls ||
  //                   response?.caste_wise[1]?.girls === 0
  //                     ? response?.caste_wise[1].girls
  //                     : "",
  //               },
  //               {
  //                 id: 3,
  //                 grade: "SC",
  //                 male:
  //                   response?.caste_wise[2]?.boys ||
  //                   response?.caste_wise[2]?.boys === 0
  //                     ? response?.caste_wise[2].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[2]?.girls ||
  //                   response?.caste_wise[2]?.girls === 0
  //                     ? response?.caste_wise[2].girls
  //                     : "",
  //               },
  //               {
  //                 id: 4,
  //                 grade: "ST",
  //                 male:
  //                   response?.caste_wise[3]?.boys ||
  //                   response?.caste_wise[3]?.boys === 0
  //                     ? response?.caste_wise[3].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[3]?.girls ||
  //                   response?.caste_wise[3]?.girls === 0
  //                     ? response?.caste_wise[3].girls
  //                     : "",
  //               },
  //               {
  //                 id: 5,
  //                 grade: "NT",
  //                 male:
  //                   response?.caste_wise[4]?.boys ||
  //                   response?.caste_wise[4]?.boys === 0
  //                     ? response?.caste_wise[4].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[4]?.girls ||
  //                   response?.caste_wise[4]?.girls === 0
  //                     ? response?.caste_wise[4].girls
  //                     : "",
  //               },
  //               {
  //                 id: 6,
  //                 grade: "VJNT",
  //                 male:
  //                   response?.caste_wise[5]?.boys ||
  //                   response?.caste_wise[5]?.boys === 0
  //                     ? response?.caste_wise[5].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[5]?.girls ||
  //                   response?.caste_wise[5]?.girls === 0
  //                     ? response?.caste_wise[5].girls
  //                     : "",
  //               },
  //               {
  //                 id: 7,
  //                 grade: "OTHER",
  //                 male:
  //                   response?.caste_wise[6]?.boys ||
  //                   response?.caste_wise[6]?.boys === 0
  //                     ? response?.caste_wise[6].boys
  //                     : "",
  //                 female:
  //                   response?.caste_wise[6]?.girls ||
  //                   response?.caste_wise[6]?.girls === 0
  //                     ? response?.caste_wise[6].girls
  //                     : "",
  //               },
  //             ];

  //             let smcMemberForm = [];
  //             let smcMeetingDate = [];
  //             let smcDonorsForm = [];
  //             let smcCookForm = [];
  //             let smcSchemeForm = [];
  //             let smcFutureMeetings=[]

  //             if (response?.members?.length > 0) {
  //               smcMemberForm = response?.members?.map((res) => {
  //                 return {
  //                   name: res?.name ? res.name : "",
  //                   phone: res?.phone ? res.phone : "",
  //                   gender: res?.gender ? res.gender : "",
  //                   category: res?.caste ? res.caste : "",
  //                   patent: res?.patent ? res.patent : "",
  //                 };
  //               });
  //             } else {
  //               smcMemberForm = [
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //                 {
  //                   name: "",
  //                   phone: "",
  //                   gender: "",
  //                   category: "",
  //                   patent: "",
  //                 },
  //               ];
  //             }

  //             if (response?.smc_meetings?.length > 0) {
  //               smcMeetingDate = response?.smc_meetings?.map((res) => {
  //                 return {
  //                   date: res?.meetings_date ? res.meetings_date : "",
  //                   attendance: res?.meetings_attendance
  //                     ? res.meetings_attendance
  //                     : "",
  //                   details: res?.reports ? res.reports : "",
  //                   photo: res?.photo ? res.photo : "",
  //                 };
  //               });
  //             } else {
  //               smcMeetingDate = [
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //                 {
  //                   date: "",
  //                   attendance: "",
  //                   details: "",
  //                   photo: "",
  //                 },
  //               ];
  //             }

  //             if(response?.donors?.length>0){
  //               smcDonorsForm = response?.donors?.map((res) => {
  //                 return {
  //                   donorsName: res?.donor_name ? res.donor_name : "",
  //                 };
  //               });
  //             }else{
  //               smcDonorsForm= [
  //                 { donorsName: "" },
  //                 { donorsName: "" },
  //                 { donorsName: "" },
  //               ]
  //             }

  //             if(response?.cook_details?.length>0){
  //               smcCookForm = response?.cook_details?.map((res) => {
  //                 return {
  //                   cookName: res?.cook_name ? res.cook_name : "",
  //                   cookSalary: res?.cook_salary ? res.cook_salary : "",
  //                 };
  //               });
  //             }else{
  //               smcCookForm= [
  //                 {
  //                   cookName: "",
  //                   cookSalary: "",
  //                 },
  //               ]
  //             }

  //             if(response?.government_schemes?.length>0){
  //               smcSchemeForm = response?.government_schemes?.map((res) => {
  //                 return {
  //                   schemeName: res?.name_of_scheme ? res.name_of_scheme : "",
  //                   beneficiariesNumber: res?.number_of_beneficiaries
  //                     ? res.number_of_beneficiaries
  //                     : "",
  //                 };
  //               });
  //             }else{
  //               smcSchemeForm= [
  //                 { schemeName: "", beneficiariesNumber: "" },
  //                 { schemeName: "", beneficiariesNumber: "" },
  //                 { schemeName: "", beneficiariesNumber: "" },
  //               ]
  //             }

  //             if(response?.smc_future_meetings?.length >0){
  //               smcFutureMeetings = response?.smc_future_meetings
  //             }
  //             else{
  // smcFutureMeetings=[{ meetings_date: "" },{ meetings_date: "" }]
  //             }

  //             this.setState({
  //               headMasterName: response?.head_master ? response.head_master : "",
  //               headMasterNumber: response?.head_master_mobile
  //                 ? response.head_master_mobile
  //                 : "",
  //               seniorBoyTeacherName: response?.senior_teacher_male
  //                 ? response.senior_teacher_male
  //                 : "",
  //               seniorBoyTeacherNumber: response?.senior_teacher_male_mobile
  //                 ? response.senior_teacher_male_mobile
  //                 : "",
  //               seniorGirlTeacherName: response?.senior_teacher_female
  //                 ? response.senior_teacher_female
  //                 : "",
  //               seniorGirlTeacherNumber: response?.senior_teacher_female_mobile
  //                 ? response.senior_teacher_female_mobile
  //                 : "",
  //               headMasterCaste: response?.head_master_caste
  //                 ? response.head_master_caste
  //                 : "",
  //               seniorBoyTeacherCaste: response?.senior_teacher_male_caste
  //                 ? response.senior_teacher_male_caste
  //                 : "",
  //               seniorGirlTeacherCaste: response?.senior_teacher_female_caste
  //                 ? response.senior_teacher_female_caste
  //                 : "",
  //               schoolAddress: response?.school_address
  //                 ? response.school_address
  //                 : "",
  //               schoolAddressLink: response?.school_address_gmaps
  //                 ? response.school_address_gmaps
  //                 : "",
  //               schooltype: response?.school_type ? response.school_type : "",
  //               totalStudent:
  //                 response?.total_student || response.total_student === 0
  //                   ? response.total_student
  //                   : "",
  //               girlTotalStudent:
  //                 response?.girls || response?.girls === 0 ? response.girls : "",
  //               boyTotalStudent:
  //                 response?.boys || response?.boys === 0 ? response.boys : "",
  //               totalTeacher:
  //                 response?.total_teacher || response?.total_teacher === 0
  //                   ? response.total_teacher
  //                   : "",
  //               girlTotalTeacher:
  //                 response?.female_teacher || response?.female_teacher === 0
  //                   ? response.female_teacher
  //                   : "",
  //               boyTotalTeacher:
  //                 response?.male_teacher || response?.male_teacher === 0
  //                   ? response.male_teacher
  //                   : "",
  //               allClassStudent: classAndstudents,
  //               classrooms: response?.classrooms ? response.classrooms : "",
  //               labs: response?.labs ? response.labs : "",
  //               ground: response?.ground ? response.ground : "",
  //               compound: response?.office ? response.office : "",
  //               whole_building: response?.whole_building
  //                 ? response.whole_building
  //                 : "",
  //               securityWall: response?.compound ? response.compound : "",
  //               gamesPic: response?.games_sports_material
  //                 ? response.games_sports_material
  //                 : "",
  //               restroomPic: response?.washrooms ? response.washrooms : "",
  //               waterPic: response?.drinking_water ? response.drinking_water : "",
  //               rampPic: response?.ramp ? response.ramp : "",
  //               kitchenPic: response?.kitchen_shed ? response.kitchen_shed : "",
  //               studyMaterialPic: response?.study_teaching_materials
  //                 ? response.study_teaching_materials
  //                 : "",
  //               librarayMaterialPic: response?.library_reading_materials
  //                 ? response.library_reading_materials
  //                 : "",
  //               otherPic: response?.other_facilities
  //                 ? response.other_facilities
  //                 : "",
  //               castwisecount: castWiseData,
  //               memberForm: response?.members
  //                 ? smcMemberForm
  //                 : [
  //                     {
  //                       name: "",
  //                       phone: "",
  //                       gender: "",
  //                       category: "",
  //                       patent: "",
  //                     },
  //                     {
  //                       name: "",
  //                       phone: "",
  //                       gender: "",
  //                       category: "",
  //                       patent: "",
  //                     },
  //                     {
  //                       name: "",
  //                       phone: "",
  //                       gender: "",
  //                       category: "",
  //                       patent: "",
  //                     },
  //                     {
  //                       name: "",
  //                       phone: "",
  //                       gender: "",
  //                       category: "",
  //                       patent: "",
  //                     },
  //                   ],
  //               dateForm: response?.smc_future_meetings
  //                 ? smcFutureMeetings
  //                 : [{ meetings_date: "" },{ meetings_date: "" }],
  //               meetingsForm: response?.smc_meetings
  //                 ? smcMeetingDate
  //                 : [
  //                     {
  //                       date: "",
  //                       attendance: "",
  //                       details: "",
  //                       photo: "",
  //                     },
  //                     {
  //                       date: "",
  //                       attendance: "",
  //                       details: "",
  //                       photo: "",
  //                     },
  //                     {
  //                       date: "",
  //                       attendance: "",
  //                       details: "",
  //                       photo: "",
  //                     },
  //                   ],
  //               internetCheck: response?.school_facility?.internet
  //                 ? response.school_facility.internet
  //                 : false,
  //               smartTvCheck: response?.school_facility?.smart_tv
  //                 ? response.school_facility.smart_tv
  //                 : false,
  //               powerConnectionCheck: response?.school_facility?.power_connection
  //                 ? response.school_facility.power_connection
  //                 : false,
  //               boysWashroomCheck: response?.school_facility?.boys_washroom
  //                 ? response.school_facility.boys_washroom
  //                 : false,
  //               girlsWashroomCheck: response?.school_facility?.girls_washroom
  //                 ? response.school_facility.girls_washroom
  //                 : false,
  //               mathsBoxCheck: response?.school_facility?.maths_box
  //                 ? response.school_facility.maths_box
  //                 : false,
  //               sweeperCheck: response?.school_facility?.sweeper
  //                 ? response.school_facility.sweeper
  //                 : false,
  //               boundaryWallCheck: response?.school_facility?.boundary_wall
  //                 ? response.school_facility.boundary_wall
  //                 : false,
  //               groundCheck: response?.school_facility?.ground
  //                 ? response.school_facility.ground
  //                 : false,
  //               libraryCheck: response?.school_facility?.library
  //                 ? response.school_facility.library
  //                 : false,
  //               labCheck: response?.school_facility?.lab
  //                 ? response.school_facility.lab
  //                 : false,
  //               balaCheck: response?.school_facility?.bala
  //                 ? response.school_facility.bala
  //                 : false,
  //               solidClassRoomsCheck: response?.school_facility?.solid_class_rooms
  //                 ? response.school_facility.solid_class_rooms
  //                 : false,
  //               weakClassCoomsCheck: response?.school_facility?.weak_class_rooms
  //                 ? response.school_facility.weak_class_rooms
  //                 : false,
  //               projectorCheck: response?.school_facility?.projector
  //                 ? response.school_facility.projector
  //                 : false,
  //               playCaterialCheck: response?.school_facility?.play_material
  //                 ? response.school_facility.play_material
  //                 : false,
  //               backyardCheck: response?.school_facility?.backyard
  //                 ? response.school_facility.backyard
  //                 : false,
  //               ablCheck: response?.school_facility?.abl
  //                 ? response.school_facility.abl
  //                 : false,
  //               inverterCheck: response?.school_facility?.inverter
  //                 ? response.school_facility.inverter
  //                 : false,
  //               computerRoomCheck: response?.school_facility?.computer_room
  //                 ? response.school_facility.computer_room
  //                 : false,
  //               fifthScholarship: response?.scholarship?.scholarship_5th
  //                 ? response.scholarship.scholarship_5th == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               fifthScholarshipCount: response?.scholarship?.scholarship_5th
  //                 ? response.scholarship.scholarship_5th
  //                 : "",
  //               eighthScholarship: response?.scholarship?.scholarship_8th
  //                 ? response.scholarship.scholarship_8th == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               eighthScholarshipCount: response?.scholarship?.scholarship_8th
  //                 ? response.scholarship.scholarship_8th
  //                 : "",
  //               tenthScholarship: response?.scholarship?.scholarship_10th
  //                 ? response.scholarship.scholarship_10th == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               tenthScholarshipCount: response?.scholarship?.scholarship_10th
  //                 ? response.scholarship.scholarship_10th
  //                 : "",
  //               navodayaScholarship: response?.scholarship?.scholarship_navodaya
  //                 ? response.scholarship.scholarship_navodaya == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               navodayaScholarshipCount: response?.scholarship
  //                 ?.scholarship_navodaya
  //                 ? response.scholarship.scholarship_navodaya
  //                 : "",
  //               eighthNMSS: response?.scholarship?.scholarship_8th_nmms
  //                 ? response.scholarship.scholarship_8th_nmms == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               eighthNMSSCount: response?.scholarship?.scholarship_8th_nmms
  //                 ? response.scholarship.scholarship_8th_nmms
  //                 : "",
  //               elementaryExam: response?.scholarship?.elementary
  //                 ? response.scholarship.elementary == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               elementaryExamCount: response?.scholarship?.elementary
  //                 ? response.scholarship.elementary
  //                 : "",
  //               intermediate: response?.scholarship?.intermediate
  //                 ? response.scholarship.intermediate == 0
  //                   ? "नाही"
  //                   : "होय"
  //                 : "नाही",
  //               intermediateCount: response?.scholarship?.intermediate
  //                 ? response.scholarship.intermediate
  //                 : "",
  //               studentGettingFoodCount: response?.mid_day_meal?.total_students
  //                 ? response.mid_day_meal?.total_students
  //                 : "",
  //               totalCooks: response?.mid_day_meal?.number_of_cook
  //                 ? response.mid_day_meal?.number_of_cook
  //                 : "",
  //               cookForm: response?.cook_details
  //                 ? smcCookForm
  //                 : [
  //                     {
  //                       cookName: "",
  //                       cookSalary: "",
  //                     },
  //                   ],
  //               governmentSchemesForm: response?.government_schemes
  //                 ? smcSchemeForm
  //                 : [
  //                     { schemeName: "", beneficiariesNumber: "" },
  //                     { schemeName: "", beneficiariesNumber: "" },
  //                     { schemeName: "", beneficiariesNumber: "" },
  //                   ],
  //               eatingStudentPic: response?.mid_day_meal?.student_photo
  //                 ? response.mid_day_meal.student_photo
  //                 : "",
  //               extraInfoFood: response?.mid_day_meal?.other_details
  //                 ? response.mid_day_meal.other_details
  //                 : "",
  //               uniformsCount:
  //                 response?.uniform?.total_students ||
  //                 response?.uniform?.total_students === 0
  //                   ? response.uniform.total_students
  //                   : "",
  //               girlsUniformCount:
  //                 response?.uniform?.girls || response?.uniform?.girls === 0
  //                   ? response.uniform.girls
  //                   : "",
  //               boysUniformCount:
  //                 response?.uniform?.boys || response?.uniform?.boys === 0
  //                   ? response.uniform.boys
  //                   : "",
  //               remainingUniform:
  //                 response?.uniform?.remaining ||
  //                 response?.uniform?.remaining === 0
  //                   ? response.uniform.remaining
  //                   : "",
  //               matsCount:
  //                 response?.special_information?.crude_classrooms ||
  //                 response?.special_information?.crude_classrooms === 0
  //                   ? response.special_information.crude_classrooms
  //                   : "",
  //               benchesCount:
  //                 response?.special_information?.concrete_classrooms ||
  //                 response?.special_information?.concrete_classrooms === 0
  //                   ? response.special_information.concrete_classrooms
  //                   : "",
  //               remainingSeats:
  //                 response?.special_information?.required_more_sits ||
  //                 response?.special_information?.required_more_sits === 0
  //                   ? response.special_information.required_more_sits
  //                   : "",
  //               remainingMats:
  //                 response?.special_information?.total_classrooms ||
  //                 response?.special_information?.total_classrooms === 0
  //                   ? response.special_information.total_classrooms
  //                   : "",
  //               isWaterSouceCommon: response?.special_information
  //                 ?.water_source_common
  //                 ? response.special_information.water_source_common
  //                 : false,
  //               isTubeHandPump: response?.special_information?.tube_hand_pump
  //                 ? response.special_information.tube_hand_pump
  //                 : false,
  //               otherWaterDetails: response?.special_information?.water_other
  //                 ? response.special_information.water_other
  //                 : "",
  //               totalBooksCount: response?.special_information?.total_books
  //                 ? response.special_information.total_books
  //                 : "",
  //               totalMeetingsInYear: response?.special_information
  //                 ?.number_of_meetings
  //                 ? response.special_information.number_of_meetings
  //                 : "",
  //               headMasterMessage: response?.other_school_details?.head_master_msg
  //                 ? response.other_school_details.head_master_msg
  //                 : "",
  //               schoolHistoryPic: response?.other_school_details
  //                 ?.school_history_photo
  //                 ? response.other_school_details.school_history_photo
  //                 : "",
  //               schoolStrengthPic: response?.other_school_details
  //                 ?.school_strengths_photo
  //                 ? response.other_school_details.school_strengths_photo
  //                 : "",
  //               schoolChallengesPic: response?.other_school_details
  //                 ?.school_challenges_photo
  //                 ? response.other_school_details.school_challenges_photo
  //                 : "",
  //               headMasterPic: response?.other_school_details?.head_master_photo
  //                 ? response.other_school_details.head_master_photo
  //                 : "",
  //               schoolAchievement: response?.other_school_details?.school_history
  //                 ? response.other_school_details.school_history
  //                 : "",
  //               schoolChallenges: response?.other_school_details
  //                 ?.school_challenges
  //                 ? response.other_school_details.school_challenges
  //                 : "",
  //               schoolStrength: response?.other_school_details?.school_strengths
  //                 ? response.other_school_details.school_strengths
  //                 : "",
  //               schoolDevelopment: response?.other_school_details
  //                 ?.school_development_by_smc
  //                 ? response.other_school_details.school_development_by_smc
  //                 : "",
  //               schoolDevelopmentPic: response?.other_school_details
  //                 ?.school_development_by_smc_photo
  //                 ? response.other_school_details.school_development_by_smc_photo
  //                 : "",
  //               futurePlan: response?.other_school_details?.future_plan_of_school
  //                 ? response.other_school_details.future_plan_of_school
  //                 : "",
  //               futurePlanPic: response?.other_school_details
  //                 ?.future_plan_of_school_photo
  //                 ? response.other_school_details.future_plan_of_school_photo
  //                 : "",
  //               instructionForParents: response?.other_school_details
  //                 ?.instructions_for_parents
  //                 ? response.other_school_details.instructions_for_parents
  //                 : "",
  //               publicParticipation: response?.public_donation
  //                 ?.facilities_obtained
  //                 ? response.public_donation.facilities_obtained
  //                 : "",
  //               publicParticipationCost: response?.public_donation?.total_donation
  //                 ? response.public_donation.total_donation
  //                 : "",
  //               donorsForm: response?.donors
  //                 ? smcDonorsForm
  //                 : [{ donorsName: "" }, { donorsName: "" }, { donorsName: "" }],
  //             });
  //           }
  //         }
  //       });
  //   };

  // getHMService()
  //   .getAllFormDetails(32)
  //   .then((res) => {
  //     if(res){
  //       const {data} =res
  //       const{response} = data
  //       console.log(response)
  //       if(response.other_school_details){
  //         const {other_school_details} =response
  //         if(other_school_details.school_challenges === "" || other_school_details.school_history === "" || other_school_details.school_strengths === "" || other_school_details.school_challenges || other_school_details.school_history  || other_school_details.school_strengths   ){
  //           flagTwo = false
  //         }
  //         else{
  //           flagTwo = true
  //         }
  //       }

  //       if(response.school_details && response.school_details===[] ){
  //             flagOne = this.checkingComitteePre(response.school_details[0])
  //       }
  //       flagOne = true
  //       flagTwo=true
  //       console.log("this first")
  //     }
  //   });

  // checkingComitteePre = (res) => {
  //   let flagOne = true;
  //   let flagTwo = true;
  //   let flagThree = true;
  //   res.members.forEach((i) => {

  //     if (

  //       (i.name && i.phone && i.gender && i.category)
  //       &&
  //       (i.name === "" ||
  //         i.phone === "" ||
  //         i.gender === "" ||
  //         i.category === "")
  //     ) {
  //       flagOne = false;
  //     }
  //   });
  //   if (flagOne === false) {
  //     return true;
  //   }

  //   res.smc_meetings.forEach((i) => {
  //     if (
  //       (i.date && i.attendance && i.details) &&
  //       (i.date === "" || i.attendance === "" || i.details === "")

  //     ) {
  //       flagTwo = false;
  //     }
  //   });
  //   if (flagTwo === false) {
  //     return true;
  //   }

  //   res.smc_future_meetings.forEach((i) => {
  //     if (i.meetings_date === "" && i.meetings) {
  //       flagThree = false;
  //     }
  //   });

  //   if (flagThree === false) {
  //     return true;
  //   }

  //   return false;
  // };

  handleChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  displaySchoolDetails = () => {
    let { school_detail: schoolDetails } = this.state.HMSchool;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>Headmaster Details</div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Name : {schoolDetails.head_master}
                  </div>
                  <div className="col-6">
                    {" "}
                    Phone : {schoolDetails.head_master_mobile}
                  </div>
                  <div className="col-6">
                    {" "}
                    Email : {schoolDetails.head_master_email}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader style={{ padding: "10px" }}>
                <div style={{ padding: "10px" }} className={classes.formHead}>
                  Additional Teacher Details
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Name : {schoolDetails.additional_teacher}
                  </div>
                  <div className="col-6">
                    {" "}
                    Phone : {schoolDetails.additional_teacher_mobile}
                  </div>
                  <div className="col-6"> &nbsp;</div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader>
                <div style={{ padding: "10px" }} className={classes.formHead}>
                  School Teacher Details
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Total Count : {schoolDetails.total_teacher}
                  </div>
                  <div className="col-6"></div>
                  <div className="col-6">
                    {" "}
                    Male : {schoolDetails.male_teacher}
                  </div>
                  <div className="col-6">
                    {" "}
                    Female : {schoolDetails.female_teacher}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>Student Details</div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Total Count : {schoolDetails.total_student}
                  </div>
                  <div className="col-6"></div>
                  <div className="col-6"> Boys : {schoolDetails.boys}</div>
                  <div className="col-6"> Girls : {schoolDetails.girls}</div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>School Things</div>
              </CardHeader>
              {console.log(schoolDetails, "schoolDetails")}
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Classrooms : {schoolDetails.is_class_rooms ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    washroom : {schoolDetails.is_washroom ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    computer room :{" "}
                    {schoolDetails.is_computer_room ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Internet : {schoolDetails.is_internet ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Compound Wall : {schoolDetails.is_compound ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Library : {schoolDetails.is_library ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Ground : {schoolDetails.is_ground ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Electricity : {schoolDetails.is_electricity ? "✔️" : "❌"}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="row">
            <div className="col-3">
              {schoolDetails.whole_building &&
                this.previewImage(schoolDetails.whole_building, "Building")}
            </div>

            <div className="col-3">
              {schoolDetails.labs &&
                this.previewImage(schoolDetails.labs, "Labs")}
            </div>

            <div className="col-3">
              {schoolDetails.ground &&
                this.previewImage(schoolDetails.ground, "Ground")}
            </div>

            <div className="col-3">
              {schoolDetails.compound &&
                this.previewImage(schoolDetails.compound, "Compound")}
            </div>

            <div className="col-3">
              {schoolDetails.classrooms &&
                this.previewImage(schoolDetails.classrooms, "Classroom")}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  previewImage = (url, title) => {
    return (
      <Card>
        <CardHeader>
          <div className={classes.formHead}>{title}</div>
        </CardHeader>
        <CardBody>
          <img src={url} id="imgPrev" className={classes.imgPreview} />
        </CardBody>
      </Card>
    );
  };

  getSchoolDetails = () => {
    getHMService()
      .getMySchool()
      .then((res) => {
        let data = res.data;
        if (data.status == 200) {
          this.setState(
            {
              HMSchool: data.response,
            },
            () => {
              localStorage.setItem(
                "HMSchool",
                JSON.stringify(this.state.HMSchool)
              );
              this.setFlag();
            }
          );
        }
      });
  };

  setFlag = () => {
    let HMflag = false;
    let SMCFlag = false;
    if (this.state?.HMSchool?.school_detail) {
      HMflag = true;
    }
    if (this.state?.HMSchool?.smc) {
      SMCFlag = true;
    }
    this.setState({
      isHMDetailsPresent: HMflag,
      isSMCDetailsPresent: SMCFlag,
    });
  };

  onSMCFieldChange = (e, key) => {
    let smcDetailsObj = { ...this.state.smcDetails };
    this.setState({
      smcDetails: { ...smcDetailsObj, [key]: e.target.value },
    });
  };

  renderMemberForm = () => {
    let { memberForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];

    let memberFlag = true;
    this.state.memberForm.forEach((i) => {
      if (
        i.name === "" ||
        i.phone === "" ||
        i.gender === "" ||
        i.category === "" ||
        i.patent === ""
      ) {
        memberFlag = false;
      }
    });

    memberForm.map((data, index) => {
      form.push(
        <div>
          <div
            className={classes.committeeContainer}
            // className={["row", classes.formFields]
            //   .toString()
            //   .split(",")
            //   .join(" ")}
            key={index}
          >
            <p
              style={{ minWidth: "100px", marginTop: "10px", color: "#958989" }}
            >
              {" "}
              सदस्य क्र {index + 1}
            </p>
            <input
              type="text"
              value={data.name}
              placeholder="Name"
              onChange={(e) => this.onValChange("name", e, index)}
              className={classes.committeeNameInput}
            />

            <select
              className={classes.committeeCategoryInput}
              onChange={(e) => this.onValChange("gender", e, index)}
              value={data.gender}
              placeholder="Category"
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="MALE">
                MALE
              </option>
              <option className={classes.committeeCategoryInput} value="FEMALE">
                FEMALE
              </option>
              <option className={classes.committeeCategoryInput} value="OTHERS">
                OTHERS
              </option>
            </select>

            <select
              className={classes.committeeCategoryInput}
              onChange={(e) => this.onValChange("category", e, index)}
              value={data.category}
              placeholder="Category"
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="OPEN">
                OPEN
              </option>
              <option className={classes.committeeCategoryInput} value="OBC">
                OBC
              </option>
              <option className={classes.committeeCategoryInput} value="NT">
                NT
              </option>
              <option className={classes.committeeCategoryInput} value="ST">
                ST
              </option>
              <option className={classes.committeeCategoryInput} value="SC">
                SC
              </option>
              <option className={classes.committeeCategoryInput} value="SBC">
                SBC
              </option>
              <option className={classes.committeeCategoryInput} value="VJNT">
                VJNT
              </option>
              <option className={classes.committeeCategoryInput} value="OTHERS">
                OTHERS
              </option>
            </select>

            <select
              className={classes.committeeCategoryInput}
              onChange={(e) => this.onValChange("patent", e, index)}
              value={data.patent}
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="पालक">
                पालक
              </option>
              <option className={classes.committeeCategoryInput} value="इतर">
                इतर
              </option>
            </select>

            <PhoneInTalk
              style={{
                color: "black",
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "8px",
              }}
            />
            <input
              min="0"
              type="number"
              placeholder="Mobile"
              value={data.phone}
              className={classes.committeeMobileInput}
              onChange={(e) => this.onValChange("mobile", e, index)}
            />

            <button
              style={{ border: "0px", backgroundColor: "transparent" }}
              onClick={() => this.removeUser(index)}
            >
              <DeleteOutline style={{ color: "red" }} />
            </button>
          </div>
          {this.state.isReviewComittee &&
            (data.name === "" || data.phone === "") ? (
            <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>
      );
    });
    let formWrap = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.reminderContainer}>
            <p style={{ fontSize: "26px" }}>
              शाळा व्यवस्थापन समितीचे सदस्य-
              <span className={classes.redStar}>*</span>
              <Popup trigger={<HelpOutline />} position="right center">
                <div>
                  सर्व सदस्यांची माहिती, मोबाईल नंबर यांची माहिती अचूक लिहावी.
                </div>
              </Popup>
            </p>
          </div>
        </div>

        {form}
        {this.state.isFormReview && memberFlag === false ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
        <div
          // className={["m-2", classes.headSection]
          //   .toString()
          //   .split(",")
          //   .join(" ")}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <button
            style={{
              fontSize: "35px",
              border: "0px",
              backgroundColor: "transparent",
              marginRight: "20px",
              backgroundColor: "#c8ed91",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              height: "40px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
            onClick={() => this.addUser()}
          >
            +
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              अजून एक सदस्य जोडा
            </span>
          </button>
        </div>
      </div>
    );
    return formWrap;
  };

  onSubmitUserForm = (flag) => {
    let { school_id } = this.state.HMSchool;
    let { smcDetails } = this.state;
    let reqBody = {
      members: [...this.state.memberForm],
      school: school_id,
      ...smcDetails,
    };

    console.log(reqBody, "reqBody onSubmitUserForm", this.state.smcDetails);
    if (!flag) {
      getHMService()
        .addSMCDetails(reqBody)
        .then(
          (res) => {
            this.setState(
              {
                message: "SMC data added Successfully",
                error: false,
                isAddMember: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            this.setState({
              message: err,
              error: true,
              isAddMember: false,
            });
          }
        );
    } else {
      getHMService()
        .updateSMCDetails(reqBody, smcDetails.id)
        .then(
          (res) => {
            this.setState(
              {
                message: "SMC data updated Successfully",
                error: false,
                isAddMember: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            this.setState({
              message: err,
              error: true,
              isAddMember: false,
            });
          }
        );
    }
  };

  addUser = () => {
    let emptyForm = JSON.stringify(this.state.emptyForm);
    let memberForm = [...this.state.memberForm];
    memberForm.push(JSON.parse(emptyForm));
    this.setState(
      {
        memberForm,
      },
      () => {
        console.log(this.state.memberForm, "memberform");
      }
    );
  };

  removeUser = (index) => {
    let memberForm = [...this.state.memberForm];
    if (memberForm.length > 4) {
      memberForm.splice(index, 1);
      this.setState({
        memberForm,
      });
    }
  };

  onValChange = (flag, event, index) => {
    let memberForm = [...this.state.memberForm];

    if (flag === "name") {
      memberForm[index].name = event.target.value;
    } else if (flag === "mobile") {
      memberForm[index].phone = event.target.value;
    } else if (flag === "gender") {
      memberForm[index].gender = event.target.value;
    } else if (flag === "patent") {
      memberForm[index].patent = event.target.value;
    } else {
      memberForm[index].category = event.target.value;
    }
    this.setState({
      memberForm,
    });
  };

  renderAddMemberDialog = () => {
    return (
      <Modal
        open={this.state.isAddMember}
        onClose={() => {
          this.setState({
            isAddMember: false,
          });
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <span
            style={{
              background: "white",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <div className={classes.modalHead}>
              <div>&nbsp;</div>
              <div>SMC Details</div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    this.setState({
                      isAddMember: false,
                      memberForm: [
                        {
                          name: "",
                          phone: "",
                        },
                      ],
                      smcDetails: {},
                    });
                  }}
                >
                  <ClearIcon />
                </button>
              </div>
            </div>
            <div>{this.renderMemberForm()}</div>
          </span>
        </div>
      </Modal>
    );
  };

  onSMCDetailFieldChange = (e, key) => {
    console.log("onSMCDetailFieldChange onchange", e.target.value);
    let smcDetilsObj = { ...this.state.HMDetails };
    this.setState({
      HMDetails: { ...smcDetilsObj, [key]: e.target.value },
    });
  };

  onSMCDetailFileUpload = async (event, field) => {
    // this.setState({
    //   [field]: e.target.files[0],
    // });
    // let elem = document.getElementById(field);
    // elem.src = URL.createObjectURL(e.target.files[0]);

    const fd = new FormData();
    fd.append(
      "image",
      event.currentTarget ? event.currentTarget.files[0] : event
    );
    fd.append("school_id", this.state.HMSchool?.school_id);
    console.log(fd, "fd");
    let mediaRes = await getHMService().uploadSchoolImages(fd);
    console.log(mediaRes.data.file, "media");

    if (field === "WHOLE_BUILDING") {
      this.setState({
        whole_building: mediaRes.data.file,
      });
    } else if (field === "GROUND") {
      this.setState({
        ground: mediaRes.data.file,
      });
    } else if (field === "COMPOUND") {
      this.setState({
        compound: mediaRes.data.file,
      });
    } else if (field === "SECURITYWALL") {
      this.setState({
        securityWall: mediaRes.data.file,
      });
    } else if (field === "CLASSROOMS") {
      this.setState({
        classrooms: mediaRes.data.file,
      });
    } else if (field === "WATER") {
      this.setState({
        waterPic: mediaRes.data.file,
      });
    } else if (field === "RAMP") {
      this.setState({
        rampPic: mediaRes.data.file,
      });
    } else if (field === "KITCHEN") {
      this.setState({
        kitchenPic: mediaRes.data.file,
      });
    } else if (field === "STUDYMATERIAL") {
      this.setState({
        studyMaterialPic: mediaRes.data.file,
      });
    } else if (field === "LIBRARYMATERIAL") {
      this.setState({
        librarayMaterialPic: mediaRes.data.file,
      });
    } else if (field === "GAMES") {
      this.setState({
        gamesPic: mediaRes.data.file,
      });
    } else if (field === "RESTROOM") {
      this.setState({
        restroomPic: mediaRes.data.file,
      });
    } else {
      this.setState({
        otherPic: mediaRes.data.file,
      });
    }
  };

  onSelectedFileUpload = (key) => {
    // let { school_id } = this.state.HMSchool;
    console.log(key);
    let file = this.state[key];

    let fd = new FormData();
    fd.append("image", file);
    fd.append("school_id", this.state.HMSchool?.school_id);
    getHMService()
      .uploadSchoolImages(fd)
      .then(
        (res) => {
          console.log(res, "res after img upload");
          let field = key.toString().toLowerCase();
          this.setState({
            message: `${key.toString().toLowerCase()} uploaded Successfully`,
            error: false,
            [field]: res.data.file,
          });
        },
        (err) => {
          console.log("err", err);
          this.setState({
            message: "Something went wrong",
            error: true,
          });
        }
      );
  };

  previewFile = (key) => {
    console.log(this.state[key], "key from previewFile");
    let url = window.URL.createObjectURL(this.state[key]);
    return (
      <div>
        <img src={url} />
      </div>
    );
  };

  urlToimageName = (url) => {
    let index = url.lastIndexOf("/") + 1;
    let filename = url.substr(index);
    let dashIndex = filename.indexOf("_") + 1;
    let name = filename.substr(dashIndex);
    return name;
  };
  renderHMDform = () => {
    let {
      HMDetails,
      isEditDetail,
      whole_building,
      labs,
      ground,
      compound,
      classrooms,
      tabIndex,
    } = this.state;
    return (
      <>
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-6">
            <span>Class Type</span>
            <span>
              <input
                type="text"
                className="form-control"
                value={HMDetails.class_type}
                onChange={(e) => this.onSMCDetailFieldChange(e, "class_type")}
              />
            </span>
          </div>
        </div>
        <hr />

        <Tabs
          value={tabIndex}
          onChange={this.handleChange.bind(this)}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="HM Details"
            {...a11yProps(0)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Additinal Teacher Details"
            {...a11yProps(1)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Teacher Count"
            {...a11yProps(2)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Student Count"
            {...a11yProps(3)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="School Gallery"
            {...a11yProps(4)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="School things"
            {...a11yProps(5)}
          ></Tab>
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <div className="row" style={{ padding: "10px" }}>
            <div className={classes.formHead} style={{ padding: "10px" }}>
              Headmaster Details
            </div>
            <div className="col-6">
              <span>Name</span>
              <span>
                <input
                  type="text"
                  className="form-control"
                  value={HMDetails.head_master}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Phone</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.head_master_mobile}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master_mobile")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Email</span>
              <span>
                <input
                  type="email"
                  className="form-control"
                  value={HMDetails.head_master_email}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master_email")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <div className="row">
            <div className={classes.formHead}>Additinal Teacher Details</div>
            <div className="col-6">
              <span>Phone</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.additional_teacher_mobile}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "additional_teacher_mobile")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Email</span>
              <span>
                <input
                  type="email"
                  className="form-control"
                  value={HMDetails.additional_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "additional_teacher")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <div className="row">
            <div className={classes.formHead}>Teacher Count</div>
            <div className="col-6">
              <span>Total</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.total_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "total_teacher")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Male</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.male_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "male_teacher")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Female</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.female_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "female_teacher")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <div className="row">
            <div className={classes.formHead}>Student Count</div>
            <div className="col-6">
              <span>Total</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.total_student}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "total_student")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Boys</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.boys}
                  onChange={(e) => this.onSMCDetailFieldChange(e, "boys")}
                />
              </span>
            </div>
            <div className="col-6">
              <span>Girls</span>
              <span>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  value={HMDetails.girls}
                  onChange={(e) => this.onSMCDetailFieldChange(e, "girls")}
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={4}>
          <div
            style={{
              height: "500px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div style={{ padding: "10px" }} className={classes.formHead}>
              School Gallery
            </div>
            <div className="row">
              <div>Building</div>
              {whole_building ? (
                <>
                  <div className="col-6">
                    <img src={whole_building} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          whole_building: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "WHOLE_BUILDING")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img
                      id="WHOLE_BUILDING"
                      src=""
                      width="100px"
                      height="100px"
                    />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        this.onSelectedFileUpload("WHOLE_BUILDING")
                      }
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Labs</div>
              {labs ? (
                <>
                  <div className="col-6">
                    <img src={labs} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          labs: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) => this.onSMCDetailFileUpload(e, "LABS")}
                    />
                  </div>
                  <div className="col-4">
                    <img id="LABS" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("LABS")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Ground</div>
              {ground ? (
                <>
                  <div className="col-6">
                    <img src={ground} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          ground: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) => this.onSMCDetailFileUpload(e, "GROUND")}
                    />
                  </div>
                  <div className="col-4">
                    <img id="GROUND" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("GROUND")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Compound</div>
              {compound ? (
                <>
                  <div className="col-6">
                    <img src={compound} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          compound: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "COMPOUND")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img id="COMPOUND" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("COMPOUND")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Class room</div>
              {classrooms ? (
                <>
                  <div className="col-6">
                    <img src={classrooms} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          classrooms: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "CLASSROOMS")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img id="CLASSROOMS" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("CLASSROOMS")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={5}>
          <div>School Things</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.classCheck}
                      onChange={(e) => {
                        this.setState({
                          classCheck: !this.state.classCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Classroom"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.groundCheck}
                      onChange={(e) => {
                        this.setState({
                          groundCheck: !this.state.groundCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Ground"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.wallCheck}
                      onChange={(e) => {
                        this.setState({
                          wallCheck: !this.state.wallCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Compund Wall"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.internetCheck}
                      onChange={(e) => {
                        this.setState({
                          internetCheck: !this.state.internetCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Internet"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.libraryCheck}
                      onChange={(e) => {
                        this.setState({
                          libraryCheck: !this.state.libraryCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Library"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.washroomCheck}
                      onChange={(e) => {
                        this.setState({
                          washroomCheck: !this.state.washroomCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Washroom"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.computerCheck}
                      onChange={(e) => {
                        this.setState({
                          computerCheck: !this.state.computerCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Computer room"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.electricityCheck}
                      onChange={(e) => {
                        this.setState({
                          electricityCheck: !this.state.electricityCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Electricity"
                />
              </div>
            </div>
          </div>
        </TabPanel>

        <div
          className={["m-2", classes.headSection]
            .toString()
            .split(",")
            .join(" ")}
        >
          <div>
            <button
              style={{ backgroundColor: "#FFF", borderColor: "#18BD5B" }}
              // className="btn btn-primary"
              onClick={() => {
                this.setState({
                  isAddDetail: false,
                  isEditDetail: false,
                });
              }}
            >
              Cancel
            </button>
          </div>

          <div>
            <button
              // className="btn btn-primary"
              style={{
                backgroundColor: "#18BD5B",
                color: "#fff",
                borderColor: "#18BD5B",
              }}
              onClick={() => this.onSubmitSMCDetails(isEditDetail)}
            >
              {!isEditDetail ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </>
    );
  };

  getVal = (data) => {
    let obj = {};
    for (let key in data) {
      obj[key] = data[key] ? data[key] : "";
    }
    return obj;
  };

  onEditDetails = () => {
    let { school_detail } = this.state.HMSchool;
    this.setState({
      isEditDetail: true,
      isAddDetail: true,
      HMDetails: this.getVal(school_detail),
      whole_building: school_detail.whole_building,
      labs: school_detail.labs,
      ground: school_detail.ground,
      compound: school_detail.compound,
      classrooms: school_detail.classrooms,
    });
  };

  onSubmitSMCDetails = (flag) => {
    let formVal = { ...this.state.HMDetails };
    console.log(this.state.HMSchool.school_id, "this.state.HMSchool.school_id");
    let { school_id } = this.state.HMSchool;
    const school = JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"))
        ? JSON.parse(localStorage.getItem("AMdetails"))
        : null;

    let { whole_building, labs, ground, compound, classrooms } = this.state;
    let req_body = {
      school: JSON.parse(localStorage.getItem("HMSchool"))
        ? JSON.parse(localStorage.getItem("HMSchool").school_id)
        : JSON.parse(localStorage.getItem("AMdetails"))
          ? JSON.parse(localStorage.getItem("AMdetails").school_id)
          : null,
      ...formVal,
      whole_building,
      labs,
      ground,
      compound,
      classrooms,
      is_class_rooms: this.state.classCheck,
      is_library: this.state.libraryCheck,
      is_ground: this.state.groundCheck,
      is_washroom: this.state.washroomCheck,
      is_compound: this.state.wallCheck,
      is_computer_room: this.state.computerCheck,
      is_internet: this.state.internetCheck,
      is_electricity: this.state.electricityCheck,
    };
    console.log(req_body, "req_body --- onSubmitSMCDetails");
    if (!flag) {
      getHMService()
        .createSchoolDetails(req_body)
        .then(
          (req) => {
            this.setState(
              {
                message: "School details created",
                error: false,
                HMDetails: {},
                isAddDetail: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            console.log(err, "err while adding details.");
            this.setState({
              message: "Something went wrong",
              error: true,
              isAddDetail: false,
            });
          }
        );
    } else {
      let { id: details_id } = this.state.HMSchool.school_detail;
      getHMService()
        .editSchoolDetail(req_body, details_id)
        .then(
          (req) => {
            this.setState(
              {
                message: "School details Updated",
                error: false,
                HMDetails: {},
                isAddDetail: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            console.log(err, "err while adding details.");
            this.setState({
              message: "Something went wrong",
              error: true,
              isAddDetail: false,
            });
          }
        );
    }
  };

  renderAddDetailDialog = () => {
    return (
      <Modal
        open={this.state.isAddDetail}
        onClose={() => {
          this.setState({
            isAddDetail: false,
          });
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95%",
          }}
        >
          <span
            style={{
              background: "white",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
              backgroundColor: "#DCE6E3",
            }}
          >
            <div
              className={classes.modalHead}
              style={{ backgroundColor: "#335559" }}
            >
              <div>&nbsp;</div>
              <div style={{ color: "#fff", fontSize: "35px" }}>
                School Details
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    this.setState({
                      isAddDetail: false,
                    });
                  }}
                >
                  <ClearIcon />
                </button>
              </div>
            </div>
            <div>{this.renderHMDform()}</div>
          </span>
        </div>
      </Modal>
    );
  };

  renderMembers = (memData) => {
    let members = [];
    if (memData && memData.length) {
      memData.map((data, index) => {
        members.push(
          <tr key={index}>
            <td> {data.name}</td>
            <td> {data.phone}</td>
          </tr>
        );
      });
    }
    return members;
  };

  onEditSMCDetails = (flag) => {
    console.log(flag, "flag from onEditSMCDetails");
    this.setState(
      {
        isAddMember: true,
      },
      () => {
        if (flag) {
          this.setSMCDetails();
        }
      }
    );
  };

  setSMCDetails = () => {
    let { smc } = this.state.HMSchool;
    this.setState({
      smcDetails: smc,
      memberForm: smc.members,
    });
  };

  // render() {
  //   let {
  //     error,
  //     message,
  //     isHMDetailsPresent,
  //     isSMCDetailsPresent,
  //   } = this.state;
  //   let { school_detail, smc } = this.state.HMSchool;
  //   return (
  //     <GridItem xs={12} sm={12} md={12}>
  //       <Snackbar
  //         autoHideDuration={5000}
  //         message={message}
  //         color={error ? "danger" : "success"}
  //         close={false}
  //         place="br"
  //         closeNotification={() => {
  //           this.setState({
  //             message: null,
  //           });
  //         }}
  //         rtlActive={true}
  //         open={message ? true : false}
  //       ></Snackbar>
  //       {this.renderAddMemberDialog()}
  //       {this.renderAddDetailDialog()}
  //       <div>
  //         <Card>
  //           <CardHeader>
  //             <div className={classes.headSection}>
  //               <div className={classes.formHead}>School Details</div>
  //               <div>
  //                 {!isHMDetailsPresent ? (
  //                   <button
  //                     className="btn btn-info m-2"
  //                     title="Add HM Details"
  //                     onClick={() => {
  //                       this.setState({ isAddDetail: true });
  //                     }}
  //                   >
  //                     <AddIcon />
  //                   </button>
  //                 ) : (
  //                   <button
  //                     className="btn btn-primary m-2"
  //                     title="Edit SMC Details"
  //                     onClick={() => this.onEditDetails()}
  //                   >
  //                     <EditIcon />
  //                   </button>
  //                 )}
  //               </div>
  //             </div>
  //           </CardHeader>
  //           <CardBody>
  //             {isHMDetailsPresent ? (
  //               <>{this.displaySchoolDetails()}</>
  //             ) : (
  //               <div>No School Details Found. Please add the details.</div>
  //             )}
  //           </CardBody>
  //         </Card>
  //       </div>
  //       <div>
  //         <Card>
  //           <CardHeader>
  //             <div className={classes.headSection}>
  //               <div className={classes.formHead}>SMC Details </div>
  //               <div>
  //                 {isSMCDetailsPresent ? (
  //                   <button
  //                     className="btn btn-primary"
  //                     title="Edit SMC details"
  //                     onClick={() => this.onEditSMCDetails(true)}
  //                   >
  //                     <EditIcon />
  //                   </button>
  //                 ) : (
  //                   <button
  //                     className="btn btn-primary"
  //                     onClick={() => this.onEditSMCDetails(false)}
  //                   >
  //                     <AddIcon />
  //                   </button>
  //                 )}
  //               </div>
  //             </div>
  //           </CardHeader>
  //           <CardBody>
  //             {isSMCDetailsPresent ? (
  //               <>
  //                 <div>{school_detail.school_name}</div>
  //                 <div className="row">
  //                   <div className="col-6">Budget : {smc.budget}</div>
  //                   <div className="col-6">
  //                     Achievements : {smc.achievement}
  //                   </div>
  //                   <div className="col-6">Challenges : {smc.challenges}</div>
  //                   <div className="col-6">Meetings : {smc.meetings}</div>
  //                   <div className="col-6">
  //                     Roles and responsibility : {smc.roles_and_responsibility}
  //                   </div>
  //                   <div className="col-6">
  //                     School grant received : {smc.school_grant_received}
  //                   </div>
  //                   <Card>
  //                     <CardHeader>
  //                       <div className={classes.formHead}>Members</div>
  //                     </CardHeader>
  //                     <CardBody>
  //                       {smc.members && smc.members.length ? (
  //                         <table className="table">
  //                           <thead>
  //                             <tr>
  //                               <th>Name</th>
  //                               <th>Phone</th>
  //                             </tr>
  //                           </thead>
  //                           <tbody>{this.renderMembers(smc.members)}</tbody>
  //                         </table>
  //                       ) : (
  //                         <div>No Members Yet</div>
  //                       )}
  //                     </CardBody>
  //                   </Card>
  //                 </div>
  //               </>
  //             ) : (
  //               <div>No SMC Details Found. Please Add Details</div>
  //             )}
  //           </CardBody>
  //         </Card>
  //       </div>
  //     </GridItem>
  //   );
  // }

  changeToTeacher = () => {
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: true,
      isCommitteeDetails: false,
      isInformation: false,
      isExtraInfo: false,
    });
  };
  changeToCommittee = () => {
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: true,
      isInformation: false,
      isExtraInfo: false,
    });
  };
  changeToInfo = () => {
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: false,
      isInformation: false,
      isExtraInfo: true,
    });
  };

  changeToOtherInfo = () => {
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: false,
      isInformation: true,
      isExtraInfo: false,
    });
  };

  topButtonContainer = () => {
    return (
      <>
        {this.state.showTopContainer ? (<div>
          <button
            disabled={this.state.disableStatus}
            onClick={this.changeToTeacher}
            className={
              this.state.isTeacherDetails
                ? classes.currentPageButton
                : classes.nextPageButton
            }
          >
            प्राथमिक माहिती
          </button>
          <button
            disabled={this.state.disableStatus}
            onClick={this.changeToCommittee}
            className={
              this.state.isCommitteeDetails
                ? classes.currentPageButton
                : classes.nextPageButton
            }
          >
            शाळा व्यवस्थापन समिती
          </button>
          <button
            disabled={this.state.disableStatus}
            onClick={this.changeToInfo}
            className={
              this.state.isExtraInfo
                ? classes.currentPageButton
                : classes.nextPageButton
            }
          >
            विशेष माहिती
          </button>
          <button
            disabled={this.state.disableStatus}
            onClick={this.changeToOtherInfo}
            className={
              this.state.isInformation
                ? classes.currentPageButton
                : classes.nextPageButton
            }
          >
            इतर माहिती
          </button>
        </div>) : (<div></div>)}


        < div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <FormControl >
            <InputLabel>वर्ष निवडा</InputLabel>
            <Select
              variant="standard"
              style={{ width: '120px', marginRight: '2rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.YearValue}
              onChange={(e) => {
                console.log(e, "values")
                this.setState({
                  YearValue: e.target.value,
                  quaterValue: null
                },
                  () => {
                    this.getHalfarray()
                  })
              }}
              label="Select Year"
            >
              {
                this.state.yearList?.map((res, id) => {
                  return (
                    <MenuItem key={id} value={res}>
                      {res}
                    </MenuItem>
                  )
                })

              }
            </Select>
          </FormControl>
          <FormControl >
            <InputLabel>तिमाही निवडा</InputLabel>
            <Select
              variant="standard"
              style={{ width: '120px', marginRight: '2rem' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.quaterValue}
              onChange={(e) => {
                console.log(e, "values")
                this.setState({
                  quaterValue: e.target.value
                },
                  () => {

                  })
              }}

              label="Select Quater"
            >
              {
                this.state.quaterList?.map((res, id) => {
                  return (
                    <MenuItem key={id} value={res}>
                      {res}
                    </MenuItem>
                  )
                })

              }

            </Select>
          </FormControl>

          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: '#4B68BA', color: '#fff' }}
            onClick={() => {
              this.getSchoolDetailsForHm()
              this.setState({ showTopContainer: true })
            }

            }>फॉर्म भरा</Button>
        </div >
        {!this.state.showTopContainer ?
          (<div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem'
            // alignItems: 'center',
            // justifyContent: 'flex-start'
          }}>
            <div style={{ fontSize: "20px", padding: "10px" }}>
              महत्वाच्या सूचना:{""}
            </div>
            <div style={{ marginBottom: '1rem' }}>
              १. वर्ष आणि तिमाही निवडल्या नंतर तुम्हाला फॉर्म बघायला मिळेल.
            </div>
            <div style={{ marginBottom: '1rem' }}>
              २. फॉर्म मध्ये <b>प्राथमिक माहिती</b>,<b>शाळा व्यवस्थापन समिती</b>, <b>विशेष माहिती</b>, <b>इतर माहिती</b> भरता येईल.
            </div>
          </div>) :
          (<div></div>)
        }


        {
          this.state.NoForm ? <div>
            Form is already filled for this quarter
          </div> : null
        }
      </>
    );
  };

  moveBackward = () => {
    if (this.state.currentImage === 0) {
      this.setState({
        currentImage: 3,
      });
    } else {
      this.setState((i) => ({
        currentImage: i.currentImage - 1,
      }));
    }
  };

  moveForward = () => {
    if (this.state.currentImage === 3) {
      this.setState({
        currentImage: 0,
      });
    } else {
      this.setState((i) => ({
        currentImage: i.currentImage + 1,
      }));
    }
  };
  // onImageChange = (e) => {
  //   if (e.target.files.length !== 0) {

  //     const [file] = e.target.files;
  //     const urlValue = URL.createObjectURL(file);
  //     this.onSelectedFileUpload("WHOLE_BUILDING")
  //     const newImageArr = this.state.imageArray;
  //     newImageArr[this.state.currentImage].link = urlValue;
  //     this.setState({
  //       imageArray: [...newImageArr],
  //     });
  //   }
  // };

  sliderContainer = () => {
    let {
      HMDetails,
      isEditDetail,
      whole_building,
      labs,
      ground,
      compound,
      classrooms,
      tabIndex,
    } = this.state;
    let pictureFlag = true;
    // this.state.imageArray.forEach((i) => {
    //   if (i.link === null || i.link === "") {
    //     pictureFlag = false;
    //   }
    // });

    return (
      <div className={classes.PhotoContainer}>
        <p className={classes.sliderText}>
          शाळेतील उपलब्ध भौतिक सुविधा (फोटो)
          <span className={classes.remiderTextPrathmik}>
            (फोटो २०० के. बी. पेक्षा कमी असावे )
          </span>
          <Popup trigger={<HelpOutline />} position="right center">
            <div>किमान 200 kb साईज असणारे फोटो अपलोड करावे.</div>
          </Popup>
        </p>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>
                मुख्य इमारत <span className={classes.redStar}>*</span>
              </p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "WHOLE_BUILDING");
                    this.setState({
                      wholeBuildingName: e.currentTarget?.files[0]?.name,
                    });
                    console.log(e.currentTarget.files[0].name, "eee");
                  }}
                  style={{ width: 190, height: 38 }}
                />
                <div style={{ marginTop: "6px" }}>
                  {this.state.whole_building !== "" ? (
                    <>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CheckCircle
                          style={{
                            marginLeft: 20,
                            color: "green",
                            alignItems: "center",
                            justifyContnet: "center",
                          }}
                        />
                        <p className={classes.fileNameText}>
                          File name:
                          {this.state.wholeBuildingName ||
                            this.urlToimageName(this.state.whole_building)}
                        </p>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {this.state.isFormReview && this.state.whole_building === "" ? (
              <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
            ) : null}
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>पेयजल</p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "WATER");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.waterPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.waterPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>
                मैदान <span className={classes.redStar}>*</span>
              </p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "GROUND");
                    this.setState({
                      groundName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.ground !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.state.groundName ||
                          this.urlToimageName(this.state.ground)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {this.state.isFormReview && this.state.ground === "" ? (
              <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
            ) : null}
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>रॅम्प</p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "RAMP");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.rampPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.rampPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>
                कार्यालय <span className={classes.redStar}>*</span>
              </p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "COMPOUND");
                    this.setState({
                      hallName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                  style={{ width: 190, height: 38 }}
                />
                {this.state.compound !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.state.hallName ||
                          this.urlToimageName(this.state.compound)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {this.state.isFormReview && this.state.compound === "" ? (
              <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
            ) : null}
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>किचन शेड</p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "KITCHEN");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.kitchenPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.kitchenPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>
                वर्गखोली <span className={classes.redStar}>*</span>
              </p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "CLASSROOMS");
                    this.setState({
                      className: e.currentTarget?.files[0]?.name,
                    });
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.classrooms !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.state.className ||
                          this.urlToimageName(this.state.classrooms)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {this.state.isFormReview && this.state.classrooms === "" ? (
              <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
            ) : null}
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>
                अध्ययन – अध्यापन साहित्य
              </p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "STUDYMATERIAL");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.studyMaterialPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.studyMaterialPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.imageContainer}>
            <p className={classes.imageHeadingText}>सुरक्षा भिंत</p>
            <div>
              <input
                type="file"
                className="form-control"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  this.onSMCDetailFileUpload(e, "SECURITYWALL");
                  this.setState({
                    securityName: e.currentTarget?.files[0]?.name,
                  });
                }}
                style={{ width: 190, height: 38 }}
              />

              {this.state.securityWall !== "" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "6px",
                    }}
                  >
                    <CheckCircle
                      style={{
                        marginLeft: 20,
                        color: "green",
                        alignItems: "center",
                        justifyContnet: "center",
                      }}
                    />
                    <p className={classes.fileNameText}>
                      File name:
                      {this.state.securityName ||
                        this.urlToimageName(this.state.securityWall)}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>ग्रंथालय/वाचन साहित्य</p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "LIBRARYMATERIAL");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.librarayMaterialPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.librarayMaterialPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.imageContainer}>
            <p className={classes.imageHeadingText}>
              खेळ व क्रीडाविषयक सामग्री
            </p>
            <div>
              <input
                type="file"
                className="form-control"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  this.onSMCDetailFileUpload(e, "GAMES");
                }}
                style={{ width: 190, height: 38 }}
              />

              {this.state.gamesPic !== "" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "6px",
                    }}
                  >
                    <CheckCircle
                      style={{
                        marginLeft: 20,
                        color: "green",
                        alignItems: "center",
                        justifyContnet: "center",
                      }}
                    />
                    <p className={classes.fileNameText}>
                      File name:
                      {this.urlToimageName(this.state.gamesPic)}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div style={{ marginLeft: "30px" }}>
            <div className={classes.imageContainer}>
              <p className={classes.imageHeadingText}>इतर सुविधा</p>
              <div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onSMCDetailFileUpload(e, "OTHER");
                  }}
                  style={{ width: 190, height: 38 }}
                />

                {this.state.otherPic !== "" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "6px",
                      }}
                    >
                      <CheckCircle
                        style={{
                          marginLeft: 20,
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      <p className={classes.fileNameText}>
                        File name:
                        {this.urlToimageName(this.state.otherPic)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <p className={classes.imageHeadingText}>स्वच्छतागृहे</p>
          <div>
            <input
              type="file"
              className="form-control"
              accept="image/jpeg, image/png"
              onChange={(e) => {
                this.onSMCDetailFileUpload(e, "RESTROOM");
              }}
              style={{ width: 190, height: 38 }}
            />

            {this.state.restroomPic !== "" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "6px",
                  }}
                >
                  <CheckCircle
                    style={{
                      marginLeft: 20,
                      color: "green",
                      alignItems: "center",
                      justifyContnet: "center",
                    }}
                  />
                  <p className={classes.fileNameText}>
                    File name:
                    {this.urlToimageName(this.state.restroomPic)}
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#D3E7F8",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <p style={{ color: "#000000", fontSize: "20px" }}>महत्त्वाचे : </p>
          <p style={{ color: "#415EB6", fontSize: "20px", padding: "5px" }}>
            सदर फॉर्ममधील सर्व माहिती व फोटो अपलोड करणे आवश्यक आहे . ज्या ठिकाणी
            माहिती भरली नसेल किंवा फोटो अपलोड केला नसेल, ती सुविधा अथवा योजना
            तुमच्या शाळेत उपलब्ध नाही असा त्याचा अर्थ होईल.
          </p>
        </div>

        {/* <div
            style={{
              width: 500,
              height: 500,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "Center",
            }}
          >
          
              <div className="row">
                {whole_building ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center">
                      <img
                        className={classes.sliderImage}
                        src={whole_building}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            whole_building: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "WHOLE_BUILDING")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="WHOLE_BUILDING"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.onSelectedFileUpload("WHOLE_BUILDING")
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
                
              <div className="row">
                {compound ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row justify-content-center">
                      <img src={compound} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            compound: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    div
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "COMPOUND")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="COMPOUND"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("COMPOUND")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
         
              <div className="row">
                {ground ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row justify-content-center">
                      <img src={ground} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            ground: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "GROUND")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img id="GROUND" src="" className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("GROUND")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
               
              <div className="row">
                {classrooms ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                      <img src={classrooms} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            classrooms: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "CLASSROOMS")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="CLASSROOMS"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("CLASSROOMS")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
         
 
          </div> */}

        {/* <div className={classes.PhotoSubContainer}>
          <button onClick={this.moveBackward} className={classes.sliderButton}>
            {"<"}
          </button>
          <div
            style={{
              width: 500,
              height: 500,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "Center",
            }}
          >
            {this.state.currentImage == 0 && (
              <div className="row">
                {whole_building ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center">
                      <img
                        className={classes.sliderImage}
                        src={whole_building}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            whole_building: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "WHOLE_BUILDING")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="WHOLE_BUILDING"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.onSelectedFileUpload("WHOLE_BUILDING")
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.currentImage == 1 && (
              <div className="row">
                {compound ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row justify-content-center">
                      <img src={compound} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            compound: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    div
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "COMPOUND")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="COMPOUND"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("COMPOUND")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.currentImage == 2 && (
              <div className="row">
                {ground ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row justify-content-center">
                      <img src={ground} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            ground: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "GROUND")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img id="GROUND" src="" className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("GROUND")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.currentImage == 3 && (
              <div className="row">
                {classrooms ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                      <img src={classrooms} className={classes.sliderImage} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        onClick={() => {
                          this.setState({
                            classrooms: "",
                          });
                        }}
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12">
                      <input
                        type="file"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        onChange={(e) =>
                          this.onSMCDetailFileUpload(e, "CLASSROOMS")
                        }
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <img
                        id="CLASSROOMS"
                        src=""
                        className={classes.sliderImage}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onSelectedFileUpload("CLASSROOMS")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
 
          </div>

     
          <button onClick={this.moveForward} className={classes.sliderButton}>
            {">"}
          </button>
        </div>
         */}
        {/* {this.state.isReview &&
        (this.state.labs === "" ||
          this.state.whole_building === "" ||
          this.state.ground === "" ||
          this.state.compound === "" ||
          this.state.classrooms === "") ? (
          <p className={classes.redTextMiddleSlider}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null} */}

        {/* {this.state.isFormReview &&
        (
          this.state.whole_building === "" ||
          this.state.ground === "" ||
          this.state.compound === "" ||
          this.state.classrooms === "") ? (
          <p className={classes.redTextMiddleSlider}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null}

        <div className={classes.typeOfImage}>
          <p
            className={
              this.state.currentImage == 0
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            मुख्य इमारत
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            className={
              this.state.currentImage == 1
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            कार्यालय
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            className={
              this.state.currentImage == 2
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            मैदान
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            className={
              this.state.currentImage == 3
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            वर्गखोली
          </p>
     
        </div> */}
      </div>
    );
  };

  headMasterData = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>
            मुख्याध्यापकांचे नाव
            <span className={classes.redStar}>*</span>
          </p>
          <div className={classes.headMasterData}>
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "221px",
              }}
              value={this.state.headMasterName}
              onChange={(event) => {
                this.setState({ headMasterName: event.target.value });
              }}
              placeholder="Head Master Full Name"
              type="Text"
            />

            <PhoneInTalk
              style={{ color: "black", marginLeft: "10px", marginTop: "6px" }}
            />
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                width: "175px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className={classes.teachersNumberPlaceHolder}
              value={this.state.headMasterNumber}
              onChange={(event) => {
                this.setState({ headMasterNumber: event.target.value });
              }}
              placeholder=" Head Master Number"
              type="Number"
              min="0"
            />
            <select
              className={classes.teacherCasteSelect}
              onChange={(event) => {
                this.setState({ headMasterCaste: event.target.value });
              }}
              value={this.state.headMasterCaste}
              placeholder="Category"
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="OPEN">
                OPEN
              </option>
              <option className={classes.teacherCaste} value="OBC">
                OBC
              </option>
              <option className={classes.teacherCaste} value="NT">
                NT
              </option>
              <option className={classes.teacherCaste} value="ST">
                ST
              </option>
              <option className={classes.teacherCaste} value="SC">
                SC
              </option>
              <option className={classes.teacherCaste} value="SBC">
                SBC
              </option>
              <option className={classes.teacherCaste} value="VJNT">
                VJNT
              </option>
              <option className={classes.teacherCaste} value="OTHERS">
                OTHERS
              </option>
            </select>
          </div>
        </div>
        {/* {this.state.isReview &&
        (this.state.headMasterName === "" ||
          this.state.headMasterNumber === "") ? (
          <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null} */}
        {this.state.isFormReview &&
          (this.state.headMasterName === "" ||
            this.state.headMasterNumber === "" ||
            this.state.headMasterCaste === "") ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>
            जेष्ठ पुरुष शिक्षकाचे नाव
            <span className={classes.redStar}>*</span>
          </p>
          <div className={classes.headMasterData}>
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                width: "221px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              value={this.state.seniorBoyTeacherName}
              onChange={(event) => {
                this.setState({ seniorBoyTeacherName: event.target.value });
              }}
              placeholder="Male Senior Full Name"
            />
            <PhoneInTalk
              style={{ color: "black", marginLeft: "10px", marginTop: "6px" }}
            />
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                width: "175px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className={classes.teachersNumberPlaceHolder}
              value={this.state.seniorBoyTeacherNumber}
              onChange={(event) => {
                this.setState({ seniorBoyTeacherNumber: event.target.value });
              }}
              placeholder="Male Senior Number"
              type="Number"
              min="0"
            />
            <select
              className={classes.teacherCasteSelect}
              onChange={(event) => {
                this.setState({ seniorBoyTeacherCaste: event.target.value });
              }}
              value={this.state.seniorBoyTeacherCaste}
              placeholder="Category"
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="OPEN">
                OPEN
              </option>
              <option className={classes.teacherCaste} value="OBC">
                OBC
              </option>
              <option className={classes.teacherCaste} value="NT">
                NT
              </option>
              <option className={classes.teacherCaste} value="ST">
                ST
              </option>
              <option className={classes.teacherCaste} value="SC">
                SC
              </option>
              <option className={classes.teacherCaste} value="SBC">
                SBC
              </option>
              <option className={classes.teacherCaste} value="VJNT">
                VJNT
              </option>
              <option className={classes.teacherCaste} value="OTHERS">
                OTHERS
              </option>
            </select>
          </div>
        </div>
        {/* {this.state.isReview &&
        (this.state.seniorBoyTeacherName === "" ||
          this.state.seniorBoyTeacherNumber === "") ? (
          <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null} */}
        {this.state.isFormReview &&
          (this.state.seniorBoyTeacherName === "" ||
            this.state.seniorBoyTeacherNumber === "" ||
            this.state.seniorBoyTeacherCaste === "") ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>
            जेष्ठ महिला शिक्षकाचे नाव
            <span className={classes.redStar}>*</span>
          </p>
          <div className={classes.headMasterData}>
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                width: "221px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              value={this.state.seniorGirlTeacherName}
              onChange={(event) => {
                this.setState({ seniorGirlTeacherName: event.target.value });
              }}
              placeholder="Female Senior Full Name"
            />
            <PhoneInTalk
              style={{ color: "black", marginLeft: "10px", marginTop: "6px" }}
            />
            <input
              style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                marginLeft: "10px",
                paddingLeft: "10px",
                width: "175px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className={classes.teachersNumberPlaceHolder}
              value={this.state.seniorGirlTeacherNumber}
              onChange={(event) => {
                this.setState({ seniorGirlTeacherNumber: event.target.value });
              }}
              placeholder="Female Senior Number"
              type="Number"
              min="0"
            />
            <select
              className={classes.teacherCasteSelect}
              onChange={(event) => {
                this.setState({ seniorGirlTeacherCaste: event.target.value });
              }}
              value={this.state.seniorGirlTeacherCaste}
              placeholder="Category"
            >
              <option className={classes.committeeCategoryInput} value="">
                Select
              </option>
              <option className={classes.committeeCategoryInput} value="OPEN">
                OPEN
              </option>
              <option className={classes.teacherCaste} value="OBC">
                OBC
              </option>
              <option className={classes.teacherCaste} value="NT">
                NT
              </option>
              <option className={classes.teacherCaste} value="ST">
                ST
              </option>
              <option className={classes.teacherCaste} value="SC">
                SC
              </option>
              <option className={classes.teacherCaste} value="SBC">
                SBC
              </option>
              <option className={classes.teacherCaste} value="VJNT">
                VJNT
              </option>
              <option className={classes.teacherCaste} value="OTHERS">
                OTHERS
              </option>
            </select>
          </div>
        </div>
        {this.state.isFormReview &&
          (this.state.seniorGirlTeacherName === "" ||
            this.state.seniorGirlTeacherNumber === "" ||
            this.state.seniorGirlTeacherCaste === "") ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
        {/* {this.state.isReview &&
        (this.state.seniorGirlTeacherName === "" ||
          this.state.seniorGirlTeacherName === "") ? (
          <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null} */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "10px",
          }}
        >
          (लागू नसल्यास NA/0 असे लिहावे)
        </div>
      </div>
    );
  };
  schoolData = () => {
    return (
      <div>
        <div className={classes.PhotoContainer}>
          <div className={classes.headMasteContainer}>
            <p className={classes.headMasterText}>
              शाळेचा पत्ता
              <span className={classes.redStar}>*</span>
              <Popup trigger={<HelpOutline />} position="right center">
                <div>पोस्टाने पत्र येईल असा सविस्तर शाळेचा पत्ता लिहावा</div>
              </Popup>
            </p>
            <div className={classes.headMasterData}>
              <textarea
                className={classes.addressTextContainer}
                value={this.state.schoolAddress}
                onChange={(event) => {
                  this.setState({ schoolAddress: event.target.value });
                }}
                placeholder="Address"
              >
                {this.state.schoolAddress}
              </textarea>
            </div>
          </div>
          {this.state.isFormReview && this.state.schoolAddress === "" ? (
            <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
          ) : null}

          <div className={classes.headMasteContainer}>
            <p className={classes.headMasterText}>
              शाळेची गुगल नकाशातील जागा
              <span className={classes.redStar}>*</span>
              <Popup trigger={<HelpOutline />} position="right center">
                <div>
                  शाळेत जाऊन गुगल मॅप ओपन करून चालू (शाळेचे) लोकेशन शेअर करावे.
                </div>
              </Popup>
            </p>
            <div className={classes.headMasterData}>
              <input
                className={classes.nameTextContainer}
                value={this.state.schoolAddressLink}
                onChange={(event) => {
                  this.setState({ schoolAddressLink: event.target.value });
                }}
                placeholder="Address Link"
              />
            </div>
            <div>
              <Popup trigger={<HelpOutline />} position="right center">
                <div>
                  <div style={{ fontSize: "11px" }}>
                    https://goo.gl/maps/gazp8qYoprfF7
                  </div>
                  अश्या प्रकारची शॉर्ट गुगल लोकेशन लिंक येथे कॉपी पेस्ट करा.
                  Share location केल्यावर short link मिळून जाईल. लिंक कशी
                  मिळवावी हे पाहण्यासाठी हा व्हिडिओ पहा-
                  <a
                    style={{ fontSize: "11px" }}
                    href="https://youtu.be/oBJJhMnG_ic?t=81 "
                  >
                    https://youtu.be/oBJJhMnG_ic?t=81{" "}
                  </a>
                </div>
              </Popup>
            </div>
          </div>

          {/* 
          {this.state.isReview &&
          (this.state.schoolAddress === "" ||
            this.state.schoolAddressLink === "") ? (
            <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे   </p>
          ) : null} */}

          {this.state.isFormReview && this.state.schoolAddressLink === "" ? (
            <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
          ) : null}
        </div>

        <div className={classes.PhotoContainer}>
          <div className={classes.headMasteContainer}>
            <p className={classes.headMasterText}>
              शाळेचा प्रकार
              <span className={classes.redStar}>*</span>
            </p>
            <div className={classes.headMasterData}>
              <select
                className={classes.nameTextContainer}
                onChange={(event) => {
                  console.log(event.target.value, "school type");
                  this.setState({ schooltype: event.target.value });
                }}
                value={this.state.schooltype}
              >
                <option className={classes.nameTextContainer} value="">
                  Select
                </option>
                <option
                  className={classes.nameTextContainer}
                  value="ZILLA PARISHAD"
                >
                  जिला परिषद
                </option>
                <option
                  className={classes.nameTextContainer}
                  value="NAGAR PALIKA"
                >
                  नगर पालिका
                </option>
                <option
                  className={classes.nameTextContainer}
                  value="PRIVATE-GRANTED"
                >
                  खाजगी - Granted
                </option>
                <option
                  className={classes.nameTextContainer}
                  value="PRIVATE-NON-GRANTED"
                >
                  खाजगी - Non-Granted
                </option>
                <option className={classes.nameTextContainer} value="CENTRAL">
                  केंद्रीय
                </option>
              </select>
            </div>
          </div>
          {console.log(
            this.state.isReview,
            this.state.schooltype.length,
            "len"
          )}
          {this.state.isFormReview && this.state.schooltype === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>
      </div>
    );
  };

  schoolStudents = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <div className={classes.reminderContainer}>
            <p className={classes.headMasterText}>
              विद्यार्थी संख्या
              <span className={classes.redStar}>*</span>
            </p>
          </div>
          <div className={classes.headMasterData}>
            <input
              type="Number"
              min="0"
              className={classes.schoolStudentsTotal}
              value={this.state.totalStudent}
              onChange={(event) => {
                this.setState({ totalStudent: event.target.value });
              }}
              placeholder="Total Students Count"
            />

            <p style={{ width: 39 }}> </p>
            <div>
              <div className={classes.schoolStudentsTotaBoy}>
                <input
                  className={classes.schoolStudetsInput}
                  value={this.state.boyTotalStudent}
                  onChange={(event) => {
                    this.setState({ boyTotalStudent: event.target.value });
                  }}
                  placeholder="Boys Count"
                  type="Number"
                  min="0"
                />

                <img
                  width={50}
                  src="https://cdn-icons-png.flaticon.com/512/145/145867.png"
                />
              </div>
              <div className={classes.schoolStudentsTotaBoy}>
                <input
                  className={classes.schoolStudetsInput}
                  value={this.state.girlTotalStudent}
                  onChange={(event) => {
                    this.setState({ girlTotalStudent: event.target.value });
                  }}
                  placeholder="Girls Count"
                  type="Number"
                  min="0"
                />

                <img
                  src="https://cdn.iconscout.com/icon/premium/png-256-thumb/schoolgirl-26-1128990.png"
                  width={50}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.isReview &&
        (this.state.totalStudent === "" ||
          this.state.totalStudent === null ||
          this.state.boyTotalStudent === "" ||
          this.state.boyTotalStudent === null ||
          this.state.girlTotalStudent === "" ||
          this.state.girlTotalStudent === null) ? (
          <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे   </p>
        ) : null} */}

        {this.state.isFormReview &&
          (this.state.totalStudent === "" ||
            this.state.totalStudent === null ||
            this.state.boyTotalStudent === "" ||
            this.state.boyTotalStudent === null ||
            this.state.girlTotalStudent === "" ||
            this.state.girlTotalStudent === null) ? (
          <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
        ) : null}
      </div>
    );
  };

  changeStudentNumber = (value, id) => {
    const requiredClass = this.state.allClassStudent;
    requiredClass[id - 1].students = value;
    this.setState({
      allClassStudent: [...requiredClass],
    });
  };

  changeCastMaleNumber = (value, id) => {
    const requiredClass = this.state.castwisecount;
    requiredClass[id - 1].male = value;
    this.setState({
      castwisecount: [...requiredClass],
    });
  };

  changeCastFemaleNumber = (value, id) => {
    const requiredClass = this.state.castwisecount;
    requiredClass[id - 1].female = value;
    this.setState({
      castwisecount: [...requiredClass],
    });
  };

  allClasses = () => {
    let classStudentsFlag = true;
    this.state.allClassStudent.forEach((i) => {
      if (i.students === null || i.students === "") {
        classStudentsFlag = false;
      }
    });

    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.vidaythiSankhayaContainer}>
          <div className={classes.reminderContainer}>
            <p className={classes.headMasterText}>
              वर्गनिहाय विद्यार्थी संख्या
              <span className={classes.redStar}>*</span>
            </p>
          </div>
          <div>
            {this.state.allClassStudent.map((res) => (
              <div>
                <div className={classes.StudentsAndClassContainer}>
                  <div
                    style={{
                      border: "0",
                      backgroundColor: "#DFE4E8",
                      height: "40px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      paddingLeft: "10px",
                      width: "165px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <p className={classes.nameTextClass}>{res.grade}</p>
                  </div>
                  <p style={{ width: 39 }}></p>

                  <input
                    placeholder="Students Number"
                    type="Number"
                    min="0"
                    value={res.students}
                    className={classes.nameTextContainerClass}
                    onChange={(event) => {
                      this.changeStudentNumber(event.target.value, res.id);
                    }}
                  />
                </div>
                {this.state.isReview &&
                  (res.students === "" || res.students === null) ? (
                  <p className={classes.redTextMiddleClass}>
                    *ही माहिती भरणे बाकी आहे
                  </p>
                ) : null}
              </div>
            ))}
          </div>
        </div>

        {this.state.isFormReview && classStudentsFlag === false ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
      </div>
    );
  };

  castwiseCount = () => {
    let classCasteFlag = true;
    this.state.castwisecount.forEach((i) => {
      if (i.female === "" || i.male === "") {
        classCasteFlag = false;
      }
    });

    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.vidaythiSankhayaContainer}>
          <div className={classes.reminderContainer}>
            <p className={classes.headMasterText}>
              जात प्रवर्गनिहाय विद्यार्थी संख्या
              <span className={classes.redStar}>*</span>
            </p>
          </div>
          <div>
            {this.state.castwisecount.map((res) => (
              <div>
                <div className={classes.StudentsAndClassContainer}>
                  <div className={classes.nameTextContainerClass}>
                    <p className={classes.nameTextClass}>{res.grade}</p>
                  </div>
                  <p style={{ width: 39 }}></p>

                  <input
                    placeholder="Boys Count"
                    type="Number"
                    min="0"
                    value={res.male}
                    className={classes.nameTextContainerClass1}
                    onChange={(event) => {
                      this.changeCastMaleNumber(event.target.value, res.id);
                    }}
                  />
                  <input
                    placeholder="Girls Count"
                    type="Number"
                    min="0"
                    value={res.female}
                    className={classes.nameTextContainerClass1}
                    onChange={(event) => {
                      this.changeCastFemaleNumber(event.target.value, res.id);
                    }}
                  />
                  {/* {console.log(this.state.castwisecount,"count")} */}
                </div>
                {/* {this.state.isReview &&
                (res.students === "" || res.students === null) ? (
                  <p className={classes.redTextMiddleClass}>
                    *ही माहिती भरणे बाकी आहे  
                  </p>
                ) : null} */}
              </div>
            ))}
          </div>
        </div>
        {this.state.isFormReview && classCasteFlag === false ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}
      </div>
    );
  };

  teacherCount = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <div className={classes.reminderContainer}>
            <p className={classes.headMasterText}>शिक्षक संख्या</p>
          </div>
          <div className={classes.headMasterData}>
            <div className={classes.schoolStudentsTotal}>
              <input
                placeholder="Total Teacher Count"
                className={classes.schoolStudentsTotal}
                value={this.state.totalTeacher}
                onChange={(event) => {
                  this.setState({ totalTeacher: event.target.value });
                }}
                type="Number"
                min="0"
              />
            </div>
            <p style={{ width: 39 }}> </p>
            <div>
              <div className={classes.schoolStudentsTotaBoy}>
                <input
                  placeholder="Male Count"
                  className={classes.schoolStudetsInput}
                  value={this.state.boyTotalTeacher}
                  onChange={(event) => {
                    this.setState({ boyTotalTeacher: event.target.value });
                  }}
                  type="Number"
                  min="0"
                />

                <p className={classes.nameText}>
                  <img
                    width={50}
                    src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/3_avatar-128.png"
                  />
                </p>
              </div>
              <div className={classes.schoolStudentsTotaBoy}>
                <input
                  placeholder="Female Count"
                  className={classes.schoolStudetsInput}
                  value={this.state.girlTotalTeacher}
                  onChange={(event) => {
                    this.setState({ girlTotalTeacher: event.target.value });
                  }}
                  min="0"
                  type="Number"
                />

                <p className={classes.nameText}>
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png"
                    width={50}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.isReview &&
          (this.state.totalTeacher === "" ||
            this.state.totalTeacher === null ||
            this.state.boyTotalTeacher === "" ||
            this.state.boyTotalTeacher === null ||
            this.state.girlTotalTeacher === "" ||
            this.state.girlTotalTeacher === null) ? (
          <p className={classes.redTextMiddle}>*ही माहिती भरणे बाकी आहे </p>
        ) : null}
      </div>
    );
  };

  isCheckingTeachersData = () => {
    if (this.state.headMasterName === "") {
      return false;
    }
    if (this.state.headMasterNumber === "") {
      return false;
    }
    if (this.state.seniorBoyTeacherName === "") {
      return false;
    }
    if (this.state.seniorBoyTeacherNumber === "") {
      return false;
    }
    if (this.state.seniorGirlTeacherName === "") {
      return false;
    }
    if (this.state.seniorGirlTeacherNumber === "") {
      return false;
    }
    if (this.state.schoolAddress === "") {
      return false;
    }
    if (this.state.schoolAddressLink === "") {
      return false;
    }
    if (this.state.totalStudent === "" || this.state.totalStudent === null) {
      return false;
    }
    if (
      this.state.boyTotalStudent === "" ||
      this.state.boyTotalStudent === null
    ) {
      return false;
    }
    if (
      this.state.girlTotalStudent === "" ||
      this.state.girlTotalStudent === null
    ) {
      return false;
    }

    if (this.state.totalTeacher === "" || this.state.totalTeacher === null) {
      return false;
    }
    if (
      this.state.girlTotalTeacher === "" ||
      this.state.girlTotalTeacher === null
    ) {
      return false;
    }
    if (
      this.state.boyTotalTeacher === "" ||
      this.state.boyTotalTeacher === null
    ) {
      return false;
    }
    if (this.state.whole_building === "") {
      return false;
    }
    if (this.state.labs === "") {
      return false;
    }
    if (this.state.ground === "") {
      return false;
    }
    if (this.state.compound === "") {
      return false;
    }
    if (this.state.classrooms === "") {
      return false;
    }

    let classStudentsFlag = true;
    this.state.allClassStudent.forEach((i) => {
      if (i.students === null || i.students === "") {
        classStudentsFlag = false;
      }
    });
    if (classStudentsFlag === false) {
      return false;
    }

    return true;
  };

  moveToMeetings = () => {
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: true,
      isInformation: false,
    });
  };

  checkTeachersData = () => {
    // const afterCheck = this.isCheckingTeachersData();
    // let classAndstudents = [
    //   {
    //     id: 1,
    //     grade: "1st",
    //     students: "",
    //   },
    //   {
    //     id: 2,
    //     grade: "2nd",
    //     students: "",
    //   },
    //   {
    //     id: 3,
    //     grade: "3rd",
    //     students: "",
    //   },
    //   {
    //     id: 4,
    //     grade: "4th",
    //     students: "",
    //   },
    //   {
    //     id: 5,
    //     grade: "5th",
    //     students: "",
    //   },
    //   {
    //     id: 6,
    //     grade: "6th",
    //     students: "",
    //   },
    //   {
    //     id: 7,
    //     grade: "7th",
    //     students: "",
    //   },
    //   {
    //     id: 8,
    //     grade: "8th",
    //     students: "",
    //   },
    //   {
    //     id: 9,
    //     grade: "9th",
    //     students: "",
    //   },
    //   {
    //     id: 10,
    //     grade: "10th",
    //     students: "",
    //   },
    // ];

    // if (afterCheck === true) {
    //   let genderWiseData = [];
    //   this.state.allClassStudent.forEach((res) => {
    //     genderWiseData.push({
    //       class_name: res.id.toString(),
    //       total_students: parseInt(res.students),
    //     });
    //   });

    //   const schoolObject = {
    //     head_master: this.state.headMasterName,
    //     head_master_mobile: parseInt(this.state.headMasterNumber),
    //     senior_teacher_male: this.state.seniorBoyTeacherName,
    //     senior_teacher_male_mobile: parseInt(this.state.seniorBoyTeacherNumber),
    //     senior_teacher_female: this.state.seniorGirlTeacherName,
    //     senior_teacher_female_mobile: parseInt(
    //       this.state.seniorGirlTeacherNumber
    //     ),
    //     school_address: this.state.schoolAddress,
    //     school_address_gmaps: this.state.schoolAddressLink,
    //     total_student: parseInt(this.state.totalStudent),
    //     boys: parseInt(this.state.boyTotalStudent),
    //     girls: parseInt(this.state.girlTotalStudent),
    //     total_teacher: parseInt(this.state.totalTeacher),
    //     male_teacher: parseInt(this.state.boyTotalTeacher),
    //     female_teacher: parseInt(this.state.girlTotalTeacher),
    //     gender_wise: genderWiseData,
    //     classrooms: this.state.classrooms,
    //     labs: this.state.labs,
    //     ground: this.state.ground,
    //     office: this.state.compound,
    //     whole_building: this.state.whole_building,
    //     school_type: this.state.schooltype,
    //     head_master_email: "charles@gmail.com",
    //     school: this.state.HMSchool.school_id,
    //     draft_data: false,
    //     class_type: "Digital",
    //   };

    //   getHMService()
    //     .createSMCForm(schoolObject)
    //     .then((res) => {
    //       if (res) {
    //         this.setState(
    //           {
    //             headMasterName: "",
    //             headMasterNumber: "",
    //             seniorBoyTeacherName: "",
    //             seniorBoyTeacherNumber: "",
    //             seniorGirlTeacherName: "",
    //             seniorGirlTeacherNumber: "",
    //             schoolAddress: "",
    //             schoolAddressLink: "",
    //             schooltype: "ZILLA PARISHAD",
    //             totalStudent: "",
    //             girlTotalStudent: "",
    //             boyTotalStudent: "",
    //             totalTeacher: "",
    //             girlTotalTeacher: "",
    //             boyTotalTeacher: "",
    //             allClassStudent: classAndstudents,
    //             isReview: false,
    //             isSubmitPrathmik: true,
    //             whole_building: "",
    //             labs: "",
    //             ground: "",
    //             compound: "",
    //             classrooms: "",
    //             currentImage: 0,
    //             message : res.status == 200 ? "Form submited Successfully" : res.data.message
    //           },
    //           this.moveToMeetings
    //         );

    //         getHMService()
    //           .getSMCFormDetails(this.state.HMSchool.school_id)
    //           .then((res) => {
    //             console.log(res, "res INSIDE TEACHER");
    //           });
    //       }
    //     });
    //   console.log(schoolObject);
    // } else {
    //   this.setState({
    //     isReview: true,
    //   });
    // }

    // document.getElementById('scroll').scrollTo({ top: 0, behavior:
    //   'smooth'
    // })

    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: true,
      isInformation: false,
      isExtraInfo: false,
    });
    document.getElementById("scroll").scrollIntoView();
  };
  saveAsDraft = () => {
    // console.log(schoolObject, "schoolObject");
    let finalValue = this.FillSmcData(true);
    finalValue.draft_data = true;
    getHMService()
      .createSchoolDetails(finalValue)
      .then((res) => {
        console.log(res, "Draft Res");
        this.setState({
          message: res.data.response
            ? res.data.response
            : "Something Went Wrong",
        });
      });
  };

  teacherDetails = () => {
    return (
      <div>
        {this.sliderContainer()}
        {this.schoolData()}
        {this.schoolStudents()}
        {this.allClasses()}
        {this.castwiseCount()}
        {this.headMasterData()}
        {this.teacherCount()}
        <div className={classes.submitButtonContainer}>
          <button className={classes.submitButton} onClick={this.saveAsDraft}>
            Save Draft
          </button>
          <button
            className={classes.submitButton}
            onClick={this.checkTeachersData}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  changeSchoolAchievement = (event) => {
    this.setState({
      schoolAchievement: event.target.value,
    });
  };
  changeSchoolStrength = (event) => {
    this.setState({
      schoolStrength: event.target.value,
    });
  };
  changeSchoolChallenges = (event) => {
    this.setState({
      schoolChallenges: event.target.value,
    });
  };
  changeHeadmasterMessage = (event) => {
    this.setState({
      headMasterMessage: event.target.value,
    });
  };

  clearWholeForm = () => {
    if (this.state.isSubmitShalaVyavsthpan === true) {
      let classAndstudents = [
        {
          id: 1,
          grade: "1st",
          students: "",
        },
        {
          id: 2,
          grade: "2nd",
          students: "",
        },
        {
          id: 3,
          grade: "3rd",
          students: "",
        },
        {
          id: 4,
          grade: "4th",
          students: "",
        },
        {
          id: 5,
          grade: "5th",
          students: "",
        },
        {
          id: 6,
          grade: "6th",
          students: "",
        },
        {
          id: 7,
          grade: "7th",
          students: "",
        },
        {
          id: 8,
          grade: "8th",
          students: "",
        },
        {
          id: 9,
          grade: "9th",
          students: "",
        },
        {
          id: 10,
          grade: "10th",
          students: "",
        },
      ];
      this.setState({
        headMasterName: "",
        headMasterNumber: "",
        seniorBoyTeacherName: "",
        seniorBoyTeacherNumber: "",
        seniorGirlTeacherName: "",
        seniorGirlTeacherNumber: "",
        schoolAddress: "",
        schoolAddressLink: "",
        schooltype: "",
        totalStudent: "",
        girlTotalStudent: "",
        boyTotalStudent: "",
        totalTeacher: "",
        girlTotalTeacher: "",
        boyTotalTeacher: "",
        allClassStudent: classAndstudents,
        isReview: false,
        isTeacherDetails: true,
        isCommitteeDetails: false,
        isInformation: false,
        schoolAchievement: "",
        schoolChallenges: "",
        schoolStrength: "",
        isReviewInfo: false,
        isReviewComittee: false,
        schoolDetailsId: null,
        isSubmitPrathmik: false,
        isSubmitShalaVyavsthpan: false,
        whole_building: "",
        labs: "",
        ground: "",
        compound: "",
        classrooms: "",
        currentImage: 0,
      });
    }
  };

  onSubmitInfoAfterDetails = () => {
    const infoObject = {
      school_history: this.state.schoolAchievement,
      school_strengths: this.state.schoolStrength,
      school_challenges: this.state.schoolChallenges,
      school_details: this.state.schoolDetailsId,
    };

    getHMService()
      .createSMCExtraDetails(infoObject)
      .then((res) => {
        if (res) {
          this.setState({
            message:
              res.status == 200
                ? "Form submited Successfully"
                : res.data.message,
          });
          this.props.history.push("/user/schoolDetail");
          this.clearWholeForm();
        }
      });
  };

  onSubmitInfo = () => {
    console.log(
      JSON.parse(localStorage.getItem("HMSchool"))
        ? JSON.parse(localStorage.getItem("HMSchool"))
        : JSON.parse(localStorage.getItem("AMdetails"))
          ? JSON.parse(localStorage.getItem("AMdetails"))
          : null,
      "this.state.HMSchool.school_id"
    );
    let finalValue = this.FillSmcData(true);

    const flag = this.checkSMCForm();
    console.log(flag, "last thing");
    if (flag === true) {
      this.setState({
        alertbox: true,
      });
    } else {
      this.setState({
        isFormReview: true,
        checkAlertBox: true,
        // message: "तुमचा फॉर्म अर्धवट भरलेला आहे. कृपया पुन्हा चेक करा.",
      });
    }

    // if (
    //   this.state.schoolAchievement === "" ||
    //   this.state.schoolChallenges === "" ||
    //   this.state.schoolStrength === ""
    // ) {
    //   this.setState({
    //     isReviewInfo: true,
    //   });
    // } else {
    //   getHMService()
    //     .getSMCFormDetails(this.state.HMSchool.school_id)
    //     .then((res) => {
    //       this.setState(
    //         {
    //           schoolDetailsId: res.data.response.id,
    //         },
    //         this.onSubmitInfoAfterDetails
    //       );
    //     });
    // }
  };

  onSubmitform = () => {
    let finalValue = this.FillSmcData(true);
    getHMService()
      .createSchoolDetails(finalValue)
      .then((res) => {
        console.log(res, "Response");
        this.setState({
          smcFormData: finalValue,
          message: res.data.message ? res.data.message : "Something Went Wrong",
        });
        if (res.data.message == "success") {
          this.setState({
            dialogCheck: true,
          });
          setTimeout(() => {
            this.props.history.push("/user/schoolDetail");
          }, 4000);
        }
      });
  };

  onChangeExtraInfoPic = async (event, value) => {
    const fd = new FormData();
    fd.append(
      "image",
      event.currentTarget ? event.currentTarget.files[0] : event
    );
    fd.append("school_id", this.state.HMSchool?.school_id);
    console.log(fd, "fd");
    let mediaRes = await getHMService().uploadSchoolImages(fd);
    console.log(mediaRes.data.file, "media");
    if (value == "headmaster") {
      this.setState({
        headMasterPic: mediaRes.data.file,
      });
    } else if (value == "schoolHistory") {
      this.setState({
        schoolHistoryPic: mediaRes.data.file,
      });
    } else if (value == "schoolChallenges") {
      this.setState({
        schoolChallengesPic: mediaRes.data.file,
      });
    } else if (value == "schoolDevelopment") {
      this.setState({
        schoolDevelopmentPic: mediaRes.data.file,
      });
    } else if (value == "futurePlan") {
      this.setState({
        futurePlanPic: mediaRes.data.file,
      });
    } else {
      this.setState({
        schoolStrengthPic: mediaRes.data.file,
      });
    }
  };

  specialInfoView = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              मुख्याध्यापकांचा आपल्या शाळेतील विद्यार्थ्यांना संदेश
              <span className={classes.redStar}>*</span>
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                शाळेतील विद्यार्थ्यांना मारगदर्शनपर संदेश द्यावा, जेणेकरून
                विद्यार्थ्यांना प्रेरणा मिळेल आणि अभ्यासात प्रगती होईल.
              </div>
            </Popup>
          </div>

          <div>
            <textarea
              onChange={this.changeHeadmasterMessage}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              placeholder="Enter Text"
              value={this.state.headMasterMessage}
            >
              {this.state.headMasterMessage}
            </textarea>

            {this.state.isFormReview &&
              this.state.headMasterMessage.length < 200 ? (
              <p className={classes.redStar}>
                *किमान १०० शब्दा मध्ये लिहिणे अपेक्षित आहे.
              </p>
            ) : null}
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "26px",
                  color: "#958989",
                  marginRight: "10px",
                }}
              >
                मुख्याध्यापकांचा प्रोफाइल फोटो
              </p>
              <Popup trigger={<HelpOutline />} position="right center">
                <div>चेहरा स्पष्ट दिसेल असा प्रोफाईल फोटो अपलोड करावा</div>
              </Popup>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ marginTop: "5px", paddingTop: "7px" }}
                  className={classes.committeeDetailsInput}
                  onChange={(e) => {
                    this.setState({
                      HMpicNAme: e.currentTarget?.files[0]?.name,
                    });
                    this.onChangeExtraInfoPic(e, "headmaster");
                  }}
                />
                <span style={{ fontSize: "25px" }} className={classes.redStar}>
                  *
                </span>
              </div>
              {this.state.headMasterPic !== "" ? (
                <div
                  className={classes.fileNameText}
                  style={{
                    display: "flex",
                  }}
                >
                  <CheckCircle style={{ color: "green", marginLeft: "10px" }} />
                  <div>
                    File name:
                    {this.urlToimageName(this.state.headMasterPic)}
                  </div>
                </div>
              ) : null}
              {console.log(this.state.HMpicNAme, "HMpicNAme")}
            </div>
          </div>

          {this.state.isFormReview && this.state.headMasterPic === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
          {/* {this.state.isReviewInfo && this.state.schoolAchievement === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळेचा इतिहास
              <span className={classes.redStar}>*</span>
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                शाळेच्या स्थापनेपासूनचा प्रवास, महत्वाच्या घटना, योगदान देणारे
                विद्यार्थी, विशेष व्यक्तीबाबत थोडक्यात माहिती लिहावी.
              </div>
            </Popup>
          </div>

          <div>
            <textarea
              onChange={this.changeSchoolAchievement}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.schoolAchievement}
              placeholder="Enter Text"
            >
              {this.state.schoolAchievement}
            </textarea>

            {this.state.isFormReview &&
              this.state.schoolAchievement.length < 200 ? (
              <p className={classes.redStar}>
                *किमान १०० शब्दा मध्ये लिहिणे अपेक्षित आहे.
              </p>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                color: "#958989",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              शाळेचा इतिहास दर्शवणारा फोटो
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ marginTop: "5px", paddingTop: "7px" }}
                  className={classes.committeeDetailsInput}
                  onChange={(e) => {
                    this.onChangeExtraInfoPic(e, "schoolHistory");
                    this.setState({
                      historyPicName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <span style={{ fontSize: "25px" }} className={classes.redStar}>
                  *
                </span>
              </div>
              {this.state.schoolHistoryPic !== "" ? (
                <div
                  className={classes.fileNameText}
                  style={{
                    display: "flex",
                  }}
                >
                  <CheckCircle style={{ color: "green", marginLeft: "10px" }} />
                  <div>
                    File name:
                    {this.urlToimageName(this.state.schoolHistoryPic)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {this.state.isFormReview && this.state.schoolHistoryPic === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}

          {/* {this.state.isReviewInfo && this.state.schoolAchievement === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळेत राबविल्या जाणाऱ्या वैशिष्ठ्यपूर्ण बाबी/ सकारात्मक उपक्रम
              <span className={classes.redStar}>*</span>
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                शाळेला अथवा विद्यार्थ्यांना मिळालेले पुरस्कार तसेच शाळेचे उत्तम
                उपक्रम लिहावेत
              </div>
            </Popup>
          </div>

          <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
            <textarea
              onChange={this.changeSchoolStrength}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.schoolStrength}
              placeholder="Enter Text"
            >
              {this.state.schoolStrength}
            </textarea>
          </div>
          {this.state.isFormReview && this.state.schoolStrength.length < 200 ? (
            <p className={classes.redStar}>
              *किमान १०० शब्दा मध्ये लिहिणे अपेक्षित आहे.
            </p>
          ) : null}
          <div
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                color: "#958989",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              शाळेचे सकारात्मक बाबी दर्शवणारा फोटो
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ marginTop: "5px", paddingTop: "7px" }}
                  className={classes.committeeDetailsInput}
                  onChange={(e) => {
                    this.onChangeExtraInfoPic(e, "schoolStrength");
                    this.setState({
                      schoolStrengthName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <span style={{ fontSize: "25px" }} className={classes.redStar}>
                  *
                </span>
              </div>
              {this.state.schoolStrengthPic !== "" ? (
                <div
                  className={classes.fileNameText}
                  style={{
                    display: "flex",
                  }}
                >
                  <CheckCircle
                    style={{
                      marginLeft: 20,
                      color: "green",
                      alignItems: "center",
                      justifyContnet: "center",
                    }}
                  />
                  <div>
                    File name:
                    {this.urlToimageName(this.state.schoolStrengthPic)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.isFormReview && this.state.schoolStrengthPic === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}

          {/* {this.state.isReviewInfo && this.state.schoolChallenges === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळेसमोरील आव्हाने व समस्या / भौतिक सुविधांबाबत शाळेची गरज
              <span className={classes.redStar}>*</span>
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                शैक्षणिक समस्या अथवा वस्तू बाबतचा अभाव असेल तर थोडक्यात लिहावे
              </div>
            </Popup>
          </div>

          <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
            <textarea
              onChange={this.changeSchoolChallenges}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.schoolChallenges}
              placeholder="Enter Text"
            >
              {this.state.schoolChallenges}
            </textarea>
          </div>
          {this.state.isFormReview &&
            this.state.schoolChallenges.length < 200 ? (
            <p className={classes.redStar}>
              *किमान १०० शब्दा मध्ये लिहिणे अपेक्षित आहे.
            </p>
          ) : null}
          <div
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                color: "#958989",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              संबंधित फोटो{" "}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ marginTop: "5px", paddingTop: "7px" }}
                  className={classes.committeeDetailsInput}
                  onChange={(e) => {
                    this.onChangeExtraInfoPic(e, "schoolChallenges");
                    this.setState({
                      visionName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                <span style={{ fontSize: "25px" }} className={classes.redStar}>
                  *
                </span>
              </div>
              {this.state.schoolChallengesPic !== "" ? (
                <div
                  className={classes.fileNameText}
                  style={{
                    display: "flex",
                  }}
                >
                  <CheckCircle style={{ color: "green", marginLeft: "10px" }} />
                  <div>
                    File name:
                    {this.urlToimageName(this.state.schoolChallengesPic)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.isFormReview && this.state.schoolChallengesPic === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}

          {/* {this.state.isReviewInfo && this.state.schoolStrength === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळेचे भविष्यकालीन नियोजन / आराखडा
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                नवीन इमारत अथवा भविष्यात होणारे शैक्षणिक उपक्रम लिहावेत.
              </div>
            </Popup>
          </div>

          <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
            <textarea
              onChange={(e) => {
                this.setState({
                  futurePlan: e.target.value,
                });
              }}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.futurePlan}
              placeholder="Enter Text"
            >
              {this.state.futurePlan}
            </textarea>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                color: "#958989",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              नियोजन / आराखडा संबंधित फोटो{" "}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ marginTop: "5px", paddingTop: "7px" }}
                  className={classes.committeeDetailsInput}
                  onChange={(e) => {
                    this.onChangeExtraInfoPic(e, "futurePlan");
                  }}
                />
              </div>
              {this.state.futurePlanPic !== "" ? (
                <div
                  className={classes.fileNameText}
                  style={{
                    display: "flex",
                  }}
                >
                  <CheckCircle style={{ color: "green", marginLeft: "10px" }} />
                  <div>
                    File name:
                    {this.urlToimageName(this.state.futurePlanPic)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* {this.state.isReviewInfo && this.state.schoolStrength === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              पालकांसाठी सूचना
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                पालक सभा, विद्यार्थी हिताचे निर्णय किमान ३० शब्दात थोडक्यात
                लिहावेत.
              </div>
            </Popup>
          </div>

          <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
            <textarea
              onChange={(e) => {
                this.setState({
                  instructionForParents: e.target.value,
                });
              }}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.instructionForParents}
              placeholder="Enter Text"
            >
              {this.state.instructionForParents}
            </textarea>
          </div>

          {/* {this.state.isReviewInfo && this.state.schoolStrength === "" ? (
            <p
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              *Enter Correct Message
            </p>
          ) : null} */}
        </div>

        <div className={classes.submitButtonContainer}>
          <button className={classes.submitButton} onClick={this.saveAsDraft}>
            Save Draft
          </button>
          <button className={classes.submitButton} onClick={this.onSubmitInfo}>
            Submit
          </button>
        </div>
      </div>
    );
  };

  onChangeEatingPic = async (event) => {
    const fd = new FormData();
    fd.append(
      "image",
      event.currentTarget ? event.currentTarget.files[0] : event
    );
    fd.append("school_id", this.state.HMSchool?.school_id);
    console.log(fd, "fd");
    let mediaRes = await getHMService().uploadSchoolImages(fd);
    console.log(mediaRes.data.file, "media");

    this.setState({
      eatingStudentPic: mediaRes.data.file,
    });
  };

  cookValChange = (flag, event, index) => {
    let cookForm = [...this.state.cookForm];

    if (flag == "cookName") {
      cookForm[index].cookName = event.target.value;
    } else {
      cookForm[index].cookSalary = event.target.value;
    }
    this.setState({
      cookForm,
    });
  };

  schemeValChange = (flag, event, index) => {
    let governmentSchemesForm = [...this.state.governmentSchemesForm];

    if (flag == "schemeName") {
      governmentSchemesForm[index].schemeName = event.target.value;
    } else {
      governmentSchemesForm[index].beneficiariesNumber = event.target.value;
    }
    this.setState({
      governmentSchemesForm,
    });
  };

  addCooks = () => {
    let emptyCook = JSON.stringify(this.state.emptyCook);
    let cookForm = [...this.state.cookForm];
    cookForm.push(JSON.parse(emptyCook));
    this.setState(
      {
        cookForm,
      },
      () => {
        console.log(this.state.cookForm, "donors Dorm");
      }
    );
  };

  addGovernmentSchemes = () => {
    let emptyGovernmentScheme = JSON.stringify(
      this.state.emptyGovernmentScheme
    );
    let governmentSchemesForm = [...this.state.governmentSchemesForm];
    governmentSchemesForm.push(JSON.parse(emptyGovernmentScheme));
    this.setState(
      {
        governmentSchemesForm,
      },
      () => {
        console.log(this.state.governmentSchemesForm, "donors Dorm");
      }
    );
  };

  extraInfoView = () => {
    let cookFlag = true;
    this.state.cookForm.forEach((i) => {
      if (i.cookName === "" || i.cookSalary === "") {
        cookFlag = false;
      }
    });

    return (
      <div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "26px" }}>
            आपल्या शाळेतील विद्यार्थ्यांनी मागील वर्षी (२०२१-२२) कोणत्या स्पर्धा
            परीक्षा दिल्या त्यानुसार खालील बाबी लिहा
            <span className={classes.redStar}>*</span>
          </p>
          <div style={{ display: "flex" }}>
            <p className={classes.competitiveExamText}> </p>
            <span style={{ width: "200px", textAlign: "center" }}>
              होय/ नाही
            </span>
            <span style={{ width: "330px", textAlign: "center" }}>
              विद्यार्थी संख्या
            </span>
          </div>

          <div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>इ. ५ वी शिष्यवृत्ती</p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",caste_wise
                    }}
                  >
                    he
                  </div> */}

              <select
                onChange={(event) => {
                  this.setState({ fifthScholarship: event.target.value });
                }}
                className={classes.committeeCategoryInput}
                value={this.state.fifthScholarship}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.fifthScholarship === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.fifthScholarshipCount}
                onChange={(event) => {
                  this.setState({ fifthScholarshipCount: event.target.value });
                }}
                placeholder="
Number of students "
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>
                इ . ५ वी नवोदय परीक्षा
              </p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}इ. ८ वी शिष्यवृत्ती
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ navodayaScholarship: event.target.value });
                }}
                value={this.state.navodayaScholarship}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={
                  this.state.navodayaScholarship === "होय" ? false : true
                }
                className={classes.nameTextContainer}
                value={this.state.navodayaScholarshipCount}
                onChange={(event) => {
                  this.setState({
                    navodayaScholarshipCount: event.target.value,
                  });
                }}
                placeholder="
          Number of students "
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>इ ८ वी शिष्यवृत्ती</p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ eighthScholarship: event.target.value });
                }}
                value={this.state.eighthScholarship}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.eighthScholarship === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.eighthScholarshipCount}
                onChange={(event) => {
                  this.setState({ eighthScholarshipCount: event.target.value });
                }}
                placeholder="
Number of students "
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>
                ८ वी एन. एम. एम. एस.
              </p>
              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ eighthNMSS: event.target.value });
                }}
                value={this.state.eighthNMSS}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.eighthNMSS === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.eighthNMSSCount}
                onChange={(event) => {
                  this.setState({
                    eighthNMSSCount: event.target.value,
                  });
                }}
                placeholder="Number of students"
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>
                इ. १० वी शिष्यवृत्ती
              </p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ tenthScholarship: event.target.value });
                }}
                value={this.state.tenthScholarship}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.tenthScholarship === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.tenthScholarshipCount}
                onChange={(event) => {
                  this.setState({ tenthScholarshipCount: event.target.value });
                }}
                placeholder="
Number of students "
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>एलिमेंटरी परीक्षा</p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ elementaryExam: event.target.value });
                }}
                value={this.state.elementaryExam}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.elementaryExam === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.elementaryExamCount}
                onChange={(event) => {
                  this.setState({
                    elementaryExamCount: event.target.value,
                  });
                }}
                placeholder="
          Number of students "
                type="Number"
                min="0"
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className={classes.competitiveExamText}>इंटरमिजीएट</p>

              {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div>
                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

              <select
                className={classes.committeeCategoryInput}
                onChange={(event) => {
                  this.setState({ intermediate: event.target.value });
                }}
                value={this.state.intermediate}
                placeholder="Category"
              >
                <option className={classes.committeeCategoryInput} value="नाही">
                  नाही
                </option>
                <option className={classes.committeeCategoryInput} value="होय">
                  होय
                </option>
              </select>

              <input
                disabled={this.state.intermediate === "होय" ? false : true}
                className={classes.nameTextContainer}
                value={this.state.intermediateCount}
                onChange={(event) => {
                  this.setState({
                    intermediateCount: event.target.value,
                  });
                }}
                placeholder="
          Number of students "
                type="Number"
                min="0"
              />
            </div>
          </div>
          {this.state.isFormReview &&
            ((this.state.fifthScholarship === "होय" &&
              this.state.fifthScholarshipCount === "") ||
              (this.state.tenthScholarship === "होय" &&
                this.state.tenthScholarshipCount === "") ||
              (this.state.eighthScholarship === "होय" &&
                this.state.eighthScholarshipCount === "") ||
              (this.state.navodayaScholarship === "होय" &&
                this.state.navodayaScholarshipCount === "") ||
              (this.state.eighthNMSS === "होय" &&
                this.state.eighthNMSSCount === "") ||
              (this.state.elementaryExam === "होय" &&
                this.state.elementaryExamCount === "") ||
              (this.state.intermediate === "होय" &&
                this.state.intermediateCount === "")) ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}
          >
            <div
              style={{
                width: "60%",
                borderWidth: "1px",
                borderColor: "black",
                borderRightStyle: "solid",
              }}
            >
              <p style={{ fontSize: "26px" }}>
                प्रधानमंत्री पोषण शक्ती निर्माण (PM Poshan) योजना (२०२२ -२३ )
                <span className={classes.redStar}>*</span>
              </p>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{ padding: "10px", width: "40%", color: "#958989" }}
                >
                  पोषण आहार घेणाऱ्या विद्यार्थ्यांची संख्या
                </div>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "25%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

                <input
                  className={classes.nameTextContainer}
                  value={this.state.studentGettingFoodCount}
                  onChange={(event) => {
                    this.setState({
                      studentGettingFoodCount: event.target.value,
                    });
                  }}
                  placeholder="संख्या"
                  type="Number"
                  min="0"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{ padding: "10px", width: "40%", color: "#958989" }}
                >
                  स्वयंपाकी/मदतनीस संख्या
                </div>

                <input
                  className={classes.nameTextContainer}
                  value={this.state.totalCooks}
                  onChange={(event) => {
                    this.setState({ totalCooks: event.target.value });
                  }}
                  placeholder="संख्या"
                  type="Number"
                  min="0"
                />
              </div>
              <hr style={{ width: "99%" }} color="black" size="4" />
              <p
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "#958989",
                }}
              >
                पोषण आहार तयार करणाऱ्या स्वयंपाकी / मदतनीस यांची माहिती
              </p>
              <div style={{ marginLeft: "15%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      width: "40%",
                      textAlign: "center",
                      color: "#958989",
                    }}
                  >
                    नाव
                  </span>
                  <span
                    style={{
                      marginLeft: "40px",
                      width: "40%",
                      textAlign: "center",
                      color: "#958989",
                    }}
                  >
                    मासिक भत्ता
                  </span>
                </div>
                {this.state.cookForm.map((data, index) => {
                  return (
                    <div style={{ marginTop: "20px" }}>
                      <input
                        type="text"
                        value={data.cookName}
                        style={{
                          marginTop: "5px",
                          paddingTop: "7px",
                          width: "40%",
                          height: "46px",
                          backgroundColor: "#DFE4E8",
                          border: 0,
                          paddingLeft: "10px",
                        }}
                        onChange={(e) =>
                          this.cookValChange("cookName", e, index)
                        }
                      />
                      <input
                        type="Number"
                        min="0"
                        value={data.cookSalary}
                        style={{
                          marginTop: "5px",
                          paddingTop: "7px",
                          width: "40%",
                          height: "46px",
                          backgroundColor: "#DFE4E8",
                          border: 0,
                          paddingLeft: "10px",
                          marginLeft: "40px",
                        }}
                        onChange={(e) =>
                          this.cookValChange("cookSalary", e, index)
                        }
                      />
                      <button
                        style={{
                          border: "0px",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => this.removeCooks(index)}
                      >
                        <DeleteOutline style={{ color: "red" }} />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div
                // className={["m-2", classes.headSection]
                //   .toString()
                //   .split(",")
                //   .join(" ")}
                className="d-flex flex-row align-items-center justify-content-center"
              >
                <button
                  style={{
                    fontSize: "35px",
                    border: "0px",
                    backgroundColor: "transparent",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    this.addCooks();
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "black",
                      width: "28px",
                      height: "28px",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "white", fontSize: "18px" }}>+</span>
                  </span>
                  <span
                    style={{
                      fontSize: "15px",
                      marginLeft: "10px",
                      color: "#958989",
                      fontWeight: "800",
                    }}
                  >
                    अजून एक नाव नोंदवा
                  </span>
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginRight: "120px",
                marginLeft: "30px",
                width: "38%",
              }}
            >
              <div
                style={{
                  marginTop: "-40px",
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p style={{ color: "#958989", marginLeft: "-20px" }}>
                  इतर माहिती
                </p>
                <textarea
                  style={{
                    backgroundColor: "#DFE4E8",
                    height: "253px",
                    color: "#415EB6",
                    fontSize: "22px",
                    fontWeight: "800",
                    width: "400px",
                    marginLeft: "-20px",
                    border: 0,
                    padding: "10px",
                  }}
                  value={this.state.extraInfoFood}
                  onChange={(event) => {
                    this.setState({ extraInfoFood: event.target.value });
                  }}
                  placeholder="तुमच्या शाळेतील मध्यान्न पोषण आहाराविषयी तुमची टिप्पणी येथे लिहा."
                >
                  {this.state.extraInfoFood}
                </textarea>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p
                  style={{
                    padding: "10px",
                    minWidth: "150px",
                    color: "#958989",
                  }}
                >
                  पोषण आहार घेताना विद्यार्थ्यांच्या रचनेचा फोटो
                </p>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "25%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      className={classes.nameTextContainer}
                      onChange={(e) => {
                        this.onChangeEatingPic(e);
                        this.setState({
                          foodName: e.currentTarget?.files[0]?.name,
                        });
                      }}
                      type="file"
                      style={{ paddingTop: "5px" }}
                      accept="image/jpeg, image/png"
                    />
                  </div>
                  {this.state.eatingStudentPic !== "" ? (
                    <div
                      className={classes.fileNameText}
                      style={{
                        display: "flex",
                      }}
                    >
                      <CheckCircle
                        style={{
                          color: "green",
                          alignItems: "center",
                          justifyContnet: "center",
                        }}
                      />
                      File name:
                      {this.urlToimageName(this.state.eatingStudentPic)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {this.state.isFormReview &&
            (this.state.studentGettingFoodCount === "" ||
              this.state.extraInfoFood === "" ||
              this.state.totalCooks === "" ||
              this.state.eatingStudentPic === "" ||
              cookFlag === false) ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "50px",
          }}
        >
          <p style={{ fontSize: "26px" }}>
            शाळेत दिल्या जाणाऱ्या विविध शासकीय योजनांची नावे : (FY २०२१ -२२ )
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center", color: "#958989" }}>
                योजनेचे नाव{" "}
              </p>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center", color: "#958989" }}>
                लाभार्थ्यांची संख्या{" "}
              </p>
            </div>
          </div>

          {this.state.governmentSchemesForm.map((data, index) => {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      marginTop: "5px",
                      paddingTop: "7px",
                      width: "80%",
                      height: "46px",
                      backgroundColor: "#DFE4E8",
                      border: 0,
                      marginTop: "20px",
                    }}
                    className={classes.committeeDetailsInput}
                    placeholder="योजनेचे नाव"
                    value={data.schemeName}
                    onChange={(e) =>
                      this.schemeValChange("schemeName", e, index)
                    }
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    value={data.beneficiariesNumber}
                    type="number"
                    min="0"
                    style={{
                      marginTop: "5px",
                      paddingTop: "7px",
                      width: "80%",
                      height: "46px",
                      backgroundColor: "#DFE4E8",
                      border: 0,
                      color: "black",
                      marginTop: "20px",
                    }}
                    className={classes.committeeDetailsInput}
                    onChange={(e) =>
                      this.schemeValChange("beneficiariesNumber", e, index)
                    }
                  />
                </div>
                <button
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  onClick={() => this.removeSchemes(index)}
                >
                  <DeleteOutline style={{ color: "red" }} />
                </button>
              </div>
            );
          })}

          <div
            // className={["m-2", classes.headSection]
            //   .toString()
            //   .split(",")
            //   .join(" ")}
            className="d-flex flex-row align-items-center justify-content-center"
          >
            <button
              style={{
                fontSize: "35px",
                border: "0px",
                backgroundColor: "transparent",
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                height: "40px",
                borderRadius: "10px",
                marginTop: "20px",
              }}
              onClick={() => {
                this.addGovernmentSchemes();
              }}
            >
              <span
                style={{
                  backgroundColor: "black",
                  width: "28px",
                  height: "28px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white", fontSize: "18px" }}>+</span>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  marginLeft: "10px",
                  color: "#958989",
                  fontWeight: "800",
                }}
              >
                अजून एका शासकीय योजनेचे नाव जोडा
              </span>
            </button>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "50px",
          }}
        >
          <p style={{ fontSize: "26px" }}>
            विद्यार्थ्यांचे गणवेश (चालू वर्ष २०२२-२३)
            <span className={classes.redStar}>*</span>
          </p>

          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}
          >
            <div style={{ width: "200%" }}>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p className={classes.uniformText}>
                  एकूण किती विद्यार्थ्यांना गणवेश वाटप केले
                </p>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

                <input
                  className={classes.nameTextContainer}
                  value={this.state.uniformsCount}
                  onChange={(event) => {
                    this.setState({ uniformsCount: event.target.value });
                  }}
                  placeholder="Number of students "
                  type="Number"
                  min="0"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p className={classes.uniformText}>
                  एकूण किती मुलींना गणवेश वाटप केले
                </p>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}
                <input
                  className={classes.nameTextContainer}
                  value={this.state.girlsUniformCount}
                  onChange={(event) => {
                    this.setState({ girlsUniformCount: event.target.value });
                  }}
                  placeholder="Number of students"
                  type="Number"
                  min="0"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p className={classes.uniformText}>
                  एकूण किती मुलांना गणवेश वाटप केले
                </p>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}

                <input
                  className={classes.nameTextContainer}
                  value={this.state.boysUniformCount}
                  onChange={(event) => {
                    this.setState({ boysUniformCount: event.target.value });
                  }}
                  placeholder="Number of students"
                  type="Number"
                  min="0"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p className={classes.uniformText}>
                  किती विद्यार्थ्यांना अजून गणवेशाची गरज आहे
                </p>

                {/* <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    he
                  </div> */}
                <input
                  className={classes.nameTextContainer}
                  value={this.state.remainingUniform}
                  onChange={(event) => {
                    this.setState({ remainingUniform: event.target.value });
                  }}
                  placeholder="Number of students"
                  type="Number"
                  min="0"
                />
              </div>
            </div>
          </div>
          {this.state.isFormReview &&
            (this.state.uniformsCount === "" ||
              this.state.girlsUniformCount === "" ||
              this.state.boysUniformCount === "" ||
              this.state.remainingUniform === "") ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "50px",
          }}
        >
          <p style={{ fontSize: "26px" }}>
            विद्यार्थ्यांसाठी वर्गात बसण्याची सुविधा (चालू वर्ष २०२२-२३)
            <span className={classes.redStar}>*</span>
          </p>

          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}
          >
            <div style={{ width: "200%" }}>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ padding: "10px", color: "#958989" }}>
                  कच्चे बांधकाम असलेल्या वर्ग खोल्यांची संख्या{" "}
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "90px",
                    border: "0px",
                  }}
                  placeholder="Number of classroom"
                  type="Number"
                  min="0"
                  value={this.state.matsCount}
                  onChange={(e) => {
                    this.setState({
                      matsCount: e.target.value,
                    });
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ padding: "10px", color: "#958989" }}>
                  पक्के बांधकाम असलेल्या वर्ग खोल्यांची संख्या:
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "85px",
                    border: "0px",
                  }}
                  type="Number"
                  min="0"
                  value={this.state.benchesCount}
                  onChange={(e) => {
                    this.setState({
                      benchesCount: e.target.value,
                    });
                  }}
                  placeholder="Number of classroom"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ padding: "10px", color: "#958989" }}>
                  किती विद्यार्थ्यांना बसण्यासाठी अधिक बाकांची गरज आहे?
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "20px",
                    border: "0px",
                  }}
                  min="0"
                  type="Number"
                  value={this.state.remainingSeats}
                  onChange={(e) => {
                    this.setState({
                      remainingSeats: e.target.value,
                    });
                  }}
                  placeholder="Number of students"
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ padding: "10px", color: "#958989" }}>
                  शाळेतील एकूण वर्गखोल्यांची संख्या{" "}
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "160px",
                    border: "0px",
                  }}
                  type="Number"
                  min="0"
                  value={this.state.remainingMats}
                  onChange={(e) => {
                    this.setState({
                      remainingMats: e.target.value,
                    });
                  }}
                  placeholder="Number of classrooms "
                />
              </div>
            </div>
          </div>
          {this.state.isFormReview &&
            (this.state.matsCount === "" ||
              this.state.benchesCount === "" ||
              this.state.remainingSeats === "" ||
              this.state.remainingMats === "") ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "50px",
          }}
        >
          <p style={{ fontSize: "26px" }}>
            विद्यार्थ्यांसाठी पिण्याचे पाणी खालील स्त्रोत मार्फत उपलब्ध आहे
            <span className={classes.redStar}>*</span>
          </p>

          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}
          >
            <div style={{ width: "200%" }}>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isWaterSouceCommon}
                        onChange={(e) => {
                          this.setState({
                            isWaterSouceCommon: !this.state.isWaterSouceCommon,
                          });
                        }}
                        name="Phone"
                        color="primary"
                      />
                    }
                    label="सामायिक नळाद्वारे"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isTubeHandPump}
                        onChange={(e) => {
                          this.setState({
                            isTubeHandPump: !this.state.isTubeHandPump,
                          });
                        }}
                        name="Phone"
                        color="primary"
                      />
                    }
                    label="कुपनलिका/हातपंप"
                  />
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "20px",
                    border: "0px",
                  }}
                  placeholder="इतर स्त्रोत येथे लिहा"
                  type="text"
                  value={this.state.otherWaterDetails}
                  onChange={(e) => {
                    this.setState({
                      otherWaterDetails: e.target.value,
                    });
                  }}
                />
              </div>
              {this.state.isFormReview &&
                this.state.isWaterSouceCommon === false &&
                this.state.isTubeHandPump === false &&
                this.state.otherWaterDetails === "" ? (
                <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
              ) : null}
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div className={classes.reminderContainer}>
              <p>
                ग्रंथालयात असणाऱ्या एकूण पुस्तकांची संख्या लिहा{" "}
                <span className={classes.redStar}>*</span>
              </p>
              <input
                style={{
                  backgroundColor: "#DFE4E8",
                  width: "100%",
                  padding: "10px",
                  border: "0px",
                }}
                placeholder="Number of books"
                type="Number"
                min="0"
                value={this.state.totalBooksCount}
                onChange={(e) => {
                  this.setState({
                    totalBooksCount: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* <div
            style={{ display: "flex", flexDirection: "row", marginTop: "2em" }}
          >
            <div style={{ width: "200%" }}>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "10px" }}>
                  मोफत पाठ्यपुस्तक योजने अंतर्गत किती विध्यार्थ्यांना
                  पाठ्यपुस्तक मिळाले
                  <span className={classes.redStar}>*</span>
                </div>

                <input
                  style={{
                    backgroundColor: "#DFE4E8",
                    width: "15%",
                    padding: "10px",
                    marginLeft: "20px",
                    border: "0px",
                  }}
                  placeholder="Number of Students"
                  type="Number"
                  min="0"
                  value={this.state.freeTextBookCount}
                  onChange={(e) => {
                    this.setState({
                      freeTextBookCount: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}
          {this.state.isFormReview && this.state.totalBooksCount === "" ? (
            <p className={classes.redStar}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>

        <div className={classes.submitButtonContainer}>
          <button className={classes.submitButton} onClick={this.saveAsDraft}>
            Save Draft
          </button>
          <button
            className={classes.submitButton}
            onClick={this.saveSpecialInfoData}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  getFileName = (url) => {
    let index = url.lastIndexOf("/") + 1;
    let filename = url.substr(index);
    let dashIndex = filename.indexOf("_") + 1;
    let name = filename.substr(dashIndex);
    return name;
  };

  saveSpecialInfoData = () => {
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: false,
      isInformation: true,
      isExtraInfo: false,
    });
    document.getElementById("scroll").scrollIntoView();
  };
  onValChangeMeetings = async (flag, event, index) => {
    let meetingsForm = [...this.state.meetingsForm];

    if (flag === "date") {
      meetingsForm[index].date = event.target.value;
    } else if (flag === "attendance") {
      meetingsForm[index].attendance = event.target.value;
    } else if (flag === "photo") {
      const fd = new FormData();
      fd.append(
        "image",
        event.currentTarget ? event.currentTarget.files[0] : event
      );
      fd.append("school_id", this.state.HMSchool?.school_id);
      console.log(fd, "fd");
      let mediaRes = await getHMService().uploadSchoolImages(fd);
      console.log(mediaRes, "media");

      meetingsForm[index].photo = mediaRes.data.file;
    } else {
      const fd = new FormData();
      fd.append(
        "image",
        event.currentTarget ? event.currentTarget.files[0] : event
      );
      fd.append("school_id", this.state.HMSchool?.school_id);
      console.log(fd, "fd");
      let mediaRes = await getHMService().uploadSchoolImages(fd);
      console.log(mediaRes, "media");
      meetingsForm[index].details = mediaRes.data.file;
    }
    this.setState({
      meetingsForm,
    });
  };

  addMeetings = () => {
    let emptyMeetings = JSON.stringify(this.state.emptyMeetings);
    let meetingsForm = [...this.state.meetingsForm];
    meetingsForm.push(JSON.parse(emptyMeetings));
    this.setState(
      {
        meetingsForm,
      },
      () => {
        console.log(this.state.meetingsForm, "meetingsform");
      }
    );
  };

  removeMeetings = (index) => {
    let meetingsForm = [...this.state.meetingsForm];
    if (meetingsForm.length > 3) {
      meetingsForm.splice(index, 1);
      this.setState({
        meetingsForm,
      });
    }
  };
  renderMemberTiming = () => {
    let { meetingsForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];

    meetingsForm.map((data, index) => {
      form.push(
        <div>
          <div
            className={classes.committeeContainer}
            // className={["row", classes.formFields]
            //   .toString()
            //   .split(",")
            //   .join(" ")}
            key={index}
          >
            <p
              style={{ minWidth: "100px", marginTop: "10px", color: "#958989" }}
            >
              {" "}
              बैठक क्र {index + 1}
            </p>
            <input
              max="9999-12-31"
              type="date"
              value={data.date}
              placeholder="YYYY-MM-DD"
              onChange={(e) => this.onValChangeMeetings("date", e, index)}
              className={classes.committeeDateInput}
            />
            <input
              type="Number"
              min="0"
              placeholder="Attendance"
              value={data.attendance}
              className={classes.committeeAttendanceInput}
              onChange={(e) => this.onValChangeMeetings("attendance", e, index)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  style={{ paddingTop: "7px" }}
                  accept="application/pdf"
                  className={classes.committeeDetailsMeetings}
                  onChange={(e) => {
                    this.onValChangeMeetings("details", e, index);
                    this.setState({
                      meetPicName: e.currentTarget?.files[0]?.name,
                    });
                  }}
                />
                {/* <Popup
                  // className={classes.popup}
                  trigger={<HelpOutline />}
                  position="right center"
                >
                  <div>
                    बैठकीचा वृतांत (proceeding) कसा लिहावा यासाठी हा व्हिडिओ
                    पहा.
                    <a
                      style={{ fontSize: "11px" }}
                      href="https://youtu.be/JL4dM24iSxI"
                    >
                      https://youtu.be/JL4dM24iSxI
                    </a>
                  </div>
                </Popup> */}
              </div>
              {data.details ? (
                <div className={classes.fileNameText}>
                  {console.log(index, "inde", data)}
                  <CheckCircle
                    style={{
                      color: "green",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  />
                  File name:
                  {this.urlToimageName(data?.details)?.length > 10
                    ? `${this.urlToimageName(data?.details)?.substr(0, 10)}..`
                    : this.urlToimageName(data?.details)}
                </div>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ paddingTop: "7px" }}
                  className={classes.committeeDetailsMeetings}
                  onChange={(e) => this.onValChangeMeetings("photo", e, index)}
                />
                {/* <Tooltip title="Size: upto 200kb">
                  <HelpOutline style={{ marginRight: 5, fontSize: "25px" }} />
                </Tooltip> */}
              </div>
              {data.photo ? (
                <div className={classes.fileNameText}>
                  <CheckCircle
                    style={{
                      marginLeft: "10px",
                      color: "green",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  />
                  File name:
                  {this.urlToimageName(data.photo)?.length > 10
                    ? `${this.urlToimageName(data.photo)?.substr(0, 10)}..`
                    : this.urlToimageName(data.photo)}
                </div>
              ) : null}
            </div>

            <button
              style={{ border: "0px", backgroundColor: "transparent" }}
              onClick={() => this.removeMeetings(index)}
            >
              <DeleteOutline style={{ color: "red" }} />
            </button>
          </div>
          {this.state.isReviewComittee &&
            (data.date === "" ||
              data.attendance === "" ||
              data.details === "") ? (
            <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>
      );
    });
    let formWrap = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळा व्यवस्थापन समिती बैठक वृतांत (2022-23)
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                वृत्तान्त pdf स्वरूपात अपलोड करावा तसेच बैठकीचा स्वच्छ फोटो
                अपलोड करावा
              </div>
            </Popup>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginBottom: "20px",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              color: "#958989",
              marginRight: "10px",
              marginTop: "20px",
            }}
          >
            या वर्षात झालेल्या सभांची संख्या
          </p>

          <input
            type="Number"
            style={{ marginTop: "5px", paddingTop: "7px" }}
            className={classes.committeeAttendanceInput}
            onChange={(e) => {
              this.setState({
                totalMeetingsInYear: e.target.value,
              });
            }}
            placeholder="संख्या"
            min="0"
            value={this.state.totalMeetingsInYear}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "120px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "160px",
              color: "#958989",
            }}
          >
            दिनांक
          </p>
          <p
            style={{
              textAlign: "center",
              width: "200px",
              color: "#958989",
            }}
          >
            उपस्थिती
          </p>
          <p
            style={{
              textAlign: "center",
              width: "200px",
              color: "#958989",
            }}
          >
            तपशील
            <Popup trigger={<HelpOutline />} position="right center">
              <div>PDF स्वरूपात तपशील अपलोड करावा</div>
            </Popup>
          </p>
          <p
            style={{
              textAlign: "center",
              width: "160px",
              color: "#958989",
            }}
          >
            बैठकीचा फोटो
            <Popup trigger={<HelpOutline />} position="right center">
              <div>बैठकीचा स्पष्टपणे समूहाचा फोटो अपलोड करावा</div>
            </Popup>
          </p>
        </div>

        {form}
        {/* {this.state.isFormReview && flagTwo === false ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे  </span>
        ) : null} */}

        <div
          // className={["m-2", classes.headSection]
          //   .toString()
          //   .split(",")
          //   .join(" ")}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <button
            style={{
              fontSize: "35px",
              border: "0px",
              backgroundColor: "transparent",
              marginRight: "20px",
              backgroundColor: "#c8ed91",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              height: "40px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
            onClick={() => this.addMeetings()}
          >
            +
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              अजून एका बैठकीचा तपशील जोडा
            </span>
          </button>
        </div>
      </div>
    );
    return formWrap;
  };
  renderMeetingsDates = () => {
    let { dateForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];

    let flagThree = true;
    this.state.dateForm.forEach((i) => {
      if (i.meetings_date === "") {
        flagThree = false;
      }
    });

    dateForm.map((data, index) => {
      form.push(
        <div>
          <div
            className={classes.committeeContainer}
            // className={["row", classes.formFields]
            //   .toString()
            //   .split(",")
            //   .join(" ")}
            key={index}
          >
            <p
              style={{ minWidth: "100px", marginTop: "10px", color: "#958989" }}
            >
              {" "}
              दिनांक
              {index == 0 ? <span className={classes.redStar}>*</span> : null}
            </p>
            <input
              max="9999-12-31"
              type="date"
              value={data.meetings_date}
              placeholder="YYYY-MM-DD"
              onChange={(e) => this.onValChangeDates(e, index)}
              className={classes.committeeDateInput}
            />

            <button
              style={{ border: "0px", backgroundColor: "transparent" }}
              onClick={() => this.removeDates(index)}
            >
              <DeleteOutline style={{ color: "red" }} />
            </button>
          </div>
          {this.state.isReviewComittee && data.meetings_date === "" ? (
            <p className={classes.redText}>*ही माहिती भरणे बाकी आहे </p>
          ) : null}
        </div>
      );
    });
    let formWrap = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontSize: "26px" }}>पुढील नियोजित बैठका</p>
        </div>

        {form}
        {this.state.isFormReview && flagThree === false ? (
          <span className={classes.redStar}>*ही माहिती भरणे बाकी आहे </span>
        ) : null}

        <div
          // className={["m-2", classes.headSection]
          //   .toString()
          //   .split(",")
          //   .join(" ")}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <button
            style={{
              fontSize: "35px",
              border: "0px",
              backgroundColor: "transparent",
              marginRight: "20px",
              backgroundColor: "#c8ed91",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              height: "40px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
            onClick={() => this.addDates()}
          >
            +
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              अजून एक बैठक नोंदवा
            </span>
          </button>
        </div>
      </div>
    );
    return formWrap;
  };
  onValChangeDates = (event, index) => {
    let dateForm = [...this.state.dateForm];
    dateForm[index].meetings_date = event.target.value;

    this.setState({
      dateForm,
    });
  };

  addDates = () => {
    let emptyDate = JSON.stringify(this.state.emptyDate);
    let dateForm = [...this.state.dateForm];
    dateForm.push(JSON.parse(emptyDate));
    this.setState(
      {
        dateForm,
      },
      () => {
        console.log(this.state.dateForm, "dateform");
      }
    );
  };

  removeDates = (index) => {
    let dateForm = [...this.state.dateForm];
    if (dateForm.length > 2) {
      dateForm.splice(index, 1);
      this.setState({
        dateForm,
      });
    }
  };

  removeDonors = (index) => {
    let donorsForm = [...this.state.donorsForm];
    if (donorsForm.length > 3) {
      donorsForm.splice(index, 1);
      this.setState({
        donorsForm,
      });
    }
  };
  removeCooks = (index) => {
    let cookForm = [...this.state.cookForm];
    if (cookForm.length > 1) {
      cookForm.splice(index, 1);
      this.setState({
        cookForm,
      });
    }
  };

  removeSchemes = (index) => {
    let governmentSchemesForm = [...this.state.governmentSchemesForm];
    if (governmentSchemesForm.length > 3) {
      governmentSchemesForm.splice(index, 1);
      this.setState({
        governmentSchemesForm,
      });
    }
  };

  checkingComittee = () => {
    let flagOne = true;
    let flagTwo = true;
    let flagThree = true;
    this.state.memberForm.forEach((i) => {
      if (
        i.name === "" ||
        i.phone === "" ||
        i.gender === "" ||
        i.category === ""
      ) {
        flagOne = false;
      }
    });
    if (flagOne === false) {
      return false;
    }

    this.state.meetingsForm.forEach((i) => {
      if (i.date === "" || i.attendance === "" || i.details === "") {
        flagTwo = false;
      }
    });
    if (flagTwo === false) {
      return false;
    }

    this.state.dateForm.forEach((i) => {
      if (i.meetings_date === "") {
        flagThree = false;
      }
    });

    if (flagThree === false) {
      return false;
    }

    return true;
  };

  moveToInfoPage = () => {
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: false,
      isInformation: true,
    });
  };

  onSubmitCommitteAfterDetails = () => {
    let updatedMembers = [];
    let updatedMeetings = [];

    this.state.memberForm.forEach((res) => {
      updatedMembers.push({
        name: res.name,
        gender: res.gender,
        phone: parseInt(res.phone),
        caste: res.category,
      });
    });
    this.state.meetingsForm.forEach((res) => {
      updatedMeetings.push({
        meetings_date: res.date,
        meetings_attendance: parseInt(res.attendance),
        reports: res.details,
      });
    });
    const memberObject = {
      members: updatedMembers,
      smc_meetings: updatedMeetings,
      smc_future_meetings: this.state.dateForm,
      school: null,
      school_grant_received: null,
      budget: null,
      meetings: null,
      roles_and_responsibility: null,
      achievement: null,
      challanges: null,
      school_details: this.state.schoolDetailsId,
    };
    getHMService()
      .createSMCMeetingsForm(memberObject)
      .then((res) => {
        if (res) {
          this.setState(
            {
              memberForm: [
                {
                  name: "",
                  phone: "",
                  gender: "MALE",
                  category: "OPEN",
                },
              ],
              meetingsForm: [
                {
                  date: "",
                  attendance: "",
                  details: "",
                },
              ],
              dateForm: [{ meetings_date: "" }],
              isReviewComittee: false,
              isSubmitShalaVyavsthpan: true,
              message:
                res.status == 200
                  ? "Form submited Successfully"
                  : res.data.message,
            },
            this.moveToInfoPage
          );
        }
      });
  };

  donorValChange = (event, index) => {
    let donorsForm = [...this.state.donorsForm];
    donorsForm[index].donorsName = event.target.value;

    this.setState({
      donorsForm: donorsForm,
    });
  };

  submitComitteDetails = () => {
    // console.log("committee");
    // const afterCommiteeCheck = this.checkingComittee();
    // console.log("committee", afterCommiteeCheck);
    // if (afterCommiteeCheck === true) {
    //   getHMService()
    //     .getSMCFormDetails(this.state.HMSchool.school_id)
    //     .then((res) => {
    //       this.setState(
    //         {
    //           schoolDetailsId: res.data.response.id,
    //         },
    //         this.onSubmitCommitteAfterDetails
    //       );
    //     });
    // } else {
    //   this.setState({
    //     isReviewComittee: true,
    //   });
    // }
    this.FillSmcData(false);
    this.setState({
      isTeacherDetails: false,
      isCommitteeDetails: false,
      isInformation: false,
      isExtraInfo: true,
    });
    document.getElementById("scroll").scrollIntoView();
  };

  addDonors = () => {
    let emptyDonors = JSON.stringify(this.state.emptyDonors);
    let donorsForm = [...this.state.donorsForm];
    donorsForm.push(JSON.parse(emptyDonors));
    this.setState(
      {
        donorsForm,
      },
      () => {
        console.log(this.state.donorsForm, "donors Dorm");
      }
    );
  };

  renderSchoolParticpation = () => {
    return (
      <div
        style={{
          backgroundColor: "#ECFBFF",
          fontWeight: "bold",
          marginTop: "15px",
          justifyContent: "center",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "58%",
            borderWidth: "1px",
            borderColor: "black",
            borderRightStyle: "solid",
            paddingRight: "60px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: "26px", marginRight: "10px" }}>
              शाळेस या शैक्षणिक वर्षात प्राप्त लोकसहभाग (२०२२-२३)
            </p>
            <Popup trigger={<HelpOutline />} position="right center">
              <div>
                शाळेच्या विकासासाठी CSR मार्फत अथवा नागरिकांनी जो देणगी दिला
                असेल तो तपशील थोडक्यात लिहावा.
              </div>
            </Popup>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                color: "#958989",
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              एकूण प्राप्त लोकसहभाग अंदाजे किंमत (रु.)
            </p>

            <input
              type="Number"
              style={{ marginTop: "5px", paddingTop: "7px" }}
              className={classes.committeeAttendanceInput}
              onChange={(e) => {
                this.setState({
                  publicParticipationCost: e.target.value,
                });
              }}
              placeholder="संख्या"
              min="0"
              value={this.state.publicParticipationCost}
            />
          </div>

          <div>
            <p
              style={{
                fontSize: "20px",
                color: "#958989",
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              लोकसहभागातून मिळालेली सुविधा -
            </p>
            <textarea
              onChange={(e) => {
                this.setState({
                  publicParticipation: e.target.value,
                });
              }}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              value={this.state.publicParticipation}
              placeholder="Enter Text"
            >
              {this.state.publicParticipation}
            </textarea>
          </div>
        </div>
        <div style={{ width: "38%" }}>
          <p
            style={{
              fontSize: "20px",
              color: "#958989",
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            देणगीदारांची नावे -
          </p>

          {this.state.donorsForm.map((data, index) => {
            return (
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  value={data.donorsName}
                  style={{
                    marginTop: "20px",
                    paddingTop: "7px",
                    width: "100%",
                    height: "46px",
                    backgroundColor: "#DFE4E8",
                    border: 0,
                    paddingLeft: "10px",
                  }}
                  onChange={(e) => this.donorValChange(e, index)}
                />
                <button
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  onClick={() => this.removeDonors(index)}
                >
                  <DeleteOutline style={{ color: "red" }} />
                </button>
              </div>
            );
          })}

          <div
            // className={["m-2", classes.headSection]
            //   .toString()
            //   .split(",")
            //   .join(" ")}
            className="d-flex flex-row align-items-center justify-content-center"
          >
            <button
              style={{
                fontSize: "35px",
                border: "0px",
                backgroundColor: "transparent",
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                height: "40px",
                borderRadius: "10px",
                marginTop: "20px",
              }}
              onClick={() => this.addDonors()}
            >
              <span
                style={{
                  backgroundColor: "black",
                  width: "28px",
                  height: "28px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white", fontSize: "18px" }}>+</span>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  marginLeft: "10px",
                  color: "#958989",
                  fontWeight: "800",
                }}
              >
                अजून एक देणगीदाराचे नाव नोंदवा
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderSchoolManagement = () => {
    return (
      <div
        style={{
          backgroundColor: "#ECFBFF",
          fontWeight: "bold",
          marginTop: "15px",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ fontSize: "26px", marginRight: "10px" }}>
            शाळा व्यवस्थापन समितीच्या माध्यमातून झालेला शाळेचा विकास
          </p>
          <Popup trigger={<HelpOutline />} position="right center">
            <div>
              शाळेत ज्या भौतिक सुधारणा तसेच मुल्यात्मक सुधारणा झाल्या असतील त्या
              स्पष्टपणे नमूद कराव्यात.
            </div>
          </Popup>
        </div>

        <div>
          <textarea
            onChange={(e) => {
              this.setState({
                schoolDevelopment: e.target.value,
              });
            }}
            style={{
              marginTop: "15px",
              backgroundColor: "#DFE4E8",
              padding: "20px",
              border: 0,
              height: "253px",
              width: "100%",
              resize: "none",
              color: "#415EB6",
              fontSize: "22px",
              fontWeight: "800",
            }}
            placeholder="Enter Text"
            value={this.state.schoolDevelopment}
          >
            {this.state.schoolDevelopment}
          </textarea>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              color: "#958989",
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            संबंधित फोटो अपलोड करा
          </p>

          <input
            type="file"
            accept="image/jpeg, image/png"
            style={{ marginTop: "5px", paddingTop: "7px" }}
            className={classes.committeeDetailsInput}
            onChange={(e) => {
              this.onChangeExtraInfoPic(e, "schoolDevelopment");
            }}
          />
          <Popup trigger={<HelpOutline />} position="right center">
            <div>इमारत, बांधकाम किंवा विकासाचे फोटो आणि कृती अपलोड करा</div>
          </Popup>
        </div>
        {this.state.schoolDevelopmentPic !== "" ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "6px",
              }}
            >
              <CheckCircle
                style={{
                  color: "green",
                  alignItems: "center",
                  justifyContnet: "center",
                }}
              />
              <p className={classes.fileNameText}>
                File name:
                {this.urlToimageName(this.state.schoolDevelopmentPic)}
              </p>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  renderCommitteeView = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {this.renderMemberForm()}
        </div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {this.renderMemberTiming()}
        </div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {this.renderMeetingsDates()}
        </div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {this.renderSchoolManagement()}
        </div>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {this.renderSchoolParticpation()}
        </div>
        <div
          style={{
            marginTop: "10px",
            backgroundColor: "#ECFBFF",
          }}
        >
          <p
            style={{
              backgroundColor: "#ECFBFF",
              fontWeight: "bold",
              marginTop: "15px",
              justifyContent: "center",
              padding: "20px",
              fontSize: "26px",
            }}
          >
            आपल्या शाळेत काय उपलब्ध आहे त्यानुसार योग्य ते सर्व चेकबॉक्स निवडा.
            <span className={classes.redStar}>*</span>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "10px",
              backgroundColor: "#ECFBFF",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.internetCheck}
                      onChange={(e) => {
                        this.setState({
                          internetCheck: !this.state.internetCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="इंटरनेट"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.smartTvCheck}
                      onChange={(e) => {
                        this.setState({
                          smartTvCheck: !this.state.smartTvCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="स्मार्ट टीव्ही"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.powerConnectionCheck}
                      onChange={(e) => {
                        this.setState({
                          powerConnectionCheck: !this.state
                            .powerConnectionCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="वीज जोडणी"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.boysWashroomCheck}
                      onChange={(e) => {
                        this.setState({
                          boysWashroomCheck: !this.state.boysWashroomCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="मुलांचे स्वच्छतागृह"
                />
              </div>
              {/* <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.computerRoomCheck}
                      onChange={(e) => {
                        this.setState({
                          computerRoomCheck: !this.state.computerRoomCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="संगणक कक्ष"
                />
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.mathsBoxCheck}
                      onChange={(e) => {
                        this.setState({
                          mathsBoxCheck: !this.state.mathsBoxCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="गणित पेटी"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.girlsWashroomCheck}
                      onChange={(e) => {
                        this.setState({
                          girlsWashroomCheck: !this.state.girlsWashroomCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="मुलींचे स्वच्छतागृह"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.sweeperCheck}
                      onChange={(e) => {
                        this.setState({
                          sweeperCheck: !this.state.sweeperCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="सफाई कर्मचारी"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.boundaryWallCheck}
                      onChange={(e) => {
                        this.setState({
                          boundaryWallCheck: !this.state.boundaryWallCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="सुरक्षा भिंत"
                />
              </div>
              {/* <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.backyardCheck}
                      onChange={(e) => {
                        this.setState({
                          backyardCheck: !this.state.backyardCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="परसबाग"
                />
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.groundCheck}
                      onChange={(e) => {
                        this.setState({
                          groundCheck: !this.state.groundCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="मैदान"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.libraryCheck}
                      onChange={(e) => {
                        this.setState({
                          libraryCheck: !this.state.libraryCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="ग्रंथालय कक्ष"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.labCheck}
                      onChange={(e) => {
                        this.setState({
                          labCheck: !this.state.labCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="प्रयोगशाळा"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.balaCheck}
                      onChange={(e) => {
                        this.setState({
                          balaCheck: !this.state.balaCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="BALA रचना"
                />
              </div>
              {/* <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.inverterCheck}
                      onChange={(e) => {
                        this.setState({
                          inverterCheck: !this.state.inverterCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="इन्व्हर्टर"
                />
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.solidClassRoomsCheck}
                      onChange={(e) => {
                        this.setState({
                          solidClassRoomsCheck: !this.state
                            .solidClassRoomsCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="पक्क्या वर्ग खोल्या "
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.weakClassCoomsCheck}
                      onChange={(e) => {
                        this.setState({
                          weakClassCoomsCheck: !this.state.weakClassCoomsCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="कच्च्या वर्गखोल्या"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.projectorCheck}
                      onChange={(e) => {
                        this.setState({
                          projectorCheck: !this.state.projectorCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="प्रोजेक्टर"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.playCaterialCheck}
                      onChange={(e) => {
                        this.setState({
                          playCaterialCheck: !this.state.playCaterialCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="खेळाचे साहित्य"
                />
              </div>
              {/* <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.ablCheck}
                      onChange={(e) => {
                        this.setState({
                          ablCheck: !this.state.ablCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="ABL"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className={classes.submitButtonContainer}>
          <button className={classes.submitButton} onClick={this.saveAsDraft}>
            Save Draft
          </button>
          <button
            className={classes.submitButton}
            onClick={this.submitComitteDetails}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  checkSMCForm = () => {
    if (this.state.headMasterName === "") {
      return false;
    }
    if (this.state.headMasterNumber === "") {
      return false;
    }
    if (this.state.headMasterCaste === "") {
      return false;
    }
    if (this.state.seniorBoyTeacherCaste === "") {
      return false;
    }
    if (this.state.seniorGirlTeacherCaste === "") {
      return false;
    }
    if (this.state.seniorBoyTeacherName === "") {
      return false;
    }
    if (this.state.seniorBoyTeacherNumber === "") {
      return false;
    }

    if (this.state.seniorGirlTeacherName === "") {
      return false;
    }
    if (this.state.seniorGirlTeacherNumber === "") {
      return false;
    }
    if (this.state.schoolAddress === "") {
      return false;
    }
    if (this.state.schooltype === "") {
      return false;
    }
    if (this.state.schoolAddressLink === "") {
      return false;
    }
    if (this.state.totalStudent === "" || this.state.totalStudent === null) {
      return false;
    }
    if (
      this.state.boyTotalStudent === "" ||
      this.state.boyTotalStudent === null
    ) {
      return false;
    }
    if (
      this.state.girlTotalStudent === "" ||
      this.state.girlTotalStudent === null
    ) {
      return false;
    }

    // if (this.state.totalTeacher === "" || this.state.totalTeacher === null) {
    //   return false;
    // }
    // if (
    //   this.state.girlTotalTeacher === "" ||
    //   this.state.girlTotalTeacher === null
    // ) {
    //   return false;
    // }
    // if (
    //   this.state.boyTotalTeacher === "" ||
    //   this.state.boyTotalTeacher === null
    // ) {
    //   return false;
    // }
    if (this.state.whole_building === "") {
      return false;
    }
    // if (this.state.labs === "") {
    //   return false;
    // }
    if (this.state.ground === "") {
      return false;
    }
    if (this.state.compound === "") {
      return false;
    }
    if (this.state.classrooms === "") {
      return false;
    }

    let classStudentsFlag = true;
    this.state.allClassStudent.forEach((i) => {
      if (i.students === null || i.students === "") {
        classStudentsFlag = false;
      }
    });
    if (classStudentsFlag === false) {
      return false;
    }

    let classCasteFlag = true;
    this.state.castwisecount.forEach((i) => {
      if (i.female === "" || i.male === "") {
        classCasteFlag = false;
      }
    });

    if (classCasteFlag === false) {
      return false;
    }

    let flagOne = true;
    // let flagTwo = true;
    let flagThree = true;
    let cookFlag = true;

    this.state.memberForm.forEach((i) => {
      if (
        i.name === "" ||
        i.phone === "" ||
        i.gender === "" ||
        i.category === "" ||
        i.patent === ""
      ) {
        flagOne = false;
      }
    });
    if (flagOne === false) {
      return false;
    }

    // this.state.meetingsForm.forEach((i) => {
    //   if (
    //     i.date === "" ||
    //     i.attendance === "" ||
    //     i.details === "" ||
    //     i.photo === ""
    //   ) {
    //     flagTwo = false;
    //   }
    // });
    // if (flagTwo === false) {
    //   return false;
    // }

    this.state.dateForm.forEach((i) => {
      if (i.meetings_date === "") {
        flagThree = false;
      }
    });

    if (flagThree === false) {
      return false;
    }
    if (
      (this.state.fifthScholarship === "होय" &&
        this.state.fifthScholarshipCount === "") ||
      (this.state.tenthScholarship === "होय" &&
        this.state.tenthScholarshipCount === "") ||
      (this.state.eighthScholarship === "होय" &&
        this.state.eighthScholarshipCount === "") ||
      (this.state.navodayaScholarship === "होय" &&
        this.state.navodayaScholarshipCount === "") ||
      (this.state.eighthNMSS === "होय" && this.state.eighthNMSSCount === "") ||
      (this.state.elementaryExam === "होय" &&
        this.state.elementaryExamCount === "") ||
      (this.state.intermediate === "होय" && this.state.intermediateCount === "")
    ) {
      return false;
    }

    if (
      this.state.studentGettingFoodCount === "" ||
      this.state.totalCooks === "" ||
      this.state.eatingStudentPic === "" ||
      this.state.extraInfoFood === ""
    ) {
      return false;
    }

    this.state.memberForm.forEach((i) => {
      if (i.cookName === "" || i.cookSalary === "") {
        cookFlag = false;
      }
    });
    if (cookFlag === false) {
      return false;
    }

    if (
      this.state.uniformsCount === "" ||
      this.state.girlsUniformCount === "" ||
      this.state.boysUniformCount === "" ||
      this.state.remainingUniform === ""
    ) {
      return false;
    }
    if (
      this.state.schoolChallenges.length < 200 ||
      this.state.schoolStrength.length < 200 ||
      this.state.headMasterMessage.length < 200 ||
      this.state.schoolAchievement.length < 200 ||
      this.state.schoolChallengesPic === "" ||
      this.state.headMasterPic === "" ||
      this.state.schoolHistoryPic === "" ||
      this.state.schoolStrengthPic === ""
    ) {
      return false;
    }
    if (
      this.state.matsCount === "" ||
      this.state.benchesCount === "" ||
      this.state.remainingSeats === "" ||
      this.state.remainingMats === ""
    ) {
      return false;
    }
    if (this.state.totalBooksCount === "") {
      return false;
    }
    if (
      this.state.isWaterSouceCommon === false &&
      this.state.isTubeHandPump === false &&
      this.state.otherWaterDetails === ""
    ) {
      return false;
    }
    return true;
  };

  FillSmcData = (val) => {
    let genderWiseData = [];
    this.state.allClassStudent.forEach((res) => {
      genderWiseData.push({
        class_name: res.id.toString(),
        total_students: parseInt(res.students),
      });
    });

    let updatedMembers = [];
    let updatedMeetings = [];
    let updatedCasteWise = [];
    let updatedDonors = [];
    let updatedCook = [];
    let updatedSchemes = [];

    this.state.castwisecount.forEach((res) => {
      updatedCasteWise.push({
        caste_name: res.grade,
        boys: parseInt(res.male),
        girls: parseInt(res.female),
      });
    });

    this.state.memberForm.forEach((res) => {
      updatedMembers.push({
        name: res.name,
        gender: res.gender,
        phone: parseInt(res.phone),
        caste: res.category,
        patent: res.patent,
      });
    });

    this.state.meetingsForm.forEach((res) => {
      let meet = {};
      if (res.date !== "") {
        meet.meetings_date = res.date;
      }
      if (res.attendance !== "") {
        meet.meetings_attendance = res.attendance;
      }
      if (res.photo !== "") {
        meet.photo = res.photo;
      }
      if (res.details !== "") {
        meet.reports = res.details;
      }
      updatedMeetings.push(meet);
    });

    this.state.donorsForm.forEach((res) => {
      let donor = {};
      if (res.donorsName !== "") {
        donor.donor_name = res.donorsName;
      }
      updatedDonors.push(donor);
    });

    this.state.cookForm.forEach((res) => {
      updatedCook.push({
        cook_name: res.cookName,
        cook_salary: parseInt(res.cookSalary),
      });
    });

    this.state.governmentSchemesForm.forEach((res) => {
      updatedSchemes.push({
        name_of_scheme: res.schemeName,
        number_of_beneficiaries: parseInt(res.beneficiariesNumber),
      });
    });
    // this.state.schoolHistoryPic
    //   ? (school_history_photo: this.state.schoolHistoryPic) : null

    const school = JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"))
        ? JSON.parse(localStorage.getItem("AMdetails"))
        : null;
    const smcData = {
      draft_data: false,
      quarter: this.state.quaterValue,
      year: this.state.YearValue,
      school_type: this.state.schooltype,
      head_master: this.state.headMasterName,
      head_master_mobile: parseInt(this.state.headMasterNumber),
      head_master_caste: this.state.headMasterCaste,
      school: school.school_id,
      school_address: this.state.schoolAddress,
      school_address_gmaps: this.state.schoolAddressLink,
      senior_teacher_male: this.state.seniorBoyTeacherName,
      senior_teacher_male_mobile: parseInt(this.state.seniorBoyTeacherNumber),
      senior_teacher_male_caste: this.state.seniorBoyTeacherCaste,
      senior_teacher_female: this.state.seniorGirlTeacherName,
      senior_teacher_female_mobile: parseInt(
        this.state.seniorGirlTeacherNumber
      ),
      senior_teacher_female_caste: this.state.seniorGirlTeacherCaste,
      total_teacher:
        this.state.totalTeacher !== ""
          ? parseInt(this.state.totalTeacher)
          : null,
      male_teacher:
        this.state.boyTotalTeacher !== ""
          ? parseInt(this.state.boyTotalTeacher)
          : null,
      female_teacher:
        this.state.girlTotalTeacher !== ""
          ? parseInt(this.state.girlTotalTeacher)
          : null,
      total_student: parseInt(this.state.totalStudent),
      boys: parseInt(this.state.boyTotalStudent),
      girls: parseInt(this.state.girlTotalStudent),
      // version: "V3",
      whole_building: this.state.whole_building,
      ground: this.state.ground,
      classrooms: this.state.classrooms,
      office: this.state.compound,
      gender_wise: genderWiseData,
      compound: this.state.securityWall,
      games_sports_material: this.state.gamesPic,
      washrooms: this.state.restroomPic,
      drinking_water: this.state.waterPic,
      ramp: this.state.rampPic,
      kitchen_shed: this.state.kitchenPic,
      study_teaching_materials: this.state.studyMaterialPic,
      library_reading_materials: this.state.librarayMaterialPic,
      other_facilities: this.state.otherPic,
      other_school_details: {
        school_history: this.state.schoolAchievement,
        school_strengths: this.state.schoolStrength,
        school_challenges: this.state.schoolChallenges,
        head_master_msg: this.state.headMasterMessage,
        head_master_photo: this.state.headMasterPic,
        school_challenges_photo: this.state.schoolChallengesPic,
        // school_development_by_smc:this.state.schoolDevelopment,
        // school_development_by_smc_photo: this.state.schoolDevelopmentPic,
        // future_plan_of_school:this.state.futurePlan,
        // future_plan_of_school_photo:this.state.futurePlanPic,
        // instructions_for_parents:this.state.instructionForParents
      },
      members: updatedMembers,
      smc_meetings: updatedMeetings,
      smc_future_meetings: this.state.dateForm,
      caste_wise: updatedCasteWise,
      donors: updatedDonors,
      government_schemes: updatedSchemes,

      mid_day_meal: {
        total_students: parseInt(this.state.studentGettingFoodCount),
        student_photo: this.state.eatingStudentPic,
        other_details: this.state.extraInfoFood,
        number_of_cook: parseInt(this.state.totalCooks),
      },
      cook_details: updatedCook,
      uniform: {
        total_students: parseInt(this.state.uniformsCount),
        boys: parseInt(this.state.boysUniformCount),
        girls: parseInt(this.state.girlsUniformCount),
        remaining: parseInt(this.state.remainingUniform),
      },
      school_facility: {
        internet: this.state.internetCheck,
        smart_tv: this.state.smartTvCheck,
        power_connection: this.state.powerConnectionCheck,
        boys_washroom: this.state.boysWashroomCheck,
        girls_washroom: this.state.girlsWashroomCheck,
        maths_box: this.state.mathsBoxCheck,
        sweeper: this.state.sweeperCheck,
        boundary_wall: this.state.boundaryWallCheck,
        ground: this.state.groundCheck,
        library: this.state.libraryCheck,
        lab: this.state.labCheck,
        bala: this.state.balaCheck,
        solid_class_rooms: this.state.solidClassRoomsCheck,
        weak_class_rooms: this.state.weakClassCoomsCheck,
        projector: this.state.projectorCheck,
        play_material: this.state.playCaterialCheck,
        backyard: this.state.backyardCheck,
        abl: this.state.ablCheck,
        inverter: this.state.inverterCheck,
        computer_room: this.state.computerRoomCheck,
      },
      scholarship: {
        scholarship_5th:
          this.state.fifthScholarship === "होय"
            ? parseInt(this.state.fifthScholarshipCount)
            : 0,
        scholarship_8th:
          this.state.eighthScholarship === "होय"
            ? parseInt(this.state.eighthScholarshipCount)
            : 0,
        scholarship_10th:
          this.state.tenthScholarship === "होय"
            ? parseInt(this.state.tenthScholarshipCount)
            : 0,
        scholarship_navodaya:
          this.state.navodayaScholarship === "होय"
            ? parseInt(this.state.navodayaScholarshipCount)
            : 0,

        scholarship_8th_nmms:
          this.state.eighthNMSS === "होय"
            ? parseInt(this.state.eighthNMSSCount)
            : 0,
        elementary:
          this.state.elementaryExam === "होय"
            ? parseInt(this.state.elementaryExamCount)
            : 0,
        intermediate:
          this.state.intermediate === "होय"
            ? parseInt(this.state.intermediateCount)
            : 0,
      },
      special_information: {
        crude_classrooms: this.state.matsCount,
        concrete_classrooms: this.state.benchesCount,
        required_more_sits: this.state.remainingSeats,
        total_classrooms: this.state.remainingMats,
        water_source_common: this.state.isWaterSouceCommon,
        tube_hand_pump: this.state.isTubeHandPump,
        water_other: this.state.otherWaterDetails,
        total_books: this.state.totalBooksCount,
      },
    };

    if (this.state.schoolHistoryPic !== "") {
      smcData.other_school_details.school_history_photo = this.state.schoolHistoryPic;
    }
    if (this.state.schoolStrengthPic !== "") {
      smcData.other_school_details.school_strengths_photo = this.state.schoolStrengthPic;
    }

    if (this.state.schoolDevelopmentPic !== "") {
      smcData.other_school_details.school_development_by_smc_photo = this.state.schoolDevelopmentPic;
    }
    if (this.state.futurePlanPic !== "") {
      smcData.other_school_details.future_plan_of_school_photo = this.state.futurePlanPic;
    }

    if (this.state.schoolDevelopment !== "") {
      smcData.other_school_details.school_development_by_smc = this.state.schoolDevelopment;
    }
    if (this.state.futurePlan !== "") {
      smcData.other_school_details.future_plan_of_school = this.state.futurePlan;
    }

    if (this.state.instructionForParents !== "") {
      smcData.other_school_details.instructions_for_parents = this.state.instructionForParents;
    }

    if (
      this.state.publicParticipation !== "" ||
      this.state.publicParticipationCost !== ""
    ) {
      smcData.public_donation = {};
      if (this.state.publicParticipationCost !== "") {
        smcData.public_donation.total_donation = this.state.publicParticipationCost;
      }
      if (this.state.publicParticipation !== "") {
        smcData.public_donation.facilities_obtained = this.state.publicParticipation;
      }
    }

    if (this.state.totalMeetingsInYear !== "") {
      smcData.special_information.number_of_meetings = this.state.totalMeetingsInYear;
    }

    console.log("added data", smcData);
    if (val === true) {
      return smcData;
    }
  };

  getPreviousData = () => {
    const school = JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"))
        ? JSON.parse(localStorage.getItem("AMdetails"))
        : null;
    console.log(this.state.HMSchool.school_id, "45454");
    getHMService()
      .getSMCFormDetailsForDraft(this.state.HMSchool.school_id)
      .then(async (res) => {
        if (res) {
          const { data } = res;
          const { response } = data;

          console.log(response, "component did mount draft");
          // var flagOne = false;
          // var flagTwo = false;

          if (response.id) {
            const url = JSON.parse(localStorage.getItem("HMSchool"))
              ? "get_all_version"
              : `get_all_version/?school_id=${this.state.HMSchool.school_id}`;
            getHMService()
              .getVersionName(url)
              .then((res) => {
                if (res?.data?.response?.length > 0) {
                  this.autoFillSMCForm(res?.data?.response?.[0]);
                } else {
                  let classAndstudents = [
                    {
                      id: 1,
                      grade: "1st",
                      students: "",
                    },
                    {
                      id: 2,
                      grade: "2nd",
                      students: "",
                    },
                    {
                      id: 3,
                      grade: "3rd",
                      students: "",
                    },
                    {
                      id: 4,
                      grade: "4th",
                      students: "",
                    },
                    {
                      id: 5,
                      grade: "5th",
                      students: "",
                    },
                    {
                      id: 6,
                      grade: "6th",
                      students: "",
                    },
                    {
                      id: 7,
                      grade: "7th",
                      students: "",
                    },
                    {
                      id: 8,
                      grade: "8th",
                      students: "",
                    },
                    {
                      id: 9,
                      grade: "9th",
                      students: "",
                    },
                    {
                      id: 10,
                      grade: "10th",
                      students: "",
                    },
                  ];

                  this.setState({
                    isAddMember: false,
                    isAddDetail: false,
                    isEditDetail: false,
                    memberForm: [
                      {
                        name: "",
                        phone: "",
                        gender: "",
                        category: "",
                        patent: "",
                      },
                      {
                        name: "",
                        phone: "",
                        gender: "",
                        category: "",
                        patent: "",
                      },
                      {
                        name: "",
                        phone: "",
                        gender: "",
                        category: "",
                        patent: "",
                      },
                      {
                        name: "",
                        phone: "",
                        gender: "",
                        category: "",
                        patent: "",
                      },
                    ],
                    emptyForm: {
                      name: "",
                      phone: "",
                      gender: "",
                      category: "",
                      patent: "",
                    },
                    meetingsForm: [
                      {
                        date: "",
                        attendance: "",
                        details: "",
                        photo: "",
                      },
                      {
                        date: "",
                        attendance: "",
                        details: "",
                        photo: "",
                      },
                      {
                        date: "",
                        attendance: "",
                        details: "",
                        photo: "",
                      },
                    ],
                    emptyMeetings: {
                      date: "",
                      attendance: "",
                      details: "",
                      photo: "",
                    },
                    dateForm: [{ meetings_date: "" }, { meetings_date: "" }],
                    emptyDate: { meetings_date: "" },
                    error: false,
                    message: "",
                    HMDetails: {},
                    HMSchool: JSON.parse(localStorage.getItem("HMSchool")),
                    isHMDetailsPresent: false,
                    isSMCDetailsPresent: false,
                    smcDetails: {},
                    WHOLE_BUILDING: null,
                    LABS: null,
                    GROUND: null,
                    COMPOUND: null,
                    CLASSROOMS: null,
                    whole_building: "",
                    labs: "",
                    ground: "",
                    compound: "",
                    classrooms: "",
                    tabIndex: 0,
                    // classCheck: false,
                    // groundCheck: false,
                    // washroomCheck: false,
                    // internetCheck: false,
                    // computerCheck: false,
                    // electricityCheck: false,
                    // wallCheck: false,
                    // MY STATES
                    currentImage: 0,
                    headMasterName: "",
                    headMasterNumber: "",
                    seniorBoyTeacherName: "",
                    seniorBoyTeacherNumber: "",
                    seniorGirlTeacherName: "",
                    seniorGirlTeacherNumber: "",
                    schoolAddress: "",
                    schoolAddressLink: "",
                    schooltype: "",
                    totalStudent: "",
                    girlTotalStudent: "",
                    boyTotalStudent: "",
                    totalTeacher: "",
                    girlTotalTeacher: "",
                    boyTotalTeacher: "",
                    allClassStudent: classAndstudents,
                    isReview: false,
                    isTeacherDetails: true,
                    isCommitteeDetails: false,
                    isInformation: false,
                    isExtraInfo: false,
                    schoolAchievement: "",
                    schoolChallenges: "",
                    schoolStrength: "",
                    isReviewInfo: false,
                    isReviewComittee: false,
                    schoolDetailsId: null,
                    isSubmitPrathmik: false,
                    isSubmitShalaVyavsthpan: false,
                    castwisecount: [
                      {
                        id: 1,
                        grade: "OPEN",
                        male: "",
                        female: "",
                      },
                      {
                        id: 2,
                        grade: "OBC",
                        male: "",
                        female: "",
                      },
                      {
                        id: 3,
                        grade: "SC",
                        male: "",
                        female: "",
                      },
                      {
                        id: 4,
                        grade: "ST",
                        male: "",
                        female: "",
                      },
                      {
                        id: 5,
                        grade: "NT",
                        male: "",
                        female: "",
                      },
                      {
                        id: 6,
                        grade: "VJNT",
                        male: "",
                        female: "",
                      },
                      {
                        id: 7,
                        grade: "OTHER",
                        male: "",
                        female: "",
                      },
                    ],
                    smcFormData: [],
                    headMasterMessage: "",
                    schoolHistoryPic: "",
                    schoolStrengthPic: "",
                    schoolChallengesPic: "",
                    headMasterPic: "",
                    fifthScholarship: "नाही",
                    fifthScholarshipCount: "",
                    eighthScholarship: "नाही",
                    eighthScholarshipCount: "",
                    tenthScholarship: "नाही",
                    tenthScholarshipCount: "",
                    navodayaScholarship: "नाही",
                    navodayaScholarshipCount: "",
                    cookName: "",
                    studentGettingFoodCount: "",
                    cookSalary: "",
                    eatingStudentPic: "",
                    extraInfoFood: "",
                    uniformsCount: "",
                    girlsUniformCount: "",
                    boysUniformCount: "",
                    remainingUniform: "",
                    internetCheck: false,
                    smartTvCheck: false,
                    powerConnectionCheck: false,
                    boysWashroomCheck: false,
                    girlsWashroomCheck: false,
                    mathsBoxCheck: false,
                    sweeperCheck: false,
                    boundaryWallCheck: false,
                    groundCheck: false,
                    libraryCheck: false,
                    labCheck: false,
                    balaCheck: false,
                    solidClassRoomsCheck: false,
                    weakClassCoomsCheck: false,
                    projectorCheck: false,
                    playCaterialCheck: false,
                    backyardCheck: false,
                    ablCheck: false,
                    inverterCheck: false,
                    computerRoomCheck: false,
                    headMasterCaste: "",
                    seniorBoyTeacherCaste: "",
                    seniorGirlTeacherCaste: "",
                    isFormReview: false,
                    matsCount: "",
                    benchesCount: "",
                    remainingSeats: "",
                    remainingMats: "",
                    freeTextBookCount: "",
                    isWaterSouceCommon: false,
                    isTubeHandPump: false,
                    otherWaterDetails: "",
                    totalBooksCount: "",
                    alertbox: false,
                    securityWall: "",
                    dialogCheck: false,
                    //
                    wholeBuildingName: "",
                    securityName: "",
                    groundName: "",
                    hallName: "",
                    className: "",
                    HMpicNAme: "",
                    historyPicName: "",
                    schoolStrengthName: "",
                    visionName: "",
                    foodName: "",
                    meetPicName: "",
                    //
                    gamesPic: "",
                    restroomPic: "",
                    waterPic: "",
                    rampPic: "",
                    kitchenPic: "",
                    studyMaterialPic: "",
                    librarayMaterialPic: "",
                    otherPic: "",
                    schoolDevelopment: "",
                    schoolDevelopmentPic: "",
                    totalMeetingsInYear: "",
                    publicParticipationCost: "",
                    publicParticipation: "",
                    donorsForm: [
                      { donorsName: "" },
                      { donorsName: "" },
                      { donorsName: "" },
                    ],
                    emptyDonors: { donorsName: "" },
                    eighthNMSS: "नाही",
                    eighthNMSSCount: "",
                    elementaryExam: "नाही",
                    elementaryExamCount: "",
                    intermediate: "नाही",
                    intermediateCount: "",
                    totalCooks: "",
                    cookForm: [
                      {
                        cookName: "",
                        cookSalary: "",
                      },
                    ],
                    emptyCook: {
                      cookName: "",
                      cookSalary: "",
                    },
                    governmentSchemesForm: [
                      { schemeName: "", beneficiariesNumber: "" },
                      { schemeName: "", beneficiariesNumber: "" },
                      { schemeName: "", beneficiariesNumber: "" },
                    ],
                    emptyGovernmentScheme: {
                      schemeName: "",
                      beneficiariesNumber: "",
                    },
                    futurePlan: "",
                    futurePlanPic: "",
                    instructionForParents: "",
                    checkAlertBox: false,
                  });
                }
              });
          } else {
            this.autoFillSMCForm(response);
          }
        }
      });
  };

  autoFillSMCForm = (response) => {
    let classAndstudents = [
      {
        id: 1,
        grade: "1st",
        students:
          response?.gender_wise[0]?.total_students ||
            response?.gender_wise[0]?.total_students === 0
            ? response.gender_wise[0].total_students
            : "",
      },
      {
        id: 2,
        grade: "2nd",
        students:
          response?.gender_wise[1]?.total_students ||
            response?.gender_wise[1]?.total_students === 0
            ? response.gender_wise[1].total_students
            : "",
      },
      {
        id: 3,
        grade: "3rd",
        students:
          response?.gender_wise[2]?.total_students ||
            response?.gender_wise[2]?.total_students === 0
            ? response.gender_wise[2].total_students
            : "",
      },
      {
        id: 4,
        grade: "4th",
        students:
          response?.gender_wise[3]?.total_students ||
            response?.gender_wise[3]?.total_students === 0
            ? response.gender_wise[3].total_students
            : "",
      },
      {
        id: 5,
        grade: "5th",
        students:
          response?.gender_wise[4]?.total_students ||
            response?.gender_wise[4]?.total_students === 0
            ? response.gender_wise[4].total_students
            : "",
      },
      {
        id: 6,
        grade: "6th",
        students:
          response?.gender_wise[5]?.total_students ||
            response?.gender_wise[5]?.total_students === 0
            ? response.gender_wise[5].total_students
            : "",
      },
      {
        id: 7,
        grade: "7th",
        students:
          response?.gender_wise[6]?.total_students ||
            response?.gender_wise[6]?.total_students === 0
            ? response.gender_wise[6].total_students
            : "",
      },
      {
        id: 8,
        grade: "8th",
        students:
          response?.gender_wise[7]?.total_students ||
            response?.gender_wise[7]?.total_students === 0
            ? response.gender_wise[7].total_students
            : "",
      },
      {
        id: 9,
        grade: "9th",
        students:
          response?.gender_wise[8]?.total_students ||
            response?.gender_wise[8]?.total_students === 0
            ? response.gender_wise[8].total_students
            : "",
      },
      {
        id: 10,
        grade: "10th",
        students:
          response?.gender_wise[9]?.total_students ||
            response?.gender_wise[9]?.total_students === 0
            ? response.gender_wise[9].total_students
            : "",
      },
    ];
    let castWiseData = [
      {
        id: 1,
        grade: "OPEN",
        male:
          response?.caste_wise[0]?.boys || response?.caste_wise[0]?.boys === 0
            ? response?.caste_wise[0].boys
            : "",
        female:
          response?.caste_wise[0]?.girls || response?.caste_wise[0]?.girls === 0
            ? response?.caste_wise[0].girls
            : "",
      },

      {
        id: 2,
        grade: "OBC",
        male:
          response?.caste_wise[1]?.boys || response?.caste_wise[1]?.boys === 0
            ? response?.caste_wise[1].boys
            : "",
        female:
          response?.caste_wise[1]?.girls || response?.caste_wise[1]?.girls === 0
            ? response?.caste_wise[1].girls
            : "",
      },
      {
        id: 3,
        grade: "SC",
        male:
          response?.caste_wise[2]?.boys || response?.caste_wise[2]?.boys === 0
            ? response?.caste_wise[2].boys
            : "",
        female:
          response?.caste_wise[2]?.girls || response?.caste_wise[2]?.girls === 0
            ? response?.caste_wise[2].girls
            : "",
      },
      {
        id: 4,
        grade: "ST",
        male:
          response?.caste_wise[3]?.boys || response?.caste_wise[3]?.boys === 0
            ? response?.caste_wise[3].boys
            : "",
        female:
          response?.caste_wise[3]?.girls || response?.caste_wise[3]?.girls === 0
            ? response?.caste_wise[3].girls
            : "",
      },
      {
        id: 5,
        grade: "NT",
        male:
          response?.caste_wise[4]?.boys || response?.caste_wise[4]?.boys === 0
            ? response?.caste_wise[4].boys
            : "",
        female:
          response?.caste_wise[4]?.girls || response?.caste_wise[4]?.girls === 0
            ? response?.caste_wise[4].girls
            : "",
      },
      {
        id: 6,
        grade: "VJNT",
        male:
          response?.caste_wise[5]?.boys || response?.caste_wise[5]?.boys === 0
            ? response?.caste_wise[5].boys
            : "",
        female:
          response?.caste_wise[5]?.girls || response?.caste_wise[5]?.girls === 0
            ? response?.caste_wise[5].girls
            : "",
      },
      {
        id: 7,
        grade: "OTHER",
        male:
          response?.caste_wise[6]?.boys || response?.caste_wise[6]?.boys === 0
            ? response?.caste_wise[6].boys
            : "",
        female:
          response?.caste_wise[6]?.girls || response?.caste_wise[6]?.girls === 0
            ? response?.caste_wise[6].girls
            : "",
      },
    ];

    let smcMemberForm = [];
    let smcMeetingDate = [];
    let smcDonorsForm = [];
    let smcCookForm = [];
    let smcSchemeForm = [];
    let smcFutureMeetings = [];

    if (response?.members?.length > 0) {
      smcMemberForm = response?.members?.map((res) => {
        return {
          name: res?.name ? res.name : "",
          phone: res?.phone ? res.phone : "",
          gender: res?.gender ? res.gender : "",
          category: res?.caste ? res.caste : "",
          patent: res?.patent ? res.patent : "",
        };
      });
    } else {
      smcMemberForm = [
        {
          name: "",
          phone: "",
          gender: "",
          category: "",
          patent: "",
        },
        {
          name: "",
          phone: "",
          gender: "",
          category: "",
          patent: "",
        },
        {
          name: "",
          phone: "",
          gender: "",
          category: "",
          patent: "",
        },
        {
          name: "",
          phone: "",
          gender: "",
          category: "",
          patent: "",
        },
      ];
    }

    if (response?.smc_meetings?.length > 0) {
      smcMeetingDate = response?.smc_meetings?.map((res) => {
        return {
          date: res?.meetings_date ? res.meetings_date : "",
          attendance: res?.meetings_attendance ? res.meetings_attendance : "",
          details: res?.reports ? res.reports : "",
          photo: res?.photo ? res.photo : "",
        };
      });
    } else {
      smcMeetingDate = [
        {
          date: "",
          attendance: "",
          details: "",
          photo: "",
        },
        {
          date: "",
          attendance: "",
          details: "",
          photo: "",
        },
        {
          date: "",
          attendance: "",
          details: "",
          photo: "",
        },
      ];
    }

    if (response?.donors?.length > 0) {
      smcDonorsForm = response?.donors?.map((res) => {
        return {
          donorsName: res?.donor_name ? res.donor_name : "",
        };
      });
    } else {
      smcDonorsForm = [
        { donorsName: "" },
        { donorsName: "" },
        { donorsName: "" },
      ];
    }

    if (response?.cook_details?.length > 0) {
      smcCookForm = response?.cook_details?.map((res) => {
        return {
          cookName: res?.cook_name ? res.cook_name : "",
          cookSalary: res?.cook_salary ? res.cook_salary : "",
        };
      });
    } else {
      smcCookForm = [
        {
          cookName: "",
          cookSalary: "",
        },
      ];
    }

    if (response?.government_schemes?.length > 0) {
      smcSchemeForm = response?.government_schemes?.map((res) => {
        return {
          schemeName: res?.name_of_scheme ? res.name_of_scheme : "",
          beneficiariesNumber: res?.number_of_beneficiaries
            ? res.number_of_beneficiaries
            : "",
        };
      });
    } else {
      smcSchemeForm = [
        { schemeName: "", beneficiariesNumber: "" },
        { schemeName: "", beneficiariesNumber: "" },
        { schemeName: "", beneficiariesNumber: "" },
      ];
    }

    if (response?.smc_future_meetings?.length > 0) {
      smcFutureMeetings = response?.smc_future_meetings?.map((res) => {
        return {
          meetings_date: res?.meetings_date ? res.meetings_date : "",
        };
      });
    } else {
      smcFutureMeetings = [{ meetings_date: "" }, { meetings_date: "" }];
    }

    this.setState({
      headMasterName: response?.head_master ? response.head_master : "",
      headMasterNumber: response?.head_master_mobile
        ? response.head_master_mobile
        : "",
      seniorBoyTeacherName: response?.senior_teacher_male
        ? response.senior_teacher_male
        : "",
      seniorBoyTeacherNumber: response?.senior_teacher_male_mobile
        ? response.senior_teacher_male_mobile
        : "",
      seniorGirlTeacherName: response?.senior_teacher_female
        ? response.senior_teacher_female
        : "",
      seniorGirlTeacherNumber: response?.senior_teacher_female_mobile
        ? response.senior_teacher_female_mobile
        : "",
      headMasterCaste: response?.head_master_caste
        ? response.head_master_caste
        : "",
      seniorBoyTeacherCaste: response?.senior_teacher_male_caste
        ? response.senior_teacher_male_caste
        : "",
      seniorGirlTeacherCaste: response?.senior_teacher_female_caste
        ? response.senior_teacher_female_caste
        : "",
      schoolAddress: response?.school_address ? response.school_address : "",
      schoolAddressLink: response?.school_address_gmaps
        ? response.school_address_gmaps
        : "",
      schooltype: response?.school_type ? response.school_type : "",
      totalStudent:
        response?.total_student || response.total_student === 0
          ? response.total_student
          : "",
      girlTotalStudent:
        response?.girls || response?.girls === 0 ? response.girls : "",
      boyTotalStudent:
        response?.boys || response?.boys === 0 ? response.boys : "",
      totalTeacher:
        response?.total_teacher || response?.total_teacher === 0
          ? response.total_teacher
          : "",
      girlTotalTeacher:
        response?.female_teacher || response?.female_teacher === 0
          ? response.female_teacher
          : "",
      boyTotalTeacher:
        response?.male_teacher || response?.male_teacher === 0
          ? response.male_teacher
          : "",
      allClassStudent: classAndstudents,
      classrooms: response?.classrooms ? response.classrooms : "",
      labs: response?.labs ? response.labs : "",
      ground: response?.ground ? response.ground : "",
      compound: response?.office ? response.office : "",
      whole_building: response?.whole_building ? response.whole_building : "",
      securityWall: response?.compound ? response.compound : "",
      gamesPic: response?.games_sports_material
        ? response.games_sports_material
        : "",
      restroomPic: response?.washrooms ? response.washrooms : "",
      waterPic: response?.drinking_water ? response.drinking_water : "",
      rampPic: response?.ramp ? response.ramp : "",
      kitchenPic: response?.kitchen_shed ? response.kitchen_shed : "",
      studyMaterialPic: response?.study_teaching_materials
        ? response.study_teaching_materials
        : "",
      librarayMaterialPic: response?.library_reading_materials
        ? response.library_reading_materials
        : "",
      otherPic: response?.other_facilities ? response.other_facilities : "",
      castwisecount: castWiseData,
      memberForm: response?.members
        ? smcMemberForm
        : [
          {
            name: "",
            phone: "",
            gender: "",
            category: "",
            patent: "",
          },
          {
            name: "",
            phone: "",
            gender: "",
            category: "",
            patent: "",
          },
          {
            name: "",
            phone: "",
            gender: "",
            category: "",
            patent: "",
          },
          {
            name: "",
            phone: "",
            gender: "",
            category: "",
            patent: "",
          },
        ],
      dateForm: response?.smc_future_meetings
        ? smcFutureMeetings
        : [{ meetings_date: "" }, { meetings_date: "" }],
      meetingsForm: response?.smc_meetings
        ? smcMeetingDate
        : [
          {
            date: "",
            attendance: "",
            details: "",
            photo: "",
          },
          {
            date: "",
            attendance: "",
            details: "",
            photo: "",
          },
          {
            date: "",
            attendance: "",
            details: "",
            photo: "",
          },
        ],
      internetCheck: response?.school_facility?.internet
        ? response.school_facility.internet
        : false,
      smartTvCheck: response?.school_facility?.smart_tv
        ? response.school_facility.smart_tv
        : false,
      powerConnectionCheck: response?.school_facility?.power_connection
        ? response.school_facility.power_connection
        : false,
      boysWashroomCheck: response?.school_facility?.boys_washroom
        ? response.school_facility.boys_washroom
        : false,
      girlsWashroomCheck: response?.school_facility?.girls_washroom
        ? response.school_facility.girls_washroom
        : false,
      mathsBoxCheck: response?.school_facility?.maths_box
        ? response.school_facility.maths_box
        : false,
      sweeperCheck: response?.school_facility?.sweeper
        ? response.school_facility.sweeper
        : false,
      boundaryWallCheck: response?.school_facility?.boundary_wall
        ? response.school_facility.boundary_wall
        : false,
      groundCheck: response?.school_facility?.ground
        ? response.school_facility.ground
        : false,
      libraryCheck: response?.school_facility?.library
        ? response.school_facility.library
        : false,
      labCheck: response?.school_facility?.lab
        ? response.school_facility.lab
        : false,
      balaCheck: response?.school_facility?.bala
        ? response.school_facility.bala
        : false,
      solidClassRoomsCheck: response?.school_facility?.solid_class_rooms
        ? response.school_facility.solid_class_rooms
        : false,
      weakClassCoomsCheck: response?.school_facility?.weak_class_rooms
        ? response.school_facility.weak_class_rooms
        : false,
      projectorCheck: response?.school_facility?.projector
        ? response.school_facility.projector
        : false,
      playCaterialCheck: response?.school_facility?.play_material
        ? response.school_facility.play_material
        : false,
      backyardCheck: response?.school_facility?.backyard
        ? response.school_facility.backyard
        : false,
      ablCheck: response?.school_facility?.abl
        ? response.school_facility.abl
        : false,
      inverterCheck: response?.school_facility?.inverter
        ? response.school_facility.inverter
        : false,
      computerRoomCheck: response?.school_facility?.computer_room
        ? response.school_facility.computer_room
        : false,
      fifthScholarship: response?.scholarship?.scholarship_5th
        ? response.scholarship.scholarship_5th == 0
          ? "नाही"
          : "होय"
        : "नाही",
      fifthScholarshipCount: response?.scholarship?.scholarship_5th
        ? response.scholarship.scholarship_5th
        : "",
      eighthScholarship: response?.scholarship?.scholarship_8th
        ? response.scholarship.scholarship_8th == 0
          ? "नाही"
          : "होय"
        : "नाही",
      eighthScholarshipCount: response?.scholarship?.scholarship_8th
        ? response.scholarship.scholarship_8th
        : "",
      tenthScholarship: response?.scholarship?.scholarship_10th
        ? response.scholarship.scholarship_10th == 0
          ? "नाही"
          : "होय"
        : "नाही",
      tenthScholarshipCount: response?.scholarship?.scholarship_10th
        ? response.scholarship.scholarship_10th
        : "",
      navodayaScholarship: response?.scholarship?.scholarship_navodaya
        ? response.scholarship.scholarship_navodaya == 0
          ? "नाही"
          : "होय"
        : "नाही",
      navodayaScholarshipCount: response?.scholarship?.scholarship_navodaya
        ? response.scholarship.scholarship_navodaya
        : "",
      eighthNMSS: response?.scholarship?.scholarship_8th_nmms
        ? response.scholarship.scholarship_8th_nmms == 0
          ? "नाही"
          : "होय"
        : "नाही",
      eighthNMSSCount: response?.scholarship?.scholarship_8th_nmms
        ? response.scholarship.scholarship_8th_nmms
        : "",
      elementaryExam: response?.scholarship?.elementary
        ? response.scholarship.elementary == 0
          ? "नाही"
          : "होय"
        : "नाही",
      elementaryExamCount: response?.scholarship?.elementary
        ? response.scholarship.elementary
        : "",
      intermediate: response?.scholarship?.intermediate
        ? response.scholarship.intermediate == 0
          ? "नाही"
          : "होय"
        : "नाही",
      intermediateCount: response?.scholarship?.intermediate
        ? response.scholarship.intermediate
        : "",
      studentGettingFoodCount: response?.mid_day_meal?.total_students
        ? response.mid_day_meal?.total_students
        : "",
      totalCooks: response?.mid_day_meal?.number_of_cook
        ? response.mid_day_meal?.number_of_cook
        : "",
      cookForm: response?.cook_details
        ? smcCookForm
        : [
          {
            cookName: "",
            cookSalary: "",
          },
        ],
      governmentSchemesForm: response?.government_schemes
        ? smcSchemeForm
        : [
          { schemeName: "", beneficiariesNumber: "" },
          { schemeName: "", beneficiariesNumber: "" },
          { schemeName: "", beneficiariesNumber: "" },
        ],
      eatingStudentPic: response?.mid_day_meal?.student_photo
        ? response.mid_day_meal.student_photo
        : "",
      extraInfoFood: response?.mid_day_meal?.other_details
        ? response.mid_day_meal.other_details
        : "",
      uniformsCount:
        response?.uniform?.total_students ||
          response?.uniform?.total_students === 0
          ? response.uniform.total_students
          : "",
      girlsUniformCount:
        response?.uniform?.girls || response?.uniform?.girls === 0
          ? response.uniform.girls
          : "",
      boysUniformCount:
        response?.uniform?.boys || response?.uniform?.boys === 0
          ? response.uniform.boys
          : "",
      remainingUniform:
        response?.uniform?.remaining || response?.uniform?.remaining === 0
          ? response.uniform.remaining
          : "",
      matsCount:
        response?.special_information?.crude_classrooms ||
          response?.special_information?.crude_classrooms === 0
          ? response.special_information.crude_classrooms
          : "",
      benchesCount:
        response?.special_information?.concrete_classrooms ||
          response?.special_information?.concrete_classrooms === 0
          ? response.special_information.concrete_classrooms
          : "",
      remainingSeats:
        response?.special_information?.required_more_sits ||
          response?.special_information?.required_more_sits === 0
          ? response.special_information.required_more_sits
          : "",
      remainingMats:
        response?.special_information?.total_classrooms ||
          response?.special_information?.total_classrooms === 0
          ? response.special_information.total_classrooms
          : "",
      isWaterSouceCommon: response?.special_information?.water_source_common
        ? response.special_information.water_source_common
        : false,
      isTubeHandPump: response?.special_information?.tube_hand_pump
        ? response.special_information.tube_hand_pump
        : false,
      otherWaterDetails: response?.special_information?.water_other
        ? response.special_information.water_other
        : "",
      totalBooksCount: response?.special_information?.total_books
        ? response.special_information.total_books
        : "",
      totalMeetingsInYear: response?.special_information?.number_of_meetings
        ? response.special_information.number_of_meetings
        : "",
      headMasterMessage: response?.other_school_details?.head_master_msg
        ? response.other_school_details.head_master_msg
        : "",
      schoolHistoryPic: response?.other_school_details?.school_history_photo
        ? response.other_school_details.school_history_photo
        : "",
      schoolStrengthPic: response?.other_school_details?.school_strengths_photo
        ? response.other_school_details.school_strengths_photo
        : "",
      schoolChallengesPic: response?.other_school_details
        ?.school_challenges_photo
        ? response.other_school_details.school_challenges_photo
        : "",
      headMasterPic: response?.other_school_details?.head_master_photo
        ? response.other_school_details.head_master_photo
        : "",
      schoolAchievement: response?.other_school_details?.school_history
        ? response.other_school_details.school_history
        : "",
      schoolChallenges: response?.other_school_details?.school_challenges
        ? response.other_school_details.school_challenges
        : "",
      schoolStrength: response?.other_school_details?.school_strengths
        ? response.other_school_details.school_strengths
        : "",
      schoolDevelopment: response?.other_school_details
        ?.school_development_by_smc
        ? response.other_school_details.school_development_by_smc
        : "",
      schoolDevelopmentPic: response?.other_school_details
        ?.school_development_by_smc_photo
        ? response.other_school_details.school_development_by_smc_photo
        : "",
      futurePlan: response?.other_school_details?.future_plan_of_school
        ? response.other_school_details.future_plan_of_school
        : "",
      futurePlanPic: response?.other_school_details?.future_plan_of_school_photo
        ? response.other_school_details.future_plan_of_school_photo
        : "",
      instructionForParents: response?.other_school_details
        ?.instructions_for_parents
        ? response.other_school_details.instructions_for_parents
        : "",
      publicParticipation: response?.public_donation?.facilities_obtained
        ? response.public_donation.facilities_obtained
        : "",
      publicParticipationCost: response?.public_donation?.total_donation
        ? response.public_donation.total_donation
        : "",
      donorsForm: response?.donors
        ? smcDonorsForm
        : [{ donorsName: "" }, { donorsName: "" }, { donorsName: "" }],
    });
  };

  render() {
    let {
      error,
      message,
      isHMDetailsPresent,
      isSMCDetailsPresent,
    } = this.state;

    return (
      <div id="scroll">
        <Dialog
          open={this.state.dialogCheck}
          onClose={() => {
            this.setState({
              dialogCheck: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ border: "4px solid black" }}
          >
            <div>
              {
                " अभिनंदन, आपला SMC फॉर्म यशस्वीपणे सबमिट झाला आहे.  तुम्ही प्रोफाइलसाठी नोंदणी केलेल्या जी-मेल एड्रेसवर सबमिट केलेला PDF फॉर्म send केलेला आहे.!!!!"
              }
            </div>
          </DialogTitle>
        </Dialog>
        <AlertDialog
          open={this.state.alertbox}
          title="Save Information?"
          text="Please check all information and make sure that it is correct"
          onCancel={() => this.setState({ alertbox: false })}
          onConfirm={() => {
            this.onSubmitform();
            this.setState({ alertbox: false });
          }}
        />
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>

        <Dialog
          open={this.state.checkAlertBox}
          onClose={() => {
            this.setState({
              checkAlertBox: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              तुमचा फॉर्म अर्धवट भरलेला आहे. कृपया पुन्हा चेक करा.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  checkAlertBox: false,
                });
              }}
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {this.topButtonContainer()}
        {this.state.isTeacherDetails && this.teacherDetails()}
        {this.state.isCommitteeDetails && this.renderCommitteeView()}
        {this.state.isExtraInfo && this.extraInfoView()}
        {this.state.isInformation && this.specialInfoView()}
      </div>
    );
  }
}

export default SMCCreateForm;
