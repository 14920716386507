/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AccountBalanceRounded, ArrowDownwardRounded, ArrowDropDownRounded, ArrowRightRounded, GroupWorkRounded, MenuBookRounded } from "@material-ui/icons";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import cls from "./Sidebar.module.css";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import Card from "components/Card/Card.js";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(styles);
const user = JSON.parse(localStorage.getItem("user"));
let type = user ? user.user_type : null;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Sidebar(props) {
  const { color, logo, image, logoText, routes, history } = props;
  const [userData, setUserData] = React.useState({});
  const [school, setSchool] = React.useState({});
  const [routeFlag, setRouteFlag] = useState(false);
  const [routeWelcomeFlag, setWelcomeFlag] = useState(false);
  const [routeSMCFlag, setSMCFlag] = useState(false);
  const [labelClick, setLabelClick] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    if (userObj) {
      setUserData(userObj);
    }
  }, []);

  useEffect(() => {
    let schoolInfo = JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"));
    if (schoolInfo) {
      setSchool(schoolInfo);
    }
  }, []);
  const getLoggedInUser = () => {
    if (userData && userData.user_details) {
      return (
        userData.user_details.first_name +
        " " +
        userData.user_details.last_name +
        " - \n" +
        userData.user_details.mobile
      );
    }
    return "";
  };

  const classes = useStyles();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  // Function to check if any ListItem is active
  function isTreeItemActive(routes) {
    for (const prop of routes) {
      if (activeRoute(prop.layout + prop.path)) {
        return true;
      }
    }
    return false;
  }

  let brand = (
    <>
      <div style={{ paddingBottom: "35px" }} className={classes.logo}>
        <a
          href="https://vopa.in"
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive,
          })}
          target="_blank"
        >
          <div className={[classes.logoImage, cls.logoWrapper].join(" ")}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
        </a>
      </div>
      <div className={[classes.itemText, cls.loginContainer].join(" ")}>
        <Card style={{ width: "70%", padding: "10px", borderRadius: "15px" }}>
          {getLoggedInUser()}
        </Card>
      </div>
    </>
  );

  const handleMenuItemsClick = (prop) => {
    console.log("%%%%%", prop.name);
    history.push(prop.layout + prop.path);
    return;
  }

  const renderMenuItems = () => {
    if (userData.user_type === "HEADMASTER") {

      // Filter routes for "School Setup" items
      const schoolSetupRoutes = routes.filter(
        (res) =>
          res.name === 'Upload Student List' ||
          res.name === 'Assign Uploader Teacher' ||
          res.name === 'Transfer Teacher' ||
          res.name === 'Add/Remove Student'
      );

      const smcRoutes = routes.filter(
        (res) =>
          res.name === 'SMC Home' ||
          res.name === 'Update Information' ||
          res.name === 'View/Download'
      );

      const welcomeRoute = routes.filter((res) => res.name === 'Welcome');

      const FLNAnalysisRoute = routes.filter((res) => res.name === 'FLN Analysis')

      const LOAnalysisRoute = routes.filter((res) => res.name === 'LO Analysis')

      const LogOut = routes.filter((res) => res.name === 'Log Out')

      return (
        <List className={[classes.list, cls.sideBarContainer].join(' ')}>

          {/* Welcome Screen */}
          {welcomeRoute.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}

          {/* TreeView for "School Setup" */}
          <TreeView
            defaultCollapseIcon={<ArrowDropDownRounded style={{ fontSize: 50 }} />}
            defaultExpandIcon={<ArrowRightRounded style={{ fontSize: 50 }} />}>
            <TreeItem nodeId="school-setup"
              label={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: isTreeItemActive(schoolSetupRoutes) ? 'blue' : 'white',
                  width: '100%',
                  padding: '5px',
                  backgroundColor: isTreeItemActive(schoolSetupRoutes) ? 'rgb(238 238 238 / 90%)' : '',
                }}>
                  <span
                    style={{
                      marginLeft: '12px',
                    }}>School Setup</span>
                </div>
              }
              style={{
                color: 'white',
                marginLeft: '1rem',
              }}

            >
              {schoolSetupRoutes.map((prop, key) => (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={activeRoute(prop.layout + prop.path) ? { backgroundColor: '#eeeeee', width: '100%' } : null}
                  className={[classes.itemLink].join(' ')}
                >
                  {typeof prop.icon === 'string' ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '0.8rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      // whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
              ))}
            </TreeItem>
          </TreeView>

          {/* FLN Analysis List Item*/}
          {FLNAnalysisRoute.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}

          {/* LO Analysis */}
          {LOAnalysisRoute.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}


          {/* TreeView for "SMC" */}
          <TreeView
            defaultCollapseIcon={<ArrowDropDownRounded style={{ fontSize: 50 }} />} defaultExpandIcon={<ArrowRightRounded style={{ fontSize: 50 }} />}>
            <TreeItem nodeId="smc"
              label={
                <div style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  color: isTreeItemActive(smcRoutes) ? 'blue' : 'white',
                  width: '100%',
                  padding: '5px',
                  backgroundColor: isTreeItemActive(smcRoutes) ? 'rgb(238 238 238 / 90%)' : '', 
                  }}>
                  <span style={{ marginLeft: '12px' }}>SMC</span>
                </div>
              }
              style={{ color: "white", marginLeft: '1rem' }}>
              {smcRoutes.map((prop, key) => (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={activeRoute(prop.layout + prop.path) ? { backgroundColor: '#eeeeee', width: '100%' } : null}
                  className={[classes.itemLink].join(' ')}
                >
                  {typeof prop.icon === 'string' ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '0.8rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      // whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
              ))}
            </TreeItem>
          </TreeView>

          {/* LOG OUT */}
          {LogOut.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    console.log(prop.path, "path");
                    if (prop.path == "/logout") {
                      localStorage.clear();
                      history.replace("/");
                      return;
                    }
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}
        </List>
      );
    } else if (userData.user_type === "ADMINISTRATIVE") {

      const AMWelcomeRoute = routes.filter(
        (res) =>
          res.name === 'Welcome'
      );

      const AMFLNRoute = routes.filter(
        (res) =>
          res.name === 'FLN Home' ||
          // res.name === 'Data Analysis' ||
          res.name === 'Download Reports' ||
          res.name === 'FLN  Setup'
      );

      const AMSMCRoute = routes.filter(
        (res) =>
          res.name === 'SMC Home' ||
          res.name === 'SMC Overview' ||
          res.name === 'SMC  For AM'

      );

      const AMFLNAnalysis = routes.filter(
        (res) =>
          res.name === 'FLN Analysis'
      );

      const AMLOAnalysis = routes.filter(
        (res) =>
          res.name === 'LO analysis'
      );


      const AMLogOut = routes.filter(
        (res) =>
          res.name === 'Log Out'
      );
      return (
        <List className={[classes.list, cls.sideBarContainer].join(" ")}>

          {/* AM Welcome Screen */}
          {AMWelcomeRoute.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}

          {/* AM FLN TreeView */}
          <TreeView defaultCollapseIcon={<ArrowDropDownRounded style={{ fontSize: 50 }} />} defaultExpandIcon={<ArrowRightRounded style={{ fontSize: 50 }} />}>
            <TreeItem nodeId="AMFLN"
              label={
                <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: isTreeItemActive(AMFLNRoute) ? 'blue' : 'white',
                  width: '100%',
                  padding: '5px',
                  backgroundColor: isTreeItemActive(AMFLNRoute) ? 'rgb(238 238 238 / 90%)' : '',
                }}
                >
                  {/* <MenuBookRounded style={{ fontSize: 20 }} /> */}
                  <span style={{ marginLeft: '12px'}}>FLN Nipun Bharat</span>
                </div>
              }
              style={{ color: "white", marginLeft: '1.25rem' }}>
              {AMFLNRoute.map((prop, key) => (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={activeRoute(prop.layout + prop.path) ? { backgroundColor: '#eeeeee', width: '100%' } : null}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '0.8rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      // whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
              ))}
            </TreeItem>
          </TreeView>

          {/*AM FLN Analysis Screen */}
          {AMFLNAnalysis.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}

          {/*AM LO Analysis Screen */}
          {AMLOAnalysis.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}

          {/* AM SMC TreeView */}
          <TreeView
            defaultCollapseIcon={<ArrowDropDownRounded style={{ fontSize: 50 }} />}
            defaultExpandIcon={<ArrowRightRounded style={{ fontSize: 50 }} />}>
            <TreeItem
              nodeId="AMSMC"
              label={
                <div 
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: isTreeItemActive(AMSMCRoute) ? 'blue' : 'white',
                  width: '100%',
                  padding: '5px',
                  backgroundColor: isTreeItemActive(AMSMCRoute) ? 'rgb(238 238 238 / 90%)' : '',
                }}
                >
                  <span style={{ marginLeft: '12px' }}>Digital SMC</span>
                </div>
              }
              style={{ color: "white", marginLeft: '1.25rem' }} >
              {AMSMCRoute.map((prop, key) => (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleMenuItemsClick(prop)
                  }}
                  button
                  className={[classes.itemLink].join(" ")}
                  style={activeRoute(prop.layout + prop.path) ? { backgroundColor: '#eeeeee', width: '100%' } : null}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        // whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '0.8rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      // whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
              ))}
            </TreeItem>
          </TreeView>

          {/* LOG out item */}
          {AMLogOut.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  key={key}
                  onClick={() => {
                    console.log(prop.path, "path");
                    if (prop.path == "/logout") {
                      localStorage.clear();
                      history.replace("/");
                      return;
                    }
                  }}
                  button
                  style={{ marginLeft: '1rem' }}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    style={{
                      fontSize: '1rem'
                    }}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}
        </List>
      )
    }
    else {
      return (
        <List className={[classes.list, cls.sideBarContainer].join(" ")}>
          {console.log(routes, "routes")}
          {routes.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  disabled={
                    getDisbaledStatus(prop.path)
                  }
                  key={key}
                  onClick={() => {
                    if (
                      prop.path == "/FLNHome" ||
                      prop.path == "/FLNStudentList" ||
                      prop.path == "/FLNTeacherList" ||
                      prop.path == "/FLNTransferTeacher" ||
                      prop.path == "/StudentView" ||
                      prop.path == "/FLNAnylsis" ||
                      prop.path == "/LOAnylsis"
                    ) {
                      setRouteFlag(true);
                      setWelcomeFlag(false);
                      setSMCFlag(false);
                    } else if (prop.path === "/dashboard") {
                      setRouteFlag(false);
                      setWelcomeFlag(true);
                      setSMCFlag(false);
                    } else if (prop.path == "/SMCHome") {
                      setSMCFlag(true);
                      setRouteFlag(false);
                      setWelcomeFlag(false);
                    } else {
                      setRouteFlag(false);
                    }
                    console.log(prop.path, "path");
                    if (prop.path == "/logout") {
                      localStorage.clear();
                      history.replace("/");
                      return;
                    }
                    history.push(prop.layout + prop.path);
                    return;
                  }}
                  button
                  // className={classes.itemLink + listItemClasses + cls.navItem}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}
        </List>
      );
    }
  };

  const getDisbaledStatus = (data) => {
    let flag;
    if (
      data == "/FLNStudentList" ||
      data == "/FLNTeacherList" ||
      data == "/FLNTransferTeacher" ||
      data == "/StudentView"
    ) {
      if (school.hasOwnProperty("school_name")) {
        flag = false;
      } else {
        flag = true;
      }
    } else {
      flag = false;
    }
    console.log(school, "dataschool");
    return flag;
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div
            className={[classes.sidebarWrapper, cls.sideBarContainer].join(" ")}
          >
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {userData.user_type === null ? 'Loding...' : renderMenuItems()}
          </div>
          {image !== undefined ? (
            <div
              style={{
                backgroundImage: "url(" + image + ")",
              }}
              className={classes.background}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <div>
          <Drawer
            anchor={props.rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerPaperRTL]: props.rtlActive,
              }),
            }}
          >
            {brand}
            <div style={{ width: '265px', overflowX: 'hidden' }} className={classes.sidebarWrapper}>{userData.user_type === null ? 'Loding...' : renderMenuItems()}</div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
          {console.log(school, "school454")}
          {userData.user_type == "ADMINISTRATIVE" ? (
            <div style={{ backgroundColor: "#414FB5", height: "60px" }} hidden>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "15%",
                    borderRadius: "15px",
                    textAlign: "center",
                    marginLeft: "15%",
                    marginTop: "15px",
                  }}
                >
                  {school?.student_district}
                </div>

                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "20%",
                    borderRadius: "15px",
                    textAlign: "center",
                    marginLeft: "15%",
                    marginTop: "15px",
                  }}
                >
                  {school?.school_name?.length > 35
                    ? `${school?.school_name.substr(0, 35)}..`
                    : school?.school_name}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: "#414FB5", height: "60px" }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: "17em",
                  justifyContent: "space-around",
                  padding: "10px",
                }}
              >
                <Tooltip title={school?.school_name} placement="bottom">
                  <div
                    className={cls.titleContainer}
                    onClick={() => {
                      navigator.clipboard.writeText(school?.school_name)
                      handleClick();
                    }}
                  >
                    {school?.school_name?.length > 25
                      ? `${school?.school_name.substr(0, 25)}...`
                      : school?.school_name}
                  </div>
                </Tooltip>
                <div
                  className={cls.titleContainer}
                  onClick={() => {
                    navigator.clipboard.writeText(school?.district)
                    handleClick();
                  }}
                >
                  {school?.district}
                </div>
                <div
                  className={cls.titleContainer}
                  onClick={() => {
                    navigator.clipboard.writeText(school?.block)
                    handleClick();
                  }}
                >
                  {school?.block}
                </div>
                <div
                  className={cls.titleContainer}
                  onClick={() => {
                    navigator.clipboard.writeText(school?.u_dise)
                    handleClick();
                  }}
                >
                  UDISE: {school?.u_dise}
                </div>
              </div>
            </div>
          )}
        </div>
      </Hidden>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={handleClose} severity="success">
          Copied ✔
        </Alert>
      </Snackbar>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};