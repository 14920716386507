//default imports
import React, { useState } from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import {
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import {
  Box,
  Button,
  Input,
  Modal,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import home from "assets/img/SMCHome.jpg";
import {
  Add,
  AddCircleRounded,
  ArrowBack,
  ArrowForward,
  Cancel,
  DeleteOutline,
  Edit,
  Forward,
  Save,
  Search,
  SearchRounded,
} from "@material-ui/icons";
import HMService from "service/HMService";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Snackbar from "components/Snackbar/Snackbar";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
};

const classes = styles;
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    studnetList: [],
    flag: false,
    school: JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool")).school_id
      : JSON.parse(localStorage.getItem("AMdetails"))
      ? JSON.parse(localStorage.getItem("AMdetails")).school_id
      : null,
    selectedClass: null,
    selectedMedium: null,
    editView: false,
    editIndex: null,
    deleteActivity: false,
    deleteID: null,
    studnetName: "",
    studnetSRLId: "",
    singleMedium: null,
    singleStandard: null,
    singleName: "",
    singleId: null,
    gender: null,
    offsetPage: 0,
    totalStudent: null,
    EditStudnet: false,
    firstName: null,
    MiddleName: null,
    LastName: null,
    SingleMiddleName: "",
    SingleLastName: "",
    flags: false,
    snackBarFlag: false,
    message: null,
    searchName: "",
    filteredStudents: "",
  };

  componentDidMount() {
    this.getStudent();
    this.getMedium();
  }

  getStudent = () => {
    let url = this.state.searchFlag
      ? `/fln_student/?school=${this.state.school}&&offset=${this.state.offsetPage}&&medium=${this.state.selectedMedium}&&student_class=${this.state.selectedClass}`
      : `/fln_student/?school=${this.state.school}&&offset=${this.state.offsetPage}`;
    getHMService()
      .getStudentListDetails(url)
      .then((res) => {
        console.log(res, "<----res");
        this.setState({
          studnetList: res.data.results,
          flag: true,
          totalStudent: res.data.count,
          snackBarFlag: false,
        });
      });
  };
  nextPage = () => {
    let data = this.state.offsetPage + 10;
    if (data > this.state.totalStudent) {
      return;
    } else {
      this.setState(
        {
          offsetPage: data,
        },
        () => {
          this.getStudent();
        }
      );
    }
  };

  BackPage = () => {
    let data =
      this.state.offsetPage > 0
        ? this.state.offsetPage - 10
        : this.state.offsetPage;

    this.setState(
      {
        offsetPage: data,
      },
      () => {
        this.getStudent();
      }
    );
  };

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
          });
        }
      });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  deleteStudent = () => {
    getHMService()
      .deleteStudent(this.state.school, this.state.deleteID)
      .then((res) => {
        console.log(res, "res");
        this.getStudent();
      });
  };
  updateStudentList = () => {
    let data = [...this.state.studnetList];
    console.log(this.state.studnetName, "studnetName");
    if (this.state.firstName.length > 0) {
      data[this.state.editIndex].first_name = this.state.firstName;
    }
    if (this.state.MiddleName.length > 0) {
      data[this.state.editIndex].middle_name = this.state.MiddleName;
    }
    if (this.state.LastName.length > 0) {
      data[this.state.editIndex].last_name = this.state.LastName;
    }
    if (this.state.studnetSRLId > 0) {
      data[this.state.editIndex].saral_id = this.state.studnetSRLId;
    }
    this.setState({
      studnetSRLId: "",
      studnetName: "",
    });
    console.log(data[this.state.editIndex], "data[this.state.editIndex]");
    getHMService()
      .editStudent(
        data[this.state.editIndex].student_id,
        data[this.state.editIndex].school,
        data[this.state.editIndex]
      )
      .then((res) => {
        console.log(res, "Rs");
        if (res.status == 200) {
          this.setState(
            {
              editView: false,
              EditStudnet: false,
            },
            () => {
              this.getStudent();
            }
          );
        }
      });
    console.log(data, "data");
  };

  createSingleStudent = () => {
    console.log(
      this.state.singleMedium === null &&
        this.state.studnetSRLId === "" &&
        this.state.singleName === "" &&
        this.state.SingleLastName === "" &&
        this.state.singleStandard === null &&
        this.state.gender === null &&
        this.state.SingleMiddleName === "",
      "validate"
    );
    {
    }
    console.log(this.state.singleName == null, "single");
    if (
      this.state.singleMedium === null &&
      this.state.studnetSRLId === "" &&
      this.state.singleName === "" &&
      this.state.SingleLastName === "" &&
      this.state.singleStandard === null &&
      this.state.gender === null &&
      this.state.SingleMiddleName === ""
    ) {
      this.setState({ flags: true });
    } else {
      let obj = {
        medium: this.state.singleMedium,
        school: this.state.school,
        saral_id: this.state.studnetSRLId,
        first_name: this.state.singleName,
        middle_name: this.state.SingleMiddleName,
        last_name: this.state.SingleLastName,
        student_class: this.state.singleStandard,
        gender: this.state.gender,
      };
      console.log(obj, "obj");
      // for (var key in obj) {
      //   if (obj[key] === "") {
      //     console.log(key + " is blank. Deleting it");
      //     this.setState({ flags: true });
      //     return;
      //   } else {
      //     this.setState({ flags: false });
      //   }
      // }
      // console.log(this.state.flags, "flags");
      // if (!this.state.flags) {
      getHMService()
        .createFLNStudnet(obj)
        .then((res) => {
          console.log(res, "res");
          if (res) {
            this.setState(
              {
                singleName: "",
                studnetSRLId: "",
                snackModal: false,
                flags: true,
                snackBarFlag: true,
                message: "Successfully Done",
              },
              () => {
                this.getStudent();
              }
            );
          }
        })
        .catch((e) => {
          {
            console.log(e.response.data, "response");
            let data = e.response.data.saral_id
              ? e.response.data.saral_id
              : e.response.data.gender
              ? e.response.data.gender
              : e.response.data.last_name
              ? e.response.data.last_name
              : e.response.data.middle_name
              ? e.response.data.middle_name
              : e.response.data.student_class
              ? e.response.data.student_class
              : e.response.data.first_name
              ? e.response.data.first_name
              : e.response.data.middle_name
              ? e.response.data.middle_name
              : "Error";

            this.setState({
              snackBarFlag: true,
              message: data,
            });
            console.log(e, "e");
          }
        });
    }
  };

  provideName = (fname, mname, lname) => {
    let name = `${fname} ${mname} ${lname}`;
    console.log(name, "fullName");
    const fullName = name.length > 18 ? `${name.substr(0, 18)}..` : name;
    return fullName;
  };

  handleSearchName = (event) => {
    this.setState({ searchName: event.target.value });
   let url = `/fln_student/?school=${this.state.school}&&search=${event.target.value}`;
   getHMService()
    .getStudentListDetails(url)
    .then((res) => {
      this.setState({
        studnetList: res.data.results,
        flag: true,
        totalStudent: res.data.count,
        snackBarFlag: false,
      });
    });
  };

  render() {
    let { stdWiseGraph } = this.state;
    return (
      <>
        <Snackbar
          autoHideDuration={4000}
          color={"success"}
          close={false}
          open={this.state.message}
          message={this.state.message}
          place="br"
          rtlActive={true}
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
        />
        <AlertDialog
          open={this.state.deleteActivity}
          title="Delete?"
          text="Do you want to delete this field?"
          onCancel={() => this.setState({ deleteActivity: false })}
          onConfirm={() => {
            this.deleteStudent();
            this.setState({ deleteActivity: false });
          }}
        />
        <Modal
          open={this.state.snackModal}
          onClose={() => {
            this.setState({
              snackModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{
              maxHeight: 400,
              overflow: "auto",
              borderRadius: "10px",
              overflow: "hidden",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 900,
                    }}
                  >
                    Add Student
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      marginTop: "2rem",
                    }}
                  >
                    Name:{" "}
                  </Typography>
                  <TextField
                    variant="standard"
                    style={{
                      color: "#415EB6",
                    }}
                    type="text"
                    label="First Name"
                    // className={classes.committeeCategoryInput}
                    onChange={(e) =>
                      this.setState({
                        singleName: e.target.value,
                      })
                    }
                  />

                  <TextField
                    style={{
                      // background: "#DFE4E8",
                      color: "#415EB6",
                    }}
                    type="text"
                    label="Middle Name"
                    // className={classes.committeeCategoryInput}
                    onChange={(e) =>
                      this.setState({
                        SingleMiddleName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    style={{
                      // background: "#DFE4E8",
                      color: "#415EB6",
                    }}
                    type="text"
                    label="Last Name"
                    // className={classes.committeeCategoryInput}
                    onChange={(e) =>
                      this.setState({
                        SingleLastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <div>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Medium
                      </InputLabel>
                      <Select
                        variant="standard"
                        style={{
                          color: "#415EB6",
                          border: 0,
                          // backgroundColor: "#DFE4E8",
                          width: "200px",
                          height: "46px",
                          marginRight: "20px",
                        }}
                        label="Medium"
                        // className={classes.committeeCategoryInput}
                        value={this.state.singleMedium}
                        onChange={(e) =>
                          this.setState(
                            {
                              singleMedium: e.target.value,
                            },
                            () => {
                              this.getAllstandards();
                            }
                          )
                        }
                      >
                        {this.state.mediumList?.map((res) => {
                          return (
                            <MenuItem
                              value={res.medium_id}
                              className={classes.committeeCategoryInput}
                              key={res.medium_name}
                            >
                              {res.medium_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Class
                      </InputLabel>
                      <Select
                        variant="standard"
                        style={{
                          color: "#415EB6",
                          border: 0,
                          // backgroundColor: "#DFE4E8",
                          width: "200px",
                          height: "46px",
                          marginRight: "20px",
                        }}
                        className={classes.committeeCategoryInput}
                        value={this.state.singleStandard}
                        onChange={(e) =>
                          this.setState({
                            singleStandard: e.target.value,
                          })
                        }
                      >
                        {this.state.classList?.map((res) => {
                          return (
                            <MenuItem
                              value={res.class_id}
                              className={classes.committeeCategoryInput}
                              key={res.class_name}
                            >
                              {res.class_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        variant="standard"
                        style={{
                          color: "#415EB6",
                          border: 0,
                          // backgroundColor: "#DFE4E8",
                          width: "200px",
                          height: "46px",
                          marginRight: "20px",
                        }}
                        className={classes.committeeCategoryInput}
                        value={this.state.gender}
                        onChange={(e) =>
                          this.setState({
                            gender: e.target.value,
                          })
                        }
                      >
                        <MenuItem
                          value="MALE"
                          className={classes.committeeCategoryInput}
                          key="MALE"
                        >
                          MALE
                        </MenuItem>
                        <MenuItem
                          value="FEMALE"
                          className={classes.committeeCategoryInput}
                          key="FEMALE"
                        >
                          FEMALE
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      marginTop: "2rem",
                    }}
                  >
                    Saral ID:{" "}
                  </Typography>
                  <TextField
                    variant="standard"
                    style={{
                      // background: "#DFE4E8",
                      color: "#415EB6",
                      maxWidth: "200px",
                    }}
                    type="number"
                    label="Unique ID"
                    onChange={(e) =>
                      this.setState({
                        studnetSRLId: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {this.state.flags ? (
                <div style={{ color: "red", marginTop: "5px" }}>
                  *Fill all details
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    this.setState({
                      snackModal: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={() => {
                    this.createSingleStudent();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.EditStudnet}
          onClose={() => {
            this.setState({
              EditStudnet: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{ maxHeight: 400, overflow: "auto", borderRadius: "10px" }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              p: 4,
            }}
          >
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Edit Student
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    marginRight: "1rem",
                  }}
                >
                  Name:{" "}
                </Typography>
                <TextField
                  label="First Name"
                  value={this.state.firstName}
                  onChange={(e) =>
                    this.setState({
                      firstName: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Middle Name"
                  value={this.state.MiddleName}
                  onChange={(e) =>
                    this.setState({
                      MiddleName: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Last Name"
                  value={this.state.LastName}
                  onChange={(e) =>
                    this.setState({
                      LastName: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Saral ID:{" "}
                </Typography>
                <TextField
                  type="number"
                  label="Unique ID"
                  value={this.state.studnetSRLId}
                  className={classes.committeeAttendanceInput}
                  onChange={(e) =>
                    this.setState({
                      studnetSRLId: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "1rem",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={() => {
                  this.setState({
                    EditStudnet: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                }}
                onClick={() => {
                  this.updateStudentList();
                }}
              >
                Edit
              </Button>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            marginTop: "15px",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
            color: "rgb(65, 94, 182)",
          }}
        >
          <div style={{ fontSize: "20px", padding: "10px" }}>
            महत्वाच्या सूचना:{""}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            १. तुम्ही अपलोड केलेली विद्यार्थ्यांची यादी इथे पाहता येईल व त्यात
            बदल करता येतील.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              marginBottom: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "18rem",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                size="large"
                value={this.state.searchName}
                onChange={this.handleSearchName}
                style={{
                  backgroundColor: "white",
                  width: "50%",
                  borderRadius: "5px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="नाव / Saral ID नुसार सर्च करा..."
              />

              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  this.setState({ snackModal: true });
                }}
                style={{
                  backgroundColor: "#3F50B5",
                  color: "white",
                }}
                endIcon={<Add />}
              >
                Add Student
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginLeft: "5rem",
              }}
            >
              <div>{""}</div>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                विद्यार्थ्याचेे पूर्ण नाव
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                Saral ID
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                माध्यम
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                इयत्ता
              </p>
            </div>
            <div>
              {console.log(this.state.studnetList)}
              {this.state.flag &&
                this.state.studnetList?.map(
                  (res, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "1rem",
                          gap: "1rem",
                          minWidth: "5%",
                          height: "46px",
                          marginBottom: "1rem",
                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          boxShadow:
                            "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.1)",
                        }}
                        className={classes.committeeContainer}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "1rem",
                            color: "#958989",
                          }}
                        >
                          {" "}
                          {index + 1}
                        </div>

                        {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#415EB6",
                              marginLeft: "3rem",
                              marginBottom: "1rem",
                            }}
                            placeholder="Name"
                            value={this.state.studnetName || res.first_name}
                            // className={classes.committeeAttendanceInput}
                            onChange={(e) =>
                              this.setState({
                                studnetName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#415EB6",
                              border: "0px",
                              // backgroundColor: "#DFE4E8",
                              minWidth: "17%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "3rem",
                              marginBottom: "1rem",
                              textAlign: "center",
                            }}
                          >
                            {this.provideName(
                              res.first_name,
                              res.middle_name,
                              res.last_name
                            )}
                          </div>
                        )}

                        {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              // backgroundColor: "#DFE4E8",
                              minWidth: "7%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "3rem",
                              textAlign: "center",
                            }}
                            type="number"
                            placeholder="Name"
                            value={this.state.studnetSRLId || res.saral_id}
                            className={classes.committeeAttendanceInput}
                            onChange={(e) =>
                              this.setState({
                                studnetSRLId: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#415EB6",
                              border: "0px",
                              // backgroundColor: "#DFE4E8",
                              minWidth: "20%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "3rem",
                              marginBottom: "1rem",
                              textAlign: "center",
                              padding: "10px",
                            }}
                          >
                            {res.saral_id}
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#415EB6",
                            // backgroundColor: "#DFE4E8",
                            minWidth: "17%",
                            height: "46px",
                            marginLeft: "3rem",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          {res.medium_name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#415EB6",
                            // backgroundColor: "#DFE4E8",
                            minWidth: "7%",
                            height: "46px",
                            marginLeft: "3rem",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          {res.class_name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            marginBottom: "1rem",
                            gap: "0.5rem",
                          }}
                        >
                          <DeleteOutline
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({
                                deleteID: res.student_id,
                                deleteActivity: true,
                              });
                            }}
                          />
                          {!this.state.editView ? (
                            <Edit
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  // editView: true,
                                  editIndex: index,
                                  EditStudnet: true,
                                  firstName: res.first_name,
                                  MiddleName: res.middle_name,
                                  LastName: res.last_name,
                                  studnetSRLId: res.saral_id,
                                })
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "center",
            flexDirection: "row",
          }}
        >
          <Button
            style={{ justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              this.BackPage();
            }}
          >
            <ArrowBack />
          </Button>
          <div
            style={{
              alignSelf: "center",
              alignContent: "center",
              justifyItems: "center",
              display: "flex",
            }}
          >
            Showing Page {this.state.offsetPage / 10 + 1} out of{" "}
            {Math.floor(this.state.totalStudent / 10) + 1}
          </div>
          <Button
            onClick={() => {
              this.nextPage();
            }}
          >
            <ArrowForward />
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            visibility: "hidden",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "50px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#1ACA2B",
              color: "#fff",
              padding: "10px",
            }}
          >
            माहिती डाउनलोड करा
          </Button>
        </div>
      </>
    );
  }
}
