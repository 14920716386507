import React from "react";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getSuperAdminService,getHMService } from "service/service";
import {
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Switch,
} from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import http from "common/axios";
import { getContentWriterService } from "service/service";
import MaterialTable from "material-table";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar";
import DatePicker from "react-date-picker";
import * as moment from "moment";

const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default class FCMNotification extends React.Component {
  state = {
    baseAnalytics: {},
    studentList: [],
    column: [],
    queryObject: { searchText: "" },
    checked: false,
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    userList: [],
    state: {
      helperText: null,
      value: "",
    },
    board: "",
    district: {
      helperText: null,
      value: "",
    },
    currentTabIndex: 0,
    districts: [],
    blocks: [],
    block: {
      helperText: null,
      value: "",
    },
    schools: [],
    school: "",
    classStandrd: "",
    AllmediumList: [],
    selectedMedium: null,
    selectedStandard: "",
    classList: [],
    selectSubject: "",
    subList: [],
    openDetailDialog: false,
    title: "",
    message: "",
    URL: "",
    bulkSend: false,
    MediumList: [],
    medium: "",
    snackMsg: "",
    scheduledDate: null,
    scheduledHour: null,
    scheduledMinutes: null,
    date: null,
    imageUrl: "",
    showWarning: false,
    scheduleList:null
  };
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  inputRef = React.createRef();


  componentDidMount() {
    this.getData();
    this.getScheduledData()
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      const stateList = stateRes.response;
      this.setState(
        {
          stateList: stateList,
          chapter,
          // state: {
          //   value:
          //     stateList.length > 0
          //       ? stateList[0].state_id
          //       : null,
          //   helperText: null,
          // },
          stateForGraph: stateList.length > 0 ? stateList[0].state_id : null,
        },
        () => {
          // this.getBoardByState();
          if (this.state.currentTabIndex == 0) {
            this.getDistrictsByState(this.state.stateForGraph);
          }
        }
      );
    } else {
      this.setState({
        stateList: [],
        state: {
          helperText: null,
          value: null,
        },
      });
    }
  }

  renderStateMenu(type) {
    let { stateList, districts, blocks, schools, classList } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "district"
        ? districts
        : type == "class"
        ? classList
        : type == "block"
        ? blocks
        : schools;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "district"
          ? state.student_district_id
          : type == "class"
          ? classList
          : type == "block"
          ? state.student_block_id
          : state.school_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "district"
          ? state.district_name
          : type == "block"
          ? state.block_name
          : type == "class"
          ? classList
          : type == "school"
          ? state.school_name
          : "";
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  getDistrictsByState = (state_id) => {
    getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              districts: response.response,
              // district: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_district_id
              //       : null,
              //   helperText: null,
              // },
              // districtForGraph: response.response.length > 0
              //   ? response.response[0].student_district_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                if (this.state.districtForGraph) {
                  //   this.getStudentDataForChart();
                }
                //this.getStudentBlock(this.state.districtForGraph)
              }
            }
          );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      });
  };

  getStudentBlock = (district_id) => {
    getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id ? district_id : 1,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
              // block: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_block_id
              //       : null,
              //   helperText: null,
              // },
              schools: [],
              // blockForGraph: response.response.length > 0
              //   ? response.response[0].student_block_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                // this.getStudentDataForChart();
              }
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = (block, searchtext) => {
    const param = {
      student_block_id: block,
      searchText: searchtext ? searchtext : "",
    };
    getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        console.log(result, "res");
        result = result.data;
        console.log(result, "school");
        if (result && result.status == 200) {
          this.setState({
            schools: result.response,
            filteredSchools: result.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
    // getSuperAdminService()
    //   .getSchoolsForBlock(param)
    //   .then((result) => {
    //     result = result.data;
    //     console.log(result, "school");
    //     if (result && result.status == 200) {
    //       this.setState({
    //         schools: result.response,
    //         filteredSchools: result.response,
    //       });
    //     } else {
    //       this.setState({
    //         schools: [],
    //         filteredSchools: [],
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       schools: [],
    //       filteredSchools: [],
    //     });
    //   });
  };

  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  getAllstandards = async (value) => {
    let apidata3 = {
      board_id: this.state.selectedMedium,
      district_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };
  getAllSubject = () => {
    let param = {
      class_id: this.state.selectedStandard,
      medium_id: 3,
    };

    getContentWriterService()
      .getSubjectByClassMed(param)
      .then((res) => {
        this.setState({
          subList: res.data.response,
        });
      });
  };

  getMedium = async () => {
    let apidata = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
      apidata
    );
    console.log(CWDistrictRes.data.response);
    this.setState({
      AllmediumList: CWDistrictRes.data.response,
    });
  };
  getAnalytics = (id) => {
    console.log(this.state.state, this.state.district, this.state.block);
  };

  sendNotification = () => {
    const {
      URL,
      message,
      title,
      state,
      district,
      block,
      selectedMedium,
      selectedStandard,
      selectSubject,
      AutoSchool_id,
    } = this.state;
    console.log(
      state.value,
      district.value,
      block.value,
      selectedMedium,
      selectedStandard,
      selectSubject,
      URL,
      message,
      title
    );
    let param = {};

    console.log("tell me the states", this.state);

    if (!this.state.bulkSend) {
      if (this.state.title && this.state.message) {
        if (this.state?.title) {
          param.heading = this.state.title;
        }
        if (this.state?.message) {
          param.content = this.state.message;
        }
        if (this.state?.URL) {
          param.url = this.state.URL;
        }
        if (this.state?.imageUrl) {
          param.image_url = this.state.imageUrl;
        }
        if (this.state?.state?.value) {
          param.student_state_id = this.state.state.value;
        }
        if (this.state?.block?.value) {
          param.student_block_id = this.state.block.value;
        }
        if (this.state?.district?.value) {
          param.student_district_id = this.state.district.value;
        }
        if (this.state.school) {
          param.school_id = this.state.school;
        }

        if (this.state?.medium) {
          param.medium_id = this.state.medium;
        }
        if (this.state?.selectedStandard) {
          param.class_id = this.state?.selectedStandard;
        }
        if (this.state.selectedMedium) {
          param.district_id = this.state.selectedMedium;
        }

        if (
          this.state.scheduledDate &&
          this.state.scheduledHour &&
          this.state.scheduledMinutes
        ) {
          let timeToSend = `${this.state.scheduledHour}:${this.state.scheduledMinutes}:00`;
          param.schedule_notification = true;
          param.run_date = this.state.scheduledDate;
          param.run_time = timeToSend;
        }

        console.log(param, "params");

        getSuperAdminService()
          .sendNotificationByFCM(param)
          .then((Res) => {
            console.log(Res, "result");
            this.setState({
              title: "",
              snackMsg: "Sent successfuly",
              message: "",
              URL: "",
              imageUrl: "",
              showWarning: false,
            },()=>{
              this.inputRef.current.value = null;
            });
          })
          .catch((err) => {
            console.log("Err", err?.response);
            if (err?.response?.status == 400) {
              this.setState({
                snackMsg: err?.response?.data?.response,
              });
            }
          });
      } else {
        this.setState({
          showWarning: true,
        });
      }
    } else {
      if (this.state.title && this.state.message) {
        param = {
          heading: title,
          content: message,
          send_to_all: this.state.bulkSend,
        };
        if (this.state?.URL) {
          param.url = this.state.URL;
        }
        if (this.state?.imageUrl) {
          param.image_url = this.state.imageUrl;
        }

        if (
          this.state.scheduledDate &&
          this.state.scheduledHour &&
          this.state.scheduledMinutes
        ) {
          let timeToSend = `${this.state.scheduledHour}:${this.state.scheduledMinutes}:00`;
          param.schedule_notification = true;
          param.run_date = this.state.scheduledDate;
          param.run_time = timeToSend;
        }

        console.log("param", param);

        getSuperAdminService()
          .sendNotificationByFCM(param)
          .then((Res) => {
            console.log(Res, "res");
            this.setState({
              title: "",
              snackMsg: "Sent successfuly",
              message: "",
              URL: "",
              imageUrl: "",
              showWarning: false,
            },()=>{
              this.inputRef.current.value = null;
            });
          })
          .catch((err) => {
            console.log("Err", err?.response);

            if (err?.response?.status == 400) {
              this.setState({
                snackMsg: err?.response?.data?.response,
              });
            }
          });
      } else {
        this.setState({
          showWarning: true,
        });
      }
    }
  };

  onImageUpload =async (event)=>{
    const fd = new FormData();
    fd.append(
      "image",
      event.currentTarget ? event.currentTarget.files[0] : event
    );
    fd.append("school_id", this.state.HMSchool?.school_id);
    console.log(fd, "fd");
    let mediaRes = await getHMService().uploadSchoolImages(fd);
    console.log(mediaRes.data.file, "media"); 
      this.setState({
        imageUrl:mediaRes.data.file
      })

  }

  getScheduledData = () =>{

    getSuperAdminService().getScheduledNotification().
    then((res)=>{
      console.log(res,"res")
      if(res.data)
      {
        this.setState({
          scheduleList : res.data.response
        })
      }

    })

  }
  render() {
    console.log("states are", this.state);

    const timeHour = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ];
    const timeMinutes = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ];

    return (
      <>
        <Snackbar
          autoHideDuration={3000}
          message={this.state.snackMsg}
          color="success"
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              snackMsg: "",
            })
          }
          rtlActive={true}
          open={this.state.snackMsg ? true : false}
        ></Snackbar>
        <Card>
          <CardHeader color="primary">
            <h4 className={styles.cardTitleWhite}>Notifications</h4>
          </CardHeader>
          <CardContent>
            <div className="row">
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select State
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={this.state.state.value}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            state: { value: e.target.value, helperText: null },
                          },
                          () => {
                            this.getMedium();
                            this.getDistrictsByState(this.state.state.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select District
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.district.value}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            district: {
                              value: e.target.value,
                              helperText: null,
                            },
                            block: {
                              helperText: null,
                              value: "",
                            },
                            school: "",
                          },
                          () => {
                            this.getMedium();
                            this.getStudentBlock(this.state.district.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("district")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select block
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.block.value}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            block: {
                              value: e.target.value,
                              helperText: null,
                            },
                          },
                          () => {
                            this.getSchools(this.state.block.value);
                            this.getMedium();
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("block")}
                      {console.log(this.state.block)}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Syllabus Set
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedMedium}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedMedium: e.target.value,
                          },
                          () => {
                            this.getAllMedium();
                          }
                        );
                      }}
                    >
                      {this.state.AllmediumList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.district_id}
                            key={stdClass.district_id}
                          >
                            {stdClass.district_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select Medium
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.medium}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            medium: e.target.value,
                          },
                          () => {
                            this.getAllstandards();
                          }
                        );
                      }}
                    >
                      {this.state.MediumList.map((med) => {
                        return (
                          <MenuItem value={med.medium_id} key={med.medium_id}>
                            {med.medium_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Standard
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedStandard}
                      disabled={this.state.bulkSend}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedStandard: e.target.value,
                          },
                          () => {
                            this.getAllSubject();
                          }
                        );
                      }}
                    >
                      {this.state.classList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.class_id}
                            key={stdClass.class_id}
                          >
                            {stdClass.class_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <Autocomplete
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      style={{ width: "12em" }}
                      disabled={this.state.bulkSend}
                      open={this.state.isSetAuto}
                      disableClearable
                      onOpen={() => {
                        this.setOpen(true);
                      }}
                      onClose={() => {
                        this.setOpen(false);
                      }}
                      onChange={(event, value) => {
                        this.setState({
                          schoolName: event.target.value,
                          AutoSchool_id: value.school_id,
                        });
                        console.log("e val", event.target.value);
                        this.onChangeHandle(value.school_id, value);
                      }}
                      getOptionLabel={(option) => option.school_name}
                      options={this.state.filteredSchools}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search School"
                          style={{ marginTop: "1em", width: "12em" }}
                          onChange={(ev) => {
                            console.log(ev.target.value, "ev");

                            this.getSchools(
                              this.state.block.value,
                              ev.target.value
                            );
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {console.log(this.state.filteredSchools)}
                    {console.log(this.state.AutoSchool_id, "id")}
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <FormControl style={styles.dropdown}>
                  <div>
                    <DatePicker
                      format="y-MM-dd"
                      value={this.state.date}
                      minDate={new Date()}
                      onChange={(e) => {
                        if (e != null) {
                          let Stringdate = new Date(e).toISOString();
                          let filterDate = Stringdate.replace("T", " ");
                          let data = filterDate.replace("Z", "0");
                          console.log(moment(e), "string");
                          this.setState({
                            date: e,
                            scheduledDate: moment(e).format("DD/MM/YY"),
                          });
                        } else {
                          this.setState({
                            date: e,
                            scheduledDate: null,
                          });
                        }
                      }}
                    />
                    <p style={{ marginTop: "10px" }}>Select Date</p>
                  </div>
                </FormControl>
              </div>
              <div className="col-2">
                <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select Hour
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.scheduledHour}
                    onChange={(e) => {
                      console.log(e.target.value, "eee");
                      this.setState({
                        scheduledHour: e.target.value,
                      });
                    }}
                  >
                    {timeHour.map((i) => {
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-2">
                <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select Minutes
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.scheduledMinutes}
                    onChange={(e) => {
                      console.log(e.target.value, "eee");
                      this.setState({
                        scheduledMinutes: e.target.value,
                      });
                    }}
                  >
                    {timeMinutes.map((i) => {
                      return <MenuItem value={i}>{i}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.bulkSend}
                  color="primary"
                  onChange={() => {
                    this.setState({
                      bulkSend: !this.state.bulkSend,
                    });
                  }}
                  name="Send to All"
                />
              }
              label="Send to All"
            />
          </CardFooter>
        </Card>
        <Card>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2%",
              }}
            >
              <FormControl>
                <TextField
                  style={{ width: "50em" }}
                  variant="outlined"
                  label="Notification Title"
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.title}
                  onChange={(e) => {
                    console.log(e.target.value, "E");
                    this.setState({
                      title: e.target.value,
                    });
                  }}
                ></TextField>
              </FormControl>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2%",
                height: "5em",
              }}
            >
              <FormControl>
                <TextField
                  style={{ width: "50em", height: "3em" }}
                  variant="outlined"
                  label="Message"
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.message}
                  onChange={(e) => {
                    console.log(e.target.value, "E");
                    this.setState({
                      message: e.target.value,
                    });
                  }}
                ></TextField>
              </FormControl>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2%",
              }}
            >
              <FormControl>
                <TextField
                  style={{ width: "50em" }}
                  variant="outlined"
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  label="URL"
                  value={this.state.URL}
                  onChange={(e) => {
                    console.log(e.target.value, "E");
                    this.setState({
                      URL: e.target.value,
                    });
                  }}
                ></TextField>
              </FormControl>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2%",
              }}
            >
              <FormControl>
                <TextField
                  style={{ width: "50em" }}
                  variant="outlined"
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  label="Image URL"
                  value={this.state.imageUrl}
                  onChange={(e) => {
                    console.log(e.target.value, "E");
                    this.setState({
                      imageUrl: e.target.value,
                    });
                  }}
                ></TextField>
              </FormControl>
            </div>
          </div>
          <p style={{
            marginTop:"10px",
            marginLeft:"51%",
            marginBottom:"10px",
          }} >OR</p>  
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
            <p>
              </p>
              <div> 
                <input
                  ref={this.inputRef}
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    this.onImageUpload(e);
                  }}
                  style={{ width: 260, height: 38 }}
                />
              </div>
            </div>
          </div>

          {this.state.showWarning &&
          (this.state.title == "" || this.state.message == "") ? (
            <div style={{ width: "50em" }}>
              <p style={{ color: "red", marginTop: "10px" }}>
                *Enter Correct Values
              </p>
            </div>
          ) : null}
          <CardFooter>
            <Button
              style={{
                marginLeft: "50%",
                color: "#fff",
                backgroundImage: "linear-gradient(60deg, #3f50b5, #8e24aa)",
              }}
              onClick={() => {
                this.sendNotification();
              }}
            >
              Send
            </Button>
          </CardFooter>
        </Card>

        <Card style={{ marginBottom:'75px'}}>

{this.state.scheduleList? <MaterialTable
          columns={[
            { title: "heading", field: "heading" },
            { title: "run_time", field: "run_time" },
            { title: "content", field: "content",  },
            {
              title: "url",
              field: "url",
              
            },
          ]}
          data={
            this.state.scheduleList
          }
          title="Schedule List"
        />:null}
        </Card>
      </>
    );
  }
}
