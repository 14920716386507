"use client";
import React, { useEffect, useState } from 'react';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js"
import Button from "components/CustomButtons/Button.js";
import { getSuperAdminService } from 'service/service';
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, FormHelperText, Input, ListItemText, TextField } from '@material-ui/core';
import Snackbar from "components/Snackbar/Snackbar";
import "react-datepicker/dist/react-datepicker.css";
import { getContentWriterService } from 'service/service';
import httpV2 from 'common/axiosV2';
import { Link, useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function CreateChallenge() {
  const [challengeTitle, setChallengeTitle] = useState('')
  //filter{}
  const [state_id, setState_id] = useState('');
  const [stateList, setStateList] = useState([]);
  const [district_id, setDistrict_id] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [student_block_id, setStudent_block_id] = useState('');
  const [blockList, setBlockList] = useState([]);
  const [syllabus_id, setSyllabus_id] = useState(3);
  // const [syllabus_id, setSyllabus_id] = useState('');
  const [syllabusList, setSyllabusList] = useState([]);
  const [medium_id, setMedium_id] = useState('');
  const [mediumList, setMediumList] = useState([]);
  const [class_id, setClass_id] = useState('');
  const [classList, setClassList] = useState([]);
  const [specialClass_id, setSpecialClass_id] = useState('');
  const [specialClassList, setSpecialClassList] = useState([]);
  //Advance filter
  const [user_type, setUser_type] = useState('');
  const [advanceFilterList, setAdvanceFilterList] = useState([]);
  const [timeframe, setTimeframe] = useState('');
  const [advanceFilterTimeframeList, setAdvanceFilterTimeframeList] = useState([]);
  const [timeframeValue, setTimeframeValue] = useState('');
  const [timeframeValueList, setTimeframeValueList] = useState([]);
  const [advanceFilterPoints, setAdvanceFilterPoints] = useState('');
  const [advanceFilterComparison, setAdvanceFilterComparison] = useState();
  const [advanceFilterComparisonList, setAdvanceFilterComparisonList] = useState([]);
  // school
  const [schoolSearchTxt, setSchoolSearchTxt] = useState('');
  const [schoolPageNo, setSchoolPageNo] = useState(1);
  const [selectedSchools, setSelectedSchools] = useState([]);//school id list
  const [selectedSchoolsObject, setSelectedSchoolsObject] = useState([]);//school id and name object
  const [schoolList, setSchoolList] = useState([]);//school list from Api

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //validity
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [UTCStartDate, setUTCStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [UTCEndDate, setUTCEndDate] = useState('');
  const [dateErrorMsg, setDateErrorMsg] = useState('')
  //reward
  const [rewardPoints, setRewardPoints] = useState('');
  const [rewardDescription, setRewardDescription] = useState('');
  //challenge info{}
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [challengeList, setChallengeList] = useState([]);
  const [selectedSubChallenge, setSelectedSubChallenge] = useState('');
  const [subChallengeList, setSubChallengeList] = useState([]);
  const [subject_id, setSubject_id] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [chapter_id, setChapter_id] = useState('');
  const [chapterList, setChapterList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState('')
  const [selectedTimeframe, setSelectedTimeframe] = useState('')
  const [components, setComponents] = useState([])
  const [AIGenerated, setAIGenerated] = useState('')
  const [comparisonForChallenge, setComparisonForChallenge] = useState('');
  const [comparisonForChallengeList, setComparisonForChallengeList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [unitList, setUnitList] = useState([]);
  const [selectedUnitValue, setSelectedUnitValue] = useState('');
  // challenge_display{}
  const [challengeDisplayImage, setChallengeDisplayImage] = useState('');
  const [challengeDisplayImageURL, setChallengeDisplayImageURL] = useState('');
  const [challengeDisplayImageURLS3, setChallengeDisplayImageURLS3] = useState('');
  const [challengeDisplayImageErrorMsg, setChallengeDisplayImageErrorMsg] = useState(false);
  const [challengeDisplaySubtitle, setChallengeDisplaySubtitle] = useState('');
  const [challengeDisplayDescription, setChallengeDisplayDescription] = useState('');
  // after_success{}
  const [afterSuccessImage, setAfterSuccessImage] = useState('');
  const [afterSuccessImageURL, setAfterSuccessImageURL] = useState('');
  const [afterSuccessImageURLS3, setAfterSuccessImageURLS3] = useState('');
  const [afterSuccessImageErrorMsg, setAfterSuccessImageErrorMsg] = useState(false);
  const [afterSuccessSubtitle, setAfterSuccessSubtitle] = useState('');
  const [afterSuccessDescription, setAfterSuccessDescription] = useState('');
  //extras
  const [challengeConfig, setChallengeConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  //error
  const [schoolError, setSchoolError] = useState(false)
  const [showSchoolList, setShowSchoolList] = useState(false)
  const [showMCQChallengeError, setShowMCQChallengeError] = useState(false)
  //snackbar
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  //disable save 
  const [specialClassExists, setSpecialClassExists] = useState(false)
  const [subjectExists, setSubjectExists] = useState(false)
  const [chapterExists, setChapterExists] = useState(false)
  const [selectedComponentExists, setSelectedComponentExists] = useState(false)
  const [selectedTimeframeExists, setSelectedTimeframeExists] = useState(false)

  const [createChallengePayload, setCreateChallengePayload] = useState({})

  const handleCreateChallengePayload = () => {
    console.log(createChallengePayload, "qwe")

    createChallengePayload["challenge_title"] = challengeTitle;

    if (state_id) {
      createChallengePayload['student_state_id'] = state_id;
    }
    if (district_id) {
      createChallengePayload['student_district_id'] = district_id;
    }
    if (student_block_id) {
      createChallengePayload['student_block_id'] = student_block_id;
    }
    if (syllabus_id) {
      createChallengePayload['district_id'] = syllabus_id;
    }
    if (medium_id) {
      createChallengePayload['medium_id'] = medium_id;
    }
    if (class_id) {
      createChallengePayload['class_id'] = class_id;
    }
    //advance filter
    if (user_type) {
      createChallengePayload['advance_filter'] = {};
    }
    if (user_type) {
      createChallengePayload.advance_filter['user_type'] = user_type;

      if (timeframe) {
        createChallengePayload.advance_filter['time_frame'] = timeframe;
      }
      if (timeframeValue !== '') {
        createChallengePayload.advance_filter['unit_value'] = timeframeValue;
      }
      if (user_type === 'Point') {
        createChallengePayload.advance_filter['unit_key'] = "point";
      } else {
        createChallengePayload.advance_filter['unit_key'] = "time";
      }
      if (advanceFilterPoints !== '') {
        createChallengePayload.advance_filter['unit_value'] = advanceFilterPoints;
      }
    }
    // school
    if (selectedSchools) {
      createChallengePayload['school_id'] = selectedSchools;
    }

    createChallengePayload["start_date"] = UTCStartDate;
    createChallengePayload["end_date"] = UTCEndDate;

    createChallengePayload["reward_points"] = rewardPoints;
    createChallengePayload["reward_message"] = rewardDescription;

    createChallengePayload["challenge_info"] = {};
    // Challenge info
    if (selectedChallenge) {
      createChallengePayload.challenge_info['challenge'] = selectedChallenge;
    }
    if (selectedSubChallenge) {
      createChallengePayload.challenge_info['sub_challenge'] = selectedSubChallenge;
    }
    if (specialClass_id) {
      createChallengePayload.challenge_info['class_id'] = specialClass_id;
    }
    if (subject_id) {
      createChallengePayload.challenge_info['subject_id'] = subject_id;
    }
    if (chapter_id) {
      createChallengePayload.challenge_info['chapter_id'] = chapter_id;
    }
    if (selectedComponent) {
      createChallengePayload.challenge_info['component_type'] = selectedComponent;
    }
    if (selectedTimeframe) {
      createChallengePayload.challenge_info['timeframe'] = selectedTimeframe;
    }
    if (AIGenerated) {
      createChallengePayload.challenge_info['ai_generated'] = AIGenerated;
    }
    if (comparisonForChallenge) {
      createChallengePayload.challenge_info['comparison_operator'] = comparisonForChallenge;
    }
    if (selectedUnit) {
      createChallengePayload.challenge_info['unit_key'] = selectedUnit;
    }
    if (selectedUnitValue) {
      createChallengePayload.challenge_info['unit_value'] = selectedUnitValue;
    }

    createChallengePayload["challenge_display"] = {};
    createChallengePayload.challenge_display['display_image'] = challengeDisplayImageURLS3;
    createChallengePayload.challenge_display['display_sub_title'] = challengeDisplaySubtitle;
    createChallengePayload.challenge_display['display_description'] = challengeDisplayDescription;

    createChallengePayload["after_success"] = {};
    createChallengePayload.after_success['success_image'] = afterSuccessImageURLS3;
    createChallengePayload.after_success['success_sub_title'] = afterSuccessSubtitle;
    createChallengePayload.after_success['success_description'] = afterSuccessDescription;
  }

  const handlePositiveNumberInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const intValue = inputValue === '' ? '' : parseInt(inputValue, 10)
    return (intValue)
  }

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const currentDate = new Date();
    const delayedDate = new Date(currentDate.getTime() - 60000); // 60,000 milliseconds = 1 minute
    const isValidStartDate = new Date(newStartDate) > delayedDate

    if (isValidStartDate) {
      setStartDate(newStartDate);
      setUTCStartDate(new Date(newStartDate).toISOString().slice(0, -5) + 'Z')

      if (endDate !== '' && new Date(endDate) <= new Date(newStartDate)) {
        setEndDate('');
        setDateErrorMsg('End date must be after start date.')
      } else {
        setDateErrorMsg('')
      }
    } else {
      setStartDate('');
      setDateErrorMsg('Start date must be in future.')
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;

    const isValidEndDate = new Date(newEndDate) > new Date(startDate);

    if (isValidEndDate) {
      setEndDate(newEndDate);
      setUTCEndDate(new Date(newEndDate).toISOString().slice(0, -5) + 'Z')
      setDateErrorMsg('')
    } else {
      setEndDate('');
      setDateErrorMsg('End date must be after start date.')
    }
  };

  const disablePublishChallenge = () => {
    if (challengeTitle &&
      state_id &&
      startDate &&
      endDate &&
      rewardPoints &&
      rewardDescription &&
      selectedChallenge &&
      selectedSubChallenge &&
      comparisonForChallenge &&
      selectedUnit &&
      selectedUnitValue &&
      // challengeDisplayImage &&
      challengeDisplayImageURLS3 &&
      challengeDisplaySubtitle &&
      challengeDisplayDescription &&
      // afterSuccessImage &&
      afterSuccessImageURLS3 &&
      afterSuccessSubtitle &&
      afterSuccessDescription
    ) {
      if (specialClassExists && specialClass_id) {
        return (false)
      } else if (subjectExists && subject_id && chapterExists && chapter_id) {
        return (false)
      } else if (subjectExists && subject_id && selectedSubChallenge !== "Chapter Progress" && selectedSubChallenge !== "MCQ Set") {
        return (false)
      } else if (selectedComponentExists && selectedComponent) {
        return (false)
      } else if (selectedTimeframeExists && selectedTimeframe) {
        return (false)
      } else if (selectedSubChallenge === "AI reels total" || selectedSubChallenge === "Teacher genrated total") {
        return (false)
      }
      else {
        return (true)
      }

    } else {
      return (true)
    }
  }

  async function getData() {
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      setStateList(stateRes.response)
    }
  }

  async function getDistrictsByState(state_id) {
    let response = await getSuperAdminService().getStudentDistrict({ student_state_id: state_id, })
    response = response.data;
    if (response && response.status == 200) {
      setDistrictList(response.response)
    }
  }

  async function getStudentBlock(district_id) {
    try {
      let response = await getSuperAdminService().getStudentBlock({ student_district_id: district_id, })
      response = response.data;
      if (response && response.status == 200) {
        setBlockList(response.response)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  async function getSyllabus() {
    let param = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(param);
    CWDistrictRes = CWDistrictRes.data
    if (CWDistrictRes && CWDistrictRes.status == 200) {
      setSyllabusList(CWDistrictRes.response)
    }
  };

  async function getMedium() {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data
    if (mediumRes && mediumRes.status == 200) {
      setMediumList(mediumRes.response)
    }
  };

  async function getClass() {
    let param = {
      district_id: syllabus_id,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(param)
    classRes = classRes.data
    if (classRes && classRes.status == 200) {
      setClassList(classRes.response)
    }
  };

  async function getSpecialClass() {
    let param = {
      district_id: 7,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(param)
    classRes = classRes.data
    if (classRes && classRes.status == 200) {
      setSpecialClassList(classRes.response)
    }
  };

  async function getAllSubject() {
    let param = {
      class_id: class_id,
      medium_id: medium_id,
    };
    let subRes = await getContentWriterService().getSubjectByClassMed(param)
    setSubjectList(subRes.data.response)
  };

  async function getChapterListByChapter() {
    let URL = 'chapters/'
    if (selectedChallenge === "MCQ Set") {
      URL = 'chapters/?mcq_exits=true'
    }
    let chapterListData = await httpV2.get(URL, {
      params: {
        limit: 100,
        offset: 0,
        status: "PUBLISHED",
        subject_id: subject_id,
      },
    });
    chapterListData = chapterListData.data;
    if (chapterListData) {
      setChapterList(chapterListData.results)
      if (chapterListData.results?.length < 1) {
        setShowMCQChallengeError(true)
      } else {
        setShowMCQChallengeError(false)
      }
    } else {
      setChapterList([])
    }
  };

  const getSchoolsForBlockData = async () => {
    const param = {
      student_block_id: student_block_id,
      searchText: schoolSearchTxt,
    };

    try {
      const response = await getSuperAdminService().getSchoolsForBlock(param)
      setSchoolList(response.data.response)
    } catch (error) {
      console.error('Error fetching school list', error)
    }
  }

  const getAdvanceFilter = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterData()
      setAdvanceFilterList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter list', error)
    }
  }

  const getAdvanceFilterTimeframe = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterTimeframeData(user_type)
      setAdvanceFilterTimeframeList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter timeframe list', error)
    }
  }

  const getAdvanceFilterTimeframeValue = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterTimeframeValueData(user_type, timeframe)
      setTimeframeValueList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter timeframe value list', error)
    }
  }

  const getAdvanceFilterComparison = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterComparisonData(user_type, timeframe, timeframeValue)
      setAdvanceFilterComparisonList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter comparison list', error)
    }
  }

  const getChallengeList = async () => {
    try {
      const response = await getSuperAdminService().getChallengeList()
      setChallengeList(response.data.response)
    } catch (error) {
      console.error('Error fetching challenge list', error)
    }
  }

  const getSubChallengeList = async () => {
    try {
      const response = await getSuperAdminService().getSubChallengeListByChallenge(selectedChallenge)
      setSubChallengeList(response.data.response)
    } catch (error) {
      console.error('Error fetching sub challenge list', error)
    }
  }

  const getChallengeConfiguration = async () => {
    try {
      const response = await getSuperAdminService().getChallengeConfig(selectedChallenge, selectedSubChallenge)
      setChallengeConfig(response.data.response)
    } catch (error) {
      console.error('Error fetching challenge configuration', error)
    }
  }

  const uploadChallengeDisplayImageAndGetURL = async () => {
    try {
      const formData = new FormData()
      formData.append('image', challengeDisplayImage)
      const uploadResponse = await getSuperAdminService().getImageURLs3(formData)
      setChallengeDisplayImageURLS3(uploadResponse.data.file)
      setError(false)
      setMessage("Image uploaded successfully")
    } catch (error) {
      console.error('Error get image URL from s3 bucket', error)
      // setMessage(response.data.response)
      setMessage("Error uploading image")
      setError(true)
    }
  }

  const uploadAfterSuccessImageAndGetURL = async () => {
    try {
      const formData = new FormData()
      formData.append('image', afterSuccessImage)
      const uploadResponse = await getSuperAdminService().getImageURLs3(formData)
      setAfterSuccessImageURLS3(uploadResponse.data.file)
      setError(false)
      setMessage("Image uploaded successfully")
    } catch (error) {
      console.error('Error get image URL from s3 bucket', error)
      // setMessage(response.data.response)
      setMessage("Error uploading image")
      setError(true)
    }
  }

  const handleSaveChallenge = async () => {
    try {
      const handleCreateChallenge = async () => {
        let response = await getSuperAdminService().createSavedChallenge(createChallengePayload)
        if (response.data.status === 201) {
          setError(false)
          setMessage("Challenge created successfully")
          const timeoutId = setTimeout(() => {
            history.push("/admin/challenges")
          }, 1000);
        } else {
          setMessage(response.data.response)
          setError(true)
        }
      }
      handleCreateChallenge()
    } catch (error) {
      console.error('Error save challenge', error)
    }
  }
 
  useEffect(() => {
    getData()
    getMedium()
    getAdvanceFilter()

    getChallengeList()

  }, []);

  useEffect(() => {
    for (let i = 0; i < challengeConfig[0]?.parameters?.length; i++) {
      if (challengeConfig[0]?.parameters[i] === "Special class") {
        setSpecialClassExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Subject") {
        setSubjectExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Chapter") {
        setChapterExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Select component") {
        setSelectedComponentExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "timeframe") {
        setSelectedTimeframeExists(true)
      }

    }
  }, [challengeConfig]);

  useEffect(() => {
    setDistrict_id('')
    setDistrictList([])
    if (state_id) {
      getDistrictsByState(state_id)
    }
    setStudent_block_id('')
    setBlockList([])
  }, [state_id]);

  useEffect(() => {
    setStudent_block_id('')
    setBlockList([])
    if (district_id) {
      getStudentBlock(district_id)
    }
  }, [district_id]);

  useEffect(() => {
    if (student_block_id) {
      getSchoolsForBlockData()
    }
  }, [schoolPageNo, schoolSearchTxt, student_block_id]);

  useEffect(() => {
    setClass_id('')
    setClassList([])
    if (syllabus_id) {
      getClass()
    }
    setSubject_id('')
    setSubjectList([])
    setChapter_id('')
    setChapterList([])
  }, [syllabus_id]);

  useEffect(() => {
    setSubject_id('')
    setSubjectList([])
    if (class_id && medium_id) {
      getAllSubject()
    }
    setChapter_id('')
    setChapterList([])
  }, [class_id, medium_id]);

  useEffect(() => {
    setChapter_id('')
    setChapterList([])
    if (subject_id) {
      getChapterListByChapter()
    }
  }, [subject_id]);

  useEffect(() => {
    setTimeframe('')
    setTimeframeValue('')
    setAdvanceFilterPoints('')
    if (user_type) {
      getAdvanceFilterTimeframe();
    }
  }, [user_type]);

  useEffect(() => {
    if (user_type === "Champion User") {
      setTimeframeValue('')
    }
    if (timeframe) {
      getAdvanceFilterTimeframeValue();
    }
  }, [timeframe]);

  useEffect(() => {
    setSelectedSubChallenge('')
    setSubChallengeList([])
    if (selectedChallenge) {
      getSubChallengeList()
    }
  }, [selectedChallenge]);

  useEffect(() => {
    if (selectedChallenge && selectedSubChallenge) {
      getChallengeConfiguration()
    }
    if (selectedSubChallenge === 'Special course') {
      getSpecialClass()
    } else {
      setSpecialClass_id('')
      setSpecialClassList([])
    }
    setSubject_id('')
    setChapter_id('')
    setSelectedComponent('')
    setSelectedTimeframe('')
    setAIGenerated('')
    setComparisonForChallenge('')
    setSelectedUnit('')
    setSelectedUnitValue('')
  }, [selectedChallenge, selectedSubChallenge]);

  useEffect(() => {
    if (challengeConfig[0]?.components) {
      setComponents(challengeConfig[0]?.components)
    } else {
      setComponents([])
    }

    if (challengeConfig[0]?.comparison_operator) {
      setComparisonForChallengeList(challengeConfig[0]?.comparison_operator)
    } else {
      setComparisonForChallengeList([])
    }

    if (challengeConfig[0]?.unit) {
      setUnitList(challengeConfig[0]?.unit)
    } else {
      setUnitList([])
    }
  }, [challengeConfig]);

  useEffect(() => {
    // Function to get current date and time in the format 2023-12-14T10:25
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDateTime;
    };
    setCurrentDateTime(getCurrentDateTime());
  }, []);

  useEffect(() => {
    handleCreateChallengePayload();
  }, [challengeTitle, state_id, district_id, student_block_id, syllabus_id, medium_id, class_id, user_type, timeframe, timeframeValue, advanceFilterPoints, selectedSchools, UTCStartDate, UTCEndDate, rewardPoints, rewardDescription, selectedChallenge, selectedSubChallenge, specialClass_id, subject_id, chapter_id, selectedComponent, selectedTimeframe, AIGenerated, comparisonForChallenge, selectedUnit, selectedUnitValue, challengeDisplayImageURLS3, challengeDisplaySubtitle, challengeDisplayDescription, afterSuccessImageURLS3, afterSuccessSubtitle, afterSuccessDescription]);

  return (
    <>
      <Snackbar
        autoHideDuration={5000} mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("")
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <Card className="mb-5 ps-4 pb-4 pe-4" >
        <CardHeader color="primary">
          <h4 className="cardTitleWhite" >Create Challenge</h4>
        </CardHeader>
        <CardContent>
          <div className="row pt-5">
            <div className="col-12">
              <div className='pb-2'><h5 className='color8A'>Challenge Title<span className='colorRed' >*</span></h5></div>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxLength="200"
                value={challengeTitle}
                onChange={(e) => {
                  setChallengeTitle(e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div className="row challengeListFilterDiv pt-5">
            <div className=''><h5 className='color8A'>Challenge filter</h5></div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select State<span className='colorRed' >*</span>
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={state_id}
                  onChange={(e) => {
                    setState_id(e.target.value)
                  }}
                >
                  {stateList.map((state, index) => {
                    return (
                      <MenuItem key={index + "state"} value={state.state_id}>
                        {`${state.state_name}`.toString().charAt(0).toUpperCase() + `${state.state_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select District
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={district_id}
                  onChange={(e) => {
                    setDistrict_id(e.target.value)
                  }}
                >
                  {districtList.map((district, index) => {
                    return (
                      <MenuItem key={index + "district"} value={district.student_district_id}>
                        {`${district.district_name}`.toString().charAt(0).toUpperCase() + `${district.district_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select Block
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={student_block_id}
                  onChange={(e) => {
                    setStudent_block_id(e.target.value)
                  }}
                >
                  {blockList.map((block, index) => {
                    return (
                      <MenuItem key={index + "block"} value={block.student_block_id}>
                        {`${block.block_name}`.toString().charAt(0).toUpperCase() + `${block.block_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Medium
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={medium_id}
                  onChange={(e) => {
                    setMedium_id(e.target.value)
                  }}
                >
                  {mediumList.map((medium, index) => {
                    return (
                      <MenuItem key={index + "medium"} value={medium.medium_id}>
                        {`${medium.medium_name}`.toString().charAt(0).toUpperCase() + `${medium.medium_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Class
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={class_id}
                  onChange={(e) => {
                    setClass_id(e.target.value)
                  }}
                >
                  {classList.map((cls, index) => {
                    return (
                      <MenuItem key={index + "class"} value={cls.class_id}>
                        {cls.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Advance Filter
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={user_type}
                  onChange={(e) => {
                    setUser_type(e.target.value)
                  }}
                >
                  {advanceFilterList?.map((item, index) => {
                    return (
                      <MenuItem key={index + "advanceFilter"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className={`${user_type === "Point" ? '' : 'd-none'} col-4 inputWrapper`} >
              <FormControl className='width28'>
                <InputLabel htmlFor="my-input">Points<span className='colorRed' >*</span></InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" type='text' value={advanceFilterPoints} onChange={(e) => {
                  const intValue = handlePositiveNumberInput(e)
                  if (intValue > 0 && intValue <= 99999) {
                    setAdvanceFilterPoints(intValue)
                  } else if (intValue <= 0) {
                    setAdvanceFilterPoints('')
                  }
                }}
                />
                <FormHelperText id="my-helper-text">Point should be more than 0.</FormHelperText>
              </FormControl>
            </div>

            <div className={`${user_type && advanceFilterTimeframeList[0] ? '' : 'd-none'} col-4 inputWrapper`} >
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Timeframe
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={timeframe}
                  onChange={(e) => {

                    setTimeframe(e.target.value)
                    if (user_type === "Active User") {
                      setTimeframeValue(1000)
                    }
                    if (user_type === "Inactive User") {
                      setTimeframeValue(0)
                    }
                  }}
                >
                  {advanceFilterTimeframeList.map((item, index) => {
                    return (
                      <MenuItem key={index + "advanceFilter"} value={item}>
                        {`${item}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={`${timeframe && timeframeValueList[0]?.unit_value ? '' : 'd-none'} col-4 inputWrapper`} >
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Timeframe Value
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={timeframeValue}
                  onChange={(e) => {
                    setTimeframeValue(e.target.value)
                  }}
                >
                  {timeframeValueList.map((item, index) => {
                    return (
                      <MenuItem key={index + "advanceFilterTimeframeValue"} value={item.actual_value}>
                        {`${item.unit_value}`.toString().charAt(0).toUpperCase() + `${item.unit_value}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={`${advanceFilterComparisonList[0] ? '' : 'd-none'} col-4 inputWrapper`} >
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Comparison
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={advanceFilterComparison}
                  onChange={(e) => {
                    setAdvanceFilterComparison(e.target.value)
                  }}
                >
                  {advanceFilterComparisonList.map((item, index) => {
                    return (
                      <MenuItem key={index + "advanceFilterComparisonValue"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div>
              <div className={`col-8 inputWrapper mt-4`} >
                <InputLabel className='pb-1' id="demo-simple-select-label">
                  School
                </InputLabel>
                <Autocomplete
                  value={selectedSchoolsObject}
                  onChange={(event, value, reason) => {
                    if (reason === 'clear') {
                      setSelectedSchoolsObject([]);
                      setSelectedSchools([])
                      return;
                    }
                    if (reason === 'remove-option') {
                      setSelectedSchoolsObject(value);
                      let schoolId = []
                      for (let i = 0; i < value?.length; i++) {
                        schoolId.push(value[i]?.school_id)
                      }
                      setSelectedSchools(schoolId)
                      return;
                    }
                    if (reason === 'select-option') {
                      const updatedSchools = [...selectedSchoolsObject];
                      const selectedSchool = value.slice(-1)[0];
                      const schoolIndex = updatedSchools.findIndex(school => school.school_id === selectedSchool.school_id);

                      if (schoolIndex === -1) {
                        updatedSchools.push(selectedSchool);
                      } else {
                        updatedSchools.splice(schoolIndex, 1);
                      }
                      setSelectedSchoolsObject(updatedSchools);
                      let schoolId = []
                      for (let i = 0; i < updatedSchools?.length; i++) {
                        schoolId.push(updatedSchools[i]?.school_id)
                      }
                      setSelectedSchools(schoolId)

                    }
                  }}

                  inputValue={schoolSearchTxt}
                  onInputChange={(event, newInputValue) => {
                    setSchoolSearchTxt(newInputValue);
                  }}
                  filterOptions={options => options}
                  multiple
                  id="checkboxes-tags-demo"
                  options={schoolList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.school_name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selectedSchoolsObject.findIndex(sch => sch.school_id === option.school_id) > -1}
                      />
                      {option.school_name}
                    </React.Fragment>
                  )}
                  style={{ width: "90%" }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="" placeholder="Search..." />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="row challengeListFilterDiv">
            <div className='d-flex justify-content-end pe-5 pt-4'>
              <button type="button" className="btn btn-outline-dark px-4" onClick={
                () => {
                  setState_id('')
                  setDistrict_id('')
                  setStudent_block_id('')
                  setSyllabus_id(3)
                  setMedium_id('')
                  setClass_id('')
                  setSpecialClass_id('')
                  setUser_type('')
                  setTimeframe('')
                  setTimeframeValue('')
                  setAdvanceFilterComparison('')
                  setSelectedSchools([])
                  setSelectedSchoolsObject([])
                  setSchoolList([])
                }
              }>RESET</button>
            </div>
          </div>

          <div className="row challengeListFilterDiv pt-5">
            <div className=''><h5 className='color8A'>Select validity</h5></div>
            <div className="col-4 inputWrapper">
              <div className="dateTimeRangeInputContainer width28">
                <label className='color8A' >Start Date and Time:<span className='colorRed' >*</span></label>
                <input
                  type="datetime-local"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Enter start date and time"
                  min={currentDateTime}
                />
              </div>
            </div>
            <div className="col-4 inputWrapper">
              <div className="dateTimeRangeInputContainer width28">
                <label className='color8A'>End Date and Time:<span className='colorRed' >*</span></label>
                <input
                  type="datetime-local"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="Enter end date and time"
                  min={startDate}
                />
              </div>
            </div>
            <div className="mx-1">
              {dateErrorMsg && <p className='errorText'>{dateErrorMsg}</p>}
            </div>
          </div>

          <div className="row challengeListFilterDiv pt-5">
            <div className=''><h5 className='color8A'>Reward for completing challenge</h5></div>
            <div className="col-4 inputWrapper">
              <FormControl className='width28'>
                <InputLabel htmlFor="my-input">Points<span className='colorRed' >*</span></InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" type='text' value={rewardPoints} onChange={(e) => {
                  const intValue = handlePositiveNumberInput(e)
                  if (intValue > 0 && intValue <= 99999) {
                    setRewardPoints(intValue)
                  } else if (intValue <= 0) {
                    setRewardPoints('')
                  }
                }}
                />
                <FormHelperText id="my-helper-text">Point should be more than 0.</FormHelperText>
              </FormControl>
            </div>
            <div className="col-8 inputWrapper">
              <FormControl style={{ width: "90%" }}>
                <label className='color8A'>Reward message<span className='colorRed' >*</span></label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  maxLength="200"
                  value={rewardDescription}
                  onChange={(e) => {
                    setRewardDescription(e.target.value);
                  }}
                ></textarea>
                <div className='d-flex justify-content-end color8A pt-1'>{rewardDescription.length}/200</div>
              </FormControl>
            </div>
          </div>

          <div className="row challengeListFilterDiv pt-5">
            <div className=''><h5 className='color8A'>Challenge type</h5></div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Challenge<span className='colorRed' >*</span>
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectedChallenge}
                  onChange={(e) => {
                    setSelectedChallenge(e.target.value)
                  }}
                >
                  {challengeList.map((item, index) => {
                    return (
                      <MenuItem key={index + "challengeList"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Sub Challenge<span className='colorRed' >*</span>
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectedSubChallenge}
                  onChange={(e) => {
                    setSelectedSubChallenge(e.target.value)
                  }}
                >
                  {subChallengeList.map((item, index) => {
                    return (
                      <MenuItem key={index + "subChallengeList"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {
              challengeConfig[0]?.parameters?.map((param, index) => (
                <>
                  <div className={`${param === "Special class" ? "" : "d-none"} col-4 inputWrapper`}>
                    <FormControl className="dropdown width28" >
                      <InputLabel id="demo-simple-select-label">
                        Special class
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={specialClass_id}
                        onChange={(e) => {
                          setSpecialClass_id(e.target.value)
                        }}
                      >
                        {specialClassList?.map((cls, index) => {
                          return (
                            <MenuItem key={index + "class"} value={cls.class_id}>
                              {cls.class_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={`${param === "Subject" ? "" : "d-none"} col-4 inputWrapper`}>
                    <FormControl className="dropdown width28" >
                      <InputLabel id="demo-simple-select-label">
                        Subject<span className='colorRed' >*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={subject_id}
                        onChange={(e) => {
                          setSubject_id(e.target.value)
                        }}
                      >
                        {subjectList.map((subject, index) => {
                          return (
                            <MenuItem key={index + "subject"} value={subject.subject_id}>
                              {`${subject.subject_name}`.toString().charAt(0).toUpperCase() + `${subject.subject_name}`.slice(1)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText id="my-helper-text"><span className="colorRed" >{class_id && medium_id ? "" : "To select Subject, select class and medium first"}</span></FormHelperText>
                    </FormControl>
                  </div>
                  <div className={`${param === "Chapter" ? "" : "d-none"} col-4 inputWrapper`}>
                    <FormControl className="dropdown width28" >
                      <InputLabel id="demo-simple-select-label">
                        Chapter<span className='colorRed' >*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={chapter_id}
                        onChange={(e) => {
                          setChapter_id(e.target.value)
                        }}
                      >
                        {chapterList.map((chapter, index) => {
                          return (
                            <MenuItem key={index + "chapter"} value={chapter.chapter_id}>
                              {`${chapter.chapter_name}`.toString().charAt(0).toUpperCase() + `${chapter.chapter_name}`.slice(1)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText className="colorRed" id="my-helper-text"><span className="colorRed" >{subject_id ? "" : "To select Chapter, select subject first"}</span></FormHelperText>
                      <FormHelperText className={`${showMCQChallengeError && subject_id ? "" : "d-none"} colorRed`} id="my-helper-text"><span className="colorRed" >No available chapter</span></FormHelperText>
                    </FormControl>
                  </div>
                  <div className={`${param === "Select component" ? "" : "d-none"} col-4 inputWrapper`}>
                    <FormControl className="dropdown width28" >
                      <InputLabel id="demo-simple-select-label">
                        Select component<span className='colorRed' >*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={selectedComponent}
                        onChange={(e) => {
                          setSelectedComponent(e.target.value)
                        }}
                      >
                        {components.map((item, index) => {
                          return (
                            <MenuItem key={index + "components"} value={item}>
                              {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={`${param === "timeframe" ? "" : "d-none"} col-4 inputWrapper`}>
                    <FormControl className="dropdown width28" >
                      <InputLabel id="demo-simple-select-label">
                        Timeframe
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={selectedTimeframe}
                        onChange={(e) => {
                          setSelectedTimeframe(e.target.value)
                        }}
                      >
                        {components.map((item, index) => {
                          return (
                            <MenuItem key={index + "components"} value={item}>
                              {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )
              )
            }

            <div className={`${challengeConfig[0]?.comparison_operator ? "" : "d-none"} col-4 inputWrapper`}>
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  <div className='d-flex'>Comparison<span className='colorRed' >*</span></div>
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={comparisonForChallenge}
                  onChange={(e) => {
                    setComparisonForChallenge(e.target.value)
                  }}
                >
                  {comparisonForChallengeList.map((item, index) => {
                    return (
                      <MenuItem key={index + "components"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={`${challengeConfig[0]?.unit ? "" : "d-none"} col-4 inputWrapper`}>
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Unit<span className='colorRed' >*</span>
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectedUnit}
                  onChange={(e) => {
                    setSelectedUnit(e.target.value)
                    setSelectedUnitValue('')
                  }}
                >
                  {unitList?.map((item, index) => {
                    return (
                      <MenuItem key={index + "components"} value={item}>
                        {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={`${selectedUnit === "Percentage" ? "" : "d-none"} col-4 inputWrapper`}>
              <FormControl className='width28'>
                <InputLabel htmlFor="my-input">{selectedUnit}<span className='colorRed' >*</span></InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" type='text' value={selectedUnitValue} onChange={(e) => {
                  const intValue = handlePositiveNumberInput(e)
                  if (intValue > 0 && intValue <= 100) {
                    setSelectedUnitValue(intValue)
                  } else if (intValue <= 0) {
                    setSelectedUnitValue('')
                  }
                }} />
              </FormControl>
            </div>
    
            <div className={`${selectedUnit !== "Percentage" && selectedUnit !== "" ? "" : "d-none"} col-4 inputWrapper`}>
              <FormControl className='width28'>
                <InputLabel htmlFor="my-input">{selectedUnit}<span className='colorRed' >*</span></InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" type='text' value={selectedUnitValue} onChange={(e) => {
                  const intValue = handlePositiveNumberInput(e)
                  if (intValue > 0 && intValue <= 99999) {
                    setSelectedUnitValue(intValue)
                  } else if (intValue <= 0) {
                    setSelectedUnitValue('')
                  }
                }} />
              </FormControl>
            </div>
          </div>

          <div className='challengeImgTitleDesc'>
            <div className="mt-5 py-5 bg-light px-4 d-flex justify-content-around">
              <div>
                <div>
                  <h3>Challenge displayed</h3>
                </div>
                <div className="d-flex">
                  <div className="px-4 w-100">
                    <div className="upload-image" style={{ marginBottom: '20px' }}>
                      <label className="form-label" htmlFor="customFile">
                        Upload icon/image<span className='colorRed' >*</span>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        id="customFile"
                        onChange={(e) => {
                          if (!e.target.files[0]) {
                            setChallengeDisplayImage('')
                            setChallengeDisplayImageURL('');
                            setChallengeDisplayImageErrorMsg(false)
                            return;
                          } else if (e.target.files[0].size > 1024 * 1024) {
                            setChallengeDisplayImage('')
                            setChallengeDisplayImageURL('');
                            setChallengeDisplayImageErrorMsg(true)
                          } else {
                            setChallengeDisplayImage(e.target.files[0])
                            setChallengeDisplayImageURL(URL.createObjectURL(e.target.files[0]));
                            setChallengeDisplayImageErrorMsg(false)
                          }
                        }}
                      />
                      <div className={`pt-3`}>
                        <img className={`${challengeDisplayImageURL ? "" : "d-none"}`} src={challengeDisplayImageURL} width={370} height={370} alt="Uploaded" />
                        <div className={`${challengeDisplayImageErrorMsg ? "" : "d-none"} text-danger`} id="my-helper-text">
                          Image size exceeds 1MB.
                        </div>
                      </div>
                    </div>

                    <div className={`${challengeDisplayImage ? "" : "d-none"} d-flex justify-content-center`}>
                      <Button
                        className='ms-2'
                        color="primary"
                        onClick={uploadChallengeDisplayImageAndGetURL}
                      >
                        UPLOAD
                      </Button>
                    </div>

                    <div className="py-3">
                      <label htmlFor="exampleFormControlTextarea1">Challenge subtitle<span className='colorRed' >*</span></label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxLength="200"
                        value={challengeDisplaySubtitle}
                        onChange={(e) => {
                          setChallengeDisplaySubtitle(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Challenge description<span className='colorRed' >*</span></label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        maxLength="200"
                        value={challengeDisplayDescription}
                        onChange={(e) => {
                          setChallengeDisplayDescription(e.target.value);
                        }}
                      ></textarea>
                      <div className='d-flex justify-content-end color8A pt-1'>{challengeDisplayDescription.length}/200</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <h3>After success</h3>
                </div>
                <div className="d-flex">
                  <div className="px-4 w-100">
                    <div className="upload-image" style={{ marginBottom: '20px' }}>
                      <label className="form-label" htmlFor="customFile">
                        Upload icon/image<span className='colorRed' >*</span>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        id="customFile"
                        onChange={(e) => {
                          if (!e.target.files[0]) {
                            setAfterSuccessImage('')
                            setAfterSuccessImageURL('');
                            setAfterSuccessImageErrorMsg(false)
                            return;
                          } else if (e.target.files[0].size > 1024 * 1024) {
                            setAfterSuccessImage('')
                            setAfterSuccessImageURL('');
                            setAfterSuccessImageErrorMsg(true)
                          } else {
                            setAfterSuccessImage(e.target.files[0])
                            setAfterSuccessImageURL(URL.createObjectURL(e.target.files[0]));
                            setAfterSuccessImageErrorMsg(false)
                          }
                        }}
                      />
                      <div className={`pt-3`}>
                        <img className={`${afterSuccessImageURL ? "" : "d-none"}`} src={afterSuccessImageURL} width={370} height={370} alt="Uploaded" />
                        <div className={`${afterSuccessImageErrorMsg ? "" : "d-none"} text-danger`} id="my-helper-text">
                          Image size exceeds 1MB.
                        </div>
                      </div>
                    </div>

                    <div className={`${afterSuccessImage ? "" : "d-none"} d-flex justify-content-center`}>
                      <Button
                        className='ms-2'
                        color="primary"
                        onClick={uploadAfterSuccessImageAndGetURL}
                      >
                        UPLOAD
                      </Button>
                    </div>

                    <div className="py-3">
                      <label htmlFor="exampleFormControlTextarea1">Challenge title<span className='colorRed' >*</span></label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxLength="200"
                        value={afterSuccessSubtitle}
                        onChange={(e) => {
                          setAfterSuccessSubtitle(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Challenge description<span className='colorRed' >*</span></label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        maxLength="200"
                        value={afterSuccessDescription}
                        onChange={(e) => {
                          setAfterSuccessDescription(e.target.value);
                        }}
                      ></textarea>
                      <div className='d-flex justify-content-end color8A pt-1'>{afterSuccessDescription.length}/200</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end pe-5'>
            <label><span className='colorRed' >*</span> fields mandatory</label>
          </div>
        </CardContent>
        <CardFooter className="cardFooter d-flex justify-content-end" >
          <Button
            color="primary"
            disabled={disablePublishChallenge()}
            onClick={handleSaveChallenge}
          >
            SAVE
          </Button>
          <Link to="/admin/challenges">
            <Button
              color="secondary"
            >
              Cancel
            </Button>
          </Link>
        </CardFooter>
      </Card >
    </>
  );
}

export default CreateChallenge;