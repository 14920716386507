import Dashboard from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import ImportContacts from "@material-ui/icons/ImportContacts";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import PersonAdd from "@material-ui/icons/PersonAdd";
import People from "@material-ui/icons/People";
import SchoolIcon from "@material-ui/icons/School";
import MenuBook from "@material-ui/icons/MenuBook";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import GroupIcon from "@material-ui/icons/Group";
import ListAltIcon from "@material-ui/icons/ListAlt";

import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import AdminCWTableList from "views/TableList/AdminCWTableList.js";
import CheckingSection from "views/CheckingSection/checkingSection";
import CreateChapter from "views/CreateChapter/createChapter";
import AddSubject from "views/AddSubject/AddSubject";
import Schools from "views/Schools/Schools";
import * as constants from "./common/constants";
import Notifications from "views/Notifications/Notifications";
import Activity from "views/Activity/Activity";
import Students from "views/Students/StudentsAnalytics";
import Chapters from "views/Chapters/Chapters";
import Challenges from "views/Challenges/Challenges";
import Background from "views/background/background";
import HMDashboardPage from "views/HMDashboard/HMDashboard";
import SchoolDetail from "views/SchoolDetail/SchoolDetail";
import ChapterAnyalytics from "views/Chapters/ChapterAnalytics";
import FCMNotifications from "views/Notifications/FCMNotification";
import LearningElement from "views/LearningElement/LearningElement";
import SMCCreateForm from "views/CreateSMCForm/SMCCreateForm";
import FLNHome from "./views/FLNHome/FLNHome";
import FLNStudentList from "./views/FLNStudent/FLNStudentList";
import FLNTeacherList from "./views/FLNTeacher/FLNTeacherList";
import SMCHome from "./views/SMCHome/SMCHome";
import ViewStudentList from "./views/FLNStudent/ViewStudentList";
import FLNAnylsis from "./views/FLNAnalysis/FLNAnalysis";
import LOAnylsis from "./views/LOAnylsis/LOAnylsis";
import VSAnaylytic from "./views/VSAnlytics/VSAnlytics";
import FLNDataCollection from "./views/FLNDataCollection/FLNDataCollection";
import FLNNipunBharat from "./views/FLNNipun/FLNNipunBharat";
import SMCAnalytics from "./views/SMCAnalytics/SMCAnalytics";
import SMCAnalysisHome from "./views/SMCAnalysisHome/SMCAnalysisHome";
import FLNAM from "./views/FLNAM/FLNAM";
import SMCAM from "./views/SMCAM/SMCAM";
import AMDashboard from "./views/AMDashboard/AMDashboard";
import FlnTransferTeacher from "views/FlnTransferTeacher/FlnTransferTeacher";
import LiveSessions from "views/LiveSessions/LiveSessions";
import MCQAI from "views/MCQAI/MCQAI";
import MCQAIQuestion from "views/MCQAIQuestion/MCQAIQuestion";
import SpecialCourse from "views/SpecialCourse/SpecialCourse";
import LearingOutcome from "views/LearingOutcome/LearingOutcome";
import LOAdminAnalysis from "./views/LOAdminAnalysis/LOAdminAnalysis"
import FAQs from "views/FAQ/FAQ";
import { AssessmentRounded, AssignmentRounded, DashboardRounded, ExposureRounded, GetAppRounded, HomeRounded, PageviewRounded, PeopleAltRounded, PostAddRounded, StorageRounded, TransferWithinAStation, UpdateRounded } from "@material-ui/icons";

const getDashboardRoutes = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let type = user ? user.user_type : null;
  let dashboardRoutes = [];
  if (type == constants.USER_TYPE.CONTENT_WRITER) {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Analytics/Summary",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/user",
      },
      {
        path: "/create-lesson",
        name: "Create Lesson",
        icon: PlaylistAdd,
        component: CreateChapter,
        layout: "/user",
      },
      {
        path: "/lessons",
        name: "My Lessons",
        icon: ImportContacts,
        component: CheckingSection,
        layout: "/user",
      },
      {
        path: "/logout",
        name: "Log Out",
        icon: ExitToAppIcon,
        layout: "/user",
      },
    ];
  } else if (type === constants.USER_TYPE.SUPER_ADMIN) {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Analytics/Summary",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
      },
      {
        path: "/students",
        name: "Students analytics",
        icon: GroupIcon,
        component: Students,
        layout: "/admin",
      },
      {
        path: "/chapterAnaylytics",
        name: "Chapter analytics",
        icon: GroupIcon,
        component: ChapterAnyalytics,
        layout: "/admin",
      },
      {
        path: "/VSAnaylytics",
        name: "VS analytics",
        icon: GroupIcon,
        component: VSAnaylytic,
        layout: "/admin",
      },
      {
        path: "/subjects",
        name: "Subjects",
        icon: MenuBook,
        component: AddSubject,
        layout: "/admin",
      },
      // {
      //   path: "/user",
      //   name: "Create User",
      //   icon: PersonAdd,
      //   component: UserProfile,
      //   layout: "/admin",
      // },
      {
        path: "/all-users",
        name: "User List",
        icon: People,
        component: AdminCWTableList,
        layout: "/admin",
      },
      {
        path: "/chapters",
        name: "Chapter List",
        icon: LibraryBooks,
        component: CheckingSection,
        layout: "/admin",
      },
      {
        path: "/challenges",
        name: "Challenges",
        icon: LibraryBooks,
        component: Challenges,
        layout: "/admin",
      },
      {
        path: "/all-chapters",
        name: "All Chapters List",
        icon: ListAltIcon,
        component: Chapters,
        layout: "/admin",
      },
      {
        path: "/schools",
        name: "Schools",
        icon: SchoolIcon,
        component: Schools,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        icon: NotificationsIcon,
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/FCMNotifications",
        name: "Soft Notifications",
        icon: NotificationsIcon,
        component: FCMNotifications,
        layout: "/admin",
      },
      {
        path: "/LearningElement",
        name: "Learning outcome",
        icon: NotificationsIcon,
        component: LearningElement,
        layout: "/admin",
      },
      {
        path: "/activity",
        name: "Activity",
        icon: LocalActivityIcon,
        component: Activity,
        layout: "/admin",
      },
      {
        path: "/background",
        name: "Background",
        icon: LocalActivityIcon,
        component: Background,
        layout: "/admin",
      },
      {
        path: "/liveSessions",
        name: "LiveSessions",
        icon: LocalActivityIcon,
        component: LiveSessions,
        layout: "/admin",
      },
      // {
      //   path: "/MCQAI",
      //   name: "MCQ AI",
      //   icon: LocalActivityIcon,
      //   component: MCQAI,
      //   layout: "/admin",
      // },
      // {
      //   path: "/AIQuestion",
      //   name: "MCQ AI Question",
      //   icon: LocalActivityIcon,
      //   component: MCQAIQuestion,
      //   layout: "/admin",
      // },
      {
        path: "/SpecialCourse",
        name: "Special Courses",
        icon: LocalActivityIcon,
        component: SpecialCourse,
        layout: "/admin",
      },
      {
        path: "/LearingOutcome",
        name: "Learing Outcome",
        icon: LocalActivityIcon,
        component: LearingOutcome,
        layout: "/admin",
      },
      // {
      //   path: "/FAQs",
      //   name: "FAQs",
      //   icon: LocalActivityIcon,
      //   component: FAQs,
      //   layout: "/admin",
      // },
      {
        path: "/logout",
        name: "Log Out",
        icon: ExitToAppIcon,
        layout: "/admin",
      },
    ];
  } else if (type === constants.USER_TYPE.ADMIN) {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Analytics/Summary",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
      },
      {
        path: "/students",
        name: "Students analytics",
        icon: GroupIcon,
        component: Students,
        layout: "/admin",
      },
      {
        path: "/create-lesson",
        name: "Create Lesson",
        icon: PlaylistAdd,
        component: CreateChapter,
        layout: "/admin",
      },
      {
        path: "/lessons",
        name: "My Lessons",
        icon: ImportContacts,
        component: CheckingSection,
        layout: "/admin",
      },
      {
        path: "/chapters",
        name: "Chapter List",
        icon: LibraryBooks,
        component: CheckingSection,
        layout: "/admin",
      },
      {
        path: "/logout",
        name: "Log Out",
        icon: ExitToAppIcon,
        layout: "/admin",
      },
    ];
  } else if (type === constants.USER_TYPE.ADMINISTRATIVE) {
    dashboardRoutes = [
      {
        path: "/AMHome",
        name: "Welcome",
        icon: DashboardRounded,
        component: AMDashboard,
        layout: "/admin",
      },
      {
        path: "/Home-FLN",
        name: "FLN Home",
        icon: HomeRounded,
        component: FLNHome,
        layout: "/admin",
      },
      // {
      //   path: "/HomeSMC",
      //   name: "SMC Home",
      //   icon: HomeRounded,
      //   component: SMCAnalysisHome,
      //   layout: "/admin",
      // },
      {
        path: "/NipunBharat",
        name: "FLN Analysis",
        icon: AssignmentRounded,
        component: FLNNipunBharat,
        layout: "/admin",
      },
      {
        path: "/LOAdminAnalysis",
        name: "LO analysis",
        icon: AssessmentRounded,
        component: LOAdminAnalysis,
        layout: "/admin",
      },

      {
        path: "/FLN",
        name: "Download Reports",
        icon: StorageRounded,
        component: FLNDataCollection,
        layout: "/admin",
      },

      {
        path: "/AMFLN",
        name: "FLN  Setup",
        icon: GroupIcon,
        component: FLNAM,
        layout: "/admin",
      },
      {
        path: "/SMCAnalytics",
        name: "SMC Overview",
        icon: PageviewRounded,
        component: SMCAnalytics,
        layout: "/admin",
      },
      {
        path: "/AMSMC",
        name: "SMC  For AM",
        icon: GroupIcon,
        component: SMCAM,
        layout: "/admin",
      },
      {
        path: "/logout",
        name: "Log Out",
        icon: ExitToAppIcon,
        layout: "/user",
      },
    ];
  } else {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Welcome",
        icon: HomeRounded,
        component: HMDashboardPage,
        layout: "/user",
      },
      
      //School Setup
      {
        path: "/FLNStudentList",
        name: "Upload Student List",
        icon: PeopleAltRounded,
        component: FLNStudentList,
        layout: "/user",
      },
      {
        path: "/FLNTeacherList",
        name: "Assign Uploader Teacher",
        icon: SchoolIcon,
        component: FLNTeacherList,
        layout: "/user",
      },
      {
        path: "/FLNTransferTeacher",
        name: "Transfer Teacher",
        icon: TransferWithinAStation,
        component: FlnTransferTeacher,
        layout: "/user",
      },
      {
        path: "/StudentView",
        name: "Add/Remove Student",
        icon: PostAddRounded,
        component: ViewStudentList,
        layout: "/user",
      },
      
      //SMC
      {
        path: "/SMCHome",
        name: "SMC Home",
        icon: Dashboard,
        component: SMCHome,
        layout: "/user",
      },
      {
        path: "/createform",
        name: "Update Information",
        icon: UpdateRounded,
        component: SMCCreateForm,
        layout: "/user",
      },
      {
        path: "/schoolDetail",
        name: "View/Download",
        icon: GetAppRounded,
        component: SchoolDetail,
        layout: "/user",
      },

      //FLN Analysis
      {
        path: "/FLNAnylsis",
        name: "FLN Analysis",
        icon: AssignmentRounded,
        component: FLNAnylsis,
        layout: "/user",
      },

      //LO Analysis
      {
        path: "/LOAnylsis",
        name: "LO Analysis",
        icon: AssessmentRounded,
        component: LOAnylsis,
        layout: "/user",
      },

      //Log Out
      {
        path: "/logout",
        name: "Log Out",
        icon: ExitToAppIcon,
        layout: "/user",
      },

      // {
        //   path: "/FLNHome",
        //   name: "FLN Home",
        //   icon: SchoolIcon,
        //   component: FLNHome,
        //   layout: "/user",
        // },
    ];
  }

  return dashboardRoutes;
};

export default [];
export const dashboardRoutes = getDashboardRoutes;
