//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
import classes from "./FLNAnalysis.module.css";
import {
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Button, CircularProgress } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { array } from "prop-types";
import { Loader } from "react-bootstrap-typeahead";

const data = [
  "मराठी वाचन",
  "इंग्रजी वाचन",
  "गणित",
  "मराठी श्रुतलेखन",
  "इंग्रजी श्रुतलेखन",
];
const data1 = ["मराठी वाचन", "इंग्रजी वाचन", "गणित", "मराठी श्रुतलेखन"];
const monthData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const arrayData = [];
const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    tab1: true,
    tab2: false,
    tab3: false,
    school: JSON.parse(localStorage.getItem("HMSchool")),
    subjectList: null,
    anylyticsList: null,
    mediumList: null,
    yearList: null,
    monthList: null,
    classList: null,
    selectedYear: null,
    selectedMonth: [],
    selectedStage: null,
    selectedMedium: null,
    selectedClass: null,
    allMediumSelected: null,
    allYearSelected: null,
    allStandardSelected: null,
    NextFLNSub: null,
    firstTime: false,
    arrayAnyltics: null,
    arrayForLine: null,
    allClassSelected: null,
    monthListAxis: [],
    allCount: null,
    selectedSub: null,
    levelWiseCount: null,
    avaialbleCount: null,
    comapareArray: null,
    monthDataType: JSON.parse(localStorage.getItem("HMSchool")).fln_data_type,
    levelComparsionArr: [],
    ChaluSelectedYear: null,
    ChaluSelectedMonth: null,
    selectedHalf: "FIRST HALF",
    TulanaSelectedYear: null,
    TulanaSelectedMonth: null,
    TulanaselectedHalf: "FIRST HALF",
    lineSelectedHalf: null,
    loading: false,
  };

  componentDidMount() {
    this.getSubjectList();
    this.getMedium();
    this.getAllstandards();
    this.getYears();
  }

  currentInfo = () => {
    return (
      <div
        style={{
          fontSize: "20px",
          padding: "20px",
          color: "#415EB6",
          fontFamily: "Mukta",
        }}
      >
        {console.log(arrayData, "arrayAnyltics")}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          <div>
            <Select
              variant="outlined"
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              variant="outlined"
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.monthDataType == "MONTH_HALF" ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                variant="outlined"
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}

          <div>
            {!this.state.loading ? (
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                }}
                onClick={() => this.getFirstAnyltics()}
              >
                search
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
          {console.log(
            this.state.levelComparsionArr,
            "list values",
            this.state.monthList
          )}
        </div>
        <div class="row">
          {this.state.levelComparsionArr?.map((res, i) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      margin: "10px",
                      borderRadius: "10px",
                      color: "#fff",
                      backgroundColor: "#2BBC6E",
                      padding: "10px",
                      marginLeft:
                        i + 1 == this.state.levelComparsionArr?.length
                          ? "15%"
                          : "35%",
                    }}
                  >
                    {res.subject_name}
                  </div>
                </div>
                {this.getCharts(res.analytics_level_wise, res)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getMonthValue = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!

    console.log(dd, mm, "ddmm");
    let half = dd > 15 ? "II" : "I";
    let value = `${monthData[mm - 1]}-${half}`;
    return (
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#6872DD",
        }}
      >
        {" "}
        महिना :{value}
      </div>
    );
  };
  comparision = () => {
    return (
      <>
        <div
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {console.log(this.state.selectedSub, "sub")}
            {this.state.subjectList?.map((res) => {
              return (
                <div
                  onClick={() => {
                    this.CompareInfo(res);
                  }}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                    color: "#fff",
                    backgroundColor:
                      this.state.selectedSub == res.subject_id
                        ? "#2BBC6E"
                        : "#8D9A93",
                    padding: "15px",
                    width: "18%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {res.subject}
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              color: "#fff",
            }}
          >
            <div>
              <Select
                variant="outlined"
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.TulanaSelectedYear}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      TulanaSelectedYear: e.target.value,
                      TulanaSelectedMonth: null,
                    },
                    () => {
                      this.getMonthsList(e.target.value);
                      // this.getDataForCompare()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                {this.state.yearList?.map((res) => {
                  return <MenuItem value={res}>{res}</MenuItem>;
                })}
              </Select>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <Select
                variant="outlined"
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.TulanaSelectedMonth}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      TulanaSelectedMonth: e.target.value,
                    },
                    () => {
                      this.getDataForCompare();
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                {this.state.monthList?.map((res) => {
                  return <MenuItem value={res}>{res}</MenuItem>;
                })}
              </Select>
            </div>

            {this.state.monthDataType === "MONTH_HALF" ? (
              <div style={{ marginLeft: "10px" }}>
                <Select
                  variant="outlined"
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.TulanaselectedHalf}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState(
                      {
                        TulanaselectedHalf: e.target.value,
                      },
                      () => {
                        this.getDataForCompare();
                        // this.getStudentBlock(this.state.selectedDistrict);
                      }
                    );
                  }}
                >
                  {console.log(this.state.districts, "district")}
                  <MenuItem value="FIRST HALF">part-I</MenuItem>
                  <MenuItem value="SECOND HALF">part-II</MenuItem>
                </Select>
              </div>
            ) : null}

            {console.log(
              this.state.monthDataType,
              "list values",
              this.state.monthList
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.getComaparision()}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div>
            <div>जिल्हा स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.student_district}{" "}
              उपलब्ध नोंदी: {this.state.avaialbleCount?.student_district}
            </div>
          </div>

          <div>
            <div>केंद्र स्तर</div>
            <div>
              विद्यार्थी संख्या:{" "}
              {this.state.levelWiseCount?.kendra
                ? this.state.levelWiseCount?.kendra
                : "NA"}{" "}
              उपलब्ध नोंदी:{" "}
              {this.state.avaialbleCount?.kendra
                ? this.state.avaialbleCount?.kendra
                : "NA"}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            color: "#415EB6",
            fontFamily: "Mukta",
            marginBottom: "40px",
          }}
        >
          <div>
            <div>तालुका स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.student_block}{" "}
              उपलब्ध नोंदी: {this.state.avaialbleCount?.student_block}
            </div>
          </div>

          <div>
            <div>शाळा स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.school} उपलब्ध
              नोंदी: {this.state.avaialbleCount?.school}
            </div>
          </div>
        </div>
      </>
    );
  };

  moreInfoTab = () => {
    return (
      <>
        <div
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
        </div>
        <div style={{ borderBottom: "4px solid #8F50D6" }}></div>
        <div
          style={{
            borderBottomWidth: "4px",
            borderBottomColor: "black",
            marginTop: "35px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: '2rem'
          }}
        >
          <div>
            <div>
              <div>वर्ष</div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    this.setAllYears();
                  }}
                  style={{
                    backgroundColor:
                      this.state.allYearSelected?.length > 0
                        ? "#DB83E2"
                        : "#C2B0BB",
                  }}
                >
                  सर्व
                </Button>
                {this.state.yearList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            selectedYear: res,
                            allYearSelected: null,
                          },
                          () => {
                            this.getMonthsList(res);
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedYear === res
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div style={{ marginTop: "35px" }}>
              <div>महिना</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.monthList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        // this.createMonthArray(res)
                        if (
                          this.state.selectedMonth?.length > 0 &&
                          this.state.selectedMonth.includes(res)
                        ) {
                          this.state.selectedMonth.splice(
                            this.state.selectedMonth.indexOf(res),
                            1
                          );
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth],
                          });
                        } else {
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth, res],
                          });
                        }
                      }}
                      style={{
                        backgroundColor: this.state.selectedMonth.includes(res)
                          ? "#DB83E2"
                          : "#C2B0BB",
                        marginLeft: "10px",
                        minWidth: "15%",
                        marginTop: "5px",
                        padding: "5px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
                {console.log(this.state.selectedMonth, "select mnth")}
                {/* <Button style={{ backgroundColor: "#C2B0BB" }}>Oct</Button> */}
              </div>
            </div>

            {this.state.monthDataType == "MONTH_HALF" ? (
              <div style={{ marginTop: "35px" }}>
                <div>Part</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: null,
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == null
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    ALL
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "FIRST HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "FIRST HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    I
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "SECOND HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "SECOND HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    II
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{}}>
            <div>
              <div>
                <div>अध्ययनस्तर विषय निवडा.</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.subjectList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedStage: res.subject_id,
                          });
                        }}
                        style={{
                          width: "15%",
                          marginTop: "5px",
                          backgroundColor:
                            this.state.selectedStage === res.subject_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.subject}
                      </Button>
                    );
                  })}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>माध्यम निवडा</div>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      this.setAllMedium();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allMediumSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                    }}
                  >
                    सर्व
                  </Button>
                  {this.state.mediumList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedMedium: res.medium_id,
                            allMediumSelected: null,
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedMedium === res.medium_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.medium_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>सेमी</Button> */}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>आपली इयत्ता निवडा</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Button
                    onClick={() => {
                      this.setAllStandrs();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allClassSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                      width: "15%",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    सर्व
                  </Button>
                  {this.state.classList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedClass: res.class_id,
                            allClassSelected: null,
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedClass === res.class_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                          width: "15%",
                          marginTop: "5px",
                        }}
                      >
                        {res.class_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>10 वी</Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <Button
            onClick={() => {
              console.log(this.state.selectedMonth, "selectedMonth");
              if (this.state.selectedMonth.length > 0) {
                this.getLineGraph();
              }
            }}
            style={{ backgroundColor: "#7965DA", color: "#fff" }}
          >
            आलेख पहा
          </Button>
          <Button
            style={{
              backgroundColor: "#C2B0BB",
              color: "#fff",
              marginLeft: "10px",
            }}
            onClick={() => {
              if (this.state.selectedMonth.length > 0) {
                this.downloadSaraniExcel();
              }
            }}
          >
            सारणी पहा
          </Button>
        </div>

        {this.state.allCount ? (
          <div
            style={{
              color: "#415EB6",
              fontSize: "20px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {console.log(this.state.allCount, "allCount")}
            <div>
              विद्यार्थी संख्या: {this.state.allCount?.total_student_in_school}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {"  "} उपलब्ध नोंदी:{" "}
              {this.state.allCount?.total_available_entries}
            </div>
            {"  "}{" "}
            <div style={{ marginLeft: "20px" }}>
              अनुपलब्ध नोंदी: {this.state.allCount?.total_unavailable_entries}
            </div>
          </div>
        ) : null}

        <div class="row">
          {console.log(this.state.arrayForLine, "arrayForLine")}

          {this.state.arrayForLine?.map((res, index) => {
            let array = [];
            let array1 = [];
            console.log(res, "res");
            res.map((resp) => {
              if (typeof resp !== "object") {
                array.push(resp);
              } else {
                array.push(Object.values(resp));
                // console.log(Object.keys(resp),"keys pair");
                // this.setState({
                //   monthListAxis :[...this.state.monthListAxis,res]
                // })
                // this.state.monthListAxis.push(Object.keys(resp))
              }

              console.log(resp, "resp", array);
            });
            console.log("resp", array);

            Object.values(array).map((res) => {
              console.log(typeof res, "res aray");
              if (typeof res == "object") {
                const iterator = res.values();

                for (const value of iterator) {
                  console.log(value);
                  array1.push(value);
                }
              }
            });
            console.log(Object.values(array), array1);

            return (
              <div
                class="col"
                style={{
                  padding: "5px",
                  marginTop: "25px",
                  marginBottom: "40px",
                }}
              >
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      marginLeft: "48%",
                      padding: "10px",
                      backgroundColor: "#2BBC6E",
                      borderRadius: "10px",
                    }}
                  >
                    स्तर : {this.getLevels(index)}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories: this.getCatogeriesForSpline(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Count",
                          data: array1,
                        },
                      ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth - 260 - 120) / 1.05,
                        type: "spline",
                      },
                    }}
                  ></HighchartsReact>
                </div>
              </div>
            );
          })}
          {/* {data1.map((res) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: "",
                    },
                    xAxis: {
                        categories: [this.state.selectedMonth],
                      crosshair: true,
                    },
                    yAxis: {
                      stackLabels: {
                        enabled: true,
                        align: "center",
                      },
                      visible: true,
                      title: {
                        enabled: true,
                        text:
                           "count",
                      },
                    },

                    labels: {
                      items: [
                        {
                          html: "",
                          style: {
                            left: "50px",
                            top: "18px",
                          },
                        },
                      ],
                    },
                    series: [
                      {
                        name: "Population",
                        data: [
                          ["Shanghai", 3.7],
                          // ["Lagos", 16.1],
                          // ["Istanbul", 14.2],
                          // ["Karachi", 14.0],
                          // ["Karachi", 14.0],
                        ],
                      },
                    ],
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      width: window.innerWidth / 2.8,
                      type: "spline",
                    },
                  }}
                ></HighchartsReact>
                <div>
                  विद्यार्थी संख्या: १२५ उपलब्ध नोंदी: १२५ अनुपलब्ध नोंदी: १२
                </div>
              </div>
            );
          })} */}
        </div>
      </>
    );
  };

  getSubjectList = (data) => {
    //  arrayData.push(data)
    //  this.setState({
    //   arrayAnyltics : arrayData
    //  })
    // console.log(data,"fat",arrayData)
    let districtID = JSON.parse(localStorage.getItem('HMSchool')) ? JSON.parse(localStorage.getItem('HMSchool')).student_district_id : null
    getHMService()
      .getSubjectList({
        student_district_id: districtID
      })
      .then((res) => {
        console.log(res, "res");
        if (res.data.results) {
          this.setState(
            {
              subjectList: res.data.results,
              selectedStage: res.data.results[0]?.subject_id,
              selectedSub: res.data.results[0]?.subject_id,
            },
            () => {
              // this.getFirstAnyltics();
            }
          );
        }
      });
  };

  getFirstAnyltics = () => {
    let param = {};
    if (this.state.monthDataType === "MONTH_HALF") {
      param = {
        school: this.state.school.school_id,
        subject: this.state.NextFLNSub,
        month_half: this.state.selectedHalf,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
      };
    } else {
      param = {
        school: this.state.school.school_id,
        subject: this.state.NextFLNSub,

        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
      };
    }
    let array = [];
    if (this.state.NextFLNSub || !this.state.firstTime) {
      getHMService()
        .getAnyltics(param)
        .then((res) => {
          // arrayData.push(res.data)
          console.log(res.data.analytics_level_wise, "level1", res.data);

          if (res) {
            array =
              this.state.levelComparsionArr.length ==
                this.state.subjectList?.length
                ? []
                : this.state.levelComparsionArr;
            array.push(res.data);
            this.setState(
              {
                anylyticsList: res.data,
                NextFLNSub: res.data.next_subject,
                firstTime: true,
                levelComparsionArr: array,
                loading: true,
              },
              () => {
                this.getFirstAnyltics();
                // this.getSubjectList(res.data.analytics_level_wise);
              }
            );
          }
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
            selectedMedium: res.data.response[0]?.medium_id,
          });
        }
      });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
          selectedClass: res.data.response[0]?.class_id,
        });
      });
  };

  getCharts = (data, res) => {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: {
              text: "",
            },
            xAxis: {
              categories: this.getCatergories(data),
              crosshair: true,
            },
            yAxis: {
              stackLabels: {
                enabled: true,
                align: "center",
              },
              visible: true,
              title: {
                enabled: true,
                text: "Count",
              },
            },

            labels: {
              items: [
                {
                  html: "",
                  style: {
                    left: "50px",
                    top: "18px",
                  },
                },
              ],
            },
            series: this.getSerisForGraph(data),

            tooltip: {
              formatter: function () {
                var result = data.find((item) => item.level === this.x);

                let pcnt = result?.percentage;

                return Highcharts.numberFormat(pcnt) + "%";
              },
            },

            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              width: window.innerWidth / 2.8,
              type: "column",
              backgroundColor: "#fff",
            },
          }}
        ></HighchartsReact>
        {console.log(data, "data")}
        <div style={{ padding: "10px", display: "flex" }}>
          <div>विद्यार्थी संख्या: {res.total_student_in_school}</div>
          <div style={{ marginLeft: "25px" }}>
            उपलब्ध नोंदी: {res.total_available_entries}
          </div>
          <div style={{ marginLeft: "25px" }}>
            अनुपलब्ध नोंदी: {res.total_unavailable_entries}
          </div>
        </div>
      </>
    );
  };

  getSerisForGraph = (data) => {
    let array = [];
    let obj = {
      name: "count",
      data: null,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    data.map((res) => {
      array.push(res.count);
    });

    obj = {
      name: "count",
      data: array,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    return [obj];
  };

  getCatergories = (data) => {
    let array = [];
    data.map((res) => {
      array.push(res.level);
    });
    return array;
  };

  getYears = () => {
    getHMService()
      .getYear()
      .then((res) => {
        console.log(res, "res");
        this.setState({
          yearList: res.data.years,
        });
        if (res.data.years.length > 0) {
          this.setState(
            {
              selectedYear: res.data.years[0],
              ChaluSelectedYear: res.data.years[0],
              TulanaSelectedYear: res.data.years[0],
            },
            this.getMonthsList(res.data.years[0])
          );
        }
      });
  };

  getMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.months)
          this.setState(
            {
              monthList: res.data.months,
              ChaluSelectedMonth: res.data.months[0],
              TulanaSelectedMonth: res.data.months[0],
              // selectedMonth: res.data.months[0],
            },
            () => {
              if (this.state.tab2) {
                this.getDataForCompare();
              } else if (this.state.tab1) {
                // this.getFirstAnyltics()
              }
            }
          );
      });
  };

  setAllMedium = () => {
    let array = [];
    this.state.mediumList.map((res) => {
      array.push(res.medium_id);
    });
    this.setState({ allMediumSelected: array, selectedMedium: null });
  };

  setAllYears = () => {
    let array = [];
    this.state.yearList.map((res) => {
      array.push(res);
    });
    this.setState({ allYearSelected: array, selectedYear: null });
  };

  setAllStandrs = () => {
    let array = [];
    this.state.classList.map((res) => {
      array.push(res.class_id);
    });
    this.setState({ allClassSelected: array, selectedClass: null });
  };

  getLineGraph = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
    } = this.state;
    console.log(allMediumSelected, "allMediumSelected", allClassSelected);

    let param = {};

    if (this.state.monthDataType === "MONTH_HALF") {
      param = {
        school: school.school_id,
        subject: selectedStage,
        month_list: selectedMonth,
        year_list: allYearSelected ? allYearSelected : [selectedYear],
        class_list: allClassSelected ? allClassSelected : [selectedClass],
        medium_list: allMediumSelected ? allMediumSelected : [selectedMedium],
        month_half_list: this.state.lineSelectedHalf
          ? [this.state.lineSelectedHalf]
          : ["FIRST HALF", "SECOND HALF"],
      };
    } else {
      param = {
        school: school.school_id,
        subject: selectedStage,
        month_list: selectedMonth,
        year_list: allYearSelected ? allYearSelected : [selectedYear],
        class_list: allClassSelected ? allClassSelected : [selectedClass],
        medium_list: allMediumSelected ? allMediumSelected : [selectedMedium],
      };
    }

    getHMService()
      .getTrendlineAnalytics(param)
      .then((res) => {
        console.log(res, "Res");
        const entries = Object.entries(res.data.analytics_level_wise);
        // const counts = Object.entries(res.data.all_counts);
        console.log(entries, "entries");
        if (entries.length > 0) {
          if (entries[0][0] == "प्रारंभिक") {
            console.log("entries1");
            this.setState({
              arrayForLine: entries,
              allCount: res.data.all_counts,
            });
          } else {
            console.log("entries2");
            this.setState({
              arrayForLine: entries,
              allCount: res.data.all_counts,
            });
          }
        } else {
          this.setState({
            arrayForLine: entries,
            allCount: res.data.all_counts,
          });
        }
        // this.setState({ arrayForLine: entries, allCount: res.data.all_counts });
      });
  };

  downloadSaraniExcel = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
    } = this.state;
    console.log(allMediumSelected, "allMediumSelected", allClassSelected);
    let param = {
      school: school.school_id,
      subject: selectedStage,
      month_list: selectedMonth,
      year_list: allYearSelected ? allYearSelected : [selectedYear],
      class_list: allClassSelected ? allClassSelected : [selectedClass],
      medium_list: allMediumSelected ? allMediumSelected : [selectedMedium],
      month_half: this.state.monthDataType === "MONTH_HALF" ? true : false,
      excel_download: true,
    };

    getHMService()
      .getTrendlineAnalyticsForCSV(param)
      .then((res) => {
        console.log(res.data, "res", res.headers["content-disposition"]);
        let contentDisposition = res.headers["content-disposition"];
        console.log(
          "filenmae",
          contentDisposition.substr(
            contentDisposition.indexOf("=") + 1,
            contentDisposition.length
          )
        );
        if (res.data) {
          this.convertJsonToCsv(
            res.data,
            contentDisposition.substr(
              contentDisposition.indexOf("=") + 1,
              contentDisposition.length
            )
          );
        }
      });
  };

  convertJsonToCsv(items, fileTitle) {
    console.log(typeof items, "items");
    var exportedFilenmae = fileTitle || "export.csv";

    var blob = new Blob([items], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  createMonthArray = (res) => {
    if (
      this.state.selectedMonth?.length > 0 &&
      this.state.selectedMonth.includes(res)
    ) {
      this.state.selectedMonth.splice(this.state.selectedMonth.indexOf(res), 1);
    } else {
      this.state.selectedMonth.push(res);
    }
  };

  getCatogeriesForSpline = () => {
    let array = [];
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, ":resp");
        if (typeof resp == "object") {
          array.push(Object.keys(resp));
        }
      });
    });

    console.log(array, "array45454545", array1);
    array = array?.length > 1 ? array[0] : array;
    console.log(array, "array");
    return array;
  };

  getLevels = (id) => {
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, "4545resp");
        if (typeof resp !== "object") {
          array1.push(resp);
        }
      });
    });

    console.log(array1, "array45454545", array1);

    return array1[id];
  };
  getComaparision = () => {
    let array = [];
    let categoriesLabels = [];
    this.state.comapareArray &&
      this.state.comapareArray.map((res) => {
        console.log(res, "{res");
        let obj = {
          type: "column",
          name: res.level,
          data: [
            res.student_district_percentage,
            res.student_block_percentage,
            res.school_percentage,
            res.kendra_percentage,
          ],
        };
        array.push(obj);
        categoriesLabels.push(res.level);
      });

    console.log(array, "array");
    return array.length > 0 ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            categories: categoriesLabels,
            crosshair: true,
          },
          yAxis: {
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "Count",
            },
          },
          tooltip: {
            formatter: function () {
              return this.y + "%";
            },
          },
          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },

          series: this.getSerisForCompareGraph(),

          chart: {
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
          },
        }}
      ></HighchartsReact>
    ) : null;
  };

  getSerisForCompareGraph = () => {
    let arrayData = [];
    let obj1 = {};
    let obj2 = {};
    let obj3 = {};
    let obj4 = {};
    let array1 = [];
    let array2 = [];
    let array3 = [];
    let array4 = [];

    this.state.comapareArray &&
      this.state.comapareArray.map((res, index) => {
        console.log(res, "{res", typeof res.student_block_percentage);
        let districtCount = res.student_district_percentage
          ? res.student_district_percentage
          : 0;
        let blockCount = res.student_block_percentage
          ? res.student_block_percentage
          : 0;
        let schoolCount = res.school_percentage ? res.school_percentage : 0;
        let kendraCount = res.kendra_percentage ? res.kendra_percentage : 0;

        array1.push(districtCount);
        array2.push(blockCount);
        array3.push(kendraCount);
        array4.push(schoolCount);
      });

    obj1 = {
      type: "column",
      name: "District",
      color: "#23208C",
      data: array1,
    };
    obj2 = {
      type: "column",
      name: "Block",
      color: "#A0B745",
      data: array2,
    };
    obj3 = {
      type: "column",
      name: "Kendra",
      color: "#D547E1",
      data: array3,
    };
    obj4 = {
      type: "column",
      name: "School",
      color: "#47A0E1",
      data: array4,
    };
    arrayData.push(obj1, obj2, obj3, obj4);
    console.log(arrayData, "data");
    return arrayData;
  };
  CompareInfo = (res) => {
    this.setState(
      {
        selectedSub: res.subject_id,
      },
      () => [this.getDataForCompare()]
    );

    console.log(res, "res");
  };

  getDataForCompare = () => {
    let param = {};
    if (this.state.monthDataType === "MONTH_HALF") {
      param = {
        school: this.state.school.school_id,
        subject: this.state.selectedSub
          ? this.state.selectedSub
          : this.state.subjectList[0]?.subject_id,
        year: this.state.TulanaSelectedYear
          ? this.state.TulanaSelectedYear
          : 2023,
        month: this.state.TulanaSelectedMonth
          ? this.state.TulanaSelectedMonth
          : "February",
        month_half: this.state.TulanaselectedHalf,
      };
    } else {
      param = {
        school: this.state.school.school_id,
        subject: this.state.selectedSub
          ? this.state.selectedSub
          : this.state.subjectList[0]?.subject_id,
        year: this.state.TulanaSelectedYear
          ? this.state.TulanaSelectedYear
          : 2023,
        month: this.state.TulanaSelectedMonth
          ? this.state.TulanaSelectedMonth
          : "February",
      };
    }

    getHMService()
      .getCompareAnalytics(param)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState({
            comapareArray: res.data.region_level_wise_count,
            avaialbleCount: res.data.total_available_entries,
            levelWiseCount: res.data.total_students_region_wise,
          });
        }
      });
  };
  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: true,
                tab2: false,
                tab3: false,
              },
              () => this.getYears()
            );
          }}
          className={
            this.state.tab1 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          चालू माहिती
        </button>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: false,
                tab2: true,
                tab3: false,
              },
              () => {
                this.getDataForCompare();
                this.getYears();
              }
            );
          }}
          className={
            this.state.tab2 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          तुलना करा
        </button>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: false,
                tab2: false,
                tab3: true,
              },
              () => {
                this.getDataForCompare();
                this.getYears();
              }
            );
          }}
          className={
            this.state.tab3 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          अधिक माहिती पहा
        </button>

        {this.state.tab1
          ? this.currentInfo()
          : this.state.tab2
            ? this.comparision()
            : this.moreInfoTab()}
      </>
    );
  }
}
